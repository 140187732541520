import SelectField from "components/SelectField/SelectField";
import { memo, useCallback } from "react";
import ZoneFormProps from "../ZoneSettingFormProps.props";
import automationTypes, { LIST_TYPE } from "./utilities/ZoneAutomation.data";

export default memo(function ZoneAutomation({
    formData,
    setFormData,
}: ZoneFormProps): JSX.Element {
    const handleMenuChange = useCallback(
        (data: LIST_TYPE): void => {
            const newForm = { ...formData };
            const automation = data === LIST_TYPE.ON;
            newForm.enableAutomation = automation;
            setFormData(newForm);
        },
        [formData],
    );

    return (
        <div className="grid grid-cols-12 gap-4 mb-16 relative">
            <div className="col-span-6">
                <p className="pl-4 text-sm">Automation for this Zone</p>
            </div>
            <div className="col-span-6 relative">
                <div className="flex flex-col relative">
                    <SelectField
                        title={
                            formData.enableAutomation
                                ? "On: Automatically Update HVAC Setpoints"
                                : "Off: Generate Recommendations ONLY"
                        }
                        list={automationTypes}
                        onChange={handleMenuChange}
                    />
                </div>
            </div>
        </div>
    );
});
