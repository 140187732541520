import { useQuery } from "@tanstack/react-query";
import Spinner from "components/Icons/Spinner/Spinner";
import EventLogsTable from "components/Tables/EventLogsTable/EventLogsTable";
import { memo, useEffect, useState } from "react";
import DeviceService from "services/DeviceService";
import rootStore from "stores/rootStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import DeviceLog from "types/DeviceLog.type";
import CurrentDeviceCardContentCurrentProps from "../utilities/CurrentDeviceCardContentCurrent.props";
import LogRowItem from "./LogRowItem";

export default memo(function CurrentDeviceCardContentLogs({
    device,
}: CurrentDeviceCardContentCurrentProps): JSX.Element {
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);
    const {
        data: deviceLogs,
        isLoading, // for initial load
        isError,
        error,
    } = useQuery({
        queryKey: ["deviceLogs", device._id],
        queryFn: () => DeviceService.getDeviceLogs(device._id),
        refetchInterval: 3000,
        refetchIntervalInBackground: false,
    });

    useEffect(() => {
        //Check if initial load is complete
        if (!initialLoadComplete && !isLoading && !isError) {
            setInitialLoadComplete(true);
        }
    }, [initialLoadComplete, isLoading, isError]);

    if (isError) {
        console.error(error);
        rootStore.snackbarStore.enqueueSnackbar?.(
            `Failed to retrieve logs for ${device.headers.deviceName}`,
            {
                variant: "error",
            },
        );
    }

    return (
        <div className="h-full flex flex-col">
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>History</h2>
            </div>
            <div className={CLASS_NAMES.drawer.logsContainer}>
                {isLoading && !initialLoadComplete ? (
                    <div className="flex flex-row items-center h-min mx-16">
                        {" "}
                        <Spinner variant="spinner-third" size={"sm"} />
                        <p className="px-4 py-2">Loading</p>{" "}
                    </div>
                ) : deviceLogs && deviceLogs.length > 0 ? (
                    <DeviceLogsTable logs={deviceLogs} />
                ) : (
                    <p className="py-4 px-12 h-min">
                        There are no logs available for this device.
                    </p>
                )}
            </div>
        </div>
    );
});

const DeviceLogsTable = memo(
    ({ logs }: { logs: DeviceLog[] }): JSX.Element => (
        <EventLogsTable>
            {logs.map((props, index) => (
                <LogRowItem key={props.event_id + String(index)} {...props} />
            ))}
        </EventLogsTable>
    ),
);
