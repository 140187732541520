import { memo } from "react";
import type CurrentRackShelfItemProps from "./utilities/CurrentRackShelfItem.props";

export default memo(function CurrentRackShelfItem({
    label,
    value,
}: CurrentRackShelfItemProps): JSX.Element {
    return (
        <li className="flex w-full mb-1 pt-1">
            <span className="text-sm mr-1">{label}:</span>
            <span className="text-sm">{value}</span>
        </li>
    );
});
