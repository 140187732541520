import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import DeviceIconSize from "components/DeviceIcon/utilities/DeviceIconSize.enum";
import RowStyleT from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/RowStyle.props";
import setTableRowStyle from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/setTableRowStyleReduce";
import { memo, useEffect, useMemo, useReducer } from "react";
import extendClassName from "utils/extendClassName";
import DeviceRowItemProps from "./DeviceRowItemProps.props";

const initialState: RowStyleT = {
    border: "shadow-inner-offline shadow-md",
    background: "bg-white",
};

export default memo(function DeviceRowItem({
    device,
    onClickOfDevice,
}: DeviceRowItemProps): JSX.Element {
    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialState,
    );

    const { _id, headers } = device;
    const image = device.display.image;
    const offline = device.state.offline;
    const overallHealth = device.deviceHealth.overAllHealth;
    /**
     * EVENT_TYPE and HEALTH_STATUS are essentially the same,
     * the difference being that health status are usually live data while
     * events are health status that occured at the time of the event.
     */
    const health: string = useMemo(() => {
        return offline ? "offline" : overallHealth;
    }, [offline, overallHealth]);

    useEffect(
        () =>
            dispatch({
                event_type: health,
            }),
        [health],
    );
    return (
        <tr
            className={extendClassName(
                "h-5 border-gray-700 border-l-0 border-r-0 border-t-1 first:border-t-0 last:border-b-0 transition-colors hover:bg-gray-800 hover:bg-opacity-30 hover:bg-opacity-10 duration-300 cursor-pointer text-obsidian bg-white has-ignoreClass-device-drawer",
                tableRowStyle.border,
                tableRowStyle.background,
            )}
            onClick={(): void => onClickOfDevice(_id)}
        >
            {/* STATUS */}
            <td className="pl-4 capitalize py-0 h-full border-r-1 border-gray-700">
                {health}
            </td>
            {/* Type */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700">
                <span className="flex justify-center">
                    <DeviceIcon
                        headers={headers}
                        overallHealth={overallHealth}
                        offline={offline}
                        image={image}
                        containerSize={DeviceIconSize.XSMALL}
                    />
                </span>
            </td>
            {/* Device */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700 truncate">
                {" "}
                {headers.deviceName}
            </td>
            {/* Manufacturer */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700 truncate">
                {headers.manufacturer}
            </td>

            {/* SubLocation */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700 truncate">
                {" "}
                {headers.sublocation}
            </td>
            {/* Sensor Location */}
            <td className="px-4 py-0 h-full truncate">
                {" "}
                {headers.sensorLocation}
            </td>
        </tr>
    );
});
