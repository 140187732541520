import DeviceHeadersWithOptionalFields from "types/FormTypes/DeviceHeaderFields";
import * as Yup from "yup";

const deviceHeadersSchema: Yup.ObjectSchema<DeviceHeadersWithOptionalFields> =
    Yup.object()
        .shape({
            deviceName: Yup.string().required("Device name is required"),
            generalPosition: Yup.string(),
            manufacturer: Yup.string(),
            manufacturerId: Yup.string(),
            dataType: Yup.string().required("Device measurement is required"),
            deviceDescription: Yup.string(),
            location: Yup.string(),
            sublocation: Yup.string(),
            sensorLocation: Yup.string(),
            timeToLive: Yup.number()
                .transform((value, originalValue) => {
                    if (originalValue === "" || originalValue == null) {
                        return undefined;
                    }
                    // Check if the original value is a string and is a valid number (no decimal)
                    if (
                        typeof originalValue === "string" &&
                        !/^\d*$/.test(originalValue)
                    ) {
                        return NaN; // Return NaN to trigger the typeError for strings that aren't numbers
                    }
                    return parseFloat(originalValue); // Parse the value as a float for valid number-like strings
                })
                .typeError("Time to Live must be a valid number")
                .positive("Time to Live must be a positive number")
                .test(
                    "is-not-zero",
                    "Time to Live cannot be zero",
                    (value) => value !== 0,
                )
                .required("Time to Live is required"),
        })
        .shape({
            lastUpdated: Yup.string(),
        });

export default deviceHeadersSchema;
