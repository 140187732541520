import { memo, useCallback, useState } from "react";
import extendClassName from "utils/extendClassName";
import { FleetSort } from "../utilities/FleetColHeaders.data";
import FleetSortCol from "../utilities/FleetSortCol";
import FLEET_SORT_OPTIONS from "../utilities/FleetSortOptions.enum";
import type FleetControlsProps from "./utilities/FleetControls.props";
import selectBoldArrow from "./utilities/selectBoldArrow.utils";

interface HoverState {
    [key: string]: boolean;
}

export default memo(function FleetControls({
    setSortOption,
    reverse,
    options,
    initialSort,
}: FleetControlsProps): JSX.Element {
    /**There is a useSortableTable hook available to replace this code. */
    const [columnHeader, setColumnHeader] = useState<FleetSortCol>(initialSort);
    const [isHovering, setHover] = useState<HoverState>({});

    const isColumnSelected = useCallback(
        (type: FLEET_SORT_OPTIONS) => columnHeader.type === type,
        [columnHeader.type],
    );

    const handleHover = (title: string): void => {
        setHover((previousState: HoverState) => ({
            ...previousState,
            [title]: !previousState[title],
        }));
    };

    const arrowType = useCallback(
        (type: FLEET_SORT_OPTIONS): JSX.Element => {
            const isHoveringAndSelected =
                isHovering[type]?.valueOf() && isColumnSelected(type);
            const isSelected = isColumnSelected(type);
            const ascending = columnHeader.ascending;

            return selectBoldArrow(
                isHoveringAndSelected
                    ? !ascending
                    : isSelected
                    ? ascending
                    : true,
                isSelected,
            );
        },
        [columnHeader, isColumnSelected, selectBoldArrow, isHovering],
    );

    const handleSetSort = useCallback(
        ({ title, type }: FleetSort) => {
            const isSameType = isColumnSelected(type);
            const newAscending = isSameType ? !columnHeader.ascending : true;

            setColumnHeader({ title, type, ascending: newAscending });
            setSortOption(type, !reverse);
        },
        [columnHeader, isColumnSelected, setSortOption, reverse],
    );

    const colSpan = useCallback(
        (header: string): string =>
            header === "Type" || header === "Status"
                ? "w-[6%]"
                : header === "Device Name"
                ? "w-[40%]"
                : "w-[16%]",
        [],
    );

    return (
        <thead className="h-5 w-full sticky top-0 right-0 left-0 border-gray-700">
            <tr className="h-5 text-left font-normal bg-gray-100 shadow-inner-obsidian">
                {options.map(({ title, type }) => (
                    <th
                        key={title}
                        onClick={(): void => handleSetSort({ title, type })}
                        onMouseOver={(): void => handleHover(type)}
                        onMouseOut={(): void => handleHover(type)}
                        className={extendClassName(
                            "rounded-sm group cursor-pointer px-4 py-0 h-full border-r-1 border-gray-700 ",
                            colSpan(title),
                        )}
                    >
                        <span
                            className={extendClassName(
                                "w-min p-0 h-min group flex flex-row my-auto",
                                title === "Type" ? "ml-4" : "",
                            )}
                        >
                            {title}{" "}
                            {isColumnSelected(type) && (
                                <button className="w-min pl-1 mx-1 group-hover:visible">
                                    {arrowType(type)}
                                </button>
                            )}
                        </span>
                    </th>
                ))}
            </tr>
        </thead>
    );
});
