import M1Logo from "components/Icons/M1Logo/M1Logo";
import { memo } from "react";
import { Link } from "react-router-dom";

export default memo(function AppBarHouse(): JSX.Element {
    return (
        <Link
            to="/home"
            className="w-[92px] min-w-[65px] lg:min-w-[92px] px-3 my-1 border-r-1 border-gray-700 flex hover:text-white-main"
        >
            <M1Logo />
        </Link>
    );
});
