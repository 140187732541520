import Icon from "components/Icons/Icon/Icon";
import useMouseOver from "hooks/useMouseOverListener";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import rootStore from "stores/rootStore";
import { CLASS_NAMES, MOBILE_CLASS_NAMES } from "styles/classNameConstants";
import baseClassNavigationTabs from "utils/baseClassNavigation";
import extendClassName from "utils/extendClassName";
import LEFT_DRAWER_TAB from "../utilities/LEFT_DRAWER_TAB.enum";
import LeftNavDrawerTab from "./utilities/LeftNavDrawerTab.props";

export default observer(function LeftNavDrawerTab({
    tabType,
    icon,
    label,
    currentTab,
    isDrawerOpen,
    setCurrentTab,
    setDrawerOpen,
}: LeftNavDrawerTab): JSX.Element {
    const [ref, setRef] = useState<HTMLLIElement | null>(null);

    const toggleDrawerSettings = useCallback(
        (isOpen: boolean, tabType: LEFT_DRAWER_TAB | null): void => {
            setDrawerOpen(isOpen);
            setCurrentTab(tabType);
        },
        [],
    );
    const { mousedOver } = useMouseOver(ref);

    return (
        <li
            ref={(ref): void => setRef(ref)}
            key={tabType}
            data-title={label}
            className={extendClassName(
                "h-[80px] relative text-center duration-300 transition-colors group flex flex-col justify-center",
                baseClassNavigationTabs(currentTab, tabType, isDrawerOpen),
                tabType === LEFT_DRAWER_TAB.LOGOUT ? "mt-auto" : "py-4",
                rootStore.viewportStore.isMobile
                    ? MOBILE_CLASS_NAMES.iconTabs.icon
                    : "w-[91px]",
            )}
            onClick={(): void => {
                tabType !== currentTab
                    ? toggleDrawerSettings(true, tabType)
                    : tabType === currentTab
                    ? toggleDrawerSettings(false, null)
                    : null;
            }}
        >
            <span className="mx-auto">
                <Icon
                    name={icon}
                    fill={
                        tabType === currentTab || mousedOver
                            ? "#141e26"
                            : "#626262"
                    }
                    size={rootStore.viewportStore.isMobile ? "sm" : "md"}
                />
            </span>
            <p
                className={extendClassName(
                    CLASS_NAMES.iconTabs.text,
                    tabType === currentTab || mousedOver
                        ? "font-semibold"
                        : CLASS_NAMES.iconTabs.onHoverText,
                )}
            >
                {label}
            </p>
        </li>
    );
});
