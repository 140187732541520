import FLEET_SORT_OPTIONS from "./FleetSortOptions.enum";

export interface FleetSort {
    title: string;
    type: FLEET_SORT_OPTIONS;
}

const fleetColHeaders: FleetSort[] = [
    {
        title: "Status",
        type: FLEET_SORT_OPTIONS.STATUS,
    },
    {
        title: "Type",
        type: FLEET_SORT_OPTIONS.TYPE,
    },
    {
        title: "Device Name",
        type: FLEET_SORT_OPTIONS.NAME,
    },
    {
        title: "Manufacturer",
        type: FLEET_SORT_OPTIONS.MANUFACTURER,
    },
    {
        title: "Sublocation",
        type: FLEET_SORT_OPTIONS.SUBLOCATION,
    },
    {
        title: "Sensor Location",
        type: FLEET_SORT_OPTIONS.SENSOR_LOCATION,
    },
];

export default fleetColHeaders;
