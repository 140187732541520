import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import EventTimeCol from "components/Tables/Columns/EventTimeCol/EventTimeCol";
import { memo, useCallback, useEffect, useReducer, useState } from "react";
import extendClassName from "utils/extendClassName";
import { initialLogState } from "utils/initialStates";
import ZoneLogsRowItemProps from "./utilities/ZoneSimpleLogsRowItem.props";
import setTableRowStyle from "./utilities/setTableRowStyleReduce";

export default memo(function ZoneSimpleLogsRowItem({
    message,
    event_time,
    event_id,
    itemID,
    event_type,
    log_type,
}: ZoneLogsRowItemProps): JSX.Element {
    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialLogState,
    );
    const [deviceStatus, setDeviceStatus] = useState<
        HEALTH_STATUS | "offline"
    >();

    const [isOpen, setIsOpen] = useState(false);

    //Handles the opening and closing of the click-to-expand area
    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        const event = message.includes("offline")
            ? "offline"
            : (event_type as HEALTH_STATUS);

        dispatch({ health: event, type: log_type });
    }, [event_type, log_type]);

    /**
     * Device status can change over time.
     */
    useEffect(() => {
        const event = message.includes("offline")
            ? "offline"
            : (event_type as HEALTH_STATUS);
        setDeviceStatus(event || "offline");
    }, [event_id, event_type, message]);

    return (
        <>
            <tr
                className={extendClassName(
                    "text-sm bg-white border-1 border-gray-700 h-[70px] w-full",
                    tableRowStyle.background,
                )}
                onClick={(): void => handleToggle()}
                data-id={event_id}
                key={itemID}
            >
                <td
                    key="status"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 capitalize font-semibold",
                        tableRowStyle.border,
                        tableRowStyle.background,
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    {deviceStatus}
                </td>
                <td
                    key="message"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 cursor-pointer",
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    <div className=" truncate-2-lines">{message}</div>
                </td>

                <EventTimeCol eventTime={event_time} drawerIsOpen={isOpen} />
            </tr>
            {isOpen ? (
                <tr className="text-sm bg-white border-1 border-gray-700 h-min w-full">
                    <td className="p-4 shadow-inner-md" colSpan={12}>
                        {(message.length >= 96 && (
                            <p className="mb-1">{message}</p>
                        )) || <p>There are no additional messages.</p>}
                    </td>
                </tr>
            ) : null}
        </>
    );
});
