import getFeatureStatus from "stores/classes/Openlayers/utils/getFeatureStatus";
import { getSublocationOverlayStyle } from "./getSublocationOverlayStyle";
import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import { FeatureUpdateFunction } from "stores/classes/Openlayers/utils/FeatureUpdateFunctions.util";

/**
 * Updates SublocationOverlay style and status. SublocationOverlay cannnot change coordinates yet so this is exluded.
 */
const updateSublocationOverlayFeature: FeatureUpdateFunction<
    LocationMapOverlayDetail
> = async (sublocationOverlay, map, state, existingFeature) => {
    const status = await getFeatureStatus(
        "SublocationOverlay",
        sublocationOverlay,
    );

    return {
        properties: {
            ...sublocationOverlay,
            featureType: "SublocationOverlay",
            layerName: "SublocationOverlay",
        },
        style: getSublocationOverlayStyle({
            feature: existingFeature,
            status,
            state,
        }),
    };
};

export default updateSublocationOverlayFeature;
