import type { Map } from "ol";
import { useEffect, useRef } from "react";
import FeatureManager from "stores/classes/Openlayers/FeatureManager.class";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";

interface FeatureTrigger {
    active: boolean;
    featureId: string | null;
    newState: FeatureInteractiveState;
}

interface useUpdateFeatureOnExternalTrigger {
    featureManager: FeatureManager | null;
    map: Map | null;
    trigger: FeatureTrigger;
}

//2038 - it's happening because currentDevice is not always clearing or being set to null

/**
 * Passes a trigger event (and probably should also pass the necessary state) in order to the last interacted with feature's style.
 * A good improvement would be able to specify the feature to be updated, but the component does not currently have access to such data.
 * @param
 */
export default function useUpdateFeatureOnExternalTrigger({
    featureManager,
    map,
    trigger,
}: useUpdateFeatureOnExternalTrigger): void {
    const currentlySelectedFeatureIdRef = useRef<string | null>(null);

    /***
     * Checks if there is a selected feature (based on trigger.featureId)
     * and updates its style to the specified state (trigger.newState).
     * This ensures that the correct feature is set to selected state when navigating
     * to a page where a zone or device is already selected.
     */
    useEffect(() => {
        if (!featureManager || !map) return;

        if (trigger.featureId) {
            featureManager.updateFeatureStyle(
                trigger.featureId,
                trigger.newState,
            );
            currentlySelectedFeatureIdRef.current = trigger.featureId;
        }
    }, [featureManager, map, trigger.featureId, trigger.newState]);

    /**
     * It handles the case when the selected feature changes or when the
     * trigger becomes inactive (i.e., no feature should be selected).
     */
    useEffect(() => {
        if (!featureManager || !map) return;
        if (trigger.active) {
            // If the new trigger.featureId is different from the currently selected feature,
            if (trigger.featureId) {
                if (currentlySelectedFeatureIdRef.current) {
                    featureManager.updateFeatureStyle(
                        currentlySelectedFeatureIdRef.current,
                        "default",
                    );
                }
                // Update the style of the triggered feature to the specified state
                featureManager.updateFeatureStyle(
                    trigger.featureId,
                    trigger.newState,
                );
                currentlySelectedFeatureIdRef.current = trigger.featureId;
            }
        } else {
            // If the trigger becomes inactive, deselect the currently selected feature
            if (currentlySelectedFeatureIdRef.current) {
                featureManager.updateFeatureStyle(
                    currentlySelectedFeatureIdRef.current,
                    "default",
                );
                currentlySelectedFeatureIdRef.current = null;
            }
        }
    }, [
        trigger.featureId,
        trigger.active,
        trigger.newState,
        featureManager,
        map,
    ]);
}
