import MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import MultiRangeSafeValSchema from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MULTI_RANGE_SAFE_VAL_DIMENSION.schema";
export default function isValidMultiRangeSafeVal(dimension?: {
    multiRangeSafeVal?: MultiRangeSafeVal;
}): boolean {
    const configuration = dimension?.multiRangeSafeVal || null;

    if (configuration) {
        const { error } = MultiRangeSafeValSchema.validate(dimension);
        const valid = error == null;
        if (valid) {
            return true;
        }
    }
    return false;
}
