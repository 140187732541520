/**
 * Converts number value (assumed to be celcius) to farenheight
 * @param {number} celsius
 * @returns {number}
 */
export function celsiusToFahrenheit(celsius: number): number {
    return (celsius * 9) / 5 + 32;
}

/**
 * Converts number value (assumed to be farenheight) to celsius
 * @param {number} fahrenheit
 * @returns {number}
 */
export function fahrenheitToCelsius(fahrenheit: number): number {
    return ((fahrenheit - 32) * 5) / 9;
}
