import EVENT_TYPE from "types/EVENT_TYPE";
import RowStyleT from "./RowStyle.props";

export default function setTableRowStyle(
    tableRowStyle: RowStyleT,
    { event_type }: { event_type: string },
): RowStyleT {
    const health_type = event_type as EVENT_TYPE;
    switch (health_type as EVENT_TYPE) {
        case EVENT_TYPE.AUTOMATION:
            return {
                ...tableRowStyle,
                border: "shadow-inner-ocean",
            };
        case EVENT_TYPE.RECOMMENDATION:
            return {
                background: "bg-warning/[0.15]",
                border: " shadow-inner-warning",
            };
        case EVENT_TYPE.WARNING:
            return {
                background: "bg-warning/[0.15]",
                border: " shadow-inner-warning",
            };
        case EVENT_TYPE.UNSAFE:
            return {
                background: "bg-unsafe/[0.15]",
                border: " shadow-inner-unsafe",
            };
        case EVENT_TYPE.SAFE:
            return {
                ...tableRowStyle,
                border: " shadow-inner-safe",
            };
        default:
            return {
                ...tableRowStyle,
                border: "shadow-inner-offline",
            };
    }
}
