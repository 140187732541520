import { observer } from "mobx-react-lite";
import rootStore from "stores/rootStore";
import LEFT_DRAWER_TAB from "../LeftDrawerTabs/utilities/LEFT_DRAWER_TAB.enum";
import Analytics from "./Analytics/Analytics";
import Locations from "./Locations/Locations";
import type LeftDrawerContentProps from "./utilities/LeftDrawerContent.props";

export default observer(function LeftDrawerContent({
    organizationStore,
    currentTab,
    setCurrentTab,
    isDrawerOpen,
    setDrawerOpen,
    setDrawerItemClicked,
}: LeftDrawerContentProps) {
    if (currentTab === LEFT_DRAWER_TAB.LOGOUT) rootStore.userStore.logout();
    return (
        <div className="flex flex-col h-full flex-1 sm:w-[22rem] bg-gray-100 border-l-1 border-gray-200">
            <div className="bg-[#414141] h-[44px] w-full text-left p-2 capitalize text-lg font-nunito text-white flex items-center">
                {currentTab}
            </div>
            <div className="h-full">
                {currentTab === LEFT_DRAWER_TAB.LOCATIONS ? (
                    <Locations
                        setCurrentTab={setCurrentTab}
                        currentTab={currentTab}
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        setDrawerItemClicked={setDrawerItemClicked}
                        organizationStore={organizationStore}
                    />
                ) : currentTab === LEFT_DRAWER_TAB.ANALYTICS ? (
                    <Analytics
                        setCurrentTab={setCurrentTab}
                        organizationStore={organizationStore}
                        setDrawerOpen={setDrawerOpen}
                        setDrawerItemClicked={setDrawerItemClicked}
                    />
                ) : null}
            </div>
        </div>
    );
});
