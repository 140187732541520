function createInitials(name: string): string {
    // Split the name by both space and hyphen
    const nameParts = name.split(/[\s-]+/);

    // Take the first character of each part, uppercase it, and join them together
    const initials = nameParts.map((part) => part[0].toUpperCase()).join("");

    return initials;
}

export default createInitials;
