import { QueryClient } from "@tanstack/react-query";
import DeviceUpdateMessage, {
    isDeviceUpdateMessage,
} from "hooks/useWebsockets/utilities/DeviceUpdateMessage.type";
import findProblemsWithDevice from "hooks/useWebsockets/utilities/findProblemsWithDevice";
import { action, makeAutoObservable, observable, runInAction } from "mobx";
import { BASE_WS_URL } from "services";
import DeviceService from "services/DeviceService";
import propogateDeviceChangesToMap from "utils/propagateDeviceChangesToMap";
import NotificationStore from "./classes/NotificationStore.class";
import OpenLayersStore from "./classes/Openlayers/OpenLayersStore.class";
import setDeepValue from "utils/setDeepValue";

class WebsocketStore {
    queryClient: QueryClient;
    notificationStore: NotificationStore;
    openLayersStore: OpenLayersStore;

    @observable isConnecting = false;

    @observable
    connected = false;
    /**
     * The WebSocket.readyState read-only property returns the current state of the WebSocket connection.
     *   0	CONNECTING	Socket has been created. The connection is not yet open.
     *   1	OPEN	    The connection is open and ready to communicate.
     *   2	CLOSING	    The connection is in the process of closing.
     *   3	CLOSED 	    The connection is closed or couldn't be opened.
     */
    @observable
    readyState = 0;

    @observable
    subscribed = new Set<string>();

    @observable
    private websocket: WebSocket | null = null;

    constructor(
        queryClient: QueryClient,
        notificationStore: NotificationStore,
        openLayersStore: OpenLayersStore,
    ) {
        makeAutoObservable(this);

        this.queryClient = queryClient;
        this.notificationStore = notificationStore;
        this.openLayersStore = openLayersStore;
    }

    /**
     * Sets the connection status. It is better to have this tracked as an action,
     * rather than doing this.connected = ...
     * see https://mobx.js.org/actions.html
     * @param connected boolean
     */
    @action
    private setConnection = (connected: boolean): void => {
        this.connected = connected;
    };

    @action
    private setSubscribed = (set: Set<string>): void => {
        this.subscribed = set;
    };
    /**This updates the readyState available to the class variable itself,
     * with the websocket class readyState
     * There should be no need to make this public */
    @action
    private setReadyState = (): void => {
        if (this.websocket) {
            this.readyState = this.websocket.readyState;
        }
    };

    @action
    private handleDeviceWebSocketMessage = async (
        message: DeviceUpdateMessage,
    ): Promise<void> => {
        try {
            const queryKey = ["device", message.deviceId];

            //Used to get an existing query's cached data. If the query does not exist, `fetchQuery` will be
            //called and it's results returned.
            const device = await this.queryClient.ensureQueryData({
                queryKey,
                queryFn: () => DeviceService.getDevice(message.deviceId),
            });

            if (!device) {
                throw new Error(
                    "Device information not found for the provided device ID",
                );
            }

            // Create a new reference for the updated device so that reactive
            // components will update. If we set the properties directly on the
            // device without changing the reference, components watching the
            // device will not register the change and will not update.
            const updatedDevice = { ...device };

            // Set updated properties on device. Some update keys may be
            // dot-delimited nested properties, such as state.offline,
            // so we need to set them with setDeepValue instead of setting
            // them directly on the object
            for (const [key, value] of Object.entries(message.updates))
                setDeepValue(updatedDevice, value, key);

            // Calculate latest health data
            const deviceWithUpdatedHealth =
                findProblemsWithDevice(updatedDevice);

            //Update device reference in the cache.
            this.queryClient.setQueryData(queryKey, deviceWithUpdatedHealth);

            //I don't like this change being here.
            //This will determine if there are zones on the map, and if so,
            //Propagate the deviceHealth status to related zones
            // OR if there are no zones, just the device remains updated
            // It is meant to keep overall zonehealth on map
            // in sync with the cumulative health of the devices it contains
            runInAction(() =>
                propogateDeviceChangesToMap({
                    updatedDevice: deviceWithUpdatedHealth,
                    updateFeature: this.openLayersStore.updateFeature,
                    mapViewType: this.openLayersStore.mapType,
                }),
            );
        } catch (error) {
            console.error(
                `Error handling device websocket message for ${message.deviceId}:`,
                error,
            );
        }
    };

    public subscribe = (deviceId: string): void => {
        if (this.subscribed.has(deviceId)) {
            console.warn(`already subscribed to ${deviceId}, skipping...`);
            return;
        }
        if (this.websocket && this.readyState === WebSocket.OPEN) {
            const subscribeMessage = JSON.stringify({
                subscribing: true,
                device_id: deviceId,
            });
            this.websocket.send(subscribeMessage);
            this.subscribed.add(deviceId);
        }
    };

    /**
     * Sends websocket message to unsubscribe from a specific device via it's id.
     * Updates the subscribed set by deleting the id.
     */
    public unsubscribe = (deviceId: string): void => {
        if (this.websocket && this.readyState === WebSocket.OPEN) {
            const unsubscribeMessage = JSON.stringify({
                subscribing: false,
                device_id: deviceId,
            });
            this.websocket.send(unsubscribeMessage);
            const updated = new Set(this.subscribed);
            updated.delete(deviceId);
            this.setSubscribed(updated);
        }

        // Indicate to the query cache that data for the device will now be stale
        // because we are no longer receiving updates
        this.queryClient.invalidateQueries({
            queryKey: ["device", deviceId],
            exact: true,
            refetchType: "none",
        });
    };

    public unsubscribeFromAll = (): void => {
        if (this.websocket && this.readyState === WebSocket.OPEN) {
            this.subscribed.forEach((deviceId) => {
                const unsubscribeMessage = JSON.stringify({
                    subscribing: false,
                    device_id: deviceId,
                });
                this.websocket!.send(unsubscribeMessage);

                // Indicate to the query cache that data for the device will now be stale
                // because we are no longer receiving updates
                this.queryClient.invalidateQueries({
                    queryKey: ["device", deviceId],
                    exact: true,
                    refetchType: "none",
                });
            });
            this.setSubscribed(new Set<string>());
        }
    };

    /**
     * unsubscribe locally from all the devices NOT in the provided deviceIds argument
     * and subscribe to the new ones.
     */
    public clearAndSubscribe = (ids: string[]): void => {
        try {
            const hash = new Set<string>([...ids]);
            // Unsubscribe from all devices that are not in deviceIds or mobileDevices
            // Because unsubscribe appears to modify the length of this.subscribed, we want to
            // iterate over the array in reverse order. Even if some elements are removed from the array, it won't
            // affect the remaining elements
            this.subscribed.forEach((id) => {
                if (!hash.has(id)) {
                    this.unsubscribe(id);
                }
            });
            //Subscribe to new elements
            hash.forEach((id) => {
                if (!this.subscribed.has(id)) {
                    this.subscribe(id);
                }
            });
        } catch (error) {
            console.error(
                "There was a problem updating device subscriptions",
                error,
            );
        }
    };

    /**
     * Checks if a deviceId is currently in the subscribed list
     */
    @action
    public isSubscribed = (deviceId: string): boolean => {
        return this.subscribed.has(deviceId);
    };

    /**
     * disconnect from the web server with WebSocket.close()
     */
    @action
    public disconnect(): void {
        if (this.websocket) {
            this.websocket.onopen = null;
            this.websocket.onclose = null;
            this.websocket.onerror = null;
            this.websocket.onmessage = null;
            this.websocket.close();
        }
    }

    /**
     * connects the websocket to the webserver, and handles reconnects
     * takes the jwt token as an argument along with the websocket endpoint to use
     *
     * lets the user know if we encountered an error with @see snackbarStore.enqueueSnackbar
     *
     * when we get a new message, push it into @see _queue
     */
    @action
    public connect(endpoint: string, token: string): void {
        if (this.isConnecting) {
            console.log("WebSocket connection already in progress.");
            return;
        }

        this.isConnecting = true;

        // Close existing connection if open
        if (this.websocket && this.websocket.readyState === WebSocket.OPEN) {
            this.websocket.close();
        }

        // Setup a new WebSocket connection
        this.setupWebSocket(endpoint, token);
    }

    private setupWebSocket(endpoint: string, token: string): void {
        this.websocket = new WebSocket(
            BASE_WS_URL + endpoint + `?token=${token}`,
        );
        this.websocket.onopen = action(() => {
            this.setConnection(true);
            this.setReadyState();
            this.isConnecting = false;
        });
        this.websocket.onmessage = action((event): void => {
            try {
                // Handle incoming data
                const message = JSON.parse(event.data);
                if (isDeviceUpdateMessage(message))
                    this.handleDeviceWebSocketMessage(message);
            } catch (e) {
                console.error("ignoring message from server error: %s", e);
            }
        });

        this.websocket.onerror = action((event) => {
            console.error("WebSocket error: ", event);
            this.setConnection(false);
            this.setReadyState();
            this.isConnecting = false;
            this.connect(endpoint, token);
        });
        this.websocket.onclose = action(() => {
            this.setConnection(false);
            this.setReadyState();
            this.isConnecting = false;
        });

        setTimeout(
            action(() => {
                if (this.websocket?.readyState !== WebSocket.OPEN) {
                    console.log("WebSocket connection timed out.");
                    this.websocket?.close();
                    this.isConnecting = false;
                }
            }),
            5000,
        );
    }
}

export default WebsocketStore;
