import Button from "components/Button/Button";
import M1LogoTransparent from "components/Icons/M1LogoTransparent/M1LogoTransparent";
import Spinner from "components/Icons/Spinner/Spinner";
import { KeycloakAuthState } from "hooks/useKeycloak";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import type RootProps from "./utilities/Root.props";

export default observer(function Root({
    userStore,
    initializationFailed,
    authState,
    connectingToKeyCloak,
}: RootProps): JSX.Element {
    const navigate = useNavigate();

    useEffect(() => {
        if (userStore._id) {
            navigate("/home");
        }
    }, [navigate, userStore._id]);

    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-white text-[#3C3C3C] font-sans text-center">
            <div className="w-10 mb-4">
                <M1LogoTransparent />
            </div>
            <div className="border-red my-4">
                <Spinner variant="spinner-third" fill="#A3ACB7" />
            </div>
            {connectingToKeyCloak ? (
                <p> Connecting to authorization provider... </p>
            ) : initializationFailed ? (
                <>
                    <p className="text-red my-4">Connection Failed</p>
                    <p>
                        Failed to establish connection with the authorization
                        server. Please refresh and try again.
                        <br /> <br />
                        If the problem persists, please contact SRT support.
                    </p>
                </>
            ) : authState === KeycloakAuthState.NOT_AUTHORIZED ? (
                <>
                    <p className="text-red my-4">Connection Failed</p>
                    <p>
                        User unauthorized. If you feel like this is a mistake,
                        please contact SRT support{" "}
                    </p>
                </>
            ) : authState === KeycloakAuthState.UNKNOWN ? (
                <p> Loading... </p>
            ) : (
                <p> Redirecting to Home... </p>
            )}
            {initializationFailed ? (
                <div className="my-4">
                    <Button
                        variant="secondary"
                        onClick={(): void => window.location.reload()}
                    >
                        Refresh
                    </Button>
                </div>
            ) : null}
        </div>
    );
});
