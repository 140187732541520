import { memo } from "react";
import ErrorDrawerProps from "./utilities/ErrorDrawer.props";

export default memo(function ErrorDrawer({
    message = "An error has occurred. Please refresh and try again.",
}: ErrorDrawerProps): JSX.Element {
    return (
        <div className="flex flex-row justify-center p-4 h-full w-full bg-gray-100 text-obsidian">
            <div className="w-1/2 flex flex-col justify-center">
                <h2 className="text-center">{message}</h2>
            </div>
        </div>
    );
});
