import DeviceDisplayExecutionFunctions from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayExecutionFunctions/DeviceDisplayExecutionFunctions.type";
import { action, makeObservable, observable, runInAction } from "mobx";
import DeviceService from "services/DeviceService";

/**
 * holds the execution ID for reactivity
 */
export default class ExecutablesStore {
    /**
     * A list of total possible executions
     */
    @observable
    executionFunctions: DeviceDisplayExecutionFunctions = [];

    @action
    public async getExecutionFunctions(deviceID: string): Promise<void> {
        try {
            const executions = await runInAction(() =>
                DeviceService.getExecutionFunctions(deviceID),
            );
            if (executions) {
                runInAction(() => {
                    this.executionFunctions = executions;
                });
            }
        } catch (e) {
            console.log(e);
            throw new Error("unable to retrieve device executions");
        }
    }

    public constructor() {
        makeObservable(this);
    }
}
