import Icon from "components/Icons/Icon/Icon";
import CurrentTab from "components/TabListItem/CurrentTab.type";
import TabListItem from "components/TabListItem/TabListItem";
import useQuery from "hooks/useQuery/useQuery";
import { memo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import CurrentDeviceCardTabsProps from "./utilities/CurrentDeviceCardTabs.props";
import { default as DEVICE_DRAWER_TABS } from "./utilities/DEVICE_DRAWER_TABS.enum";
import DEVICE_DRAWER_TABS_VALUES from "./utilities/DEVICE_DRAWER_TABS_VALUES.data";

export default memo(function CurrentDeviceCardTabs({
    currentTab,
    setCurrentTab,
}: CurrentDeviceCardTabsProps): JSX.Element {
    const { setParams } = useQuery();
    return (
        <div className={CLASS_NAMES.drawer.tabsContainer}>
            <button
                className="w-1/3 p-1 hover:bg-gray-200"
                onClick={(): void =>
                    setParams({ currentDevice: null, currentDrawerTab: null })
                }
            >
                <Icon name="xmark" size={"sm"} fill="#141e26" />{" "}
            </button>
            <ul className={CLASS_NAMES.iconTabs.container}>
                {DEVICE_DRAWER_TABS_VALUES.map(
                    ({
                        name,
                        value,
                        icon,
                    }: CurrentTab<DEVICE_DRAWER_TABS>): JSX.Element => (
                        <TabListItem<DEVICE_DRAWER_TABS>
                            key={name}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            icon={icon}
                            value={value}
                        />
                    ),
                )}
            </ul>
        </div>
    );
});
