import FeatureType from "types/OpenLayers/FeatureType.type";

/**
 * Exists to provide mapping from the @see {FeatureType} to params that exist in the URL @see {UseQueryContextT}
 */
const featureToParamMap: Record<
    FeatureType,
    "currentZone" | "currentDevice" | "sublId"
> = {
    Device: "currentDevice",
    Zone: "currentZone",
    MobileDevice: "currentDevice",
    GroupedDevice: "currentDevice",
    SublocationOverlay: "sublId",
};

export default featureToParamMap;
