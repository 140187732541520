import { Feature } from "ol";
import { Geometry } from "ol/geom";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import FeatureType from "types/OpenLayers/FeatureType.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import { createDeviceFeature } from "utils/OpenLayers/Features/Device/createDeviceFeature";
import { createSublocationOverlayFeature } from "utils/OpenLayers/Features/SublocationOverlay/createSublocationOverlayFeature";
import { createZoneFeature } from "utils/OpenLayers/Features/Zones/createZoneFeature";

type FeatureCreationFunction<T> = (
    originalData: T,
    map: MapDisplayDetails,
    state: FeatureInteractiveState,
) => Feature<Geometry> | Promise<Feature<Geometry>>;

/** This mapping object will help with passing the correct feature type to
 * the relevant feature function. So when we need to create a new feature type, edit here.
 */
const featureCreationFunctions: Record<
    FeatureType,
    //Any for now until I can figure out how to type properly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FeatureCreationFunction<any>
> = {
    Device: createDeviceFeature,
    GroupedDevice: createDeviceFeature,
    MobileDevice: createDeviceFeature,
    Zone: createZoneFeature,
    SublocationOverlay: createSublocationOverlayFeature,
};

export default featureCreationFunctions;
