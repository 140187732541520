import DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import RackStatusShelfProps from "./utilities/RackStatusShelf.props";

export default memo(function RackStatusShelf({
    device,
    onClick,
}: RackStatusShelfProps): JSX.Element {
    const status = useMemo(() => {
        const status = (device.metrics.status as DevicesMetricsMetric)?.value;
        return String(status) || null;
    }, [device]);

    const style = useMemo(() => {
        let bg = "";
        let border = "border-solid border-obsidian border-1";

        switch (status) {
            case "occupied":
                bg = "bg-cobalt";
                break;
            case "misaligned":
                bg = "bg-orange";
                break;
            case "unknown":
                bg = "bg-red";
                break;
            case "available":
                border = "border-dotted border-comp-4 border-2";
                break;
            default:
                bg = "bg-primary-lightGray";
        }

        return {
            bg,
            border,
        };
    }, [status]);

    return (
        <div
            onClick={onClick}
            className={extendClassName(
                "p-1 pointer w-full flex border-1",
                style.bg,
            )}
        >
            <div
                className={extendClassName(
                    "flex justify-center items-center flex-1 py-2",
                    style.border,
                )}
            >
                <span>{status}</span>
            </div>
        </div>
    );
});
