import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import GroupedDeviceSchema from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.schema";
import type GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import DEVICE_DRAWER_TABS from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardTabs/utilities/DEVICE_DRAWER_TABS.enum";
import RackStatus from "components/RackDrawerContent/RackStatus/RackStatus";
import useQuery from "hooks/useQuery/useQuery";
import { memo, useCallback, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import { v4 } from "uuid";
import CurrentDeviceCardContentStatusDrawerItemStatusBar from "../CardContentStatusDrawerItemGeneric/CurrentDeviceCardContentStatusDrawerItemStatusBar/CurrentDeviceCardContentStatusDrawerItemStatusBar";
import LinearScaledMultiRangeMetricContainer from "./LinearScaledMultiRangeMetricContainer/LinearScaledMultiRangeMetricContainer";
import MultiRangeUnitConversionMetricContainer from "./MultiRangeUnitConversionMetricContainer/MultiRangeUnitConversionMetricContainer";
import type CardContentStatusDrawerItemNonGenericProps from "./utilities/CardContentStatusDrawerItemNonGeneric.props";

const hoverStyle = "hover:border-cobalt hover:border-2 hover:drop-shadow-md";

export default memo(function CardContentStatusDrawerItemNonGeneric({
    device,
    metric,
    drawer,
}: CardContentStatusDrawerItemNonGenericProps): JSX.Element {
    const isGroupedDevice = useMemo(
        () => GroupedDeviceSchema.validate(device)?.error === undefined,
        [device],
    );

    const { setParams, currentZone } = useQuery();

    const navigateFromZoneToDevice = useCallback(() => {
        if (currentZone) {
            setParams({
                currentDevice: device._id,
                currentZone: null,
                currentDrawerTab: DEVICE_DRAWER_TABS.STATUS,
            });
        }
    }, [currentZone, device._id]);

    if (drawer.type === DISPLAY_TYPE.RACK_CABINENT && isGroupedDevice) {
        return (
            <RackStatus
                device={device as GroupedDevice}
                drawer={drawer}
                metric={metric}
            />
        );
    } else if (
        drawer.type === DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL
    ) {
        return (
            <li
                key={v4()}
                className={extendClassName(
                    "flex flex-row overflow-hidden my-2 mx-4 border-[#D4D4D4] border-2 has-ignoreClass-device-drawer has-ignoreClass-zone-drawer ",
                    currentZone ? hoverStyle : "",
                )}
                onClick={(): void => navigateFromZoneToDevice()}
            >
                <CurrentDeviceCardContentStatusDrawerItemStatusBar
                    offline={device.state.offline}
                    health={
                        device.deviceHealth[drawer.referenceID] as HEALTH_STATUS
                    }
                />

                <LinearScaledMultiRangeMetricContainer
                    device={device}
                    drawer={drawer}
                    metric={metric}
                />
            </li>
        );
    } else if (drawer.type === DISPLAY_TYPE.MULTI_RANGE_UNIT_CONVERSION) {
        return (
            <li
                key={v4()}
                className={extendClassName(
                    "flex flex-row overflow-hidden my-2 mx-4 border-[#D4D4D4] border-2 has-ignoreClass-device-drawer has-ignoreClass-zone-drawer ",
                    currentZone ? hoverStyle : "",
                )}
                onClick={(): void => navigateFromZoneToDevice()}
            >
                <CurrentDeviceCardContentStatusDrawerItemStatusBar
                    offline={device.state.offline}
                    health={
                        device.deviceHealth[drawer.referenceID] as HEALTH_STATUS
                    }
                />

                <MultiRangeUnitConversionMetricContainer
                    device={device}
                    drawer={drawer}
                    metric={metric}
                />
            </li>
        );
    }

    return (
        <div>
            <h1>Display error, please contact SRT support. NONGENERIC</h1>
        </div>
    );
});
