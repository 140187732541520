import type DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import { memo, useMemo } from "react";
import CardContentStatusDrawerItemGeneric from "./CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGeneric";
import CardContentStatusDrawerItemNonGeneric from "./CardContentStatusDrawerItemNonGeneric/CardContentStatusDrawerItemNonGeneric";
import type CurrentDeviceCardContentStatusDrawerItemProps from "./utilities/CurrentDeviceCardContentStatusDrawerItem.props";
import NON_GENERIC_DISPLAY_TYPE_VALUES from "./utilities/NON_GENERIC_DISPLAY_TYPE.values";

export default memo(function CurrentDeviceCardContentStatusDrawerItem({
    device,
    drawer,
}: CurrentDeviceCardContentStatusDrawerItemProps): JSX.Element {
    const metric = useMemo(() => {
        const metricToUse = device.metrics[
            drawer.referenceID
        ] as DevicesMetricsMetric;

        return typeof metricToUse === "string" ? null : metricToUse;
    }, [device, drawer]);

    return !metric ? (
        <></>
    ) : (NON_GENERIC_DISPLAY_TYPE_VALUES as string[]).includes(drawer.type) ? (
        <CardContentStatusDrawerItemNonGeneric
            device={device}
            metric={metric}
            drawer={drawer}
        />
    ) : (
        <CardContentStatusDrawerItemGeneric
            device={device}
            metric={metric}
            drawer={drawer}
        />
    );
});
