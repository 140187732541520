import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import { useQueries } from "@tanstack/react-query";
import DeviceService from "services/DeviceService";

type useDevicesResults = {
    /**
     * Data to be returned from each query, with device IDs as keys and Device objects as values.
     */
    data: Map<string, Device>;

    /**
     * Indicates if there is any remaining queries in the fetch that have yet to be completed
     */
    pendingFetchData: boolean;
};

/**
 * Fetches data for devices and returns all that are successful.
 * Checks if passed param is empty, and resolves early if so.
 * @param {string[]} deviceIds  The devices to be retrieved.
 * @returns {useDevicesResults} An object containing an array of fetched data (devices) and a boolean indicating if any of the data fetching operations are still pending.
 */

export default function useDevices(deviceIds: string[]): useDevicesResults {
    return useQueries({
        queries: deviceIds.map((id) => ({
            queryKey: ["device", id],
            queryFn: async (): Promise<Device> => {
                return DeviceService.getDevice(id);
            },
            keepPreviousData: true,
        })),
        combine: (results) => {
            const deviceMap: Map<string, Device> = new Map();
            results.forEach((result) => {
                if (result.data) {
                    deviceMap.set(result.data._id, result.data);
                }
            });
            return {
                data: deviceMap,
                pendingFetchData: results.some((result) => result.isPending),
            };
        },
    });
}
