/* eslint-disable prettier/prettier */
const fahrenheitToCelsius = (x: number): number => (x - 32) * (5 / 9);
const fahrenheitToKelvin = (x: number): number => (x - 32) * (5 / 9) + 273.15;
const celsiusToFahrenheit = (x: number): number => (x * 9) / 5 + 32;
const celsiusToKelvin = (x: number): number => x + 273.15;
const kelvinToCelsius = (x: number): number => x - 273.15;
const kelvinToFahrenheit = (x: number): number => (x - 273.15) * (9 / 5) + 32;

const temperatureConversions = {
    "°C": {
        "°F": celsiusToFahrenheit,
        K: celsiusToKelvin,
    },
    "°F": {
        "°C": fahrenheitToCelsius,
        K: fahrenheitToKelvin,
    },
    K: {
        "°C": kelvinToCelsius,
        "°F": kelvinToFahrenheit,
    },
};

export default temperatureConversions;
