import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "@tanstack/react-query";
import DeviceService from "services/DeviceService";
interface useDeviceProps
    extends Omit<UseQueryOptions<Device, Error>, "queryFn" | "queryKey"> {
    deviceId: string | null;
}

function useDevice({
    deviceId,
    ...rest
}: useDeviceProps): UseQueryResult<Device, Error> {
    return useQuery<Device, Error>({
        ...rest,
        queryKey: ["device", deviceId],
        queryFn: () => DeviceService.getDevice(deviceId!),
        enabled: !!deviceId,
    });
}

export default useDevice;
