import FLEET_SORT_OPTIONS from "./FleetSortOptions.enum";
import UseSortOption from "./UseSortOption.interface";

const ZoneFleetSortOptions: Readonly<UseSortOption[]> = Object.freeze([
    {
        type: FLEET_SORT_OPTIONS.NAME,
        sort: {
            path: "name",
        },
    },
    {
        type: FLEET_SORT_OPTIONS.LOCATION,
        sort: { path: "location" },
    },
]);

export default ZoneFleetSortOptions;
