import joi from "joi";

const IndividualDeviceSchema = joi.object({
    deviceID: joi.string(),
});

/**
 * validates whether the given value is a validIndividualDevice
 *
 * @param value - the argument to be tested
 * @returns the reason if any, that the value did not pass, else is null
 */
export default function validIndividualDevice(value: unknown): string | null {
    return (
        String(IndividualDeviceSchema.validate(value)?.error?.details) || null
    );
}
