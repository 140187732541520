import NotificationBell from "components/NotificationBell/NotificationBell";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import rootStore from "stores/rootStore";
import LocationInfoSection from "../../../components/LocationInfoSection/LocationInfoSection";
import UserBar from "../UserBar/UserBar";
import AppBarHouse from "./AppBarHouse/AppBarHouse";
import type AppBarProps from "./utilities/AppBar.props";

export default observer(function AppBar({
    orgId,
    locId,
    sublId,
    userName,
    notifications,
    isMobile,
}: AppBarProps): JSX.Element {
    const numberOfNotifications = useMemo(() => {
        return rootStore.notificationStore.notifications.length;
    }, [notifications]);

    return (
        <div className="col-auto row-span-1 h-[50px] sm:h-66px bg-white text-obsidian border-b-1 border-gray-700 flex">
            <AppBarHouse />

            <LocationInfoSection />

            {userName ? (
                <>
                    <div className="ml-auto flex items-center">
                        <NotificationBell
                            isMobile={isMobile}
                            orgId={orgId}
                            locId={locId}
                            sublId={sublId}
                            numberOfNotifications={numberOfNotifications}
                        />
                        <UserBar userName={userName} isMobile={isMobile} />
                    </div>
                </>
            ) : null}
        </div>
    );
});
