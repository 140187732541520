import LeftNavDrawer from "components/LeftNavDrawer/LeftNavDrawer";
import { observer } from "mobx-react-lite";
import type LeftNavDrawerContainerProps from "./utilities/LeftNavDrawerContainer.props";

export default observer(function LeftNavDrawerContainer({
    organizationStore,
}: LeftNavDrawerContainerProps): JSX.Element {
    if (!organizationStore.organization) return <></>;
    return <LeftNavDrawer organizationStore={organizationStore} />;
});
