import type DeviceDisplayDrawerItem from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerItem/DeviceDisplayDrawerItem.type";
import LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import type MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import type RANGE_FLOAT_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/RANGE_FLOAT_DIMENSION/RANGE_FLOAT_DIMENSION.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import getLinearScaledMultiRangeMetricProps from "utils/LinearScaledMultiRangeSafeVal/getLinearScaledMultiRangeMetricProps";
import type IssueHandle from "./IssueHandle.interface";
import type IssueHandler from "./IssueHandler.type";
import MULTI_RANGE_UNIT_CONVERSION_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MULTI_RANGE_UNIT_CONVERSION_DIMENSION.type";

const safeCaseValue: HEALTH_STATUS[] = [
    HEALTH_STATUS.UNSAFE,
    HEALTH_STATUS.WARNING,
];
const unsafeCaseValue: HEALTH_STATUS[] = [
    HEALTH_STATUS.SAFE,
    HEALTH_STATUS.WARNING,
];
const warningCaseValue: HEALTH_STATUS[] = [
    HEALTH_STATUS.UNSAFE,
    HEALTH_STATUS.SAFE,
];

const issueHandlers: Record<string, IssueHandler> = {
    handleRangeFloatAndRangeInteger(value, dimension, drawer) {
        const minMaxSafeRange = (dimension as RANGE_FLOAT_DIMENSION)
            ?.minMaxSafeRange;

        if (!minMaxSafeRange) {
            return {
                error: "Does not support its own display type",
                warning: "",
            };
        }

        let error = "";
        const warning = "";

        if (minMaxSafeRange)
            if (Number(value) > minMaxSafeRange.safeMax)
                error = `${drawer.name}'s value of ${value} is greater than safe maximum value of ${minMaxSafeRange.safeMax}.`;
            else if (Number(value) < minMaxSafeRange.safeMin)
                error = `${drawer.name}'s value is less than safe minimum value of ${minMaxSafeRange.safeMin}.`;
        // else if (value === minMaxSafeRange.safeMax) warning = `${drawer.name}'s value is less than safe minimum value of ${minMaxSafeRange.safeMin}.`;
        // else if (value === minMaxSafeRange.safeMin) warning = `${drawer.name}'s value is less than safe minimum value of ${minMaxSafeRange.safeMin}.`;

        return {
            error,
            warning,
        };
    },
    multiRangeSafeVal(val, dimension, drawer) {
        const value = Number(val);

        const multiRangeSafeVal =
            (dimension as MULTI_RANGE_SAFE_VAL_DIMENSION)?.multiRangeSafeVal ||
            (dimension as LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION)
                .linearScaledMultiRangeSafeVal ||
            (dimension as MULTI_RANGE_UNIT_CONVERSION_DIMENSION)
                .multiRangeUnitConversion;

        if (!multiRangeSafeVal) {
            return {
                error: "Does not support its own display type",
                warning: "",
            };
        }

        const comparator = ([min, max]: number[]): boolean =>
            min <= value && value <= max;

        if (
            multiRangeSafeVal.safe.some(([min, max]) =>
                Boolean(value >= min && value <= max),
            )
        ) {
            return {
                error: "",
                warning: "",
            };
        }

        if (multiRangeSafeVal) {
            const caseWhereValueIs = ((): HEALTH_STATUS => {
                const arr =
                    multiRangeSafeVal.base === HEALTH_STATUS.SAFE
                        ? safeCaseValue
                        : multiRangeSafeVal.base === HEALTH_STATUS.UNSAFE
                        ? unsafeCaseValue
                        : warningCaseValue;

                for (const key of arr) {
                    // if it is in the multiRangeSafeVal, then return that it is in that multiRangeSafeVal
                    if (multiRangeSafeVal[key].some(comparator)) return key;
                }

                return multiRangeSafeVal.base;
            })();
            return {
                error:
                    caseWhereValueIs === "unsafe"
                        ? `Unsafe value of: ${value}${drawer.unit} for ${drawer.name}`
                        : "",
                warning:
                    caseWhereValueIs === "warning"
                        ? `Warning value of ${value}${drawer.unit} for ${drawer.name}`
                        : "",
            };
        }

        return {
            error: "",
            warning: "",
        };
    },
    linearScaledMultiRangeSafeVal(val, dimension, drawer) {
        const value = Number(val);
        const scaledProps = getLinearScaledMultiRangeMetricProps(
            value,
            drawer,
            (dimension as LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION)
                .linearScaledMultiRangeSafeVal,
        );
        return issueHandlers.multiRangeSafeVal(
            scaledProps.value,
            dimension,
            drawer,
        );
    },
};

class IssuesWithDevice {
    /*--------------------------------------
    // private variables for getUtilsForType
    --------------------------------------*/

    private _rangeFloatAndRangeInteger = ["range-float", "range-integer"];
    private _multiRangeSafeVal = ["multiRangeSafeVal"];
    private _linearScaledMultiRangeSafeVal = ["linearScaledMultiRangeSafeVal"];
    private _multiRangeUnitConversion = ["multiRangeUnitConversion"];

    /*--------------------------------------
    // test to see what type a drawer is for
    --------------------------------------*/

    public getUtilsForType(
        drawer: DeviceDisplayDrawerItem,
    ): IssueHandle | null {
        if (this._rangeFloatAndRangeInteger.includes(drawer.type))
            return {
                handler: issueHandlers.handleRangeFloatAndRangeInteger,
                transform: (value): number => Number(value),
            };

        if (this._multiRangeSafeVal.includes(drawer.type))
            return {
                handler: issueHandlers.multiRangeSafeVal,
                transform: (value): number => Number(value),
            };

        if (this._linearScaledMultiRangeSafeVal.includes(drawer.type))
            return {
                handler: issueHandlers.linearScaledMultiRangeSafeVal,
                transform: (value): number => Number(value),
            };
        // multiRangeUnitConversion calculates health from unconverted units
        // and needs no special handling distinct from regular multiRange
        if (this._multiRangeUnitConversion.includes(drawer.type))
            return {
                handler: issueHandlers.multiRangeSafeVal,
                transform: (value): number => Number(value),
            };
        return null;
    }
}

export default new IssuesWithDevice();
