import utc from "dayjs/plugin/utc";
import dayjs from "dayjs";
import { OccupancyOverride } from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
dayjs.extend(utc);

export type HourMinuteTimestamp = {
    hour: number;
    minute: number;
};

//TODO: Once comprehended, RENAME ALL FUCNCTIONS AND ADD DESCRIPTIONS.

class HourMinuteManager {
    public readonly HourMinuteTimestampRegxp = /(\d{2}):(\d{2})/;

    /**
     * @description This looks like it is taking in a plain string,
     *  and splitting it by it's colon to extract the hour and minute as two separate values
     * @param {string} timestamp - a string with the format "\d{2}:\d{2}"
     */
    public parseTimestamp(timestamp: string): HourMinuteTimestamp | null {
        const res = this.HourMinuteTimestampRegxp.exec(timestamp);
        if (!res) return null;

        return {
            hour: Number(res[1]),
            minute: Number(res[2]),
        };
    }

    /**
     * TODO: Can't we replace with Dayjs functionality?
     * @desription Takes in hours and miniutes and, further formats to match hh: mm
     * @param hours number
     * @param minutes number
     * @returns Time in hh: mm format
     */
    public toHourMinuteString(hours: number, minutes: number): string {
        let hour = String(hours);
        let minute = String(minutes);

        //If the hour is a single number say 1PM, this will format it so it looks like 01PM,
        //otherwise if it is a double digit (say 12), it returns it as is.
        hour = hour.length == 1 ? "0" + hour : hour;

        //Similar to the logic above. if the time has a single digit minute (01:1PM), it will add
        // a zero so that it is displayed as (01:01PM), otherwise return the presumably double digit number as is
        //
        minute = minute.length == 1 ? "0" + minute : minute;

        return hour + ":" + minute;
    }

    public toLocale(timestamp: string): string {
        const d = this.toLocaleDate(timestamp);

        if (d) {
            const hours = d.getHours();
            const minutes = d.getMinutes();

            return this.toHourMinuteString(hours, minutes);
        }

        return "";
    }

    public toUTC(timestamp: string): string {
        if (!timestamp) return "";

        const d = this.toUTCDate(timestamp);

        if (d) {
            const hours = d.getUTCHours();
            const minutes = d.getUTCMinutes();

            return this.toHourMinuteString(hours, minutes);
        }

        return "";
    }

    /**
     * @description parses timestamp to extract hour and minute, then sets it as UTC time.
     * @param timestamp
     * @returns
     */
    public toLocaleDate(timestamp: string): Date | null {
        const t = this.parseTimestamp(timestamp)!;

        if (t == null) return null;

        return dayjs().utc().hour(t.hour).minute(t.minute).toDate();
    }

    /**
     * This returns a Day.js object with a flag to use local time. So since we are using dayjs.utc()
     * @param timestamp
     * @returns
     */
    public toUTCDate(timestamp: string): Date | null {
        const t = this.parseTimestamp(timestamp)!;

        if (t == null) return null;

        return dayjs().local().hour(t.hour).minute(t.minute).toDate();
    }

    public parseTimestampToUTC(timestamp: string): HourMinuteTimestamp | null {
        const date = this.parseToDate(timestamp);
        if (!date) return null;

        return {
            hour: date.getUTCHours(),
            minute: date.getUTCMinutes(),
        };
    }

    public timestampToUTC(
        timestamp: HourMinuteTimestamp,
        day = new Date(),
    ): Date {
        const d = new Date(day);
        d.setUTCHours(timestamp.hour);
        d.setUTCMinutes(timestamp.minute);
        return d;
    }

    public parseToDate(inputTimeString: string, day = new Date()): Date | null {
        const timestamp = this.parseTimestamp(inputTimeString);

        return timestamp ? this.timestampToUTC(timestamp, day) : null;
    }

    public parseOverride(
        override: OccupancyOverride,
        day = new Date(),
    ): {
        start: Date;
        end: Date;
    } | null {
        if (override.userDefinedStartTime && override.userDefinedEndTime) {
            const start = this.parseToDate(override.userDefinedStartTime, day)!;
            let end = this.parseToDate(override.userDefinedEndTime, day)!;
            start.setDate(day.getDate());
            end.setDate(day.getDate());
            if (end < start) {
                end = new Date(end.setDate(end.getDate() + 1));
            }

            return {
                start,
                end,
            };
        }

        return null;
    }
}

export default new HourMinuteManager();
