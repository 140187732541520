import Spinner from "components/Icons/Spinner/Spinner";
import { observer } from "mobx-react-lite";
import { Link } from "react-router-dom";
import type BaseProps from "./utilities/Base.props";

export default observer(function Base({ userStore }: BaseProps): JSX.Element {
    return (
        <div className="flex flex-col items-center overflow-auto h-auto">
            {userStore._id ? (
                <>
                    <h2 className="text-2xl mt-16 mb-2 pt-3">
                        Welcome to SRT Labs
                    </h2>
                    <h1 className="text-4xl mt-8 mb-4">My Organizations</h1>

                    <ul className="flex flex-col sm:min-w-55ch mb-8">
                        {Object.entries(userStore.access)
                            .sort(([, a], [, b]) =>
                                a.name.localeCompare(b.name),
                            )
                            .map(
                                ([orgId, { name }]): JSX.Element => (
                                    <li
                                        key={orgId}
                                        className="mt-4 w-full text-xl group relative"
                                    >
                                        <Link to={`/home/maps?orgId=${orgId}`}>
                                            <div className="absolute left-0 right-0 top-0 bottom-0 group-hover:bg-obsidian hover:bg-opacity-30 opacity-30" />
                                            <span className="w-full border-1 border-white border-opacity-30 p-2.5 pr-10 rounded-md block group-hover:underline cursor-pointer">
                                                {name}
                                            </span>
                                        </Link>
                                    </li>
                                ),
                            )}
                    </ul>
                </>
            ) : (
                <div className="w-screen h-screen flex justify-center items-center">
                    <span className="flex flex-row items-center text-center">
                        <Spinner />
                        <p className="mx-5">Loading...</p>
                    </span>
                </div>
            )}
        </div>
    );
});
