import LocationMap from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMap.type";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import rootStore from "stores/rootStore";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import useImageDimensions from "utils/OpenLayers/utilities/useImageDimensions";
import LocationMapCanvasContainer from "../LocationMapCanvasContainer/LocationMapCanvasContainer";
import { useFetchMapImageQuery } from "../utilities/fetchImageSrc";
import useZones from "hooks/useZones.hook";
import LOCATION_MAP_TYPE from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LOCATION_MAP_TYPE/LOCATION_MAP_TYPE.enum";

export default observer(function LocationMapContainer(): JSX.Element {
    const [currentMapKey, setCurrentMapKey] = useState("");
    const openLayersStore = rootStore.openLayersStore;
    const organizationStore = rootStore.organizationStore;

    /**
     * Queries @see fetchImageSrc only when the enabled values are all true. Caches the result of the call
     * under "locationMapSrcImage" followed by the distinct path where the map is to be found
     */
    const {
        data: imgSrc,
        isError: isImgSrcError,
        error: imgSrcFetchError,
        isLoading: isImgSrcLoading,
    } = useFetchMapImageQuery({
        key: "location",
        mapIdentifier: {
            orgId: organizationStore.orgId,
            locId: organizationStore.locId,
            currentMapKey,
        },
        queryKey: [
            "locationMapImgSrc",
            organizationStore.orgId,
            organizationStore.locId,
            currentMapKey,
        ],
    });

    const { dimensions, isImageLoading } = useImageDimensions(
        imgSrc ? imgSrc : "",
    );

    //Test to see if delay in org store is causing zone/device not set.
    const activeLocationMaps = useMemo((): [string, LocationMap][] | null => {
        if (organizationStore.location) {
            return Object.entries(organizationStore.location.maps);
        }
        return null;
    }, [organizationStore.location]);

    const map = useMemo(() => {
        if (activeLocationMaps) {
            const map = activeLocationMaps.find(
                ([name]) => name === currentMapKey,
            );
            if (map) return map[1];
        }
        return null;
    }, [currentMapKey, activeLocationMaps]);

    // Determine whether or not to fetch zones based on map type
    const activeZones = useMemo(() => {
        return map?.type === LOCATION_MAP_TYPE.SUBLOCATION_ZONES
            ? organizationStore.activeZones
            : [];
    }, [map]);

    // Fetch zones (if required)
    const { data: zones, pendingFetchData: pendingZoneFetchData } =
        useZones(activeZones);

    const sublocationOverlays = useMemo(() => {
        return map?.overlay_details || [];
    }, [map?.overlay_details]);

    useEffect(() => {
        // Update currentMapKey based on available maps
        if (
            currentMapKey &&
            !activeLocationMaps?.some(([name]) => name === currentMapKey)
        ) {
            setCurrentMapKey("");
        } else if (!currentMapKey && activeLocationMaps?.[0][0]) {
            setCurrentMapKey(activeLocationMaps?.[0][0]);
        }

        // Set map view type based on map type
        if (!map) return;
        if (map.type == LOCATION_MAP_TYPE.SUBLOCATION_OVERLAYS) {
            openLayersStore.setMapType(MAP_VIEW_TYPE.SUBLOCATION_OVERLAY);
        } else if (map.type == LOCATION_MAP_TYPE.SUBLOCATION_ZONES) {
            openLayersStore.setMapType(MAP_VIEW_TYPE.ZONE);
        }
    }, [
        currentMapKey,
        activeLocationMaps,
        organizationStore.orgId,
        organizationStore.locId,
        sublocationOverlays,
        map,
    ]);

    // Set data to overlays or zones based on map type
    const data = useMemo(() => {
        if (openLayersStore.mapType === MAP_VIEW_TYPE.SUBLOCATION_OVERLAY) {
            return sublocationOverlays;
        } else if (openLayersStore.mapType === MAP_VIEW_TYPE.ZONE) {
            return Array.from(zones.values());
        } else {
            return [];
        }
    }, [
        openLayersStore.mapType,
        pendingZoneFetchData,
        sublocationOverlays,
        zones,
    ]);

    const [layersInitializing, setLayersInitializing] = useState(false);

    const showLoading =
        isImgSrcLoading ||
        isImageLoading ||
        pendingZoneFetchData ||
        layersInitializing;

    return (
        <div className="flex flex-1 flex-col items-center h-full text-sm">
            {isImgSrcError || imgSrcFetchError ? (
                <h1 className="m-auto text-sm">
                    Failed to load image, please retry or contact srt support
                </h1>
            ) : showLoading || !dimensions || !map || !imgSrc ? (
                <h1 className="m-auto text-sm">Loading image</h1>
            ) : (
                <LocationMapCanvasContainer
                    key={imgSrc}
                    mapViewMode={openLayersStore.mapType}
                    dimensions={dimensions}
                    isImageLoading={isImageLoading}
                    setLayersInitializing={setLayersInitializing}
                    imageSrc={imgSrc}
                    map={{ resolution: map.resolution, origin: map.origin }}
                    data={data}
                />
            )}
        </div>
    );
});
