import { memo, useCallback, useEffect, useState } from "react";
import { celsiusToFahrenheit } from "utils/temperatureConversion";
import type CurrentDeviceCardContentStatusDrawerItemHeaderProps from "./utilities/CurrentDeviceCardContentStatusDrawerItemHeader.props";
export default memo(function CurrentDeviceCardContentStatusDrawerItemHeader({
    value,
    unit,
    type,
}: CurrentDeviceCardContentStatusDrawerItemHeaderProps): JSX.Element {
    const [truncateValue, setTruncateValue] = useState(false);
    const toggleFullValue = useCallback((show: boolean) => {
        setTruncateValue(!show);
    }, []);

    useEffect(() => {
        if (String(value).length > 4) setTruncateValue(true);
    }, [value]);

    return (
        <header
            className={`w-[7em] max-w-[7em] h-auto my-auto flex flex-wrap pl-1 overflow-hidden
            `}
        >
            {!(
                type === "range-float" ||
                type === "range-integer" ||
                type === "multiRangeSafeVal" ||
                type === "linearScaledMultiRangeSafeVal" ||
                type === "minMaxSafeValImperialTemperature"
            ) ? null : (
                <>
                    <h1
                        title={String(value)}
                        className={`text-2xl ${
                            String(value).length > 4 ? "cursor-pointer" : ""
                        } ${
                            truncateValue
                                ? "text-ellipsis overflow-hidden w-full"
                                : "break-words w-full"
                        }`}
                        onClick={(): void => toggleFullValue(truncateValue)}
                    >
                        {type === "minMaxSafeValImperialTemperature"
                            ? celsiusToFahrenheit(Number(value))
                            : String(value)}
                    </h1>
                    <i
                        className={`ml-1 text-sm self-end mr-auto ${
                            truncateValue ? "min-w-[24px]" : ""
                        }`}
                    >
                        {unit === "none" ? "" : unit}
                    </i>
                </>
            )}
        </header>
    );
});
