import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import type InAndOutOfRangeToString from "@srtlabs/m1_types/lib/Displays/Dimensions/IN_AND_OUT_OF_RANGE_TO_STRING_DIMENSION/InAndOutOfRangeToString.type";
import MinMaxSafeValImperialTemperature from "@srtlabs/m1_types/lib/Displays/Dimensions/MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE_DIMENSION/MinMaxSafeValImperialTemperature.type";
import type MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import type MinMaxSafeRangeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/other/MinMaxSafeRangeVal/MinMaxSafeRangeVal.type";
import Text from "components/Text/Text";
import { memo } from "react";
import isValidMultiRangeSafeVal from "utils/MetricValueVerifyUtils/isValidMultiRangeSafeVal";
import { celsiusToFahrenheit } from "utils/temperatureConversion";
import InAndOutOfRangeToStringMetric from "./InAndOutOfRangeToStringMetric/InAndOutOfRangeToStringMetric";
import MultiRangeSafeValMetric from "./MultiRangeSafeValMetric/MultiRangeSafeValMetric";
import NumberMetric from "./NumberMetric/NumberMetric";
import type CurrentDeviceCardContentStatusDrawerItemMetricProps from "./utilities/CurrentDeviceCardContentStatusDrawerItemMetric.props";
export default memo(function CurrentDeviceCardContentStatusDrawerItemMetric({
    device,
    metric,
    drawer,
    dimensions,
}: CurrentDeviceCardContentStatusDrawerItemMetricProps): JSX.Element {
    if (drawer.type === DISPLAY_TYPE.TRUE_FALSE) {
        return (
            <Text className="m-auto font-bold">
                {metric.value === true ? "True" : "False"}
            </Text>
        );
    }

    if (drawer.type === DISPLAY_TYPE.OCCUPIED_UNOCCUPIED) {
        return (
            <Text className="m-auto font-bold">
                {metric.value === true ? "Occupied" : "Unoccupied"}
            </Text>
        );
    }

    if (drawer.type === DISPLAY_TYPE.OPEN_CLOSE) {
        return <Text className="m-auto font-bold">{String(metric.value)}</Text>;
    }

    if (drawer.type === DISPLAY_TYPE.STRING) {
        return <Text className="m-auto font-bold">{String(metric.value)}</Text>;
    }

    if (drawer.type === DISPLAY_TYPE.IMAGE) {
        return (
            <div className="w-full max-h-42 overflow-scroll">
                <img
                    className="w-full"
                    src={String(metric.value)}
                    alt={drawer.referenceID}
                />{" "}
            </div>
        );
    }

    /*-----------------
    // Dimensions Items
    -----------------*/

    if (!dimensions[drawer.referenceID]) {
        return (
            <Text className="m-auto font-bold">
                Error from Undefined Referenced Id.
            </Text>
        );
    }

    if (
        (drawer.type === DISPLAY_TYPE.MULTI_RANGE_SAFE_VAL ||
            drawer.type === DISPLAY_TYPE.RANGE_FLOAT ||
            drawer.type === DISPLAY_TYPE.RANGE_INTEGER) &&
        isValidMultiRangeSafeVal(dimensions[drawer.referenceID]?.[drawer.type])
    ) {
        return (
            <MultiRangeSafeValMetric
                unit={drawer.unit}
                value={Number(metric.value)}
                {...(dimensions[drawer.referenceID][drawer.type]
                    ?.multiRangeSafeVal as MultiRangeSafeVal)}
            />
        );
    }

    if (
        drawer.type === DISPLAY_TYPE.RANGE_FLOAT ||
        drawer.type === DISPLAY_TYPE.RANGE_INTEGER
    ) {
        return (
            <NumberMetric
                value={Number(metric.value)}
                unit={drawer.unit}
                {...(dimensions[drawer.referenceID][drawer.type]
                    ?.minMaxSafeRange as MinMaxSafeRangeVal)}
            />
        );
    }

    if (drawer.type === DISPLAY_TYPE.MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE) {
        const { rangeMax, rangeMin, safeMax, safeMin } = dimensions[
            drawer.referenceID
        ][drawer.type]
            ?.minMaxSafeValImperialTemperature as MinMaxSafeValImperialTemperature;

        const convertedTemperatures = {
            rangeMax: celsiusToFahrenheit(rangeMax),
            rangeMin: celsiusToFahrenheit(rangeMin),
            safeMax: celsiusToFahrenheit(safeMax),
            safeMin: celsiusToFahrenheit(safeMin),
        };

        return (
            <NumberMetric
                value={celsiusToFahrenheit(Number(metric.value))}
                unit={drawer.unit}
                {...convertedTemperatures}
            />
        );
    }

    if (drawer.type === DISPLAY_TYPE.IN_AND_OUT_OF_RANGE_TO_STRING) {
        return (
            <InAndOutOfRangeToStringMetric
                offline={device.state.offline}
                value={Number(metric.value)}
                {...(dimensions[drawer.referenceID]?.inAndOutOfRangeToString
                    ?.inAndOutOfRangeToString as InAndOutOfRangeToString)}
            />
        );
    }

    if (drawer.type === DISPLAY_TYPE.BOOLEAN_TO_STRING) {
        // If the device is offline, return 'Offline' as drawer status
        if (device.state.offline)
            return <Text className="m-auto font-bold">Offline</Text>;

        // Else return usual 'true' string val or 'false' string val
        return (
            <Text className="m-auto font-bold">
                {dimensions[drawer.referenceID]?.booleanToString
                    ?.booleanToString?.[metric.value ? "true" : "false"] ||
                    "Unknown"}
            </Text>
        );
    }

    if (typeof metric.value === "string" || typeof metric.value === "number") {
        return <Text className="m-2 font-bold">{String(metric.value)}</Text>;
    }

    return <></>;
});
