import { v4 } from "uuid";

const hours: {
    id: string;
    time: string;
}[] = [
    { id: v4(), time: "12:00 am" },
    { id: v4(), time: "12:30 am" },
];

for (let i = 1; i <= 11; i++) {
    hours.push({
        id: v4(),
        time: String(i) + ":00 am",
    });

    hours.push({
        id: v4(),
        time: String(i) + ":30 am",
    });
}

hours.push({ id: v4(), time: "12:00 pm" });
hours.push({ id: v4(), time: "12:30 pm" });

for (let i = 1; i <= 11; i++) {
    hours.push({
        id: v4(),
        time: String(i) + ":00 pm",
    });

    hours.push({
        id: v4(),
        time: String(i) + ":30 pm",
    });
}

hours.push({ id: v4(), time: "12:00 am" });

export default hours;
