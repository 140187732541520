import { memo, useEffect, useState } from "react";
import EditDrawerProps from "../utilities/EditDrawer.props";
import LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import MultiRangeSafeValMetric from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGenericMetricContainer/CurrentDeviceCardContentStatusDrawerItemMetric/MultiRangeSafeValMetric/MultiRangeSafeValMetric";
import MultiRangeEditableContent from "../MultiRangeSafeValEditDrawer/MultiRangeEditableContent/MultiRangeEditableContent";
import LinearScaledMultiRangeEditableContent from "./LinearScaledMultiRangeEditableContent/LinearScaledMultiRangeEditableContent";
import getLinearScaledMultiRangeMetricProps from "utils/LinearScaledMultiRangeSafeVal/getLinearScaledMultiRangeMetricProps";
import LinearScaledMultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScaledMultiRangeSafeVal.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import LinearScale from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScale/LinearScale.type";

export default memo(function LinearScaledMultiRangeSafeValEditDrawer({
    value,
    drawer,
    disabled,
    onChange,
    options,
    setHasErrors,
}: EditDrawerProps<
    number,
    LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION
>): JSX.Element {
    // Retrieve values to display for the metric -- scaled or
    // unscaled depending on whether scale settings are set.
    const metricProps = getLinearScaledMultiRangeMetricProps(
        value,
        drawer,
        options.linearScaledMultiRangeSafeVal,
    );

    //Error state of both "forms"
    const [linearScaledHasErrors, setLinearScaledFormHasErrors] =
        useState(false);
    const [multiRangeHasErrors, setMultiRangeFormHasErrors] = useState(false);

    //TODO: We can probably combined these two states and only pass the relevant
    //prop to the relevant child component.But keeping them separate may help reduce unnecessary
    //renders
    const [multiRangeSafeValState, setMultiRangeSafeValueState] =
        useState<MultiRangeSafeVal>({
            base: options.linearScaledMultiRangeSafeVal.base,
            operatingRange:
                options.linearScaledMultiRangeSafeVal.operatingRange,
            safe: options.linearScaledMultiRangeSafeVal.safe,
            warning: options.linearScaledMultiRangeSafeVal.warning,
            unsafe: options.linearScaledMultiRangeSafeVal.unsafe,
        });

    const [linearScaleState, setLinearScaleState] = useState<LinearScale>(
        options.linearScaledMultiRangeSafeVal.scale!,
    );

    // Use the drawer (unconverted metric) name if the metric unit is unconverted
    const metricName =
        drawer.unit == metricProps.unit
            ? drawer.name
            : options.linearScaledMultiRangeSafeVal.scale.scaledDataType;

    //TODO: Lift state up here and use onChange to update form, this way
    // you have the entire object needed for the update and don't have to
    // befundle pieces together and force them to fit in the child components

    useEffect(() => {
        const scale = {
            scale: linearScaleState,
        };
        const combined: LinearScaledMultiRangeSafeVal = {
            ...multiRangeSafeValState,
            ...scale,
        };
        onChange({
            [DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL]: combined,
        });
    }, [linearScaleState, multiRangeSafeValState]);

    //Because we are combining two different "forms", we need a cohesive error state.
    // Otherwise, the setHasErrors of one state will overwrite the error state of the other form.
    useEffect(() => {
        //If either part of the form is considered invalid, mark the whole form as unsubmissible.
        if (linearScaledHasErrors || multiRangeHasErrors) {
            setHasErrors(true);
        } else {
            setHasErrors(false);
        }
    }, [linearScaledHasErrors, multiRangeHasErrors]);

    return (
        <>
            {/* Status had to be disabled in CurrentDeviceCardContentEditDimensions
                 to allow for variable status here */}
            <h3 className="whitespace-nowrap text-lg">Status: {metricName}</h3>

            {/* Display a standard MultiRangeMetric component, using either the standard
                metric, or the scaled metric if the scale settings are set. */}
            <div className="flex w-full my-8">
                <MultiRangeSafeValMetric {...metricProps} />
            </div>

            <span className="text-center">
                Default Reading:{" "}
                <i>{options.linearScaledMultiRangeSafeVal!.base}</i>
            </span>

            {!disabled ? (
                <>
                    {/* LinearScaledMultiRange has the same settings aside from the scale
                        property, so we can reuse the standard MultiRange component */}
                    <MultiRangeEditableContent
                        onChange={onChange}
                        options={options}
                        setHasErrors={(hasErrors): void => {
                            setMultiRangeFormHasErrors(hasErrors);
                        }}
                        setFormState={setMultiRangeSafeValueState}
                        dimensionType={
                            DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL
                        }
                    />
                    {/* Scale settings specific to LinearScaledMultiRange */}
                    <LinearScaledMultiRangeEditableContent
                        onChange={onChange}
                        options={options}
                        setHasErrors={(hasErrors): void => {
                            setLinearScaledFormHasErrors(hasErrors);
                        }}
                        setLinearScaleState={setLinearScaleState}
                    />
                </>
            ) : null}
        </>
    );
});
