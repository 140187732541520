import ZoneSettingFormErrors from "../types/ZoneSettingFormErrors.type";

const ZoneSettingFormErrorBase: ZoneSettingFormErrors = {
    automation: false,
    occupancy: {
        other: "",
        userDefinedStartTime: "",
        userDefinedEndTime: "",
    },
    hvac: {
        heating: {
            occupied: "",
            unoccupied: "",
        },
        cooling: {
            occupied: "",
            unoccupied: "",
        },
    },
};

export default ZoneSettingFormErrorBase;
