import Button from "components/Button/Button";
import useSaveZone from "hooks/useSaveZone.hook";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { CLASS_NAMES } from "styles/classNameConstants";
import FormErrors from "types/FormErrors";
import convertStringToDotNotation from "utils/convertStringToDotNotation";
import extendClassName from "utils/extendClassName";
import { ObjectSchema, ValidationError } from "yup";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import ZoneForm from "types/FormTypes/ZoneForm";
import zoneFormSchema from "schemas/zoneForm.schema";
import rootStore from "stores/rootStore";
import ZoneInfoProps from "./utilities/ZoneInfo.props";

// CSS styling for form items
const labelStyle = "py-1 text-sm font-thin col-span-4";
const inputColStyle = "min-h-[20px] col-span-8 w-full";
const rowStyle =
    "grid grid-cols-12 gap-y-2 mb-2 items-center min-h-[40px] w-full";
const errorStyle = "text-red text-xs italic";
const disabledStyle = "opacity-50";
const inputFieldStyle = (className?: string): string =>
    "w-full p-1 text-sm self-center h-full items-start " +
    (className ? ` ${className}` : "") +
    " font-thin";

// Get the OpenLayers store so we can update the zone feature when
// the zone name changes
const openLayersStore = rootStore.openLayersStore;

// Get snackbar store for save notification popups
const snackbarStore = rootStore.snackbarStore;

// Error messages for zone info form -- will contain a message
// for any field with an error while the rest will be undefined
interface ZoneFormErrors extends FormErrors {
    name?: string;
    description?: string;
    location?: string;
    sublocation?: string;
    customerId?: string;
    type?: string;
}

export default observer(function CurrentZoneCardContentInfo({
    zone,
}: ZoneInfoProps): JSX.Element {
    const [editing, setToggleEdit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const { mutate: saveZone } = useSaveZone(zone);

    const useYupValidationResolver = (
        validationSchema: ObjectSchema<ZoneForm>,
    ): ((data: ZoneForm) => Promise<{
        values: ZoneForm;
        errors: ZoneFormErrors;
    }>) =>
        useCallback(
            async (data: ZoneForm) => {
                try {
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    });
                    setIsValid(true);
                    return {
                        values,
                        errors: {} as unknown as ZoneFormErrors,
                    };
                } catch (errors) {
                    if (ValidationError.isError(errors)) {
                        //Disable submit buttons, since we found some errors
                        setIsValid(false);
                        const resolvedErrors = {
                            values: data,
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => {
                                    //RHF uses dot notation to locate fields/inputs
                                    //so we need to convert the yup paths (which use ["0"]/[0]), to dots
                                    return {
                                        ...allErrors,
                                        [(currentError.path &&
                                            convertStringToDotNotation(
                                                currentError.path,
                                            )) ||
                                        "unknown"]: {
                                            type:
                                                currentError.type ??
                                                "validation",
                                            message: currentError.message,
                                        },
                                    };
                                },
                                {},
                            ),
                        };
                        return resolvedErrors;
                    } else {
                        console.error(
                            "An unexpected error occurred during validation:",
                            errors,
                        );
                        return {
                            values: data,
                            errors: {
                                default:
                                    "An unexpected error occurred. Please try again or contact support.",
                            },
                        };
                    }
                }
            },
            [validationSchema],
        );

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        trigger,
    } = useForm({
        resolver: useYupValidationResolver(zoneFormSchema),
        defaultValues: {
            name: zone.name,
            description: zone.description,
            location: zone.headers.location,
            sublocation: zone.headers.sublocation,
            customerId: zone.headers.customerId,
            type: zone.headers.type,
        },
        mode: "onChange",
    });

    // Reset form when finished editing
    useEffect(() => {
        reset({
            name: zone.name,
            description: zone.description,
            location: zone.headers.location,
            sublocation: zone.headers.sublocation,
            customerId: zone.headers.customerId,
            type: zone.headers.type,
        });
        editing && trigger();
    }, [zone.headers, zone.name, zone.description, editing]);

    // Update the openlayers zone feature when the name updates
    // (the feature uses zone name on hover tooltip)
    useEffect(() => {
        openLayersStore.updateFeature(zone._id, zone);
    }, [zone.name]);

    const onSubmit = useCallback(
        async (formData: ZoneForm): Promise<void> => {
            try {
                const processedData: ZoneForm = {
                    name: formData.name ?? zone.name,
                    description: formData.description ?? "",
                    location: formData.location ?? "",
                    sublocation: formData.sublocation ?? "",
                    customerId: formData.customerId ?? "",
                    type: formData.type ?? "",
                };
                await saveZone({
                    ...zone,
                    headers: {
                        location: processedData.location,
                        sublocation: processedData.sublocation,
                        customerId: processedData.customerId,
                        type: processedData.type,
                    },
                    name: processedData.name,
                    description: processedData.description,
                } as ZoneWithOverallHealth);
                setToggleEdit(false);
            } catch (e) {
                console.error(e);
            }
        },
        [snackbarStore.enqueueSnackbar, zone],
    );

    // Info form fields
    return (
        <>
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>
                    Zone Information
                </h2>
            </div>{" "}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="lg:px-12 px-4 grid gap-y-0.25 text-gray-700 lg:min-w-80 mr-[10em]"
            >
                <div className={rowStyle}>
                    <label
                        className={extendClassName(
                            labelStyle,
                            "border-t-1 border-primary-lightGray",
                        )}
                        htmlFor="location"
                    >
                        Location
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="location"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabledStyle : "",
                            )}
                            disabled
                            {...register("location")}
                        />
                        {errors.location && (
                            <p className={errorStyle}>
                                {errors.location.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="sublocation">
                        Sublocation
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="sublocation"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabledStyle : "",
                            )}
                            disabled
                            {...register("sublocation")}
                        />
                        {errors.sublocation && (
                            <p className={errorStyle}>
                                {errors.sublocation.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="zoneName">
                        Display Name
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="zoneName"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("name")}
                        />
                        {errors.name && (
                            <p className={errorStyle}>{errors.name.message}</p>
                        )}
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="description">
                        Description
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="description"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("description")}
                        />
                        {errors.description && (
                            <p className={errorStyle}>
                                {errors.description.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="customerId">
                        Customer ID
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="customerId"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("customerId")}
                        />
                        {errors.customerId && (
                            <p className={errorStyle}>
                                {errors.customerId.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="type">
                        Type
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="type"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("type")}
                        />

                        <p className={errorStyle}>
                            {errors.type && errors.type.message}
                        </p>
                    </span>
                </div>

                <div className={rowStyle}>
                    <label className={labelStyle} htmlFor="zoneId">
                        Zone ID {"    "}
                    </label>
                    <span className={inputColStyle}>
                        <input
                            id="zoneId"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabledStyle : "",
                            )}
                            readOnly
                            defaultValue={zone._id}
                            disabled
                        />
                    </span>
                </div>
            </form>
            <div className="w-full mt-auto py-2 flex flex-col justify-center items-center border-t-1 border-obsidian">
                {editing ? (
                    <div className="flex flex-1 justify-center items-center gap-4">
                        <Button
                            variant={"text"}
                            onClick={(): void => setToggleEdit(!editing)}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={"contained"}
                            disabled={isSubmitting || !isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <Button
                        variant={"contained"}
                        onClick={(): void => setToggleEdit(true)}
                    >
                        Edit Zone
                    </Button>
                )}
            </div>
        </>
    );
});
