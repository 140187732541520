import Spinner from "components/Icons/Spinner/Spinner";
import { useState } from "react";
import BaseService from "services/BaseService";
import extendClassName from "utils/extendClassName";

type DownloadButtonProps = {
    canvasID: string;
    text: string;
    style: {
        base: string;
        disabled: string;
        default: string;
    };
};

export default function DownloadButton({
    canvasID,
    text,
    style,
}: DownloadButtonProps): JSX.Element {
    // Swap between not downloading/download in progress text + icon/spinner animation
    const [isDownloading, setIsDownloading] = useState(false);

    // Download the PDF to the client's downloads folder when button is clicked
    const downloadPDF = async (): Promise<void> => {
        setIsDownloading(true);
        try {
            // Get PDF blob from Dashboard API
            const response = await BaseService.request(
                `/canvas_pdf?canvasID=${canvasID}`,
                { method: "get" },
                { responseType: "arraybuffer" }, // Saves as a blank page without this property
            );

            // Create a link for the PDF blob and trigger a download from the link
            const url = window.URL.createObjectURL(
                new Blob([response.data as BlobPart]),
            );
            // Create link
            const link = document.createElement("a");
            link.href = url;
            link.setAttribute("download", "report.pdf");
            // Add link to body
            document.body.appendChild(link);
            // Trigger document download
            link.click();
            // Clean up/remove the link
            link.parentNode?.removeChild(link);
        } catch (error) {
            console.error("Error downloading the file", error);
        } finally {
            setIsDownloading(false);
        }
    };

    // Show text if not downloading, and show "Downloading..."
    // with spinner icon if the download is in progress
    return (
        <button
            className={extendClassName(
                style.base,
                isDownloading ? style.disabled : style.default,
            )}
            onClick={downloadPDF}
            disabled={isDownloading}
        >
            {isDownloading ? (
                <Spinner className="w-2 h-2 mr-2" variant="spinner" />
            ) : null}
            {isDownloading ? "Downloading..." : text}
        </button>
    );
}
