import MultiRangeUnitConversion from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MultiRangeUnitConversion.type";
import StringMultiRangeUnitConversion from "types/StringMultiRangeUnitConversion";

const numberArrayToStringArray = (arr: number[]): string[] => arr.map(String);

const numberArray2DToStringArray2D = (arr: number[][]): string[][] =>
    arr.map((subArr) => subArr.map(String));

const stringArrayToNumberArray = (arr: string[]): number[] => arr.map(Number);

const stringArray2DToNumberArray2D = (arr: string[][]): number[][] =>
    arr.map((subArr) => subArr.map(Number));

export const stringifyMultiRangeUnitConversion = (
    input: MultiRangeUnitConversion,
): StringMultiRangeUnitConversion => {
    return {
        base: input.base,
        baseUnits: input.baseUnits,
        operatingRange: numberArrayToStringArray(input.operatingRange),
        safe: numberArray2DToStringArray2D(input.safe),
        warning: numberArray2DToStringArray2D(input.warning),
        unsafe: numberArray2DToStringArray2D(input.unsafe),
    };
};

export const convertStringMultiRangeUnitConversionToNumbers = (
    input: StringMultiRangeUnitConversion,
): MultiRangeUnitConversion => {
    try {
        return {
            base: input.base,
            baseUnits: input.baseUnits,
            operatingRange: stringArrayToNumberArray(input.operatingRange),
            safe: stringArray2DToNumberArray2D(input.safe),
            warning: stringArray2DToNumberArray2D(input.warning),
            unsafe: stringArray2DToNumberArray2D(input.unsafe),
        };
    } catch (error) {
        console.error(
            "there was an error converting string multirange val to num",
            error,
        );
        throw new Error("error on string conversion");
    }
};
