import { memo } from "react";
import createInitials from "utils/createInitials";

interface UserBarProps {
    userName: string;
    isMobile: boolean;
}

export default memo(function UserBar({ userName, isMobile }: UserBarProps) {
    return (
        <div className="group flex justify-center items-center relative h-full w-6 sm:w-full">
            <div className="sm:mr-4 mr-2 flex justify-center items-center">
                <span className="sm:mx-4 text-sm md:text-lg">
                    Welcome, {isMobile ? createInitials(userName) : userName}
                </span>
            </div>
        </div>
    );
});
