import type DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import type DeviceStateDimension from "@srtlabs/m1_types/lib/Device/DeviceState/DeviceStateDimensions/DeviceStateDimension/DeviceStateDimension.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import { Fragment, memo, useMemo } from "react";
import CurrentDeviceCardContentEditDimensionsItem from "./CurrentDeviceCardContentEditDimensionsItem/CurrentDeviceCardContentEditDimensionsItem";
import type CurrentDeviceCardContentEditDimensionsProps from "./utilities/CurrentDeviceCardContentEditDimensions.props";
import DeviceDisplayDrawerItem from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerItem/DeviceDisplayDrawerItem.type";

export default memo(function CurrentDeviceCardContentEditDimensions({
    device,
    disabled,
    dimensions,
    drawers,
    updateEditable,
    updateDrawers,
    setHasErrors,
}: CurrentDeviceCardContentEditDimensionsProps): JSX.Element {
    /**
     * In note of a very specific case for FX250s, we want to hide the online settings
     * because they are not controllable by the user. This logic should be handled in a more robust way,
     * but this code will do for now. This finds the entry and removes it from the array, if available.
     */
    const filteredEntries = useMemo(() => {
        const entries = Object.entries(dimensions);
        if (device.type === "mobileDevice" && disabled) {
            const onlineIndicator = entries.find(
                (entry) => entry[0] === "online",
            );
            return entries.filter((entry) => entry !== onlineIndicator);
        }
        return entries;
    }, [dimensions]);

    const updateDrawer = (newDrawer: DeviceDisplayDrawerItem): void => {
        const newDrawers = drawers.map((drawer) => {
            if (drawer.drawerItemID !== newDrawer.drawerItemID) return drawer;
            return {
                ...newDrawer,
            };
        });
        updateDrawers(newDrawers);
    };

    return (
        <div className="flex flex-1 overflow-y-auto relative">
            <div className="flex w-full pt-4">
                <ul className="flex flex-1 h-full flex-col px-6 overflow-y-auto">
                    {filteredEntries.map(([metric, dimension]): JSX.Element => {
                        return (
                            <div key={metric}>
                                {Object.entries(dimension).map(
                                    ([displayType]) => {
                                        const key = metric + displayType;
                                        const drawer = drawers.find(
                                            (drawer) =>
                                                drawer.referenceID === metric &&
                                                drawer.type === displayType &&
                                                !drawer.hiddenFromDashboard,
                                        );

                                        if (
                                            !drawer ||
                                            !Object.prototype.hasOwnProperty.call(
                                                device.metrics,
                                                drawer?.referenceID || "",
                                            )
                                        ) {
                                            return <Fragment key={key} />;
                                        }

                                        return (
                                            <li
                                                key={key}
                                                className="flex w-full bg-white border-1 border-gray-700 flex-col mb-4 p-4 rounded-sm"
                                            >
                                                {drawer.type !==
                                                DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL ? (
                                                    <h3 className="whitespace-nowrap text-lg">
                                                        Status: {drawer.name}
                                                    </h3>
                                                ) : null}

                                                <CurrentDeviceCardContentEditDimensionsItem
                                                    key={key}
                                                    dimension={dimension}
                                                    disabled={disabled}
                                                    drawer={drawer}
                                                    updateDrawer={updateDrawer}
                                                    setHasErrors={(
                                                        bool,
                                                    ): void =>
                                                        setHasErrors(
                                                            metric,
                                                            bool,
                                                        )
                                                    }
                                                    value={
                                                        (
                                                            device.metrics[
                                                                drawer
                                                                    .referenceID
                                                            ] as DevicesMetricsMetric
                                                        ).value
                                                    }
                                                    onChange={(
                                                        dimension: DeviceStateDimension,
                                                    ): void => {
                                                        updateEditable({
                                                            ...dimensions,
                                                            [metric]: dimension,
                                                        });
                                                    }}
                                                />
                                            </li>
                                        );
                                    },
                                )}
                            </div>
                        );
                    })}
                </ul>
            </div>
        </div>
    );
});
