import type DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import { OccupancyOverride } from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
import dayjs from "dayjs";
import useDevices from "hooks/useDevices.hook";
import { memo, useContext, useMemo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import HourMinuteManager from "utils/HourMinuteManager";
import calculateTemperature from "utils/calculateTemperature";
import earliestTime from "utils/earliestTime";
import isDate from "utils/isDate";
import latestTime from "utils/latestTime";
import ZoneScheduleCalendarControls from "../../CurrentZoneDrawerHeader/ZoneScheduleCalendarControls/ZoneScheduleCalendarControls";
import ZoneCurrentDayContext from "../../CurrentZoneDrawerHeader/utilities/ZoneCurrentDayContext";
import ZoneScheduleCalendar from "./ZoneScheduleCalendar/ZoneScheduleCalendar";
import { CALENDAR_RESOURCES_OCCUPANCY } from "./utilities/CALENDAR_RESOURCES.values";
import type CalendarEvent from "./utilities/CalendarEvent.type";
import CalendarEventType from "./utilities/CalendarEventType.enum";
import { ZoneScheduleProps } from "./utilities/ZoneSchedule.props";
import eventIsOnDay from "./utilities/eventIsOnDay.func";
import occupiedToCalendar from "./utilities/occupiedToCalendar.func";
import {
    bookingToCalendar,
    srtRecommendedTemperatureToCalendar,
} from "./utilities/toCalendar";

export type EMSBooking = {
    eventName: string;
    startTime: string;
    endTime: string;
    bookingID: string;
};

export default memo(function ZoneSchedule({
    zone,
}: ZoneScheduleProps): JSX.Element {
    const { currentDay } = useContext(ZoneCurrentDayContext);
    const deviceIds = useMemo(
        () => zone.attributes.roomIdSchedules.map((id) => id),
        [zone],
    );
    const { data: devices, pendingFetchData } = useDevices(deviceIds);

    const recommendedTemp = useMemo((): CalendarEvent[] => {
        return (zone.attributes.store.srtRecommendedTemperatures || [])
            .map(srtRecommendedTemperatureToCalendar)
            .filter((item) => eventIsOnDay(item, currentDay));
    }, [zone.attributes.store.srtRecommendedTemperatures, currentDay]);

    const occupancyOverride = useMemo<CalendarEvent | null>(() => {
        const zoneOverride: OccupancyOverride =
            zone.attributes.store.occupancyOverride;

        const parsedOverride = HourMinuteManager.parseOverride(
            zoneOverride,
            currentDay,
        );

        if (!parsedOverride) return null;

        const { start, end } = parsedOverride;

        if (isDate(start) && isDate(end)) {
            return {
                start: dayjs(start),
                end: dayjs(end),
                resourceId: CALENDAR_RESOURCES_OCCUPANCY.resourceId,
                title: "Manual Override",
                type: CalendarEventType.OVERRIDE,
                occupied: true,
            };
        }

        return null;
    }, [zone.attributes.store.occupancyOverride, currentDay]);

    const roomSchedules = useMemo((): CalendarEvent[] => {
        if (!pendingFetchData) {
            let events: CalendarEvent[] = [];
            devices.forEach((device) => {
                const bookings = (
                    device.metrics.bookings as DevicesMetricsMetric<
                        EMSBooking[]
                    >
                ).value;
                const calendarEvents = bookings.map(bookingToCalendar);
                events = events.concat(calendarEvents);
            });
            return events.filter((event) => eventIsOnDay(event, currentDay));
        }
        return [];
    }, [deviceIds, currentDay, pendingFetchData, devices]);

    const motionDetection = useMemo<CalendarEvent[]>(
        () =>
            zone.attributes.store.occupiedTimeFrames
                ?.map(occupiedToCalendar)
                .filter((event) => eventIsOnDay(event, currentDay)) || [],
        [zone.attributes.store.occupiedTimeFrames, currentDay],
    );

    const fallbackEvent = useMemo<CalendarEvent>(
        () => ({
            start: dayjs(earliestTime(currentDay)),
            end: dayjs(latestTime(currentDay)),
            occupied: false,
            resourceId: "",
            title: `${calculateTemperature(
                zone.attributes.store.recommendedTemperature,
                false,
            )}℉`,
            type: CalendarEventType.RECOMENDATION,
        }),
        [currentDay, zone.attributes.store.recommendedTemperature],
    );

    const events = useMemo(() => {
        const events = [
            ...motionDetection,
            ...recommendedTemp,
            ...roomSchedules,
        ];

        if (occupancyOverride) {
            events.push(occupancyOverride);
        }

        if (events.length == 0) {
            events.push(fallbackEvent);
        }

        return events;
    }, [
        motionDetection,
        recommendedTemp,
        roomSchedules,
        occupancyOverride,
        fallbackEvent,
    ]);

    return (
        <div className="h-full flex flex-col">
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>
                    Zone Schedule
                </h2>
                <ZoneScheduleCalendarControls />
            </div>

            <ZoneScheduleCalendar events={events} />
        </div>
    );
});
