import { memo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import type CurrentZoneDrawerHeaderProps from "./utilities/CurrentZoneDrawerHeader.props";

// const SeperatorClass = "mx-1 text-bold";

export default memo(function CurrentZoneDrawerHeader({
    zone,
    organizationStore,
}: CurrentZoneDrawerHeaderProps): JSX.Element {
    const sublocationName = organizationStore.sublocation
        ? organizationStore.sublocation.name
        : organizationStore.zoneSublocationNames[zone._id];
    return (
        <div className="bg-white flex flex-row py-6 w-full border-b-1 border-gray-700 h-[80px] items-center px-4 font-nunito">
            <header className="w-full inline pl-12">
                <h1 className={CLASS_NAMES.drawer.drawerHeaderTitle}>
                    {zone.name}
                </h1>
                <span>
                    <p>
                        {/* {zone.type} <span className={SeperatorClass}>|</span> */}
                        {sublocationName}{" "}
                    </p>
                </span>
            </header>
        </div>
    );
});
