import Icon from "components/Icons/Icon/Icon";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import MapControllerButton from "../MapControllerButton/MapControllerButton";

interface MapControllerProps {
    zoomIn: (amount: number) => void;
    zoomOut: (amount: number) => void;
    isAtMaxZoom: () => boolean;
    isAtMinZoom: () => boolean;
}

export default observer(function MapController({
    zoomIn,
    zoomOut,
    isAtMaxZoom,
    isAtMinZoom,
}: MapControllerProps): JSX.Element {
    const { currentDevice, currentZone } = useQuery();
    const disableBasedOnDrawer = useMemo(
        () => Boolean(currentDevice || currentZone),
        [currentDevice, currentZone],
    );

    return (
        <div className="absolute bottom-10 left-10 flex flex-col bg-white z-[50] drop-shadow-[0_3px_6px_rgba(20,30,38,0.16)]">
            <MapControllerButton
                onClick={(): void => zoomIn(0.5)}
                disabled={isAtMaxZoom() || disableBasedOnDrawer}
            >
                <Icon name="plus" />
            </MapControllerButton>

            <MapControllerButton
                onClick={(): void => zoomOut(0.5)}
                disabled={isAtMinZoom() || disableBasedOnDrawer}
            >
                <Icon name="minus" />
            </MapControllerButton>
        </div>
    );
});
