import MenuItem from "components/Menu/utilities/MenuItem.interface";

export enum LIST_TYPE {
    ON = "on",
    OFF = "off",
}

const automationTypes: MenuItem<string | null>[] = [
    {
        data: LIST_TYPE.ON,
        text: "On: Automatically Update HVAC Setpoints",
    },
    {
        data: LIST_TYPE.OFF,
        text: "Off: Generate Recommendations ONLY",
    },
];

export default automationTypes;
