import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import { computed, makeAutoObservable, observable } from "mobx";
import { View } from "ol";
import OLMap from "ol/Map";
import * as olExtent from "ol/extent";
import { Geometry } from "ol/geom";
import {
    DoubleClickZoom,
    DragPan,
    DragZoom,
    Interaction,
    MouseWheelZoom,
    PinchZoom,
    defaults as defaultInteractions,
} from "ol/interaction";
import ImageLayer from "ol/layer/Image";
import VectorLayer from "ol/layer/Vector";
import { Projection } from "ol/proj";
import ImageStatic from "ol/source/ImageStatic";
import VectorSource from "ol/source/Vector";
import { StyleLike } from "ol/style/Style";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import LayerMap from "types/OpenLayers/LayerMap.type";
import LayerNames from "types/OpenLayers/LayerNames.type";
import ManagedFeatureUnion from "types/OpenLayers/ManagedFeatureUnion.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import FeatureManager from "./FeatureManager.class";
import LayerManager from "./LayersManager.class";

export default class OpenLayersStore {
    /**
     * Represents the instance of the OpenLayers map.
     * @type {OLMap | null}
     */
    @observable
    map: OLMap | null = null;

    /**
     * details related to map size, origin and others specified by the user
     */
    @observable
    mapDisplayDetails: MapDisplayDetails | null = null;

    @observable
    mapType: MAP_VIEW_TYPE = MAP_VIEW_TYPE.DEVICE;

    /**
     * Dictionary of available layers on a map, this does not include the image layer.
     */
    @observable
    layers: LayerMap = {};

    /**
     *
     * Encapsulates the array of features managed by this class.
     */
    @observable
    features: Map<string, ManagedFeatureUnion> = new Map();

    @computed
    get featureStyles(): {
        id: string;
        style: StyleLike | undefined;
        state: FeatureInteractiveState;
    }[] {
        return Array.from(this.features.values()).map((feature) => ({
            id: feature._id,
            style: feature.olFeature.getStyle(),
            state: feature.state,
        }));
    }

    /**
     * Represents the feature Manager so that it is created only once
     */
    @observable
    featureManager: FeatureManager | null = null;

    /**
     * Represents the layer Manager so that it is created only once
     */
    @observable
    layerManager: LayerManager | null = null;
    /**
     * Tracks the loading state of the OpenLayers store.
     * @type {Boolean}
     */
    @observable
    isLoading = true;

    /**
     * The minimum allowed zoom level for the map.
     * Adjust this value to set the minimum zoom level.
     * @type {number}
     */
    @observable
    minZoom = 2;

    /**
     * The maximum allowed zoom level for the map.
     * Adjust this value to set the maximum zoom level.
     * @type {number}
     */
    @observable
    maxZoom = 5;

    /**
     * The current number the map's zoom is set to.
     * As default it set to the minimum zoom number
     * @type {number}
     */
    @observable
    currentZoom = this.minZoom;

    private zoomInteractions = this.map
        ?.getInteractions()
        .getArray()
        .filter(
            (interaction: Interaction) =>
                interaction instanceof DoubleClickZoom ||
                interaction instanceof MouseWheelZoom ||
                interaction instanceof PinchZoom ||
                interaction instanceof DragZoom,
        );

    private dragPan = new DragPan();

    public disableZoom = (): void => {
        if (this.map && this.zoomInteractions) {
            this.zoomInteractions.map(
                (interaction) => this.map?.removeInteraction(interaction),
            );
        }
    };

    public enableZoom = (): void => {
        if (this.map && this.zoomInteractions) {
            this.zoomInteractions.map(
                (interaction) => this.map?.addInteraction(interaction),
            );
        }
    };

    public disablePan = (): void => {
        if (!this.map) return;
        const dragPanInteraction = this.map
            .getInteractions()
            .getArray()
            .find((interaction) => interaction instanceof DragPan);
        if (!dragPanInteraction) return;
        this.map.removeInteraction(dragPanInteraction);
    };

    public enablePan = (): void => {
        try {
            if (!this.map) {
                throw new Error("Map not initialized, cannot enable panning");
            }
            const dragPanInteraction = this.map
                .getInteractions()
                .getArray()
                .find((interaction) => interaction instanceof DragPan);
            //If there is already a map interaction we don't need to do anything here
            if (dragPanInteraction) return;
            this.map.addInteraction(this.dragPan);
        } catch (err) {
            console.error(err);
        }
    };
    /**
     * Represents the image layer that displays static images on the map.
     * @type {ImageLayer<ImageStatic> | null}
     */
    @observable
    imageLayer: ImageLayer<ImageStatic> | null = null;

    // Projection determines how the 3D world (spherical Earth) is represented in 2D space
    // (like on a flat map or computer screen). It defines the coordinate system used by
    // the map. For static images that don't necessarily correspond to real-world locations,
    // a custom projection (like "image-static" in this case) can be used to display the image
    // without any geospatial distortions. See: https://support.esri.com/en-us/gis-dictionary/search?q=projection
    @observable
    private imageProjection: Projection = new Projection({
        code: "image-static",
        units: "pixels",
        extent: [0, 0, 1, 1],
    });

    constructor() {
        makeAutoObservable(this);
    }

    public setIsLoading = (loading: boolean): void => {
        this.isLoading = loading;
    };

    /**
     * Sets up the imag layer with the provided image source and extent.
     * Also initializes the associated image projection for correct rendering.
     *
     * @param {string} imageSrc - The URL of the image source.
     * @param {number[]} extent - The extent of the image in the format [minX, minY, maxX, maxY].
     * @returns {void}
     */
    public setMapImageLayer = (imageSrc: string, extent: number[]): void => {
        this.imageProjection = new Projection({
            code: "image-static",
            units: "pixels",
            extent: extent,
        });

        this.imageLayer = new ImageLayer<ImageStatic>({
            source: new ImageStatic({
                url: imageSrc,
                projection: this.imageProjection,
                imageExtent: extent,
                imageSize: [extent[2], extent[3]],
            }),
        });
    };

    public setMapType = (type: MAP_VIEW_TYPE): void => {
        this.mapType = type;
    };

    /**
     * Initializes the map for a sublocation within the specified target element
     * using the provided extent. Sublocation maps take zoom and pan parameters
     * because a sublocation view may be a subset of a location view.
     * If the map doesn't exist and necessary layers and projections are set,
     * it creates a new map instance with the given settings.
     *
     * @param {HTMLDivElement} target - The target HTML element where the map will be rendered.
     * @param {number[]} extent - The extent of the map view in the format [minX, minY, maxX, maxY].
     * @param {number} zoomPercent - The percent of maximum zoom at which to initialize the map
     * @param {number} panXPercent - The percent of map width (max X) at which to initialize the map
     * @param {number} panYPercent - The percent of map height (max Y) at which to initialize the map
     * @returns {void}
     */
    public initializeSublocationMap = (
        target: HTMLDivElement,
        extent: number[],
        zoomPercent: number,
        panXPercent: number,
        panYPercent: number,
    ): void => {
        try {
            if (!this.imageLayer || !this.imageProjection) {
                throw new Error("Map requirements not met");
            }
            if (!this.map && this.imageLayer && this.imageProjection) {
                // Set current zoom to specified percentage of maximum zoom
                this.currentZoom =
                    (zoomPercent / 100) * (this.maxZoom - this.minZoom) +
                    this.minZoom;
                // Get the initial X position from the map width * initial X pan percentage
                const xPosition =
                    olExtent.getWidth(extent) * (panXPercent / 100);
                // Get the initial Y position from the map width * initial Y pan percentage
                const yPosition =
                    olExtent.getHeight(extent) * (panYPercent / 100);

                this.map = new OLMap({
                    target: target,
                    layers: [this.imageLayer], //at some point, should this be active layers? can other layers be hidden?
                    controls: [],
                    view: new View({
                        projection: this.imageProjection, // Use code or default projection
                        center: [xPosition, yPosition],
                        zoom: this.currentZoom,
                        maxZoom: this.maxZoom,
                        minZoom: this.minZoom,
                        extent: extent,
                        showFullExtent: true,
                    }),
                    interactions: defaultInteractions({
                        doubleClickZoom: false,
                        pinchRotate: false,
                        pinchZoom: false,
                        mouseWheelZoom: false,
                        keyboard: false,
                    }).extend([this.dragPan]),
                });

                // Proceeds to load layer and feature managers
                this.layerManager = new LayerManager(this.map, this);
                this.featureManager = new FeatureManager(this.map, this);
                this.setIsLoading(false);
            }
        } catch (error) {
            console.error("Failed to initialize map:", error);
        }
    };

    /**
     * Initializes the map for a location within the specified target element
     * using the provided extent. The map will be initialized centered and at
     * minimum zoom, because locations should always show the full context.
     * If the map doesn't exist and necessary layers and projections are set,
     * it creates a new map instance with the given settings.
     *
     * @param {HTMLDivElement} target - The target HTML element where the map will be rendered.
     * @param {number[]} extent - The extent of the map view in the format [minX, minY, maxX, maxY].
     * @returns {void}
     */
    public initializeLocationMap = (
        target: HTMLDivElement,
        extent: number[],
    ): void => {
        try {
            if (!this.imageLayer || !this.imageProjection) {
                throw new Error("Map requirements not met");
            }
            if (!this.map && this.imageLayer && this.imageProjection) {
                this.map = new OLMap({
                    target: target,
                    layers: [this.imageLayer], //at some point, should this be active layers? can other layers be hidden?
                    controls: [],
                    view: new View({
                        projection: this.imageProjection, // Use code or default projection
                        center: olExtent.getCenter(extent),
                        zoom: this.minZoom,
                        maxZoom: this.maxZoom,
                        minZoom: this.minZoom,
                        extent: extent,
                        showFullExtent: true,
                    }),
                    interactions: defaultInteractions({
                        doubleClickZoom: false,
                        pinchRotate: false,
                        pinchZoom: false,
                        mouseWheelZoom: false,
                        keyboard: false,
                    }).extend([this.dragPan]),
                });

                // Proceeds to load layer and feature managers
                this.layerManager = new LayerManager(this.map, this);
                this.featureManager = new FeatureManager(this.map, this);
                this.setIsLoading(false);
            }
        } catch (error) {
            console.error("Failed to initialize map:", error);
        }
    };

    private setCurrentZoom = (number: number): void => {
        if (this.map) {
            this.currentZoom = number;
        }
    };

    public isAtMaxZoom = (): boolean => {
        return this.currentZoom === this.maxZoom;
    };

    public isAtMinZoom = (): boolean => {
        return this.currentZoom === this.minZoom;
    };

    public toggleZoom(enabled: boolean): void {
        if (!this.map) {
            console.log("Map not available, returning");
            return;
        }
        const zoomInteractions = this.map
            .getInteractions()
            .getArray()
            .filter(
                (interaction) =>
                    interaction instanceof DoubleClickZoom ||
                    interaction instanceof MouseWheelZoom ||
                    interaction instanceof PinchZoom ||
                    interaction instanceof DragZoom,
            );
        zoomInteractions.forEach((interaction) =>
            interaction.setActive(enabled),
        );
    }

    /**
     * Enables/disenables user interaction to drag or pan around map.
     */
    public toggleDragPan = (enabled: boolean): void => {
        if (!this.map) {
            console.log("Map not available, returning");
            return;
        }
        const dragPanInteraction = this.map
            .getInteractions()
            .getArray()
            .find((interaction) => interaction instanceof DragPan);
        if (dragPanInteraction) {
            dragPanInteraction.setActive(enabled);
        }
    };

    /**
     * Zooms in on the map view by the specified amount.
     * If the map exists, it will animate the view to the new zoom level and center.
     *
     * @param {number} amount - The amount by which to increase the zoom level.
     * @returns {void}
     */
    public zoomIn = (amount: number): void => {
        if (this.map && this.currentZoom !== this.maxZoom) {
            const view = this.map.getView();
            const numToZoomIn = view.getZoom() ?? 0;
            const newZoom = Math.min(this.maxZoom, numToZoomIn + amount);
            this.setCurrentZoom(newZoom);
            view.animate({
                zoom: newZoom,
                duration: 150,
            });
        }
    };

    /**
     * Zooms out on the map view by the specified amount.
     * If the map exists, it will animate the view to the new zoom level and center.
     *
     * @param {number} amount - The amount by which to decrease the zoom level.
     * @returns {void}
     */
    public zoomOut = (amount: number): void => {
        if (this.map && this.currentZoom !== this.minZoom) {
            const view = this.map.getView();
            const numToZoomOut = view.getZoom() ?? 0;
            const newZoom = Math.max(this.minZoom, numToZoomOut - amount);
            this.setCurrentZoom(newZoom);

            view.animate({
                zoom: newZoom,
                duration: 150,
            });
        }
    };

    /**
     * Returns true or false based on whether the passed layer name is currently in the layer state.
     * @param {LayerNames} layerName the name of the layer to specify.
     * @returns {boolean}
     */
    public hasLayer = (layerName: LayerNames): boolean => {
        return Boolean(this.layers[layerName]);
    };

    /**
     * Returns true or false based on if there ARE layers present, but does not specify which layer. Use @see {hasLayer}
     * Does not include the image layer.
     */
    get areLayersPresent(): boolean {
        return Object.keys(this.layers).length > 0;
    }

    /**
     *  Given specified layer name, creates a layer representing the foreground where features matching that type should go.
     * @param {LayerNames} layerNames - @see VALID_LAYER_NAMES which is used to match the feature objects to their specified layer "type"
     */
    public createLayer = (layerName: LayerNames): void => {
        try {
            this.layerManager?.addLayer(layerName);
        } catch (err) {
            console.error(`Could not create layer named: ${layerName}`, err);
        }
    };

    /**
     * Adds intake of features to specified layer name.
     * @param layerName @see VALID_LAYER_NAMES The name of the layer you want to add the feature to
     * @param features the map of features by their id that's to be added to the specified layer.
     */
    public addFeaturesToLayers = (
        layerName: LayerNames,
        features: Map<string, ManagedFeatureUnion>,
    ): void => {
        //Extract openlayers specific features from feature object and put into array
        const olFeatures = [...features.values()].map(
            (feature) => feature.olFeature,
        );
        try {
            if (!this.layerManager) {
                throw new Error(
                    "Layer manager not initiated, could not add features to layer",
                );
            }
            this.layerManager.addFeaturesToLayer(layerName, olFeatures);
        } catch (error) {
            console.error(
                `${layerName} does not exist. Did not add features to layer.`,
                error,
            );
        }
    };

    public removeFeaturesFromLayers = (layerName: LayerNames): void => {
        try {
            this.layerManager?.removeFeaturesFromLayer(layerName);
        } catch (error) {
            console.error(
                `Could not find ${layerName}. Did not add features to layer.`,
                error,
            );
        }
    };

    public updateFeature = async <
        T extends Device | ZoneWithOverallHealth | MobileDevice | GroupedDevice,
    >(
        id: string,
        originalData: Partial<T> | T,
    ): Promise<void> => {
        try {
            if (!this.featureManager)
                throw new Error("Feature manager not ready to update features");
            if (!this.mapDisplayDetails)
                throw new Error(
                    "Map display details unavailable, cannot update feature.",
                );
            this.featureManager.updateFeature(
                id,
                originalData,
                this.mapDisplayDetails,
            );
        } catch (err) {
            console.error(`Unable to update feature ${id}`, err);
        }
    };

    public updateFeaturePosition = (
        device: Device | MobileDevice | GroupedDevice,
        position: { x: number; y: number },
    ): void => {
        try {
            if (!this.featureManager || !this.mapDisplayDetails)
                throw new Error(
                    `Could not update position of device ${device._id}. Insuffient map details`,
                );
            this.featureManager.updateFeaturePosition(
                device,
                position,
                this.mapDisplayDetails,
            );
        } catch (err) {
            console.error(`Could not update position of device ${device._id}`);
        }
    };

    /**
     * Updates the layers with the latest feature data after a feature has been updated
     * in the FeatureManager. Note: Does not refresh the feature itself (referring to it's state)! @see {featureManager.updateFeature}
     */
    private redrawLayersBasedLatestFeatureData = (
        managedFeatures: ManagedFeatureUnion[],
    ): void => {
        try {
            //Determine layers to update based on features (but remove layers that are not found or do not exist)
            const layers: VectorLayer<VectorSource<Geometry>>[] =
                managedFeatures
                    .map((managedFeature: ManagedFeatureUnion) => {
                        const layerName =
                            managedFeature.olFeature.getProperties()[
                                "layerName"
                            ];
                        if (this.layerManager) {
                            //
                            const layer = this.layerManager.getLayer(layerName);
                            return layer;
                        }
                        return null;
                    })
                    .filter(
                        (layer): layer is VectorLayer<VectorSource<Geometry>> =>
                            layer !== null,
                    );

            //Collect layer sources, and refresh layer for sources that are found
            layers.forEach((layer) => {
                const source = layer.getSource();
                source?.changed();
            });
        } catch (err) {
            console.error(
                "Unable to update layer with latest feature data",
                err,
            );
        }
    };

    public setMapDisplayDetails = (
        mapDisplayDetails: MapDisplayDetails,
    ): void => {
        this.mapDisplayDetails = mapDisplayDetails;
    };

    /**
     * Disposes of the existing map instance, if it exists, to clean up resources and prepare for reinitialization.
     * After calling this method, the map will be set to `null`, allowing it to be reinitialized with new data.
     *
     * @returns {void}
     */
    public resetMap = (): void => {
        try {
            if (!this.map) {
                console.warn(
                    "Attempted to reset the map, but no map was initialized.",
                );
                return;
            }

            this.featureManager?.resetFeatureStore();
            this.layerManager?.clearAllLayers();

            this.map.dispose();
            this.map = null;
            // Clear or reset other relevant properties
            this.currentZoom = this.minZoom;
            this.featureManager = null; // Reset feature manager
            this.layerManager = null; // Reset layer manager
            this.imageLayer = null; // Clear the image layer
        } catch (error) {
            console.error("Error occurred while resetting the map:", error);
        }
    };
}
