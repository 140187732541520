import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import findProblemsWithDevice from "hooks/useWebsockets/utilities/findProblemsWithDevice";
import DeviceService from "services/DeviceService";
import rootStore from "stores/rootStore";
import propagateDeviceChangesToMap from "utils/propagateDeviceChangesToMap";

type UseSaveDeviceProps = Omit<
    UseMutationOptions<
        Device,
        Error,
        Device,
        { prevDevice: Device | undefined } //passed context for onSettled flow
    >,
    "mutationFn" | "onSuccess" | "onError" | "onSettled"
>;

/**
 * Wraps the useMutation hook for saving a device.
 * It handles updating the device data in the query cache upon successful save
 * and displays snackbars for success or error cases.
 *
 * @param {Device} device - The device object to be saved.
 * @param {UseSaveDeviceProps} props - Configuration options for the mutation.
 * @returns {UseMutationResult<Device, Error, Device, { prevDevice: Device | undefined }>} The result object from the useMutation hook,
 *                                                               containing data, error, and status info about the mutation.
 */
export default function useSaveDevice(
    device: Device,
    props?: UseSaveDeviceProps,
): UseMutationResult<
    Device,
    Error,
    Device,
    { prevDevice: Device | undefined }
> {
    const queryClient = useQueryClient();
    const openLayersStore = rootStore.openLayersStore;
    return useMutation({
        mutationFn: DeviceService.saveDevice,
        onMutate: async (
            updatedDevice,
        ): Promise<{ prevDevice: Device | undefined }> => {
            //cancel any current queries (fetches so that they don't overwrite our current data)
            await queryClient.cancelQueries({
                queryKey: ["device", updatedDevice._id],
            });

            //snapshot of latest query data
            const prevDevice = queryClient.getQueryData<Device>([
                "device",
                updatedDevice._id,
            ]);
            //optimistic update
            queryClient.setQueryData<Device>(
                ["device", device._id],
                updatedDevice,
            );
            return { prevDevice };
        },
        onSuccess: async (returnedDevice: Device) => {
            try {
                // Compute and update the device health status.
                const updatedDevice = findProblemsWithDevice(returnedDevice);
                propagateDeviceChangesToMap({
                    updatedDevice,
                    updateFeature: openLayersStore.updateFeature,
                    mapViewType: openLayersStore.mapType,
                });
                rootStore.snackbarStore.enqueueSnackbar?.(
                    `Successfully updated: ${updatedDevice.headers.deviceName}`,
                    {
                        variant: "success",
                        autoHideDuration: 2500,
                    },
                );
            } catch (err) {
                console.error(
                    "On success device update after-effect failed. Device was updated but data may not have propagated.",
                    err,
                );
            }
        },
        onError: (err, updatedDevice, context) => {
            console.error(err);
            if (context?.prevDevice) {
                queryClient.setQueryData(
                    ["device", updatedDevice._id],
                    context.prevDevice,
                );
            }
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Unable to update settings for: ${device.headers.deviceName}`,
                {
                    variant: "error",
                    autoHideDuration: 3000,
                },
            );
        },
        onSettled: (device) => {
            if (device) {
                queryClient.invalidateQueries({
                    queryKey: ["device", device._id],
                });
            }
        },
        ...props,
    });
}
