import CurrentTab from "components/TabListItem/CurrentTab.type";
import CURRENT_ZONE_SIMPLE_TAB from "./CURRENT_ZONE_SIMPLE_TAB.enum";

const ZONE_SIMPLE_TAB_VALUES: Readonly<CurrentTab<CURRENT_ZONE_SIMPLE_TAB>[]> =
    Object.freeze([
        {
            icon: "gauge-low",
            name: "Status",
            value: CURRENT_ZONE_SIMPLE_TAB.STATUS,
        },
        {
            name: "Info",
            value: CURRENT_ZONE_SIMPLE_TAB.INFO,
            icon: "circle-info",
        },
        {
            icon: "clock-rotate-left",
            name: "History",
            value: CURRENT_ZONE_SIMPLE_TAB.LOGS,
        },
        {
            icon: "bell",
            name: "Alerts",
            value: CURRENT_ZONE_SIMPLE_TAB.ALERTS,
        },
    ]);

export default ZONE_SIMPLE_TAB_VALUES;
