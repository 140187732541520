import { memo } from "react";
import RackStatusShelfContainer from "./RackStatusShelfContainer/RackStatusShelfContainer";
import type RackStatusShelvesContainerProps from "./utilities/RackStatusShelvesContainer.props";

export default memo(function RackStatusShelvesContainer({
    shelves,
    currentShelfID,
    setCurrentShelfID,
}: RackStatusShelvesContainerProps): JSX.Element {
    return (
        <div className="flex-1 flex justify-end flex-col p-2 pt-0 border-2 border-t-0">
            {shelves.map(
                ([id, { value: device, key }]): JSX.Element => (
                    <RackStatusShelfContainer
                        key={key}
                        device={device}
                        setCurrentShelfID={setCurrentShelfID}
                        isCurrent={currentShelfID === id}
                    />
                ),
            )}
        </div>
    );
});
