import CalendarEvent from "./CalendarEvent.type";

/**
 * Given an event, check if the event falls on the given day to determine
 * whether or not to display it. Will also check if the given day is WITHIN
 * the event, i.e., the event start time is before the day and the end time
 * is after.
 * @param event CalendarEvent with a start and end time
 * @param day The current day to check against
 * @returns True or false -- whether the event falls on the day in question
 * and should be displayed
 */
export default function eventIsOnDay(event: CalendarEvent, day: Date): boolean {
    return (
        event.start.isSame(day, "day") ||
        event.end.isSame(day, "day") ||
        (event.start.toDate() < day && event.end.toDate() > day)
    );
}
