import { useState, useEffect } from "react";

type DelayedRenderProps = {
    children: JSX.Element;
    renderDelay?: number;
    skeletonStyle?: string;
};

/**
 * Wait renderDelay ms before rendering the child component. Useful to let an animation
 * complete before rendering all the items in a list.
 * @param children The child element of this component; the component to be rendered
 * @param renderDelay Number of ms to wait before rendering the child component
 * @param skeletonStyle CSS properties to be applied to the skeleton div before rendering;
 * useful to set height param so scroll bar doesn't jump around when the list is rendered
 * @returns Nothing until timeout, then the child element
 */
const DelayedRender = ({
    children,
    renderDelay,
    skeletonStyle = "",
}: DelayedRenderProps): JSX.Element | null => {
    const [isShown, setIsShown] = useState(false);

    useEffect(() => {
        // Wait specified amount of time, then set isShown to true
        const timer = setTimeout(() => {
            setIsShown(true);
        }, renderDelay);
        return (): void => clearTimeout(timer);
    }, []);

    // Render children if shown, otherwise render skeleton if defined, otherwise
    // return null and render nothing
    return isShown ? (
        children
    ) : skeletonStyle ? (
        <div className={skeletonStyle}></div>
    ) : null;
};

export default DelayedRender;
