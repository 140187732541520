import { QueryClientProvider } from "@tanstack/react-query";
import { SnackbarProvider } from "notistack";
import React from "react";
import { createRoot } from "react-dom/client";
import { BrowserRouter } from "react-router-dom";
import queryClient from "stores/queryClient";
import rootStore from "stores/rootStore";
import Maintanence from "views/Maintanence/Maintanence";
import App from "./App";
import "./styles/tailwind.css";

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

const container = document.getElementById("root");
const root = createRoot(container!);

//TODO: To Gain full capabilities of react-router-dom, we should migrate to createBrowserRouter

root.render(
    // allows us to use some strict rules to code react components
    <React.StrictMode>
        <QueryClientProvider client={queryClient}>
            {/* ^ --This is the query context provider managed by react-query, which manages data synchronization and caching for API Service calls across the application ^*/}

            {/**check if we are in maintenance mode */}
            {isMaintenanceMode ? (
                // TODO: move this to dockerfile with conditional build step. need to modify package.lock file to make it work.
                <Maintanence />
            ) : (
                <BrowserRouter>
                    {/** This is a wrapper for low level alerts */}

                    <SnackbarProvider
                        // placing the
                        anchorOrigin={{
                            vertical: "bottom",
                            horizontal: "right",
                        }}
                    >
                        <App userStore={rootStore.userStore} />
                    </SnackbarProvider>
                </BrowserRouter>
            )}
        </QueryClientProvider>
    </React.StrictMode>,
);

function stopIFrameOverlay(): void {
    for (const child of Array.from(document.body.children)) {
        if (child.tagName == "iframe") {
            child.setAttribute("style", "");
        }
    }
}
stopIFrameOverlay();
setInterval((): void => {
    stopIFrameOverlay();
}, 1000);
