import PolygonPoints from "@srtlabs/m1_types/lib/PolygonPoints/PolygonPoints.type";
import { Coordinate } from "ol/coordinate";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";

export function transformPolygonPointsToCoordinates(
    polygonPoints: PolygonPoints,
    map: MapDisplayDetails,
): Coordinate[][] {
    /**Polygon points is an array of arrays.
     * Coordinate can be [x,y], [x,y,z], or [x,y,z,m]
     * So go into the first array and for each array, of the polygon point,
     * and instead of {x,y}, make [x,y]
     */
    const coordinates: Coordinate[][] = polygonPoints.map((polygonPoint) => {
        return polygonPoint.map((coord) => {
            const [xOrigin, yOrigin] = map.origin;

            const x = coord.x / map.resolution - xOrigin / map.resolution;

            const y = Math.abs(
                coord.y / map.resolution + yOrigin / map.resolution,
            );
            return [x, y];
        });
    });

    return coordinates;
}
