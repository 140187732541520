import { makeAutoObservable } from "mobx";
import { Feature } from "ol";
import Map from "ol/Map";
import { Geometry } from "ol/geom";
import VectorLayer from "ol/layer/Vector";
import VectorSource from "ol/source/Vector";
import LayerNames from "types/OpenLayers/LayerNames.type";
import VALID_LAYER_NAMES from "types/OpenLayers/VALID_LAYER_NAMES.type";
import OpenLayersStore from "./OpenLayersStore.class";

/**
 * Represents the instance of LayerManager.
 * The LayerManager is responsible for managing OpenLayers map layers,
 * including adding and removing layers of type "Device" or "Zone".
 * This property serves as the central point through which layers can be manipulated
 * in the mapping solution.
 * @type {LayerManager | null}
 */
export default class LayerManager {
    constructor(
        private map: Map,
        private openLayersStore: OpenLayersStore,
    ) {
        makeAutoObservable(this);
    }

    public hasLayer = (name: LayerNames): boolean => {
        return Boolean(this.openLayersStore.layers[name]);
    };

    public addLayer = (name: LayerNames): void => {
        if (!this.map) {
            throw new Error(
                "Map needs to be initialized before adding layers.",
            );
        }

        if (this.hasLayer(name)) {
            console.warn(`Layer with name "${name}" already exists.`);
            return; // Early return if the layer already exists. This prevents overwriting the existing layer.
        }

        const source = new VectorSource(); // Empty VectorSource as no features are added here.

        const layer = new VectorLayer({
            source: source,
        });

        // Set the 'name' property of the layer, keep in tandem with key of layers map
        layer.set("name", name);

        this.openLayersStore.layers[name] = layer;
        this.map.addLayer(layer);
    };

    /**
     * Takes a feature returns the layer vector source that it belongs to. Note, this assumes that the feature has the property name "layerName" which is assigned to creation
     * methods for features. @see {LayerName}
     */
    public getLayer = (
        name: LayerNames,
    ): VectorLayer<VectorSource<Geometry>> | null => {
        try {
            const layer = this.openLayersStore.layers[name];
            if (!layer) return null;
            return layer;
        } catch (err) {
            console.error(`Unable to get layer by layer name for ${name}`, err);
            throw new Error(`Unable to get layer by layer name for ${name}`);
        }
    };

    public removeLayer = (name: LayerNames): void => {
        const layer = this.openLayersStore.layers[name];
        if (!layer) {
            console.warn(
                `Layer with name "${name}" not found. Removal aborted.`,
            );
            return;
        }
        this.map.removeLayer(layer);
        delete this.openLayersStore.layers[name];
    };

    public addFeaturesToLayer = (
        layerName: LayerNames,
        features: Feature<Geometry>[],
    ): void => {
        // Check for valid layer name
        if (!VALID_LAYER_NAMES.includes(layerName)) {
            throw Error(`Invalid layer name: ${layerName}`);
        }

        const layer = this.openLayersStore.layers[layerName];

        // Check if the layer exists
        if (!layer) {
            throw new Error(
                `Layer with name "${layerName}" not found. Feature addition aborted.`,
            );
        }

        const source = layer.getSource();

        try {
            source?.addFeatures(features);
        } catch (error) {
            console.error(
                `Failed to add feature to layer "${layerName}":`,
                error,
            );
            throw error;
        }
    };

    /**
     * Removes a single feature from a specified layer
     * @param {LayerNames} layerName The name of the layer to remove the features from
     * @param {Feature<Geometry>} feature The feature to be removed
     * @returns {void}
     */
    public removeFeatureFromLayer = (
        layerName: LayerNames,
        feature: Feature<Geometry>,
    ): void => {
        // Check for valid layer name
        if (!VALID_LAYER_NAMES.includes(layerName)) {
            throw Error(`Invalid layer name: ${layerName}`);
        }

        const layer = this.openLayersStore.layers[layerName];

        // Check if the layer exists
        if (!layer) {
            console.warn(
                `Layer with name "${layerName}" not found. Feature addition aborted.`,
            );
            return;
        }
        const source = layer.getSource() as VectorSource<Geometry>;
        try {
            source.removeFeature(feature);
        } catch (error) {
            console.error(
                `Failed to add features to layer "${layerName}":`,
                error,
            );
            throw error;
        }
    };

    /**
     * Removes all features from a specified layer
     * @param layerName The name of the layer to remove the features from
     */
    public removeFeaturesFromLayer = (layerName: LayerNames): void => {
        const layer = this.openLayersStore.layers[layerName];
        if (layer) {
            const source = layer.getSource() as VectorSource<Geometry>;
            source.clear(true);
        }
    };

    public clearAllLayers = (): void => {
        for (const layerName of Object.keys(this.openLayersStore.layers)) {
            this.removeFeaturesFromLayer(layerName as LayerNames);
            this.removeLayer(layerName as LayerNames);
        }
    };

    /**
     * Takes a feature returns the layer vector source that it belongs to. Note, this assumes that the feature has the property name "layerName" which is assigned to creation
     * methods for features. @see {LayerName}
     */
    public getLayerFromFeature = (
        olFeature: Feature<Geometry>,
    ): VectorLayer<VectorSource<Geometry>> | null | undefined => {
        try {
            const layerName: string | undefined =
                olFeature.getProperties()["layerName"];
            if (!layerName)
                throw new Error(
                    `Could not retrieve layer. Layer name not found for ${olFeature.getId()}`,
                );
            const layer = this.getLayer(layerName as LayerNames);
            return layer;
        } catch (err) {
            console.warn(
                `Unable to get layer from feature ${olFeature.getId()}`,
                err,
            );
        }
    };
}
