import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import { computed, makeObservable, observable } from "mobx";

/**
 * @description Mobx store to handle the subscription, usage, and cashing of mobile devices
 */
export default class MobileDeviceStore {
    /**
     * the cached devices on the server, so that we dont have to make a new call every time
     */
    @observable
    public cache: Record<string, Device> = {};

    /**
     * the list of device ids that we are subscribed to on the webserver,
     * this could potentially be removed to just be the keys of @see cache
     */
    @observable
    public subscribed: string[] = [];

    /**
     * the list of devices that we are subscribed to in the cache
     * @see Device
     * @see this.subscribed
     * @see this.cache
     */
    @computed({ keepAlive: true })
    get mobileDevices(): Device[] {
        const arr: Device[] = [];
        for (let i = 0; i < this.subscribed.length; i++) {
            const device = this.cache[this.subscribed[i]];

            if (device) arr.push(device);
        }

        return arr;
    }

    public constructor() {
        makeObservable(this);
    }
}
