/* eslint-disable prettier/prettier */
const pascalsToAtmospheres = (x: number): number => x / 101325;
const atmospheresToPascals = (x: number): number => x * 101325;
const pascalsToBars = (x: number): number => x / 100000;
const barsToPascals = (x: number): number => x * 100000;
const pascalsToPsi = (x: number): number => x / 6894.76;
const psiToPascals = (x: number): number => x * 6894.76;
const atmospheresToBars = (x: number): number => x * 1.01325;
const barsToAtmospheres = (x: number): number => x / 1.01325;
const psiToBars = (x: number): number => x / 14.5038;
const barsToPsi = (x: number): number => x * 14.5038;
const atmospheresToPsi = (x: number): number => x * 14.696;
const psiToAtmospheres = (x: number): number => x / 14.696;

const pascalsToIwg = (x: number): number => x / 248.84;
const iwgToPascals = (x: number): number => x * 248.84;
const psiToIwg = (x: number): number => x * 27.7076;
const iwgToPsi = (x: number): number => x / 27.7076;
const barsToIwg = (x: number): number => x * 401.463;
const iwgToBars = (x: number): number => x / 401.463;
const atmospheresToIwg = (x: number): number => x * 406.782;
const iwgToAtmospheres = (x: number): number => x / 406.782;

const pressureConversions = {
    Pa: {
        atm: pascalsToAtmospheres,
        bar: pascalsToBars,
        psi: pascalsToPsi,
        iwg: pascalsToIwg,
    },
    atm: {
        Pa: atmospheresToPascals,
        bar: atmospheresToBars,
        psi: atmospheresToPsi,
        iwg: atmospheresToIwg,
    },
    bar: {
        Pa: barsToPascals,
        atm: barsToAtmospheres,
        psi: barsToPsi,
        iwg: barsToIwg,
    },
    psi: {
        Pa: psiToPascals,
        bar: psiToBars,
        atm: psiToAtmospheres,
        iwg: psiToIwg,
    },
    iwg: {
        Pa: iwgToPascals,
        bar: iwgToBars,
        atm: iwgToAtmospheres,
        psi: iwgToPsi,
    },
};

export default pressureConversions;
