import SublocationMap from "@srtlabs/m1_types/lib/Organization/Locations/Location/Sublocations/Sublocation/SublocationMaps/SublocationMap/SublocationMap.type";
import useDevices from "hooks/useDevices.hook";
import useZones from "hooks/useZones.hook";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import rootStore from "stores/rootStore";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import useImageDimensions from "utils/OpenLayers/utilities/useImageDimensions";
import SublocationMapCanvasContainer from "../SublocationMapCanvasContainer/SublocationMapCanvasContainer";
import { useFetchMapImageQuery } from "../utilities/fetchImageSrc";

export default observer(function SublocationMapContainer(): JSX.Element {
    const snackbarStore = rootStore.snackbarStore;
    const organizationStore = rootStore.organizationStore;
    const openLayersStore = rootStore.openLayersStore;
    const [currentMapKey, setCurrentMapKey] = useState("");

    const { data: devices, pendingFetchData: pendingDeviceFetchData } =
        useDevices([
            ...organizationStore.activeDevices,
            ...organizationStore.mobileDevices,
        ]);
    const { data: zones, pendingFetchData: pendingZoneFetchData } = useZones(
        organizationStore.activeZones,
    );

    /**
     * Queries @see fetchImageSrc only when the enabled values are all true. Caches the result of the call
     * under "sublocationMapSrcImage" followed by the distinct path where the map is to be found
     */
    const {
        data: imgSrc,
        isError: isImgSrcError,
        error: imgSrcFetchError,
        isLoading: isImgSrcLoading,
    } = useFetchMapImageQuery({
        key: "sublocation",
        mapIdentifier: {
            orgId: organizationStore.orgId,
            locId: organizationStore.locId,
            sublId: organizationStore.sublId,
            currentMapKey,
        },
        queryKey: [
            "sublocationMapImgSrc",
            organizationStore.orgId,
            organizationStore.locId,
            organizationStore.sublId,
            currentMapKey,
        ],
    });

    const { dimensions, isImageLoading } = useImageDimensions(
        imgSrc ? imgSrc : "",
    );

    const data = useMemo(() => {
        if (openLayersStore.mapType === MAP_VIEW_TYPE.DEVICE) {
            return Array.from(devices.values());
        } else if (openLayersStore.mapType === MAP_VIEW_TYPE.ZONE) {
            return Array.from(zones.values());
        } else {
            return [];
        }
    }, [
        openLayersStore.mapType,
        pendingDeviceFetchData,
        pendingZoneFetchData,
        devices,
        zones,
    ]);

    //Test to see if delay in org store is causing zone/device not set.
    const activeSublocationMaps = useMemo(():
        | [string, SublocationMap][]
        | null => {
        if (organizationStore.sublocation) {
            return Object.entries(organizationStore.sublocation.maps);
        }
        return null;
    }, [organizationStore.sublocation]);

    /**
     * The key and object that contains information about the current sublocation map
     */
    const map = useMemo(() => {
        if (activeSublocationMaps) {
            const map = activeSublocationMaps.find(
                ([name]) => name === currentMapKey,
            );
            if (map) return map[1];
        }
        return null;
    }, [currentMapKey, activeSublocationMaps]);

    useEffect(() => {
        // Update currentMapKey based on available maps
        if (
            currentMapKey &&
            !activeSublocationMaps?.some(([name]) => name === currentMapKey)
        ) {
            setCurrentMapKey("");
        } else if (!currentMapKey && activeSublocationMaps?.[0][0]) {
            setCurrentMapKey(activeSublocationMaps?.[0][0]);
        }

        // Set map type based on map filters
        if (map) {
            const types =
                map.filters.find((filters) => filters.types)?.types || [];
            types.includes("zone")
                ? openLayersStore.setMapType(MAP_VIEW_TYPE.ZONE)
                : openLayersStore.setMapType(MAP_VIEW_TYPE.DEVICE);
        }
    }, [
        currentMapKey,
        activeSublocationMaps,
        organizationStore.locId,
        organizationStore.sublId,
        map,
        data,
    ]);

    /**
     * Displays an error as a snackbar if one is detected when fetching the map image.
     */
    useEffect(() => {
        if (isImgSrcError) {
            snackbarStore.enqueueSnackbar?.(`Failed to load map image.`, {
                variant: "error",
            });
        }
    }, [imgSrcFetchError, isImgSrcError]);

    const showLoading = useMemo(
        () =>
            isImgSrcLoading ||
            isImageLoading ||
            pendingDeviceFetchData ||
            pendingZoneFetchData,
        [
            isImageLoading,
            pendingZoneFetchData,
            pendingDeviceFetchData,
            isImgSrcLoading,
        ],
    );

    return (
        <div className="flex-1 flex-col flex items-center h-full text-sm">
            {isImgSrcError ? (
                <h1 className="m-auto text-sm">
                    Failed to load image, please retry or contact srt support
                </h1>
            ) : showLoading || !dimensions || !map || !imgSrc ? (
                <h1 className="m-auto text-sm">loading image</h1>
            ) : (
                <SublocationMapCanvasContainer
                    key={imgSrc}
                    mapViewMode={openLayersStore.mapType}
                    dimensions={dimensions}
                    isImageLoading={isImageLoading}
                    imageSrc={imgSrc}
                    map={{
                        resolution: map.resolution,
                        origin: map.origin,
                        defaultZoomPercent: map.defaultZoomPercent,
                        defaultPanXPercent: map.defaultPanXPercent,
                        defaultPanYPercent: map.defaultPanYPercent,
                    }}
                    data={data}
                />
            )}
        </div>
    );
});
