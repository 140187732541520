import RelatedNotificationListContainer from "components/RelatedNotificationsList/RelatedNotificationContainer";
import CURRENT_ZONE_HVAC_TAB from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/utilities/CURRENT_ZONE_HVAC_TAB.enum";
import { memo } from "react";
import type ZoneDrawerContentProps from "./utilities/ZoneDrawerContent.props";
import ZoneInfo from "./ZoneInfo/ZoneInfo";
import ZoneLogs from "./ZoneLogs/ZoneLogs";
import ZoneSchedule from "./ZoneSchedule/ZoneSchedule";
import ZoneSettings from "./ZoneSettings/ZoneSettings";
import ZoneStatus from "./ZoneStatus/ZoneStatus";

export default memo(function ZoneDrawerContent({
    currentTab,
    zone,
}: ZoneDrawerContentProps): JSX.Element {
    if (!currentTab || !zone) return <></>;

    return (
        <>
            {currentTab === CURRENT_ZONE_HVAC_TAB.STATUS ? (
                <ZoneStatus zone={zone} />
            ) : currentTab === CURRENT_ZONE_HVAC_TAB.INFO ? (
                <ZoneInfo zone={zone} />
            ) : currentTab === CURRENT_ZONE_HVAC_TAB.SETTINGS ? (
                <ZoneSettings zone={zone} />
            ) : currentTab === CURRENT_ZONE_HVAC_TAB.SCHEDULE ? (
                <ZoneSchedule zone={zone} occupancy={true} />
            ) : currentTab === CURRENT_ZONE_HVAC_TAB.EVENTS ? (
                <ZoneLogs zone={zone} />
            ) : currentTab === CURRENT_ZONE_HVAC_TAB.ALERTS ? (
                <RelatedNotificationListContainer
                    notificationSrcIds={zone.devices}
                />
            ) : (
                <></>
            )}
        </>
    );
});
