import { useEffect, useState } from "react";

/**
 *  For detecting a click outside of the ref and it's children.
 * @param root The reference for the Div element to look if we are outside of it
 * @param onClickOutside The function that will hide the component once a click is detected outside it and it's children
 * @param controller Will exclude external handlers from the click outside ref detection
 * @returns a boolean that determines if the user clicked outside or not
 */
export default function useMouseOver<T extends HTMLElement = HTMLElement>(
    root: T | null,
    onMouseover?: (e: MouseEvent) => void,
    onMouseout?: (e: MouseEvent) => void,
): { mousedOver: boolean } {
    const [mousedOver, setMousedOver] = useState(false);

    useEffect(() => {
        const listener = (event: MouseEvent): void => {
            // https://developer.mozilla.org/en-US/docs/Web/API/Node/contains
            // if the event target is the root itself, or a descendant of the root
            const elem = event.target as Node;
            const isRootOrDescendant = root?.contains(elem);
            if (isRootOrDescendant) {
                onMouseover?.(event);
                setMousedOver(true);
            } else {
                onMouseout?.(event);
                setMousedOver(false);
            }
        };

        document.addEventListener("mouseover", listener);
        // document.addEventListener("mouseout", listener);

        return (): void => {
            document.removeEventListener("mouseover", listener);
            // document.removeEventListener("mouseout", listener);
        };
    }, [root, onMouseover, onMouseout]);

    return { mousedOver };
}
