import { useEffect } from "react";
import Map from "ol/Map";

/**
 * Custom hook to update the size of an OpenLayers map instance when the window is resized.
 *
 * This hook listens to the window's resize event and updates the map's size accordingly.
 * It's essential to ensure that the map fills its container fully even after the window is resized.
 *
 * @param {Map | null} mapInstance - The OpenLayers map instance to be updated on window resize.
 */
export default function useUpdateMapInstanceSize(
    mapInstance: Map | null,
): void {
    useEffect(() => {
        const handleResize = (): void => {
            if (mapInstance) {
                mapInstance.updateSize();
            }
        };

        window.addEventListener("resize", handleResize);
        return () => {
            window.removeEventListener("resize", handleResize);
        };
    }, [mapInstance]);
}
