import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import Button from "components/Button/Button";
import { memo } from "react";
import { Control, Controller } from "react-hook-form";
import { FORM_CLASSNAMES } from "styles/classNameConstants";
import StringMultiRangeSafeVal from "types/StringMultiRangeSafeVal";

interface ToggleBaseContainerProps {
    // handleToggleBase: (health: HEALTH_STATUS) => void;
    title: string;
    control: Control<StringMultiRangeSafeVal>;
}

/**
 * Contains logic for the toggle of the base input field.
 */
export default memo(function ToggleBaseContainer({
    // handleToggleBase,
    title,
    control,
}: ToggleBaseContainerProps): JSX.Element {
    return (
        <Controller
            name={"base"}
            control={control}
            render={({
                field: { value, name, onChange },
                fieldState,
            }): JSX.Element => {
                const handleToggleBase = (base: HEALTH_STATUS): void => {
                    try {
                        let newBase: HEALTH_STATUS;
                        switch (base) {
                            case HEALTH_STATUS.SAFE:
                                newBase = HEALTH_STATUS.UNSAFE;
                                break;
                            case HEALTH_STATUS.UNSAFE:
                                newBase = HEALTH_STATUS.WARNING;
                                break;
                            default:
                                newBase = HEALTH_STATUS.SAFE;
                                break;
                        }
                        onChange(newBase);
                    } catch (error) {
                        console.error("Unable to toggle base health", error);
                    }
                };

                return (
                    <>
                        <Button
                            variant="contained"
                            className="my-4"
                            type="button"
                            name={name}
                            onClick={(): void => handleToggleBase(value)}
                        >
                            {title}
                        </Button>
                        {fieldState.error?.message ? (
                            <p className={FORM_CLASSNAMES.error}>
                                {fieldState.error?.message}
                            </p>
                        ) : null}
                    </>
                );
            }}
        />
    );
});
