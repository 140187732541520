import Icon from "components/Icons/Icon/Icon";
import { observer } from "mobx-react-lite";
import { Coordinate } from "ol/coordinate";

interface MapToolTip {
    visible: boolean;
    content: string;
    coordinate: Coordinate;
    toolTipRef: React.MutableRefObject<HTMLDivElement | null>;
}

export default observer(function MapToolTip({
    visible,
    content,
    coordinate,
    toolTipRef,
}: MapToolTip) {
    if (!visible) return null;
    return (
        <div
            ref={toolTipRef}
            style={{ top: coordinate[1], left: coordinate[0], zIndex: 99 }}
            className="absolute p-0 m-0"
        >
            <div className="relative">
                {" "}
                <div className=" bg-white border-1 border-obsidian p-2">
                    <p className="m-0">{content}</p>
                </div>
                <div
                    style={{ bottom: "-10px" }}
                    className="absolute left-0 flex justify-start items-start h-auto"
                >
                    {" "}
                    <Icon
                        name="caret-down"
                        className="absolute top-[-19.75px]"
                        size={"sm"}
                    />
                </div>
            </div>
        </div>
    );
});
