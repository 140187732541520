import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import CalendarEventType from "../../../utilities/CalendarEventType.enum";
import type CalendarItemProps from "./utilities/CalendarItem.props";
import colors from "./utilities/colors.data";

const CalendarItemBaseClassName =
    "absolute p-2 hover:shadow-lg hover:pointer border-1 hover:border-obsidian border-gray-700 rounded overflow-hidden hover:min-h-16" as const;

export default memo(function CalendarItem({
    zIndex,
    bottom,
    event,
    time,
    top,
}: CalendarItemProps): JSX.Element {
    const useRightSide = useMemo(() => {
        return event.type == CalendarEventType.RECOMENDATION;
    }, [event.type]);

    const color = useMemo(() => {
        if (!useRightSide) {
            return colors.left.true;
        }

        if (event.occupied) {
            return colors.right.true;
        }

        return colors.right.false;
    }, [useRightSide, event]);

    const className = useMemo(() => {
        return extendClassName(
            CalendarItemBaseClassName,
            color,
            zIndex,
            "hover:z-40",
        );
    }, [useRightSide, zIndex, color]);

    const style = useMemo(() => {
        const s = {
            top: `${top}rem`,
            width: "240px",
            bottom: `calc(100% - ${bottom}rem)`,
        };

        if (useRightSide) {
            return {
                ...s,
                right: "4px",
            };
        }

        return {
            ...s,
            left: 150,
        };
    }, [useRightSide, top, bottom]);

    /*
    const _handle = useMemo(() => {
        const style = useRightSide
            ? {
                  top: 0,
                  right: -8,
                  borderTop: "8px solid transparent",
                  borderBottom: "8px solid transparent",
                  borderLeftWidth: "8px",
              }
            : {
                  top: 0,
                  left: -7,
                  borderTop: "8px solid transparent",
                  borderBottom: "8px solid transparent",
                  borderRightWidth: "8px",
              };

        return (
            <div
                className={extendClassName(
                    "absolute border-" + "obsidian"
                )}
                style={style}
            />
        );
    }, [color, useRightSide]);
    */

    return (
        <>
            <div className={className} style={style}>
                {/*handle*/}
                <p className="text-xs">{event.title}</p>
                <span className="text-xs">
                    {time.start} - {time.end}
                </span>
            </div>
        </>
    );
});
