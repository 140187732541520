import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";

/**
 * Transforms the position of a device for accurate rendering on the OpenLayers map,
 * taking into account the device's original position, the map's resolution, and the map's origin.
 *
 * NOTE:  By default, OpenLayers considers the origin (0,0) to be the bottom-left corner.
 *        So positive y-values go upwards.
 *        Ensure the image and the device coordinates match this convention.
 *
 *        Also, our origin typically looks like [0,0,0] but this is not generally for 2D maps. This means that our coordinates were/are based on a top-left origin,
 *        where positive y-values increase downwards
 *
 * @param {Device} device - The device containing position information to be transformed.
 * @param {CombinedMap} map - The map configuration, which includes resolution and origin data.
 * @returns {[number, number]} - The transformed position [x, y] of the device for the OpenLayers map.
 */
export default function transformDevicePosition(
    position: { x: number; y: number },
    map: MapDisplayDetails,
): [number, number] {
    // Extract the x and y origins from the map's origin configuration.
    const [xOrigin, yOrigin] = map.origin;

    // Transform the x-coordinate of the device (no changes needed here):
    const x = (position.x / map.resolution - xOrigin / map.resolution) * 1;

    // Transform the y-coordinate of the device. Because the openlayers discrepancy they are by default not only flipped but offset downwards from the expected position
    // Convert the negative y-coordinate to positive:
    const y =
        Math.abs(position.y / map.resolution + yOrigin / map.resolution) * 1;

    return [x, y];
}
