/**
 * Takes an object and uses the key,value pairs to transform it into a query string. As an example:
 * const obj = {a: 1, b : 23, c : "te!@#st"}
 * will become
 * a=1&b=23&c=te!%40%23st
 * @param obj takes an object that matches the params for the encodeURICompnent, see: https://developer.mozilla.org/en-US/docs/Web/JavaScript/Reference/Global_Objects/encodeURIComponent
 * @returns a query string
 */
export default function serialize(obj: {
    [x: string]: string | number | boolean;
}): string {
    return Object.keys(obj)
        .map((key) => `${key}=${encodeURIComponent(obj[key])}`)
        .join("&");
}
