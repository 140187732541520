import { useQueries } from "@tanstack/react-query";
import ZoneService from "services/ZoneService";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";

type useZonesResults = {
    /**
     * Data to be returned from each query
     */
    data: Map<string, ZoneWithOverallHealth>;

    /**
     * Indicates if there is any remaining queries in the fetch that have yet to be completed
     */
    pendingFetchData: boolean;
};

/**
 * Fetches data for zones and returns all that are successful.
 * Checks if passed param is empty, and resolves early if so.
 * @param {string[]} zoneIds  The zones to be retrieved.
 * @returns {useZonesResults} An object containing an array of fetched data (zones) and a boolean indicating if any of the data fetching operations are still pending.
 */

export default function useZones(zoneIds: string[]): useZonesResults {
    return useQueries({
        queries: zoneIds.map((id) => ({
            queryKey: ["zone", id],
            queryFn: async (): Promise<ZoneWithOverallHealth | null> => {
                if (zoneIds.length === 0) return Promise.resolve(null);
                return await ZoneService.getZone(id);
            },
        })),
        combine: (results) => {
            const zoneMap: Map<string, ZoneWithOverallHealth> = new Map();
            results.forEach((result) => {
                if (result.data) {
                    zoneMap.set(result.data._id, result.data);
                }
            });
            return {
                data: zoneMap,
                pendingFetchData: results.some((result) => result.isPending),
            };
        },
    });
}
