import { useNavigate } from "react-router-dom";
import serialize from "utils/serialize";

interface useNavigateToLocation {
    navigateToLocation: (
        view: "maps" | "fleet",
        params: {
            orgId: string;
            locId?: string;
            sublId?: string;
        },
    ) => void;
    generatePath: (
        view: "maps" | "fleet",
        params: {
            orgId: string;
            locId?: string;
            sublId?: string;
        },
    ) => string;
}

/**
 * A hook that takes into context the current org, and,
 * depending on the specified ids, navigates to the appropriate
 * pathname.
 *
 * Can specify navigation to map or list view
 */
export function useNavigateToLocation(): useNavigateToLocation {
    const navigate = useNavigate();

    /**
     * A function that, given the specified paramters, immediately navigates to the path. Meant to be used with onClicks or within other functions.
     * @param {"maps" | "fleet"} view the view that the query should default to
     * @param params Currently, compatible with the orgId, locId, sublId parameters.
     */
    const navigateToLocation = (
        view: "maps" | "fleet",
        params: {
            orgId: string;
            locId?: string;
            sublId?: string;
        },
    ): void => {
        const basePathname = `/home/${view}`;
        const { orgId, locId, sublId } = params;
        const definedParams = {
            orgId,
            ...(locId && { locId }),
            ...(sublId && { sublId }),
        };

        navigate({
            pathname: basePathname,
            search: serialize(definedParams),
        });
    };

    /**
     * Generates a query and returns it to be used as a string to be used for anchor links.
     * @param {"maps" | "fleet"} view the view that the query should default to
     * @param params Currently, compatible with the orgId, locId, sublId parameters.
     * @returns {string} the query string for the url to navigate to
     */
    const generatePath = (
        view: "maps" | "fleet",
        params: {
            orgId: string;
            locId?: string;
            sublId?: string;
        },
    ): string => {
        const basePathname = `/home/${view}`;
        const { orgId, locId, sublId } = params;
        const definedParams = {
            orgId,
            ...(locId && { locId }),
            ...(sublId && { sublId }),
        };
        return `${basePathname}?${serialize(definedParams)}`;
    };

    return { navigateToLocation, generatePath };
}
