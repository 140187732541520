import Button from "components/Button/Button";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo, useState } from "react";
import DeviceService from "services/DeviceService";
import ButtonTextOnPullProps from "./ButtonTextOnPullProps.props";

export interface DeviceExecutionFunction {
    deviceID: string;
    executionReferenceId: string;
    properties: unknown;
}

/**A display for an execution function.
 *
 */
export default observer(function ButtonTextOnPull({
    id,
    properties,
    executionReferenceId,
    deviceID,
    startInterval,
    stopInterval,
}: ButtonTextOnPullProps) {
    //TODO: Move to be generic logic eventually.
    const [disabled, setDisabled] = useState(false);
    const handleClick = useCallback(
        async ({
            deviceID,
            executionReferenceId,
            properties,
        }: DeviceExecutionFunction): Promise<void> => {
            /**
             * We want to stop the interval that runs @see executablesStore.getExecutionFunctions
             * while we submit the execution
             */
            stopInterval();
            // toggleInterval();
            await DeviceService.executeDeviceFunction({
                deviceID,
                executionReferenceId,
                properties,
            });
            setDisabled(true);
            /**
             * Once the call is sent, we don't know exactly when the device will send an updated status
             * To counter this, we will poll for the status every second to get as close
             * to the timing as we can
             */
            startInterval(1);

            /**
             * After 6 seconds (how long we will disable the button), we will stop the interval
             * and restart it again at the regular cadence of 6 seconds.
             * You can probably combine the start/stop step into one (restart), if additional time given.
             */
            setTimeout(() => {
                setDisabled(false);
                stopInterval();
                startInterval(6);
            }, 7000);
        },
        [
            deviceID,
            executionReferenceId,
            properties,
            DeviceService.executeDeviceFunction,
        ],
    );

    /**
     * We don't really know what the properties object will hold,
     * or if there can be multiple actions within a single property
     * To deal with this complexity, we can choose to render the
     * button at the time of deriving the value txt. This will
     * hopefully prevent the rendering of an empty button
     */
    const deriveProperty = useMemo(() => {
        for (const [key, value] of Object.entries(
            properties as Record<string, never>,
        )) {
            if (value) {
                return (
                    <Button
                        id={id}
                        name={key}
                        className="capitalize mr-0 mb-2 shadow-md"
                        variant="contained"
                        disabled={disabled}
                        onClick={(): Promise<void> =>
                            handleClick({
                                deviceID,
                                executionReferenceId,
                                properties,
                            })
                        }
                    >
                        {disabled ? "Saving..." : value}
                    </Button>
                );
            }
        }
    }, [properties, disabled]);

    return <>{deriveProperty || null}</>;
});
