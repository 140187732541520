import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import useDevices from "hooks/useDevices.hook";
import { default as useURLQuery } from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import rootStore from "stores/rootStore";
import toggleSetParamsId from "utils/toggleSetParamsId";
import DeviceRowItem from "../DeviceRowItem/DeviceRowItem";
import FleetControls from "../FleetControls/FleetControls";
import FleetList from "../FleetList/FleetList";
import fleetColHeaders from "../utilities/FleetColHeaders.data";
import FLEET_SORT_OPTIONS from "../utilities/FleetSortOptions.enum";
import useFleetSort from "../utilities/useDeviceFleetSort";

export default observer(function DeviceListContainer() {
    const { setParams, currentDevice } = useURLQuery();

    const { data, pendingFetchData } = useDevices(
        rootStore.organizationStore.activeDevices,
    );

    const sortedDataInput = useMemo(() => {
        return pendingFetchData ? [] : [...data.values()];
    }, [data, pendingFetchData]);

    const { sorted, setSortOption, reverse, setReverse } =
        useFleetSort(sortedDataInput);

    const setDeviceAsCurrentDevice = useCallback(
        (deviceId: string): void => {
            setParams({
                currentDevice: toggleSetParamsId(deviceId, currentDevice),
            });
        },
        [setParams, currentDevice],
    );

    return (
        <>
            <FleetControls
                reverse={reverse}
                setReverse={setReverse}
                setSortOption={setSortOption}
                initialSort={{
                    title: "Status",
                    type: FLEET_SORT_OPTIONS.STATUS,
                    ascending: true,
                }}
                options={fleetColHeaders}
            />
            <FleetList
                onClickItem={setDeviceAsCurrentDevice}
                items={sorted}
                isLoading={pendingFetchData}
                renderRow={({ item, onClickItem }): JSX.Element => (
                    <DeviceRowItem
                        device={item as Device}
                        onClickOfDevice={(): void => onClickItem(item._id)}
                    />
                )}
            />
        </>
    );
});
