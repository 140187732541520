import { QueryFunction } from "@tanstack/react-query";
import queryClient from "stores/queryClient";

type FetchQueriesData<T> = {
    queries: string[][];
    queryFn: QueryFunction<T, string[], never> | undefined;
};

/**
 * FetchQueries will fetch data for all queries (e.g., [device, 123]). If the data is
 * stale or invalidated, it will be refetched using the query function. If it is not,
 * it will be returned from the cache. This is different from ensureQueryData, which
 * will always fetch from the cache even if the data is invalidated.
 * @param queries An array of query keys. Should always be of size two for example queryKey: ["device", "123"]
 * @param queryFn The query function used to fetch the data for each query key.
 */
export default async function fetchQueries<T>({
    queries,
    queryFn,
}: FetchQueriesData<T>): Promise<PromiseSettledResult<Awaited<T>>[]> {
    const promises: Promise<T>[] = [];
    queries.forEach((queryKey) => {
        promises.push(
            queryClient
                .fetchQuery({
                    queryKey: queryKey,
                    queryFn,
                })
                .catch((error) => {
                    console.error(
                        `Error fetching data for query key: ${queryKey}`,
                        error,
                    );
                    throw error;
                }),
        );
    });

    try {
        const res = await Promise.allSettled(promises);
        return res;
    } catch (error) {
        console.error("Error in fetchQueriesData:", error);
        throw error;
    }
}
