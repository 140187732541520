import ToggleButtonGroupContext, {
    ToggleButtonGroupContextType,
} from "components/ToggleButtonGroup/ToggleButtonGroupContext";
import { useContext } from "react";

export default function useToggleButtonGroup<
    T,
>(): ToggleButtonGroupContextType<T> {
    const context = useContext(ToggleButtonGroupContext);
    if (!context) {
        throw new Error(
            "useToggleButtonGroup must be used within a ToggleButtonGroup",
        );
    }
    return context as ToggleButtonGroupContextType<T>;
}
