import { useCallback, useEffect, useState } from "react";

export default function useNetworkHandler(): {
    isOnline: boolean;
} {
    const [isOnline, setOnlineStatus] = useState(true);

    const changeStatus = useCallback((): void => {
        setOnlineStatus(navigator.onLine);
    }, []);

    useEffect(() => {
        window.addEventListener("online", changeStatus);
        window.addEventListener("offline", changeStatus);
        return (): void => {
            window.removeEventListener("online", changeStatus);
            window.removeEventListener("offline", changeStatus);
        };
    }, [navigator.onLine]);

    return { isOnline: isOnline };
}
