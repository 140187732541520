import AppProps from "App.props";
import ConnectionStatusBanner from "components/ConnectionStatusBanner/ConnectionStatusBanner";
import useKeycloak from "hooks/useKeycloak";
import useNetworkHandler from "hooks/useNetworkHandler";
import useQueryContext from "hooks/useQuery/useQueryContext";
import UseQueryContext from "hooks/useQuery/utilities/UseQueryContext";
import { observer } from "mobx-react-lite";
import { useSnackbar } from "notistack";
import { useEffect } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router-dom";
import AutoRunManager from "stores/AutoRunManager";
import ReactionManager from "stores/ReactionManager";
import rootStore from "stores/rootStore";
import Home from "views/Home/Home";
import Admin from "views/Home/HomeContentContainer/Admin/Admin";
import AnalyticsContainer from "views/Home/HomeContentContainer/AnalyticsContainer/AnalyticsContainer";
import Base from "views/Home/HomeContentContainer/Base/Base";
import Fleet from "views/Home/HomeContentContainer/Fleet/Fleet";
import Logs from "views/Home/HomeContentContainer/Logs/Logs";
import Maps from "views/Home/HomeContentContainer/Maps/Maps";
import NotificationsPage from "views/Home/NotificationContainer/NotificationsPage";
import M1LoadingTemplate from "views/M1LoadingTemplate";
import Root from "views/Root/Root";
import SiteOffline from "views/SiteOffline";

export default observer(function App({ userStore }: AppProps): JSX.Element {
    const navigate = useNavigate();
    const location = useLocation();
    const queryContextValue = useQueryContext();
    const networkStatus = useNetworkHandler();

    useEffect(() => {
        const checkMobile = (): void => {
            rootStore.viewportStore.setIsMobile(window.innerWidth <= 768);
        };

        // Check mobile on initial load
        checkMobile();

        // Add resize event listener
        window.addEventListener("resize", checkMobile);

        // Cleanup function
        return (): void => {
            // Remove resize event listener
            window.removeEventListener("resize", checkMobile);
        };
    }, []);

    /**
     * Use effect for setting up the AutoRunManager, and cleaning it up when the application ends
     * @see AutoRunManager
     */
    useEffect(() => {
        const autoRunManager = AutoRunManager;
        autoRunManager.run();
        return (): void => autoRunManager.cleanup();
    }, []);

    useEffect(() => {
        const reactionManager = ReactionManager;
        reactionManager.setupReactions();

        return (): void => {
            reactionManager.dispose();
        };
    }, []);

    /**
     * Exists to cleanup reactions in substores to avoid memory leaks.
     * Will run when the entire app dismounts.
     */
    useEffect(() => {
        return (): void => {
            rootStore.disposeAllReactions();
        };
    }, []);
    const { enqueueSnackbar } = useSnackbar();

    /**
     * setEnqueueSnackbar sets the enqueueSnackbar in the global snackbarStore class
     * allows mobx stores to put out notifications
     */
    useEffect(() => {
        rootStore.snackbarStore.setEnqueueSnackbar(enqueueSnackbar);
    }, [enqueueSnackbar]);

    // redriect to home if the user i not logged in
    useEffect(() => {
        const inHome = location.pathname.includes("/home");
        if (rootStore.userStore._id && !inHome) {
            navigate("/home");
            return;
        }
    }, [navigate, rootStore.userStore._id]);

    const {
        token,
        logout,
        authState,
        initializationFailed,
        connectingToKeyCloak,
    } = useKeycloak();

    useEffect(() => {
        rootStore.apiStore.setToken(token);
    }, [token]);

    useEffect(() => {
        rootStore.userStore.setLogout((): void => {
            navigate("/");
            logout();
        });
    }, [logout]);

    if (!networkStatus.isOnline) {
        return <SiteOffline />;
    }

    if (!rootStore.websocketStore.connected && !userStore._id) {
        return (
            <M1LoadingTemplate loading={true}>
                <p>Loading...</p>
            </M1LoadingTemplate>
        );
    }

    return (
        <>
            <div className="w-screen h-screen flex flex-col overflow-y-auto overflow-x-hidden">
                <UseQueryContext.Provider value={queryContextValue}>
                    {" "}
                    {/* ^ --This is our own query context for URL and param management. To be removed over time in favor of react-router-dom v6 ^*/}
                    <Routes>
                        <Route
                            path="/"
                            element={
                                <Root
                                    userStore={userStore}
                                    authState={authState}
                                    initializationFailed={initializationFailed}
                                    connectingToKeyCloak={connectingToKeyCloak}
                                />
                            }
                        />
                        <Route path="/home/*" element={<Home />}>
                            <Route
                                index
                                element={<Base userStore={userStore} />}
                            />

                            <Route path="fleet/*" element={<Fleet />} />

                            <Route
                                path="maps/*"
                                element={
                                    <Maps
                                        organizationStore={
                                            rootStore.organizationStore
                                        }
                                    />
                                }
                            />
                            <Route
                                path="analytics/*"
                                element={<AnalyticsContainer />}
                            />
                            <Route
                                path="notifications/*"
                                element={
                                    <NotificationsPage
                                        orgId={
                                            rootStore.organizationStore.orgId
                                        }
                                    />
                                }
                            />

                            <Route path="logs/*" element={<Logs />} />

                            <Route path="admin/*" element={<Admin />} />
                        </Route>
                    </Routes>
                </UseQueryContext.Provider>
                {/** used for notification (websocket errors and other serious connection notifications) */}
                <ConnectionStatusBanner
                    readyState={rootStore.websocketStore.readyState}
                />
            </div>
        </>
    );
});
