import StringLinearScale from "types/StringLinearScale";
import * as Yup from "yup";
import { numberStringSchema, validNumStrRegex } from "./utils.schema";

/**
 * Validates loosely that values scaledMin and scaledMax, when filled, fall within an accurate range
 * where the scaledMin must be less than scaled Max
 * Does not validate if either is an empty string, which is considered valid
 * and converted to null upon form submission
 */
const linearScaledMultiRangeValidationSchema: Yup.ObjectSchema<StringLinearScale> =
    Yup.object().shape(
        {
            //There is to be no validation, aside from assuring it's a number, for source Min and Max
            sourceMin: numberStringSchema.default(""),
            sourceMax: numberStringSchema.default(""),

            //However for scaled Min, we want to validate against scaledMax but only when scaled Max is
            //filled.
            scaledMin: numberStringSchema.default("").when(["scaledMax"], {
                is: (scaledMax: string) => scaledMax,
                then: (schema) =>
                    schema.test(
                        "scaledMin-less-than-max",
                        "Scaled Min must be less than Scaled Max",
                        (scaledMin, { createError, path, parent }) => {
                            if (scaledMin === "") {
                                return true;
                            }
                            const minValue = parseFloat(scaledMin);
                            const maxValue = parseFloat(parent.scaledMax);

                            if (isNaN(minValue)) {
                                return createError({
                                    path: path,
                                    message: "Must be a valid number.",
                                });
                            }

                            if (minValue === maxValue) {
                                return createError({
                                    path: path,
                                    message:
                                        "Min and Max values cannot equal each other",
                                });
                            }

                            if (minValue > maxValue) {
                                return createError({
                                    path: path,
                                    message:
                                        "Scaled Min must be less than Scaled Max",
                                });
                            }

                            return true;
                        },
                    ),
                //Otherwise, just make sure it's a valid number but can also be an empty string
                otherwise: (schema) => {
                    return schema.test(
                        "validNum",
                        "Must be a valid number",
                        (scaledMin) => {
                            if (scaledMin === "") return true;
                            const parsed = parseFloat(scaledMin);
                            if (isNaN(parsed)) return false;
                            return validNumStrRegex.test(scaledMin);
                        },
                    );
                },
            }),
            //Do the same for scaledMax
            scaledMax: numberStringSchema.default("").when(["scaledMin"], {
                is: (scaledMin: string) => scaledMin,
                then: (schema) =>
                    schema.test(
                        "scaledMax-more-than-min",
                        "Scaled Max must be more than Scaled Min",
                        (scaledMax, { createError, path, parent }) => {
                            if (scaledMax === "") {
                                return true;
                            }
                            const minValue = parseFloat(parent.scaledMin);
                            const maxValue = parseFloat(scaledMax);

                            if (isNaN(maxValue)) {
                                return createError({
                                    path: path,
                                    message: "Must be a valid number",
                                });
                            }

                            if (maxValue === minValue) {
                                return createError({
                                    path: path,
                                    message:
                                        "Min and Max values cannot equal each other",
                                });
                            }

                            if (maxValue < minValue) {
                                return createError({
                                    path: path,
                                    message:
                                        "Scaled Max must be more than Scaled Min",
                                });
                            }

                            return true;
                        },
                    ),
                otherwise: (schema) => {
                    return schema.test(
                        "validNum",
                        "Must be a valid number",
                        (scaledMax) => {
                            if (scaledMax === "") return true;
                            const parsed = parseFloat(scaledMax);
                            if (isNaN(parsed)) return false;
                            return validNumStrRegex.test(scaledMax);
                        },
                    );
                },
            }),

            //Again no validation needed for these fields.
            scaledDataType: Yup.string().default(""),
            scaledUnits: Yup.string().default(""),
        },
        [["scaledMin", "scaledMax"]],
    );

export default linearScaledMultiRangeValidationSchema;
