import { Coordinate } from "ol/coordinate";
import { Polygon } from "ol/geom";
import OLMap from "ol/Map";
import findTopLeftPoint from "utils/findTopLeftPoint";

/**
 * Calculates the pixel position of the topmost point of a polygon.
 *
 * @param {ol/geom/Polygon} geometry - The polygon geometry. Currently only works with zones. Other geoms, like circles need a different calculation
 * @param {ol/Map} map - OpenLayers map. This helps in converting coordinates to pixels.
 * @param {number} [tooltipHeight=50] - The height of the tooltip. We subtract this from the y-coordinate
 *                                      to make sure the tooltip appears above the polygon.
 *
 * @returns {number[]} - Returns the pixel coordinates.
 */
function getTopLeftmostPointPixel(
    geometry: Polygon,
    map: OLMap,
    tooltipHeight = 50,
): number[] {
    const coordinates: Coordinate[] = geometry.getCoordinates()[0]; // Assuming the geometry is a polygon

    // Find the top-most point of the polygon
    // const topLeftPoint = coordinates.reduce((highest, coord) => {
    //     return coord[1] > highest[1] ? coord : highest; // Find the highest y value
    // }, coordinates[0]);
    const topLeftPoint = findTopLeftPoint(coordinates);

    // Convert the top-most point to pixel values
    const pixel = map.getPixelFromCoordinate(topLeftPoint);
    const roundedPixel = pixel.map(Math.round);

    // Adjust for the tooltip height
    roundedPixel[1] = roundedPixel[1] - tooltipHeight;

    return roundedPixel;
}

export default getTopLeftmostPointPixel;
