import { memo } from "react";
import { Controller } from "react-hook-form";
import ScaledRangeContainerProps from "./ScaledRangeContainerProps.props";
import capitalize from "utils/capitalize";
import LinearScale from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScale/LinearScale.type";
import InputField from "components/InputField/InputField";

export default memo(function ScaledRangeContainer({
    title,
    minLabel,
    minFieldName,
    maxLabel,
    maxFieldName,
    control,
    trigger,
    errors,
}: ScaledRangeContainerProps): JSX.Element {
    /**
     * Determine whether the input field should be of type number or text,
     * depending on the fieldName
     * @param value
     * @returns
     */
    const determineInputType = (
        fieldName: keyof LinearScale,
    ): "text" | "number" => {
        if (fieldName === "scaledUnits" || fieldName === "scaledDataType") {
            return "text";
        } else {
            return "number";
        }
    };

    return (
        <li className="flex flex-1 flex-col border-t-1 border-primary-lightGray mt-4">
            <span className="capitalize mt-2">{title}</span>
            <ul className="flex flex-1 flex-row">
                <li>
                    <div className="flex flex-row">
                        <Controller
                            name={minFieldName}
                            control={control}
                            render={({ field, fieldState }): JSX.Element => (
                                <InputField
                                    label={capitalize(minLabel)}
                                    onChange={(event): void => {
                                        field.onChange(event);
                                        //If there is an error detected in the opposite field, triggers
                                        //validation to rerun to check if there was a change that resolved it
                                        errors[maxFieldName] &&
                                            trigger(maxFieldName);
                                    }}
                                    type={determineInputType(minFieldName)}
                                    value={field.value}
                                    error={fieldState.error?.message || ""}
                                />
                            )}
                        />
                        <Controller
                            name={maxFieldName}
                            control={control}
                            render={({ field, fieldState }): JSX.Element => (
                                <InputField
                                    value={field.value}
                                    label={capitalize(maxLabel)}
                                    type={determineInputType(maxFieldName)}
                                    error={fieldState.error?.message || ""}
                                    onChange={(event): void => {
                                        field.onChange(event);
                                        errors[minFieldName] &&
                                            trigger(minFieldName);
                                    }}
                                />
                            )}
                        />
                    </div>
                </li>
            </ul>
        </li>
    );
});
