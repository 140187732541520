import { observer } from "mobx-react-lite";
import NotificationListItemRow from "../NotificationListItemRow/NotificationListItemRow";
import TableNotificationItem from "../NotificationTableHeader/util/TableNotificationItem.type";
import TableBodyWithSingleRow from "../TableBodyWithSingleRow";

interface NotificationBodyProps {
    loading: boolean;
    error: boolean;
    sortedNotifications: TableNotificationItem[];
}

export default observer(function NotificationBody({
    loading,
    error,
    sortedNotifications,
}: NotificationBodyProps) {
    if (error) {
        return (
            <TableBodyWithSingleRow
                message={"There was an error loading notifications."}
            />
        );
    }

    if (loading) {
        return <TableBodyWithSingleRow message={"Loading notifications..."} />;
    }
    return (
        <tbody>
            {sortedNotifications.map((notification) => (
                <NotificationListItemRow
                    key={notification.id}
                    notification={notification}
                />
            ))}
        </tbody>
    );
});
