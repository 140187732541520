import { memo } from "react";
import SVG from "react-inlinesvg";
import type IconProps from "./Icon.props";
import getIconSize from "./getIconSize.util";
import iconMap from "./icons.data";

export default memo(function Icon(props: IconProps): JSX.Element {
    const {
        name,
        width = 35,
        height = 35,
        color = "inherit",
        size,
        fill,
        src,
        ...rest
    } = props;

    let iconSrc;

    if (name) {
        iconSrc = iconMap[name];
        if (!iconSrc) {
            console.error(`Icon not found in map: ${name}`);
            throw new Error(`Icon not found in map: ${name}`);
        }
        iconSrc =
            process.env.REACT_APP_ICON_BASE_URL +
            "/frontend/fontawesome/sharp-light/" +
            iconSrc;
    } else if (src) {
        iconSrc = src;
    } else {
        console.error(
            "Cannot render icon: Either 'name' or 'src' prop must be provided.",
        );
        throw new Error("Either 'name' or 'src' prop must be provided.");
    }

    return (
        <SVG
            src={iconSrc}
            width={size ? getIconSize(size) : width}
            height={size ? getIconSize(size) : height}
            color={color}
            fill={fill}
            {...rest}
        />
    );
});
