import FeatureDisplayState from "types/OpenLayers/FeatureDisplayState";

/**
 * Defines color families for zones. Each color family consists of different variations:
 * - background: The background color of the zone
 * - active: The color of the zone when active
 * - selected: The color of the zone when selected
 * - border: The color used for the zone border.
 *
 * @typedef {Object} ZoneColorFamily
 * @property {string} background - Background color
 * @property {string} border - Border color in Hex format.
 *
 * @type {{[color: string]: FeatureDisplayColorFamily}}
 */
const zoneColorFamilies: {
    [health: string]: FeatureDisplayState;
} = {
    safe: {
        default: {
            background: "rgba(124, 156, 239, 0.3)",
            border: "#2E61E6",
        },
        hover: {
            background: "rgba(98,136,236, 0.6)",
            border: "#2E61E6",
        },
        selected: {
            background: "rgba(46, 97, 230, 0.75)",
            border: "#2E61E6",
        },
    }, // blue
    warning: {
        default: {
            background: "rgba(248, 189, 93, 0.3)",
            border: "#E8920A",
        },
        hover: {
            background: "rgba(232, 146, 10, 0.6)",
            border: "#E8920A",
        },
        selected: {
            background: "rgba(247, 175, 60, 0.75)",
            border: "#E8920A",
        },
    }, // orange
    unsafe: {
        default: {
            background: "rgba(223, 128, 118, 0.3)",
            border: "#C43B2D",
        },
        hover: {
            background: "rgba(196, 59, 45, 0.6)",
            border: "#C43B2D",
        },
        selected: {
            background: "rgba(196, 59, 45, 0.75)",
            border: "#C43B2D",
        },
    }, // red
};

export default zoneColorFamilies;
