import ZoneEvents from "@srtlabs/m1_types/lib/Cassandra/ZoneEvents/ZoneEvents.type";
import { useQuery } from "@tanstack/react-query";
import Spinner from "components/Icons/Spinner/Spinner";
import ZoneLogsTable from "components/ZoneDrawerContainer/ZoneLogsTable/ZoneLogsTable";
import { memo, useEffect, useMemo, useState } from "react";
import ZoneService from "services/ZoneService";
import rootStore from "stores/rootStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import ZoneLog from "types/ZoneLog.type";
import ZoneLogsRowItemProps from "./ZoneLogsRowItem/utilities/ZoneLogsRowItem.props";
import type ZoneLogsProps from "./utilities/ZoneLogs.props";
import temporaryRemoveDuplicates from "./utilities/temporaryRemoveDuplicates.func";

export enum LIST_TYPE {
    FLEET,
    LOG,
}
export default memo(function ZoneLogs({ zone }: ZoneLogsProps): JSX.Element {
    const [initialLoadComplete, setInitialLoadComplete] = useState(false);

    const {
        data: logs,
        isLoading,
        isError,
        error,
    } = useQuery({
        queryKey: ["zone-logs", zone._id],
        queryFn: (): Promise<[ZoneLog[], ZoneEvents[]]> =>
            //TODO: Update to a settle or incorporate useQueries
            Promise.all([
                ZoneService.getDeviceLogs(zone),
                ZoneService.getZoneEvents(zone),
            ]),
        refetchInterval: 3000,
        refetchIntervalInBackground: false,
    });

    useEffect(() => {
        //Check if initial load is complete
        if (!initialLoadComplete && !isLoading && !isError) {
            setInitialLoadComplete(true);
        }
    }, [initialLoadComplete, isLoading, isError]);

    useEffect(() => {
        if (isError) {
            console.error(error);
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to retrieve logs for ${zone.name}`,
                {
                    variant: "error",
                },
            );
        }
    }, [isError]);

    const formattedLogs = useMemo(() => {
        if (!logs) return [];

        const [deviceLogs, zoneEvents] = logs;

        return [
            ...deviceLogs
                .filter(({ error }) => !error)
                .map(({ value }) => value)
                .flat()
                .map(
                    ({
                        message,
                        device_id,
                        event_id,
                        event_time,
                        event_type,
                    }): ZoneLogsRowItemProps => ({
                        id: event_id,
                        message: message,
                        itemID: device_id,
                        time: new Date(event_time),
                        event_type: event_type,
                    }),
                ),
            ...temporaryRemoveDuplicates(zoneEvents).map(
                ({
                    id,
                    message,
                    source,
                    start_time,
                    event_time,
                    zone_id,
                    event_type,
                }): ZoneLogsRowItemProps => ({
                    id: id,
                    message: message,
                    source: source,
                    time: new Date(event_time || start_time),
                    itemID: zone_id,
                    event_type: event_type,
                }),
            ),
        ].sort((a, b) => (a.time > b.time ? -1 : a.time == b.time ? 0 : 1));
    }, [logs]);

    return (
        <div className="h-full flex flex-col">
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>History</h2>
            </div>
            <div className={CLASS_NAMES.drawer.logsContainer}>
                {isLoading && !initialLoadComplete ? (
                    <div className="flex flex-row items-center h-min mx-16">
                        {" "}
                        <Spinner variant="spinner-third" size={"sm"} />
                        <p className="px-4 py-2">Loading</p>{" "}
                    </div>
                ) : formattedLogs && formattedLogs.length > 0 ? (
                    <ZoneLogsTable logs={formattedLogs} />
                ) : (
                    <p className="py-4 px-12 h-min">
                        There are no logs available for this zone.
                    </p>
                )}
            </div>
        </div>
    );
});
