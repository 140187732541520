/* eslint-disable @typescript-eslint/no-explicit-any */
import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import { action, makeAutoObservable, observable } from "mobx";
import { MapBrowserEvent } from "ol";
import Feature from "ol/Feature";
import OLMap from "ol/Map"; //avoid naming collisions
import { Geometry } from "ol/geom";
import Point from "ol/geom/Point";
import { StyleLike } from "ol/style/Style";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import FeatureType from "types/OpenLayers/FeatureType.type";
import ManagedFeatureUnion from "types/OpenLayers/ManagedFeatureUnion.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import { getDeviceStyle } from "utils/OpenLayers/Features/Device/getDeviceStyle";
import transformDevicePosition from "utils/OpenLayers/Features/Device/utilities/transformDevicePosition";
import { getSublocationOverlayStyle } from "utils/OpenLayers/Features/SublocationOverlay/getSublocationOverlayStyle";
import { getZoneStyle } from "utils/OpenLayers/Features/Zones/getZoneStyle";
import capitalize from "utils/capitalize";
import OpenLayersStore from "./OpenLayersStore.class";
import { hoverCallbackProps } from "./types/types";
import featureCreationFunctions from "./utils/FeatureCreationFunctions.util";
import featureUpdateFunctions from "./utils/FeatureUpdateFunctions.util";
import getFeatureStatus from "./utils/getFeatureStatus";

/**
 * Represents an instance of FeatureManager.
 * The FeatureManager is responsible for managing features (i.e., zones, devices, visual objects on a map).
 * This property serves as a central point through which features can be directly manipulated or retrieved
 */
export default class FeatureManager {
    //Because openlayers typing is referencing any, we will allow this here
    @observable
    private mapClickHandler?: (event: MapBrowserEvent<any>) => void;

    @observable
    private mapOnHoverHandler?: (event: MapBrowserEvent<any>) => void;

    constructor(
        private map: OLMap,
        private openLayersStore: OpenLayersStore,
    ) {
        makeAutoObservable(this);
    }
    /**
     * Retrieves a feature by its id (which is something all the types have in common)
     * from the managed features array.
     *
     * *NOTE: If you have not manually set the feature Id the same as the type id when
     * creating the feature, this will be null because the id is not defined.
     * @param id The id of the feature to retrieve.
     * @returns The feature with the specified id, or null if not found.
     */
    public getFeatureById = (id: string): ManagedFeatureUnion | null => {
        return this.openLayersStore.features.get(id) || null;
    };

    /**
     * This is cyclical
     * @param feature
     */
    @action
    public setFeature = (feature: ManagedFeatureUnion): void => {
        this.openLayersStore.features.set(feature._id, { ...feature });
    };

    /**
     * Bulk action function that creates multiple features at a time and adds them to the
     * features observable map.
     * @param type @type {FeatureType} the type that indicates which map the feature will belong too
     * @param data @type {Device | ZoneWithOverallHealth | MobileDevice | GroupedDevice} the data of the feature
     * @param map @type {SublocationMap} pass map metadata for positioning and scaling calculations
     */
    public createAndAddFeaturesToStore = <
        T extends Device | ZoneWithOverallHealth | MobileDevice | GroupedDevice,
    >(
        data: T[],
        type: FeatureType,
        map: MapDisplayDetails,
    ): void => {
        data.forEach((item) => {
            this.createAndAddFeatureToStore(type, item, map);
        });
    };

    /**
     * Adds a new feature to the managed features array.
     *
     */
    public async createAndAddFeatureToStore<
        T extends Device | Zone | MobileDevice | GroupedDevice,
    >(
        type: FeatureType,
        originalData: T,
        map: MapDisplayDetails,
    ): Promise<void> {
        const createFeature = featureCreationFunctions[type];
        // If no creation function is found, throw an error
        if (!createFeature) {
            throw new Error(`Unsupported feature type: ${type}`);
        }

        const olFeature = await createFeature(originalData, map, "default");
        const featType = capitalize(originalData.type) as FeatureType;
        const status = await getFeatureStatus(
            originalData.type as FeatureType,
            originalData,
        );
        const existingFeature = this.getFeatureById(originalData._id);
        const state: FeatureInteractiveState = existingFeature
            ? existingFeature.state
            : "default";

        const newFeature: ManagedFeatureUnion = {
            _id: originalData._id,
            type: featType,
            originalData,
            olFeature,
            status,
            state,
        };
        this.setFeature(newFeature);

        this.updateFeatureStyle(originalData._id, state);
    }

    /**
     * Adds a new overlay feature to the managed features array. An overlay feature is the same as a regular feature to OpenLayers, but the
     * object type @see {LocationMapOverlayDetail} is incompatible with the @see addFeatureToStore method.
     * @param type
     * @param originalData
     * @param map
     */
    public async createAndAddOverlayFeatureToStore<
        T extends LocationMapOverlayDetail,
    >(
        type: FeatureType,
        originalData: T,
        map: MapDisplayDetails,
    ): Promise<void> {
        const createFeature = featureCreationFunctions[type];
        if (!createFeature) {
            throw new Error(`Unsupported overlay feature type: ${type}`);
        }
        const olFeature = await createFeature(originalData, map, "default");
        const status = await getFeatureStatus(
            "SublocationOverlay",
            originalData,
        );
        this.openLayersStore.features.set(originalData.sub_location_id, {
            _id: originalData.sub_location_id,
            type: "SublocationOverlay",
            originalData,
            olFeature,
            status,
            state: "default",
        });
    }

    public createAndAddOverlayFeaturesToStore<
        T extends LocationMapOverlayDetail,
    >(data: T[], type: FeatureType, map: MapDisplayDetails): void {
        data.forEach((item) => {
            this.createAndAddOverlayFeatureToStore(type, item, map);
        });
    }

    /**
     * Removes a feature from the managed features array.
     * @param id The identifier of the feature to remove.
     */
    public removeFeature(featureID: string): void {
        this.openLayersStore.features.delete(featureID);
    }

    /**
     * Clears the feature map through which the features are stored, but does not
     * actually remove the features from the layer. @see {LayerManager}
     */
    public resetFeatureStore(): void {
        this.openLayersStore.features.clear();
    }

    /**
     * Updates OL representation of a feature's properties without losing its mobX reference.
     * @param id
     * @param mapDisplayDetails
     */
    @action
    public updateOlFeature = async (
        id: string,
        mapDisplayDetails: MapDisplayDetails,
    ): Promise<void> => {
        try {
            const managedFeature = this.getFeatureById(id);
            if (!managedFeature)
                throw new Error(`Could not retrieve feature ${id}.`);

            const updateFunc = featureUpdateFunctions[managedFeature.type];
            const updateData = await updateFunc(
                managedFeature.originalData,
                mapDisplayDetails,
                managedFeature.state,
                managedFeature.olFeature,
            );

            const existingFeature = managedFeature.olFeature;

            if (updateData.geometry) {
                existingFeature.setGeometry(updateData.geometry);
            }

            if (updateData.properties) {
                //Mark for later, this updates properties we set manually on the feature obj
                //such as deviceName, layerName.
                //It is probably not a good idea to update openlayer-native properties in this way
                //Please use OL native setters and getters such as
                //the case with setStyle
                Object.entries(updateData.properties).forEach(
                    ([key, value]) => {
                        existingFeature.set(key, value);
                    },
                );
            }

            if (updateData.style) {
                existingFeature.setStyle(updateData.style);
            }

            existingFeature.changed();

            // Trigger MobX update
            this.setFeature(managedFeature);
        } catch (err) {
            console.error("Could not update olFeature", err);
            throw err;
        }
    };

    /***
     * Provided with data, updates the feature in the featureStore.
     */
    public updateFeature = async <
        T extends Device | ZoneWithOverallHealth | MobileDevice | GroupedDevice,
    >(
        id: string,
        updatedData: Partial<T> | T,
        mapDisplayDetails: MapDisplayDetails,
    ): Promise<void> => {
        try {
            //Update the feature and it's cache with the featureManager
            const managedFeature = this.getFeatureById(id);
            if (!managedFeature) {
                throw new Error(
                    `Could not update feature. Feature with ID ${id} not found.`,
                );
            }
            // OriginalData is the property that holds the feature's data.
            // Merge the existing data with the updated data.
            managedFeature.originalData = {
                ...managedFeature.originalData,
                ...updatedData,
            };

            // NEW: recalculate feature status from the data so it shows the right color
            managedFeature.status = await getFeatureStatus(
                managedFeature.type as FeatureType,
                managedFeature.originalData,
            );

            //Update olFeature representation on map
            await this.updateOlFeature(id, mapDisplayDetails);
            // Update the feature in the store.
            this.setFeature(managedFeature);
        } catch (err) {
            console.error(err);
        }
    };

    /*
     * Applies a given style to a feature and updates its state in the store.
     * Triggers redraw of feature @see {this.redrawFeature}
     */
    public applyFeatureStyle = (
        featureId: string,
        newStyle: StyleLike,
        newState: FeatureInteractiveState,
    ): void => {
        const feature = this.getFeatureById(featureId);
        if (feature) {
            feature.olFeature.setStyle(newStyle);
            feature.state = newState;
            this.setFeature(feature);
            this.redrawFeature(featureId, feature.olFeature);
        }
    };

    /**
     * Forceful redraw of a sole feature on the map. Updates feature ref with the
     * latest style and geometry (i.e. coordinates)
     */
    private redrawFeature = (_id: string, feature: Feature<Geometry>): void => {
        try {
            const foundFeature = this.getFeatureById(_id);
            if (!foundFeature)
                throw new Error(
                    `Could not redraw feature for ${_id}. Feature not found`,
                );
            const geom = feature.getGeometry();
            if (!geom)
                throw new Error(
                    `Geometry not present for ${_id}. Could not redraw`,
                );

            foundFeature.olFeature = feature;

            foundFeature.olFeature.changed();
        } catch (err) {
            console.error("Could not redraw feature", err);
        }
    };

    /**
     * Retrieves the passed feature as @see {ManagedFeatureUnion} and depending on it's type
     * sets the appropriate style according to it's status and state
     * uses OpenLayersStore to apply said style to the store
     * @param {string} featureId - the id of the feature
     * @param {FeatureInteractiveState} newState - represents a default style or user interactive style
     */
    @action
    public updateFeatureStyle = (
        featureId: string,
        newState: FeatureInteractiveState,
    ): void => {
        try {
            const managedFeature = this.getFeatureById(featureId);
            if (!managedFeature)
                throw new Error(`Feature with ${featureId} not found`);

            const newStyle = this.getStyleForFeatureType(
                managedFeature.type,
                managedFeature.olFeature,
                managedFeature.status,
                newState,
            );

            this.applyFeatureStyle(featureId, newStyle, newState);
        } catch (err) {
            console.error("Could not update feature style", err);
        }
    };
    /**
     * Updates device position, after transforming passed coordinates to
     * values in relevant tothe map's resolution and origin
     *
     * Note: there's no real reason to pass the entire device here, but
     * I'd like to enforce that this function is only meant for device types.
     * We cannot update positions for Zones or SublocationOverlays yet
     * @param device
     * @param position
     */
    public updateFeaturePosition(
        device: Device | MobileDevice | GroupedDevice,
        position: { x: number; y: number },
        mapDisplayDetails: MapDisplayDetails,
    ): void {
        try {
            const deviceFeature = this.getFeatureById(device._id);
            if (!deviceFeature) throw new Error("Device not found in store");
            const coordinate: [number, number] = transformDevicePosition(
                { x: position.x, y: position.y },
                mapDisplayDetails,
            );

            const geom = deviceFeature.olFeature.getGeometry();
            if (!geom)
                throw new Error(
                    `Unable to determine feature geometry for ${device._id}`,
                );
            deviceFeature.olFeature.setGeometry(new Point(coordinate));
            this.setFeature(deviceFeature);
            this.redrawFeature(deviceFeature._id, deviceFeature.olFeature);
        } catch (err) {
            console.error(
                `Could not update position for device ${device._id}`,
                err,
            );
            throw new Error(
                `Could not update position for device ${device._id}`,
            );
        }
    }

    /**
     * Registers a click event to the map and executes the provided callback with the clicked feature.
     * @param clickedOnFeature A function to execute when a feature is clicked.
     * @param clickedOnMap An action to be performed when the user clicks on the map, but not a feature
     */
    public handleOnClick(
        clickedOnFeature: (feature: ManagedFeatureUnion | null) => void,
        clickedOnMap?: () => void,
    ): void {
        // eslint-disable-next-line @typescript-eslint/explicit-function-return-type
        this.mapClickHandler = (event) => {
            const features = this.map?.getFeaturesAtPixel(event.pixel);
            if (features && features.length > 0) {
                const clickedFeatureId = features[0].getId() as string;
                const clickedFeature = this.getFeatureById(clickedFeatureId);

                if (clickedFeature) {
                    // Update the style cache with this new style
                    this.updateFeatureStyle(clickedFeature._id, "selected");
                    clickedOnFeature(clickedFeature);
                } else {
                    clickedOnMap?.();
                }
            } else {
                clickedOnFeature(null);
            }
        };
        this.map.on("click", this.mapClickHandler);
    }

    /**
     * A handler to report when a specific feature is hovered over on the map.
     * @param callback The function to call that typically renders the popover as a DOM/HTML element
     *
     */
    public handleOnHover(
        callback?: ({ isHover, feature, content }: hoverCallbackProps) => void,
    ): void {
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        this.mapOnHoverHandler = (event: MapBrowserEvent<any>): void => {
            //collects features at availalble mouse coordinates
            const features = this.map.getFeaturesAtPixel(event.pixel);
            if (features && features.length > 0) {
                //selects first feature (if multiple) as 'selected' or 'hoverable' feature
                const hoveredFeatureId = features[0].getId() as string;
                const hoveredFeature = this.getFeatureById(hoveredFeatureId);
                if (hoveredFeature) {
                    const tooltipContent = hoveredFeature.olFeature.get("name");
                    callback?.({
                        isHover: true,
                        feature: hoveredFeature,
                        content: tooltipContent,
                    });
                }
            } else {
                callback?.({ isHover: false });
            }
        };
        this.map.on("pointermove", this.mapOnHoverHandler);
    }

    public removeOnClick = (): void => {
        if (this.mapClickHandler) {
            this.map?.un("click", this.mapClickHandler);
            this.mapClickHandler = undefined;
        }
    };

    public removeOnHover = (): void => {
        if (this.mapOnHoverHandler) {
            this.map?.un("pointermove", this.mapOnHoverHandler);
            this.mapOnHoverHandler = undefined;
        }
    };

    public getFeatureStyle(
        feature: Feature,
        state: FeatureInteractiveState,
    ): StyleLike {
        try {
            const featureType = this.determineFeatureType(feature);
            const preStyledFeature = this.getFeatureById(
                feature.getId() as string,
            );

            if (preStyledFeature) {
                return this.getStyleForFeatureType(
                    featureType,
                    feature,
                    preStyledFeature.status,
                    state,
                );
            } else {
                throw new Error("Feature not found to style");
            }
        } catch (err) {
            console.error(
                `Could not set style for ${feature.get(
                    "name",
                )}, some properties are undefined`,
            );
            throw err;
        }
    }

    private determineFeatureType(feature: Feature): FeatureType {
        const type = feature.get("featureType");
        return type;
    }

    //TODO: Should this be a map?
    /**
     * Takes in the feature type and the raw Openlayers variant of the feature (containing geometry and such), and
     * returns the appropriate function meant to style such feature depending on the feature's health status and
     * last interactive state
     * @param {FeatureType} featureType representing how the feature is categorized on the map (and it's layer type)
     * @param {Feature} feature raw OpenLayer object contain geometry details and such
     * @param {"offline" | HEALTH_STATUS} status The health status pf the feature
     * @param {FeatureInteractiveState} state The last interactive state the feature was in or it's default state
     * @returns {StyleLike} An array of style objects detailing how the feature should look
     */
    private getStyleForFeatureType = (
        featureType: FeatureType,
        feature: Feature,
        status: "offline" | HEALTH_STATUS,
        state: FeatureInteractiveState,
    ): StyleLike => {
        try {
            if (
                featureType === "Device" ||
                featureType === "MobileDevice" ||
                featureType === "GroupedDevice"
            ) {
                // Grouping Device types together
                return getDeviceStyle({
                    feature,
                    status,
                    state,
                });
            }

            if (featureType === "SublocationOverlay") {
                return getSublocationOverlayStyle({
                    feature,
                    status,
                    state,
                });
            }

            // Grouping Zone and SublocationOverlay together
            if (featureType === "Zone") {
                return getZoneStyle({
                    feature,
                    status,
                    state,
                });
            }

            //TODO:  Handle the case where featureType does not match any case
            throw new Error(`Unsupported feature type: ${featureType}`);
        } catch (err) {
            console.error(`Could not generate style for ${featureType}`);
            throw new Error("Could not generate style for feature type");
        }
    };
}
