// A type that defines a function that measures a property of an object
// and returns a number or a string.
export type SortMeasure<T> = (value: T) => number | string;

// A function that retrieves the value of a property based on a dot-separated path.
function getValueByPath(path: string, obj: Record<string, unknown>): unknown {
    const parts = path.split(".");
    let value = obj;

    for (const part of parts) {
        if (!Object.prototype.hasOwnProperty.call(value, part)) {
            return undefined;
        }

        value = value[part] as Record<string, unknown>;
    }

    return value;
}

/**
 * Sorts an array of objects based on the value of a property at a given path.
 * @param path The path to the property to sort by, e.g. "notification.status"
 * @param measure An optional function to measure the value to sort by.
 * @returns A function that compares two objects and returns a number indicating their sort order.
 */
export default function sortBy<T extends Record<string, unknown>>(
    path: string,
    measure?: (input: string | number) => string | number,
): (a: T, b: T) => number {
    return (a: T, b: T): number => {
        // Get the values to compare based on the specified path.
        const valueA = getValueByPath(path, a);
        const valueB = getValueByPath(path, b);

        // If a measure function is provided, apply it to the values.
        if (measure) {
            const measuredA = measure(valueA as string | number) as
                | string
                | number;
            const measuredB = measure(valueB as string | number) as
                | string
                | number;
            return measuredA > measuredB ? 1 : measuredB > measuredA ? -1 : 0;
        }

        // Otherwise, compare the values directly with type assertion.
        return (valueA as string | number) > (valueB as string | number)
            ? 1
            : (valueB as string | number) > (valueA as string | number)
            ? -1
            : 0;
    };
}
