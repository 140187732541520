import { observer } from "mobx-react-lite";
import EmptyAlertView from "./EmptyAlertView";
import NotificationContainer from "./NotificationContainer";

interface NotificationsPageProps {
    orgId: string | null;
}

export default observer(function NotificationsPage({
    orgId,
}: NotificationsPageProps) {
    if (!orgId) {
        return <EmptyAlertView />;
    }
    return <NotificationContainer orgId={orgId} />;
});
