import isObject from "utils/isObject";
import ValidSocketMessage from "./ValidSocketMessage.type";

type DeviceUpdateMessage = {
    updates: Record<string, unknown>;
    deviceId: string;
};

export default DeviceUpdateMessage;

export function isDeviceUpdateMessage(
    o: ValidSocketMessage,
): o is DeviceUpdateMessage {
    return typeof o.deviceId === "string" && isObject(o.updates);
}
