import DeviceStateExecutionProperties from "@srtlabs/m1_types/lib/Device/DeviceState/DeviceStateExecutionProperties/DeviceStateExecutionProperties.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import FLEET_SORT_OPTIONS from "./FleetSortOptions.enum";
import UseSortOption from "./UseSortOption.interface";

const DeviceFleetSortOptions: Readonly<UseSortOption[]> = Object.freeze([
    {
        type: FLEET_SORT_OPTIONS.ERROR,
        sort: {
            path: "deviceHealth.overAllHealth",
            measure: (input: HEALTH_STATUS): boolean =>
                input === HEALTH_STATUS.SAFE,
        },
    },
    {
        type: FLEET_SORT_OPTIONS.STATUS,
        sort: {
            path: "state.offline",
            measure: (input: boolean): boolean => !input,
        },
    },
    {
        type: FLEET_SORT_OPTIONS.POSITION,
        sort: { path: "headers.generalPosition" },
    },
    {
        type: FLEET_SORT_OPTIONS.MODEL,
        sort: { path: "references.modelId" },
    },
    {
        type: FLEET_SORT_OPTIONS.TYPE,
        sort: { path: "headers.dataType" },
    },
    {
        type: FLEET_SORT_OPTIONS.NAME,
        sort: { path: "headers.deviceName" },
    },
    {
        type: FLEET_SORT_OPTIONS.MANUFACTURER,
        sort: { path: "headers.manufacturer" },
    },
    {
        type: FLEET_SORT_OPTIONS.SENSOR_LOCATION,
        sort: { path: "headers.sensorLocation" },
    },
    {
        type: FLEET_SORT_OPTIONS.SUBLOCATION,
        sort: { path: "headers.sublocation" },
    },
    {
        type: FLEET_SORT_OPTIONS.FUNCTIONS,
        sort: {
            path: "state.executionProperties",
            measure: (input: DeviceStateExecutionProperties): boolean =>
                Object.keys(input).length > 0,
        },
    },
]);

export default DeviceFleetSortOptions;
