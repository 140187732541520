import ZoneHVACFormErrors from "../types/ZoneHVACFormErrors.type";
import ZoneOccupancyFormErrors from "../types/ZoneOccupancyFormErrors.type";
import ZoneSettingFormDataProps from "../types/FormData.type";
import isValidTimeRange from "./isValidTimeRange";

export function validateHVAC({
    hvac,
}: ZoneSettingFormDataProps): ZoneHVACFormErrors {
    const hvacErrors: ZoneHVACFormErrors = {
        heating: {
            occupied: "",
            unoccupied: "",
        },
        cooling: {
            occupied: "",
            unoccupied: "",
        },
    };
    for (const key in hvac) {
        type HVAC_KEY_T = keyof typeof hvac;
        type HVAC_VALUE_KEY_T = keyof (typeof hvac)[HVAC_KEY_T];
        const hvac_key = key as HVAC_KEY_T;
        for (const occupiedOrUnoccupied in hvac[hvac_key]) {
            const hvac_occupied_or_unoccupied =
                occupiedOrUnoccupied as HVAC_VALUE_KEY_T;

            const hvac_value_key: HVAC_VALUE_KEY_T =
                hvac_occupied_or_unoccupied;

            const value = hvac[hvac_key][hvac_value_key];

            if (isNaN(Number(value)) || String(value).trim() == "") {
                const heatingOrCooling = hvac_key.substring(0, 7) as
                    | "heating"
                    | "cooling";
                const occuppiedUnoccupied = hvac_key
                    .substring(7)
                    .toLowerCase() as "occupied" | "unoccupied";
                hvacErrors[heatingOrCooling][occuppiedUnoccupied] =
                    "Value not set";
            }
        }
    }

    return hvacErrors;
}

export function validateOccupancyOverride({
    occupancy: { userDefinedStartTime, userDefinedEndTime },
}: ZoneSettingFormDataProps): ZoneOccupancyFormErrors {
    if (!isValidTimeRange(userDefinedStartTime, userDefinedEndTime)) {
        return {
            userDefinedStartTime: "",
            userDefinedEndTime: "",
            other: "Times not valid",
        };
    } else {
        return {
            other: "",
            userDefinedStartTime: "",
            userDefinedEndTime: "",
        };
    }
}
