import DeviceDisplayDrawerItem from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerItem/DeviceDisplayDrawerItem.type";
import LinearScaledMultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScaledMultiRangeSafeVal.type";
import MultiRangeSafeValMetricProps from "../../components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGenericMetricContainer/CurrentDeviceCardContentStatusDrawerItemMetric/MultiRangeSafeValMetric/utilities/MultiRangeSafeValMetric.props";

/**
 * Get the properties to display a linear scaled MultiRangeSafeVal metric. The properties
 * returned will be either the original, unscaled metric properties, or the scaled metric
 * properties if the four required scale fields (source and scaled min and max) are set.
 * @param currentValue The current, unscaled value of the metric to display
 * @param drawer The drawerContent item associated with the metric
 * @param linearScaledMultiRange The linear scaled display object from the device, containing
 * scale properties/settings
 * @returns {MultiRangeSafeValMetricProps} Properties to use for MultiRangeSafeValMetric,
 * either scaled on unscaled.
 */
export default function getLinearScaledMultiRangeMetricProps(
    currentValue: number,
    drawer: DeviceDisplayDrawerItem,
    linearScaledMultiRange: LinearScaledMultiRangeSafeVal,
): MultiRangeSafeValMetricProps {
    // Return the standard, unscaled metric if the scale settings are not set.
    if (!hasScaleSettings(linearScaledMultiRange)) {
        return {
            value: currentValue,
            unit: drawer.unit,
            ...linearScaledMultiRange,
        };
    }

    const scale = linearScaledMultiRange.scale;
    const scaledValue =
        (currentValue - scale.sourceMin!) *
            ((scale.scaledMax! - scale.scaledMin!) /
                (scale.sourceMax! - scale.sourceMin!)) +
        scale.scaledMin!;
    return {
        value: scaledValue,
        unit: scale.scaledUnits!,
        ...linearScaledMultiRange,
    };
}

export function hasScaleSettings(
    linearScaledMultiRange: LinearScaledMultiRangeSafeVal,
): boolean {
    const scale = linearScaledMultiRange.scale;
    return (
        scale.sourceMin !== null &&
        scale.sourceMax !== null &&
        scale.scaledMin !== null &&
        scale.scaledMax !== null
    );
}
