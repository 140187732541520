import type ButtonProps from "components/Button/utilities/Button.props";
import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";

const BaseClassName =
    "w-3 h-3 text-3xl text-obsidian bg-gray-100 hover:bg-gray-200 flex justify-center items-center";

export default memo(function MapControllerButton(
    props: ButtonProps,
): JSX.Element {
    const className = useMemo(
        () => extendClassName(BaseClassName, props.className),
        [props.className],
    );

    return <button {...props} className={className} />;
});
