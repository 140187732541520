import { useCallback, useEffect, useRef, useState } from "react";

/**
 * Detectsclicks outside of a specified root and its descendants.
 *
 * @param onClickOutside - Runs when a click occurs outside the root element.
 * @param onClickInside - Runs  when a click occurs inside the root or its descendants.
 * @param ignoreClass - CSS classname used to say that particular element should be ignored during the click outside detection.
 * @param enabled - Flag to enable or disable the click outside detection. Defaults to true.
 */
export default function useClickOutside<T extends HTMLElement = HTMLElement>(
    onClickOutside?: (e: MouseEvent) => void,
    onClickInside?: (e: MouseEvent) => void,
    ignoreClass = "ignore-onClickOutside",
    enabled = true,
): {
    clickedOutside: boolean;
    setRef: (node: T | null) => void;
    rootRef: React.MutableRefObject<T | null>;
} {
    const [clickedOutside, setClickedOutside] = useState(false);
    const rootRef = useRef<T | null>(null);

    const setRef = useCallback((node: T | null) => {
        if (node) {
            rootRef.current = node;
        }
    }, []);

    const isInIgnoredElement = useCallback(
        (element: Node, ignoredClass: string) => {
            if (element === null) return;
            do {
                if (
                    element instanceof SVGAElement ||
                    element instanceof HTMLElement
                ) {
                    if (element.classList.contains(ignoredClass)) {
                        return true;
                    }
                }
            } while ((element = element.parentElement as Element));
            return false;
        },
        [ignoreClass],
    );
    useEffect(() => {
        if (!enabled) {
            return;
        }
        const listener = (event: MouseEvent): void => {
            if (!rootRef.current) {
                return;
            }
            // https://developer.mozilla.org/en-US/docs/Web/API/Event/composedPath
            // if the event target is the root itself, or a descendant of the root
            const path = event.composedPath();
            const isInside = path.some((target) => {
                return (
                    target === rootRef.current ||
                    isInIgnoredElement(target as Node, ignoreClass)
                );
            });

            if (isInside) {
                onClickInside?.(event);
                setClickedOutside(false);
            } else {
                onClickOutside?.(event);
                setClickedOutside(true);
            }
        };

        document.addEventListener("click", listener);

        return (): void => {
            document.removeEventListener("click", listener);
        };
    }, [rootRef.current, onClickOutside, onClickInside, ignoreClass, enabled]);

    return { clickedOutside, setRef, rootRef };
}
