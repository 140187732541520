import { useQueries } from "@tanstack/react-query";
import DeviceNotification from "types/Notification.type";
import getMappedNotification from "utils/getMappedNotification";
import TableNotificationItem from "../NotificationTableHeader/util/TableNotificationItem.type";

export default function useMappedNotifications(
    notifications: DeviceNotification[],
): {
    /**
     * Items to be displayed on table for notifications
     */
    data: (TableNotificationItem | undefined)[];

    /**
     * Indicates if there is any remaining queries in the fetch that have yet to be completed
     */
    pendingFetchData: boolean;
} {
    return useQueries({
        queries: notifications.map((notification) => ({
            queryKey: ["notification", notification.id],
            queryFn: async (): Promise<TableNotificationItem> =>
                getMappedNotification(notification),
        })),
        combine: (results) => {
            return {
                data: results.map((result) => result.data),
                pendingFetchData: results.some((result) => result.isPending),
            };
        },
    });
}
