import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import DeviceDisplayExecutionFunctions from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayExecutionFunctions/DeviceDisplayExecutionFunctions.type";
import { AxiosError, AxiosResponse } from "axios";
import { DeviceExecutionFunction } from "components/ExecutionFunctionDisplays/ButtonTextOnPull/ButtonTextOnPull";
import findProblemsWithDevice from "hooks/useWebsockets/utilities/findProblemsWithDevice";
import rootStore from "stores/rootStore";
import DeviceLog from "types/DeviceLog.type";
import BaseService from "./BaseService";

/**
 * An extension of @see BaseService with a focus on @see Device centric calls
 */
class DeviceService {
    /**
     * Grabs a device by its id,
     * and as a temporary solution, runs the device through @see findProblemsWithDevice
     */
    public async getDevice(
        deviceID: string,
        signal?: AbortSignal,
    ): Promise<Device> {
        try {
            const { data: device } = await BaseService.request<Device>(
                `/devices/${deviceID}`,
                { signal },
            );

            const updatedDevice = findProblemsWithDevice(device);
            return updatedDevice;
        } catch (error) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to retrieve logs for ${deviceID}`,
                {
                    variant: "error",
                },
            );
            throw new Error(`Unable to retrieve device, ${deviceID}`);
        }
    }

    /**
     * makes a put request, to update the device, if successful the server will return that device,
     * which we then let the user know everything went well,
     * and we run the device through @see findProblemsWithDevice
     * after which returns the new device
     */
    public async saveDevice(device: Device): Promise<Device> {
        try {
            const { data: returnedDevice } = await BaseService.request<Device>(
                `/devices/${device._id}`,
                { method: "put", data: device },
            );

            const updatedDevice = findProblemsWithDevice(returnedDevice);
            return updatedDevice;
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to save: ${device.headers.deviceName}`,
                { variant: "error" },
            );

            throw e;
        }
    }

    /**
     * Retrieves an array of the executionFunction Objects available
     * for a specific device.
     */
    public async getExecutionFunctions(
        deviceID: string,
    ): Promise<DeviceDisplayExecutionFunctions> {
        try {
            const { data } =
                await BaseService.request<DeviceDisplayExecutionFunctions>(
                    `/devices/${deviceID}/executions`,
                    {
                        method: "get",
                    },
                );
            return data;
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Unable to retrieve execution functions for device.`,
                {
                    variant: "error",
                },
            );

            throw e;
        }
    }

    /**
     *
     *
     * Executes the appropriate function available for a device
     * @returns the exact Execution Function Object that was available @see DeviceDisplayExecutionFunction
     *          until we can figure out something that's more useful.
     */
    public async executeDeviceFunction({
        deviceID,
        executionReferenceId,
        properties,
    }: DeviceExecutionFunction): Promise<AxiosResponse> {
        try {
            return await BaseService.request<DeviceExecutionFunction>(
                `/devices/${deviceID}/execute`,
                {
                    method: "post",
                    data: {
                        deviceID: deviceID,
                        executionReferenceId: executionReferenceId,
                        properties: properties,
                    },
                },
            ).then((res) => {
                if (res.status === 200) {
                    rootStore.snackbarStore.enqueueSnackbar?.(
                        `Execution request sent.`,
                        {
                            variant: "success",
                            autoHideDuration: 3000,
                        },
                    );
                } else {
                    rootStore.snackbarStore.enqueueSnackbar?.(
                        `Failed to submit execution request.`,
                        {
                            variant: "error",
                        },
                    );
                }
                return res;
            });
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to execute function.`,
                {
                    variant: "error",
                },
            );

            throw e;
        }
    }

    /**
     * gets the event logs for a device by accepting the devices id
     * @see DeviceLog
     */
    public async getDeviceLogs(deviceID: string): Promise<DeviceLog[]> {
        try {
            const { data } = await BaseService.request<DeviceLog[]>(
                `/devices/${deviceID}/logs`,
            );

            return Array.isArray(data) ? data : [];
        } catch (e) {
            if ((e as AxiosError)?.response?.status === 404) {
                return [];
            }

            throw e;
        }
    }
}

export default new DeviceService();
