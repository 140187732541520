import Icon from "components/Icons/Icon/Icon";
import InputField from "components/InputField/InputField";
import { memo } from "react";
import { Controller } from "react-hook-form";
import MinMaxRangeFieldProps from "../MinMaxRangeFieldProps.type";
//TODO: Give generic type
export default memo(function MinMaxRangeField({
    baseName,
    index,
    control,
    onRemove,
    errors,
    trigger,
}: MinMaxRangeFieldProps) {
    return (
        <div className="flex flex-row">
            <Controller
                name={`${baseName}.${index}.0`} // This will become "safe[0][0]" for Min field
                control={control}
                render={({ field, fieldState }): JSX.Element => (
                    <InputField
                        label="Min"
                        onChange={(event): void => {
                            field.onChange(event);
                            errors[baseName]?.[index]?.[1] &&
                                trigger(`${baseName}.${index}.1`);
                        }}
                        type="number"
                        value={field.value}
                        error={fieldState.error?.message}
                        classes={{ label: "w-14" }}
                    />
                )}
            />
            <Controller
                name={`${baseName}.${index}.1`} // This will become "safe[0][1]" for Max field
                control={control}
                render={({ field, fieldState }): JSX.Element => (
                    <InputField
                        label="Max"
                        type="number"
                        onChange={(event): void => {
                            field.onChange(event);
                            errors[baseName]?.[index]?.[0] &&
                                trigger(`${baseName}.${index}.0`);
                        }}
                        value={field.value}
                        error={fieldState.error?.message}
                        classes={{ label: "w-14" }}
                    />
                )}
            />
            <button
                type="button"
                className="text-obsidian h-3 mt-4 px-4 self-start has-ignoreClass-device-drawer has-ignoreClass-zone-drawer"
                onClick={onRemove}
            >
                <Icon name="xmark" />
            </button>
        </div>
    );
});
