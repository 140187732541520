import { animated, easings, useSpring, useTransition } from "@react-spring/web";
import useClickOutside from "hooks/useClickOutside";
import useQuery from "hooks/useQuery/useQuery";
import { useResizeObserver } from "hooks/useResizeObserver";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import CurrentZoneDrawer from "./CurrentZoneDrawer/CurrentZoneDrawer";
import ZONE_DRAWER_ANIMATION_SPEED from "./utilities/ZONE_DRAWER_ANIMATION_SPEED";

export default observer(function ZoneDrawerContainer(): JSX.Element {
    const { setParams, currentZone } = useQuery();
    const handleClickOutside = useCallback(() => {
        setParams({ currentZone: null, currentDrawerTab: null });
    }, [setParams]);

    const { setRef, rootRef } = useClickOutside(
        handleClickOutside,
        () => null,
        "has-ignoreClass-zone-drawer",
        Boolean(currentZone),
    );

    const { width: observedDrawerWidth } = useResizeObserver(rootRef);

    const drawerWidth = useMemo(() => {
        return observedDrawerWidth || 871; // Use 400 as the default value if observedDrawerWidth is 0
    }, [observedDrawerWidth]);

    // When opening the drawer, the drawer will slide/fade in starting
    // at 0.5x drawer width. This makes it so the transition happens
    // quickly without feeling rushed or frantic
    const slideProps = useSpring({
        right: currentZone ? 0 : -drawerWidth * 0.5,
        delay: 0,
        config: {
            duration: ZONE_DRAWER_ANIMATION_SPEED,
            easing: easings.easeOutQuad,
        },
    });

    // Create a unique key that changes when the zone changes
    const transitionKey = useMemo(
        () => (currentZone ? `zone-${currentZone}` : null),
        [currentZone],
    );

    // When transitioning from one zone to another, the drawer will
    // have a swipe effect with the old zone sliding out to the left
    // and the new zone sliding in from the right
    const transitions = useTransition(transitionKey, {
        from: { opacity: 0, transform: "translateX(100px)" },
        enter: { opacity: 1, transform: "translateX(0px)" },
        leave: { opacity: 0, transform: "translateX(-100px)" },
        reverse: Boolean(currentZone),
        config: { duration: ZONE_DRAWER_ANIMATION_SPEED },
    });

    return transitions(
        (transitionProps, transitionKey) =>
            transitionKey && (
                <>
                    <animated.div
                        ref={setRef}
                        style={{
                            transform: transitionProps.transform,
                            ...slideProps,
                            opacity: transitionProps.opacity,
                        }}
                        className={CLASS_NAMES.drawer.drawerContainer}
                    >
                        <CurrentZoneDrawer />
                    </animated.div>
                </>
            ),
    );
});
