import { memo, useEffect, useState } from "react";
import EditDrawerProps from "../utilities/EditDrawer.props";
import MultiRangeSafeValMetric from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemGeneric/CardContentStatusDrawerItemGenericMetricContainer/CurrentDeviceCardContentStatusDrawerItemMetric/MultiRangeSafeValMetric/MultiRangeSafeValMetric";
import type MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import type RANGE_FLOAT_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/RANGE_FLOAT_DIMENSION/RANGE_FLOAT_DIMENSION.type";
import type RANGE_INTEGER_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/RANGE_INTEGER_DIMENSION/RANGE_INTEGER_DIMENSION.type";
import MultiRangeEditableContent from "./MultiRangeEditableContent/MultiRangeEditableContent";
import MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";

export default memo(function MultiRangeSafeValEditDrawer({
    value,
    drawer,
    disabled,
    onChange,
    options,
    setHasErrors,
}: EditDrawerProps<
    //TODO: Correct typing here so that we only accept the .multiRangeSafeVal prop from any type
    number,
    | MULTI_RANGE_SAFE_VAL_DIMENSION
    | RANGE_FLOAT_DIMENSION
    | RANGE_INTEGER_DIMENSION
>): JSX.Element {
    const [formState, setFormState] = useState<MultiRangeSafeVal>(
        options.multiRangeSafeVal!,
    );

    useEffect(() => {
        onChange({ [DISPLAY_TYPE.MULTI_RANGE_SAFE_VAL]: formState });
    }, [formState]);

    if (!options.multiRangeSafeVal)
        return <h3> This item cannot be displayed. </h3>;

    //TODO: Lift state up here and use onChange to update form

    return (
        <>
            <div className="flex w-full my-8">
                <MultiRangeSafeValMetric
                    value={value}
                    {...options.multiRangeSafeVal!}
                    unit={drawer.unit}
                />
            </div>

            <span className="text-center">
                Default Reading: <i>{options.multiRangeSafeVal!.base}</i>
            </span>

            {!disabled ? (
                // Moved into its own component to make it resusable for scaled multiRanges
                <MultiRangeEditableContent
                    onChange={onChange}
                    options={options}
                    setHasErrors={setHasErrors}
                    setFormState={setFormState}
                    dimensionType={DISPLAY_TYPE.MULTI_RANGE_SAFE_VAL}
                />
            ) : null}
        </>
    );
});
