import dayjs from "dayjs";
import {
    OccupancyOverride,
    SrtRecommendedTemperature,
} from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
import { EMSBooking } from "../ZoneSchedule";
import CalendarEvent from "./CalendarEvent.type";
import CalendarEventType from "./CalendarEventType.enum";
import {
    CALENDAR_RESOURCES_OCCUPANCY,
    CALENDAR_RESOURCES_RECOMMENDED,
} from "./CALENDAR_RESOURCES.values";

export const overrideToCalendar = ({
    userDefinedEndTime,
    userDefinedStartTime,
}: OccupancyOverride): CalendarEvent => {
    return {
        start: dayjs(new Date(userDefinedStartTime)),
        end: dayjs(new Date(userDefinedEndTime)),
        title: "User Defined Time",
        resourceId: CALENDAR_RESOURCES_OCCUPANCY.resourceId,
        type: CalendarEventType.OVERRIDE,
        occupied: true,
    };
};

export const srtRecommendedTemperatureToCalendar = ({
    startTime,
    endTime,
    recommendedTemperature,
    occupied,
}: SrtRecommendedTemperature): CalendarEvent => {
    return {
        start: dayjs(new Date(startTime)),
        end: dayjs(new Date(endTime)),
        title: `${recommendedTemperature}℉`,
        resourceId: CALENDAR_RESOURCES_RECOMMENDED.resourceId,
        type: CalendarEventType.RECOMENDATION,
        occupied,
    };
};

export const bookingToCalendar = ({
    endTime,
    startTime,
    eventName,
}: EMSBooking): CalendarEvent => {
    return {
        start: dayjs(new Date(startTime)),
        end: dayjs(new Date(endTime)),
        title: eventName,
        resourceId: CALENDAR_RESOURCES_OCCUPANCY.resourceId,
        type: CalendarEventType.BOOKING,
        occupied: true,
    };
};
