import { Fill, Stroke, Style } from "ol/style";
import FeatureStyleSetterProps from "types/OpenLayers/FeatureStyleSetterProps";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import FeatureDisplayState from "types/OpenLayers/FeatureDisplayState";
import zoneColorFamilies from "stores/classes/Openlayers/utils/zoneColorFamily.data";

export const getZoneStyle = ({
    status,
    state = "default",
}: FeatureStyleSetterProps): Style[] => {
    const colorPicker = (
        key: "offline" | HEALTH_STATUS,
        state: keyof FeatureDisplayState,
    ): { background: string; border: string } => {
        switch (key) {
            case HEALTH_STATUS.SAFE:
                return zoneColorFamilies["safe"][state];
            case HEALTH_STATUS.UNSAFE:
                return zoneColorFamilies["unsafe"][state];
            case HEALTH_STATUS.WARNING:
                return zoneColorFamilies["warning"][state];
            default:
                return zoneColorFamilies["safe"][state];
        }
    };

    const { background, border } = colorPicker(status, state);

    return [
        //Polygon Style
        new Style({
            stroke: new Stroke({
                color: border,
                width: 3,
            }),
            fill: new Fill({
                color: background,
            }),
        }),
    ];
};
