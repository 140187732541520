import OrganizationService from "services/OrganizationService";
import { UseQueryResult, useQuery } from "@tanstack/react-query";

interface FetchImageSrcProps {
    mapIdentifier: {
        orgId: string;
        locId: string;
        sublId?: string;
        currentMapKey: string;
    };
    signal?: AbortSignal;
}

interface useFetchImageQueryProps extends FetchImageSrcProps {
    queryKey: string[];
    key: "location" | "sublocation";
}

/**
 * A wrapper around the service call to retrieve the src value for a sublocation map image.
 * If the call is successful, it sets the value as the @see imageSrc
 * @param orgId
 * @param locId
 * @param sublId
 * @param currentMapKey
 * @returns
 */
function fetchSublocationImageSrc({
    mapIdentifier: { orgId, locId, sublId, currentMapKey },
    signal,
}: FetchImageSrcProps): Promise<string> {
    if (sublId) {
        return OrganizationService.getSublocationMap(
            orgId,
            locId,
            sublId,
            currentMapKey,
            signal,
        );
    } else {
        throw new Error("SublId not provided, could not get sublocation map");
    }
}

function fetchLocationImageSrc({
    mapIdentifier: { orgId, locId, currentMapKey },
    signal,
}: FetchImageSrcProps): Promise<string> {
    return OrganizationService.getLocationMap(
        orgId,
        locId,
        currentMapKey,
        signal,
    );
}

function fetchImageSrcFactory(
    { key, ...props }: useFetchImageQueryProps,
    signal: AbortSignal,
): Promise<string> {
    switch (key) {
        case "location":
            return fetchLocationImageSrc({ ...props, signal });
        case "sublocation":
            return fetchSublocationImageSrc({ ...props, signal });
        default:
            throw new Error("Invalid key, could not fetch map image source");
    }
}

export const useFetchMapImageQuery = (
    props: useFetchImageQueryProps,
): UseQueryResult<string, Error> => {
    const { orgId, locId, sublId, currentMapKey } = props.mapIdentifier;
    return useQuery({
        queryKey: props.queryKey,
        queryFn: ({ signal }) => fetchImageSrcFactory(props, signal),
        enabled: Boolean(
            orgId &&
                locId &&
                (props.key === "location" || sublId) &&
                currentMapKey,
        ),
    });
};
