import { QueryClient } from "@tanstack/react-query";
import WebsocketStore from "stores/Websocket.class";
import ApiStore from "./ApiStore.class";
import MobileDeviceStore from "./MobileDeviceStore.class";
import NotificationStore from "./NotificationStore.class";
import OpenLayersStore from "./Openlayers/OpenLayersStore.class";
import OrganizationStore from "./OrganizationStore.class";
import SnackbarStore from "./SnackbarStore.class";
import UserStore from "./UserStore.class";
import ViewportStore from "./ViewportStore.class";

/**
 * Base store management.
 * Includes everything except the openlayers,
 * featuremanager, and layer stores.
 */

class RootStore {
    apiStore: ApiStore;
    websocketStore: WebsocketStore;
    notificationStore: NotificationStore;
    organizationStore: OrganizationStore;
    userStore: UserStore;
    mobileDeviceStore: MobileDeviceStore;
    viewportStore: ViewportStore;
    snackbarStore: SnackbarStore;
    openLayersStore: OpenLayersStore;

    constructor(queryClient: QueryClient) {
        // Stores that do not depend on each other go first
        this.apiStore = new ApiStore();
        this.userStore = new UserStore();
        this.organizationStore = new OrganizationStore();
        this.notificationStore = new NotificationStore();
        this.mobileDeviceStore = new MobileDeviceStore();
        this.snackbarStore = new SnackbarStore();
        this.viewportStore = new ViewportStore();
        this.openLayersStore = new OpenLayersStore();

        // Initialize WebsocketStore with a reference to NotificationStore
        this.websocketStore = new WebsocketStore(
            queryClient,
            this.notificationStore,
            this.openLayersStore,
        );
    }

    /**
     * Closes reactions and disposes of them from all relevant stores.
     * @see {ReactionManager} for a good example of what a reaction is and how it is managed.
     * As of right now, only OrgStore tracks it's own reactions.
     */
    public disposeAllReactions(): void {
        this.organizationStore.dispose();
    }

    /**
     * Disposes of current reactions and reinits them, this should/can be used during
     * major state changes that occur rarely (a good example is the authentication state handled by apiStore and UserService)
     * Stores are individually responsible for their own initialization and cleanup
     * This func should just call them all together.
     */
    public initializeAllReactions(): void {
        this.organizationStore.reinitalizeReactions();
    }
}
export default RootStore;
