import Axios, { AxiosError, AxiosResponse } from "axios";
import rootStore from "stores/rootStore";

/**
 * the request object, controls options making an axios call to the web server
 */
type RequestOptions = {
    method?: "get" | "post" | "put" | "delete" | "patch";
    data?: unknown;
    headers?: Record<string, string>;
    signal?: AbortSignal;
};

/**
 * the base web url, uses the .env to build a string for connecting to the webserver
 * example:
 * https://dev-api.srtlabs.com
 */
export const BASE_WEB_URL = `${
    process.env.REACT_APP_MAKE_SECURE_CONNECTION === "true"
        ? "https://"
        : "http://"
    // eslint-disable-next-line
}${process.env.REACT_APP_WEB_SERVER_DOMAIN}`;

class BaseService {
    /**
     * the current jwt token
     */
    private _token = "";

    /**
     * getter for @see _token
     */
    public get token(): string {
        return this._token;
    }

    /**
     * setter function for @see _token
     * NOT a traditional set token(token: string) ... for somereason..
     */
    public setToken(token: string): void {
        this._token = token;
    }

    /**
     * makes an axios request using a url, @see RequestOptions, and any other options you want to pass to axios
     *
     * if we fail a call due to a 403 error, log the userout
     *
     * @param url - the endpoint example: /users/me
     */
    public async request<T>(
        url: string,
        { method = "get", data, headers = {}, signal }: RequestOptions = {},
        other: Record<string, string> = {},
    ): Promise<AxiosResponse<T>> {
        try {
            return await Axios({
                url: BASE_WEB_URL + "/api/v1" + url,
                method,
                data,
                headers: {
                    Authorization: this._token,
                    ...headers,
                },
                signal,
                ...other,
            });
        } catch (err) {
            if ((err as AxiosError).code !== "ERR_CANCELED") {
                console.error(err);
            }

            if ((err as AxiosError)?.response?.status === 403) {
                rootStore.userStore.logout();
            }

            throw err;
        }
    }
}

export default new BaseService();
