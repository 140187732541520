import ButtonTextOnPull from "components/ExecutionFunctionDisplays/ButtonTextOnPull/ButtonTextOnPull";
import { memo, useEffect, useState } from "react";
import CurrentDeviceCardContentFunctionItemProps from "./CurrentDeviceCardContentFunctionItemProps.props";

enum EXECUTION_DISPLAY {
    BUTTON_TOGGLE = "button:toggle",
    BUTTON_TEXT_ON_PULL = "button:textOnPull",
}

export default memo(function CurrentDeviceCardContentFunctionItem({
    id,
    executionReferenceId,
    notes,
    properties,
    type,
    deviceID,
    intervalRef,
    isIntervalPaused,
    pauseInterval,
    startInterval,
    toggleInterval,
    stopInterval,
}: CurrentDeviceCardContentFunctionItemProps): JSX.Element {
    const [disabled, setDisabled] = useState(false);

    /**
     * When an execution is submitted it will pause the interval and
     * clear the timer. This means we can disable the execution display
     * for the alotted time or until the getExecution is retrieved
     */
    useEffect(() => {
        // if (intervalRef.current && isIntervalPaused) {
        //     setDisabled(true);
        // } else {
        //     setDisabled(false);
        // }
    }, [intervalRef.current, isIntervalPaused]);

    /**
     * This is a wrapper function that helps me know
     * when an execution has been made by a child component.
     */
    // const handleClick = useCallback(() => {}, []);

    /**
     * HOW WE CHOOSE DISPLAY
     * The format of the type is essentially "display:action" (ex. "button:toggle")
     * We treat this type as a unique key that is rendered appropriately.
     * More often than not the action element will not be handled by the frontend itself
     * Rather, it is more indicative of how the backend will manipulate the data.
     *
     */

    if (type === EXECUTION_DISPLAY.BUTTON_TEXT_ON_PULL) {
        return (
            <ButtonTextOnPull
                deviceID={deviceID}
                id={id}
                executionReferenceId={executionReferenceId}
                properties={properties}
                notes={notes}
                type={type}
                disabled={disabled}
                setDisabled={setDisabled}
                pauseInterval={pauseInterval}
                startInterval={startInterval}
                toggleInterval={toggleInterval}
                stopInterval={stopInterval}
            />
        );
    }

    return <></>;
});
