import ViewSelector from "components/ViewSelector/ViewSelector";
import { useResizeObserver } from "hooks/useResizeObserver";
import { observer } from "mobx-react-lite";
import { useEffect, useRef } from "react";
import { useMatch } from "react-router-dom";
import rootStore from "stores/rootStore";
import ListViewContainerProps from "./utilities/ListViewContainer.props";

export default observer(function ListViewContainer({
    organizationStore,
}: ListViewContainerProps): JSX.Element {
    const { viewportStore } = rootStore;
    const isNoticiationsActive = useMatch("/home/notifications/*");
    const isAnalyticsActive = useMatch("/home/analytics/*");
    const listViewRef = useRef(null);
    const { height: listViewHeight } = useResizeObserver(listViewRef);

    useEffect(() => {
        viewportStore.setViewSelectorHeight(listViewHeight);
    }, [listViewHeight]);

    if (
        !organizationStore.organization ||
        isAnalyticsActive ||
        isNoticiationsActive
    )
        return <></>;
    return (
        <div
            ref={listViewRef}
            className="w-full p-2 h-[5rem] sm:p-4 relative border-b-1 border-gray-700 bg-gray-100 z-20"
        >
            <ViewSelector />
        </div>
    );
});
