import dayjs from "dayjs";
import { memo, useCallback, useContext, useMemo } from "react";
import CalendarItem from "./CalendarItem/CalendarItem";
import type CalendarItemProps from "./CalendarItem/utilities/CalendarItem.props";
import type ZoneScheduleCalendarEventsAndRecomendationsProps from "./utilities/ZoneScheduleCalendarEventsAndRecomendations.props";
import top from "./utilities/top.func";
import getZIndex from "./utilities/getZIndex.func";
import isToday from "../../utilities/isToday.func";
import ZoneCurrentDayContext from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerHeader/utilities/ZoneCurrentDayContext";
import CalendarEvent from "../../utilities/CalendarEvent.type";

export default memo(function ZoneScheduleCalendarEventsAndRecomendations({
    events,
    setCurrentTimeBarRef,
}: ZoneScheduleCalendarEventsAndRecomendationsProps): JSX.Element {
    const { currentDay } = useContext(ZoneCurrentDayContext);
    const dateIsToday = useMemo(() => isToday(currentDay), [currentDay]);
    /**
     * A temporary workaround function that rounds today's event endtime to 12:00AM
     * if the endtime exceeds today at 12:00AM
     */
    const roundEventEndTime = useCallback(
        (event: CalendarEvent) => {
            if (
                dateIsToday &&
                dayjs(event.end).isAfter(dayjs(currentDay).endOf("day"))
            ) {
                return "12:00 AM";
            } else {
                return event.end.format("h:mm A");
            }
        },
        [dateIsToday],
    );
    const computedEvents = useMemo<CalendarItemProps[]>(
        () =>
            events
                .slice()
                .sort((a, b) =>
                    a.start > b.start
                        ? 1
                        : a.start < b.start
                        ? -1
                        : a.end > b.end
                        ? 1
                        : a.end < b.end
                        ? -1
                        : 0,
                )
                .map((event) => ({
                    event,
                    top: top(event.start, currentDay),
                    bottom: top(event.end, currentDay),
                    time: {
                        start: event.start.format("h:mm A"),
                        end: roundEventEndTime(event),
                    },
                    zIndex: getZIndex(event),
                })),
        [events, currentDay],
    );

    return (
        <>
            {computedEvents.map(
                (item): JSX.Element => (
                    <CalendarItem
                        key={
                            item.event.resourceId +
                            item.time.start +
                            item.time.end +
                            item.event.title
                        }
                        {...item}
                    />
                ),
            )}

            {dateIsToday ? (
                <div
                    ref={(el): void => setCurrentTimeBarRef(el)}
                    className="absolute bg-purple overflow-visible"
                    style={{
                        top: `${top(dayjs(currentDay), currentDay)}rem`,
                        height: "2px",
                        left: "95px",
                        right: "0px",
                        zIndex: 100,
                    }}
                >
                    <div
                        className="absolute rounded-full bg-purple"
                        style={{
                            left: "241px",
                            top: "-4px",
                            width: "10px",
                            height: "10px",
                        }}
                    />
                </div>
            ) : null}
        </>
    );
});
