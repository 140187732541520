export default function isDay(
    date: Date,
    dayToMatch = new Date(Date.now()),
): boolean {
    const a = new Date(date);
    const b = new Date(dayToMatch);

    return (
        a.getDate() === b.getDate() &&
        a.getMonth() === b.getMonth() &&
        a.getFullYear() === b.getFullYear()
    );
}
