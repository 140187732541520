import { memo } from "react";
import type InAndOutOfRangeToStringMetricProps from "./utilities/InAndOutOfRangeToStringMetric.props";
import Text from "components/Text/Text";

export default memo(function InAndOutOfRangeStringMetric({
    offline,
    inside,
    outside,
    range,
    value,
}: InAndOutOfRangeToStringMetricProps): JSX.Element {
    // If the device is offline, display "Offline" as the status for this metric
    if (offline) return <Text className="m-auto font-bold">Offline</Text>;

    // Else display an error if value is not a number, else display inside
    // string if value is in inside range, else display outside string
    return (
        <Text className="m-auto font-bold">
            {isNaN(value)
                ? "Error. value is not a number"
                : value <= range.safeMax && value >= range.safeMin
                ? inside
                : outside}
        </Text>
    );
});
