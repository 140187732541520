import Icon from "components/Icons/Icon/Icon";
import useMouseOver from "hooks/useMouseOverListener";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useCallback, useState } from "react";
import rootStore from "stores/rootStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import baseClassNavigationTabs from "utils/baseClassNavigation";
import extendClassName from "utils/extendClassName";

interface TabListItem<T> {
    currentTab: T;
    setCurrentTab: (tab: T) => void;
    value: T;
    icon: string;
    onClick?: (value: T) => void;
}

/**
 * A List Item that Display a Tab, which includes the name and icon
 */
export default observer(function TabListItem<T>({
    currentTab,
    setCurrentTab,
    value,
    icon,
    onClick,
}: TabListItem<T>): JSX.Element {
    const [ref, setRef] = useState<HTMLLIElement | null>(null);
    const { setParams, currentDrawerTab } = useQuery();
    const { mousedOver } = useMouseOver(ref);

    const handleClick = useCallback((): void => {
        setCurrentTab(value);
        setParams({
            currentDrawerTab: (value as string).toString(),
        });
        onClick?.(value);
    }, [value, currentDrawerTab]);

    return (
        <li
            ref={(ref): void => setRef(ref)}
            key={String(value)}
            data-title={value}
            className={extendClassName(
                CLASS_NAMES.iconTabs.zoneAndDeviceTabs,
                baseClassNavigationTabs(String(currentTab), String(value)),
                "lg:w-[90px]",
            )}
            onClick={handleClick}
        >
            <span className="mx-auto">
                <Icon
                    name={icon}
                    fill={
                        value === currentTab || mousedOver
                            ? "#141e26"
                            : "#626262"
                    }
                    size={rootStore.viewportStore.isMobile ? "sm" : "md"}
                />
            </span>
            <p
                className={extendClassName(
                    CLASS_NAMES.iconTabs.text,
                    value === currentTab || mousedOver
                        ? "font-semibold"
                        : CLASS_NAMES.iconTabs.onHoverText,
                )}
            >
                {String(value)}
            </p>
        </li>
    );
});
