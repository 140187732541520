import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import propagateDeviceUpdatesToZonesOnMap from "./propagateDeviceUpdatesToZoneOnMap.util";

interface PropagateDeviceChangesToMapProps {
    updatedDevice: Device;
    updateFeature: <
        T extends Device | ZoneWithOverallHealth | MobileDevice | GroupedDevice,
    >(
        id: string,
        updatedData: T | Partial<T>,
    ) => Promise<void>;
    mapViewType: MAP_VIEW_TYPE;
}

/**
 * Propagates passed device (considered updates at this point) to the openlayers map. Unless the user is on the fleet view or alert
 * then it doesn't. If the map is of type ZONE then the updates are propagated to the relevant zone as well @see {propagateDeviceUpdatesToZonesOnMap}
 */
async function propogateDeviceChangesToMap({
    updatedDevice,
    updateFeature,
    mapViewType,
}: PropagateDeviceChangesToMapProps): Promise<void> {
    try {
        const queryString = window.location.href;
        //Do not propagate if on Fleet/List view
        if (
            queryString.includes("fleet") ||
            queryString.includes("notification")
        )
            return;
        if (mapViewType === MAP_VIEW_TYPE.ZONE) {
            await propagateDeviceUpdatesToZonesOnMap({
                //Feels like a bad idea to add this here as a dependency?
                zoneIds: updatedDevice.references.zoneIDs,
                updateFeature: updateFeature,
            });
        } else if (mapViewType === MAP_VIEW_TYPE.DEVICE) {
            //Will not run properly if message received on List view because
            //List view does not have reference to map (because it doesn't exist :D)
            updateFeature(updatedDevice._id, updatedDevice);
        } else {
            console.error(
                "Map type undetermined cannot propagate. Map type found: ",
                mapViewType,
            );
        }
    } catch (err) {
        console.error("Propagating device update to map failed", err);
        throw new Error(
            "Propagating device update failed to occur. Map not updated.",
        );
    }
}

export default propogateDeviceChangesToMap;
