import type { Dayjs } from "dayjs";
import isDay from "../../../utilities/isDay.func";

export default function top(time: Dayjs, currentDay: Date): number {
    let hours = 0;
    let minutes = 0;
    let offset = 4;

    if (isDay(time.toDate(), currentDay)) {
        hours = time.hour();
        minutes = time.minute();
    } else if (time.isBefore(currentDay)) {
        hours = 0;
        minutes = 0;
    } else {
        offset = 0;
        hours = 24;
        minutes = 60;
    }

    return ((hours + minutes / 60) / 50) * 100 * 4 + offset;
}
