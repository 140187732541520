import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";

export const HEALTH_COLOR = {
    [HEALTH_STATUS.SAFE]: "-cobalt",
    [HEALTH_STATUS.WARNING]: "-orange",
    [HEALTH_STATUS.UNSAFE]: "-red",
};

const other = "bg-red";

export default function healthColor(health: HEALTH_STATUS): string {
    return HEALTH_COLOR[health] ? `bg${HEALTH_COLOR[health]}` : other;
}
