enum DEVICE_DRAWER_TABS {
    STATUS = "status",
    MAP = "map",
    INFO = "info",
    SETTINGS = "settings",
    ALERTS = "alerts",
    EVENTS = "history",
}

export default DEVICE_DRAWER_TABS;
