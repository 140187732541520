import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import DeviceService from "services/DeviceService";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import fetchQueries from "./fetchQueries";
/**
 * Calculates the overall health status of a given zone based on the health status of its devices.
 * @param @see  Zone - Containing the device list for which to calculate the overall health status.
 * @returns @see ZoneWithOverallHealth Zone object with added overallHealthProperty.
 */
export default async function findProblemsWithZone(
    zone: Zone,
): Promise<ZoneWithOverallHealth> {
    if (!zone.devices || zone.devices.length === 0) {
        return { ...zone, overallHealth: HEALTH_STATUS.SAFE };
    }
    try {
        // Create an array of query keys for the devices in the zone
        const queryKeys = zone.devices.map((deviceId) => ["device", deviceId]);

        // Ensure all the required device data is available
        const settledResults = await fetchQueries<Device>({
            queries: queryKeys,
            queryFn: async ({ queryKey }) => {
                return await DeviceService.getDevice(queryKey[1]);
            },
        });

        // Filter out the fulfilled results and extract the device objects
        const devices = settledResults
            .filter(
                (result): result is PromiseFulfilledResult<Device> =>
                    result.status === "fulfilled",
            )
            .map((result) => result.value);

        // collect device health statuses into an array
        const devicesOverallHealth = devices.map(
            (device) => device.deviceHealth.overAllHealth,
        );

        const hasUnsafeDevice = devicesOverallHealth.some(
            (health) => health === HEALTH_STATUS.UNSAFE,
        );
        const hasWarningDevice = devicesOverallHealth.some(
            (health) => health === HEALTH_STATUS.WARNING,
        );

        // if at least one device is in UNSAFE state, return UNSAFE status
        if (hasUnsafeDevice) {
            return { ...zone, overallHealth: HEALTH_STATUS.UNSAFE };
        }
        // if no devices are in UNSAFE state but at least one device is in WARNING state, return WARNING status
        else if (hasWarningDevice) {
            return { ...zone, overallHealth: HEALTH_STATUS.WARNING };
        }
        // if no devices are in UNSAFE or WARNING state, return SAFE status
        else {
            return { ...zone, overallHealth: HEALTH_STATUS.SAFE };
        }
    } catch (err) {
        console.error(err);
        throw new Error("Unable to calculate if problems exist within zone");
    }
}
