import DeviceHeaders from "@srtlabs/m1_types/lib/Device/DeviceHeaders/DeviceHeaders.type";
import Button from "components/Button/Button";
import useSaveDevice from "hooks/useSaveDevice.hook";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import deviceHeadersSchema from "schemas/deviceHeaders.schema";
import { CLASS_NAMES } from "styles/classNameConstants";
import FormErrors from "types/FormErrors";
import DeviceHeadersWithOptionalFields from "types/FormTypes/DeviceHeaderFields";
import convertStringToDotNotation from "utils/convertStringToDotNotation";
import extendClassName from "utils/extendClassName";
import formatDate from "utils/formatDate";
import { ObjectSchema, ValidationError } from "yup";
import type CurrentDeviceCardContentInfoProps from "./utilities/CurrentDeviceCardContentInfo.props";

const label = "py-1 text-sm font-thin col-span-4";
const inputCol = "min-h-[20px] col-span-8 w-full";
const inputFieldStyle = (className?: string): string =>
    "w-full p-1 text-sm self-center h-full items-start " +
    (className ? ` ${className}` : "") +
    " font-thin";

const row = "grid grid-cols-12 gap-y-2 mb-2 items-center min-h-[40px] w-full";
const error = "text-red text-xs italic";
const disabled = "opacity-50";

interface DeviceHeadersFormErrors extends FormErrors {
    deviceName?: string;
    generalPosition?: string;
    manufacturer?: string;
    manufacturerId?: string;
    dataType?: string;
    deviceDescription?: string;
    location?: string;
    sublocation?: string;
    sensorLocation?: string;
    timeToLive?: string;
}
export default observer(function CurrentDeviceCardContentInfo({
    device,
    snackbarStore,
}: CurrentDeviceCardContentInfoProps): JSX.Element {
    const [editing, setToggleEdit] = useState(false);
    const [isValid, setIsValid] = useState(false);
    const { mutate: saveDevice } = useSaveDevice(device);

    const useYupValidationResolver = (
        validationSchema: ObjectSchema<DeviceHeadersWithOptionalFields>,
    ): ((data: DeviceHeadersWithOptionalFields) => Promise<{
        values: DeviceHeadersWithOptionalFields;
        errors: DeviceHeadersFormErrors;
    }>) =>
        useCallback(
            async (data: DeviceHeadersWithOptionalFields) => {
                try {
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    });
                    setIsValid(true);
                    return {
                        values,
                        errors: {} as unknown as DeviceHeadersFormErrors,
                    };
                } catch (errors) {
                    if (ValidationError.isError(errors)) {
                        //Disable submit buttons, since we found some errors
                        setIsValid(false);
                        const resolvedErrors = {
                            values: data,
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => {
                                    //RHF uses dot notation to locate fields/inputs
                                    //so we need to convert the yup paths (which use ["0"]/[0]), to dots
                                    return {
                                        ...allErrors,
                                        [(currentError.path &&
                                            convertStringToDotNotation(
                                                currentError.path,
                                            )) ||
                                        "unknown"]: {
                                            type:
                                                currentError.type ??
                                                "validation",
                                            message: currentError.message,
                                        },
                                    };
                                },
                                {},
                            ),
                        };
                        return resolvedErrors;
                    } else {
                        //TODO: Handle other types of errors, most likely with the toastit note
                        console.error(
                            "An unexpected error occurred during validation:",
                            errors,
                        );
                        return {
                            values: data,
                            errors: {
                                default:
                                    "An unexpected error occurred. Please try again or contact support.",
                            },
                        };
                    }
                }
            },
            [validationSchema],
        );

    const {
        register,
        handleSubmit,
        reset,
        formState: { errors, isSubmitting },
        trigger,
    } = useForm({
        resolver: useYupValidationResolver(deviceHeadersSchema),
        defaultValues: {
            deviceName: device.headers.deviceName,
            generalPosition: device.headers.generalPosition,
            manufacturer: device.headers.manufacturer,
            manufacturerId: device.headers.manufacturerId,
            dataType: device.headers.dataType,
            deviceDescription: device.headers.deviceDescription,
            location: device.headers.location,
            sublocation: device.headers.sublocation,
            sensorLocation: device.headers.sensorLocation,
            lastUpdated: formatDate(device.metrics.metricsUpdateDate),
            timeToLive: device.headers.timeToLive,
        },
        resetOptions: {
            // keepDirtyValues: true, // user-interacted input will be retained
            // keepErrors: true, // input errors will be retained with value update
        },
        mode: "onChange",
    });

    useEffect(() => {
        reset({
            deviceName: device.headers.deviceName,
            generalPosition: device.headers.generalPosition,
            manufacturer: device.headers.manufacturer,
            manufacturerId: device.headers.manufacturerId,
            dataType: device.headers.dataType,
            deviceDescription: device.headers.deviceDescription,
            location: device.headers.location,
            sublocation: device.headers.sublocation,
            sensorLocation: device.headers.sensorLocation,
            timeToLive: device.headers.timeToLive,
        });
        editing && trigger();
    }, [device.headers, editing]);

    const onSubmit = useCallback(
        async (formData: DeviceHeadersWithOptionalFields): Promise<void> => {
            try {
                const processedData: Partial<
                    Omit<
                        DeviceHeaders,
                        "deviceGUID" | "emsRoomObjectID" | "ipAddress"
                    >
                > = {
                    deviceName:
                        formData.deviceName ?? device.headers.deviceName,
                    generalPosition: formData.generalPosition ?? "",
                    manufacturer: formData.manufacturer ?? "",
                    manufacturerId: formData.manufacturerId ?? "",
                    dataType: formData.dataType ?? "",
                    deviceDescription: formData.deviceDescription ?? "",
                    location: formData.location ?? "",
                    sublocation: formData.sublocation ?? "",
                    sensorLocation: formData.sensorLocation ?? "",
                    timeToLive: formData.timeToLive ?? 86400, // Need an acceptable default for timeToLive
                };
                await saveDevice({
                    ...device,
                    headers: { ...device.headers, ...processedData },
                });
                setToggleEdit(false);
            } catch (e) {
                console.error(e);
            }
        },
        [snackbarStore.enqueueSnackbar, device],
    );

    return (
        <>
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>
                    Device Information
                </h2>
            </div>{" "}
            <form
                onSubmit={handleSubmit(onSubmit)}
                className="lg:px-12 px-4 grid gap-y-0.25 text-gray-700 lg:min-w-80 mr-[10em]"
            >
                <div className={row}>
                    <label
                        className={extendClassName(
                            label,
                            "border-t-1 border-primary-lightGray",
                        )}
                        htmlFor="location"
                    >
                        Location
                    </label>
                    <span className={inputCol}>
                        <input
                            id="location"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabled : "",
                            )}
                            disabled
                            {...register("location")}
                        />
                        {errors.location && (
                            <p className={error}>{errors.location.message}</p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="sublocation">
                        Sublocation
                    </label>
                    <span className={inputCol}>
                        <input
                            id="sublocation"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabled : "",
                            )}
                            disabled
                            {...register("sublocation")}
                        />
                        {errors.sublocation && (
                            <p className={error}>
                                {errors.sublocation.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="deviceName">
                        Display Name
                    </label>
                    <span className={inputCol}>
                        <input
                            id="deviceName"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("deviceName")}
                        />
                        {errors.deviceName && (
                            <p className={error}>{errors.deviceName.message}</p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="dataType">
                        Device Measurement
                    </label>
                    <span className={inputCol}>
                        <input
                            id="dataType"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("dataType")}
                        />

                        <p className={error}>
                            {errors.dataType && errors.dataType.message}
                        </p>
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="generalPosition">
                        Device General Position
                    </label>
                    <span className={inputCol}>
                        <input
                            id="generalPosition"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("generalPosition")}
                        />
                        {errors.generalPosition && (
                            <p className={error}>
                                {errors.generalPosition.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="deviceDescription">
                        Device Description
                    </label>
                    <span className={inputCol}>
                        <textarea
                            id="deviceDescription"
                            className={inputFieldStyle(" resize-none")}
                            disabled={!editing}
                            {...register("deviceDescription")}
                        />
                        {errors.deviceDescription && (
                            <p className={error}>
                                {errors.deviceDescription.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="manufacturer">
                        Manufacturer
                    </label>
                    <span className={inputCol}>
                        <input
                            id="manufacturer"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("manufacturer")}
                        />
                        {errors.manufacturer && (
                            <p className={error}>
                                {errors.manufacturer.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="manufacturerId">
                        Manufacturer Device ID
                    </label>
                    <span className={inputCol}>
                        <input
                            id="manufacturerId"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("manufacturerId")}
                        />
                        {errors.manufacturerId && (
                            <p className={error}>
                                {errors.manufacturerId.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="sensorLocation">
                        Sensor Location
                    </label>
                    <span className={inputCol}>
                        <input
                            id="sensorLocation"
                            className={inputFieldStyle()}
                            disabled={!editing}
                            {...register("sensorLocation")}
                        />
                        {errors.sensorLocation && (
                            <p className={error}>
                                {errors.sensorLocation.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="deviceId">
                        Device ID {"    "}
                    </label>
                    <span className={inputCol}>
                        <input
                            id="deviceId"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabled : "",
                            )}
                            readOnly
                            defaultValue={device._id}
                            disabled
                        />
                    </span>
                </div>

                <div className={row}>
                    <label className={label} htmlFor="lastUpdated">
                        Last updated
                    </label>
                    <span className={inputCol}>
                        <input
                            id="lastUpdated"
                            className={extendClassName(
                                inputFieldStyle(),
                                editing ? disabled : "",
                            )}
                            disabled
                            {...register("lastUpdated")}
                        />
                        {errors.lastUpdated && (
                            <p className={error}>
                                {errors.lastUpdated.message}
                            </p>
                        )}
                    </span>
                </div>

                <div className={row}>
                    <label
                        className={extendClassName(label, "mt-auto")}
                        htmlFor="timeToLive"
                    >
                        Time to Live
                    </label>
                    <span className={inputCol}>
                        <input
                            id="timeToLive"
                            type="text"
                            className={extendClassName(inputFieldStyle())}
                            disabled={!editing}
                            {...register("timeToLive")}
                        />
                        <p className={error}>
                            {errors.timeToLive && errors.timeToLive.message}
                        </p>
                    </span>
                </div>
            </form>
            <div className="w-full mt-auto py-2 flex flex-col justify-center items-center border-t-1 border-obsidian">
                {editing ? (
                    <div className="flex flex-1 justify-center items-center gap-4">
                        <Button
                            variant={"text"}
                            onClick={(): void => setToggleEdit(!editing)}
                            disabled={isSubmitting}
                        >
                            Cancel
                        </Button>
                        <Button
                            variant={"contained"}
                            disabled={isSubmitting || !isValid}
                            onClick={handleSubmit(onSubmit)}
                        >
                            Save
                        </Button>
                    </div>
                ) : (
                    <Button
                        variant={"contained"}
                        onClick={(): void => setToggleEdit(true)}
                    >
                        Edit Device
                    </Button>
                )}
            </div>
        </>
    );
});
