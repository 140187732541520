import MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import StringMultiRangeSafeVal from "types/StringMultiRangeSafeVal";

const numberArrayToStringArray = (arr: number[]): string[] => arr.map(String);

const numberArray2DToStringArray2D = (arr: number[][]): string[][] =>
    arr.map((subArr) => subArr.map(String));

const stringArrayToNumberArray = (arr: string[]): number[] => arr.map(Number); //what happens if nan?

const stringArray2DToNumberArray2D = (arr: string[][]): number[][] =>
    arr.map((subArr) => subArr.map(Number));

export const convertMultiRangeSafeValToStrings = (
    input: MultiRangeSafeVal,
): StringMultiRangeSafeVal => {
    return {
        base: input.base,
        operatingRange: numberArrayToStringArray(input.operatingRange),
        safe: numberArray2DToStringArray2D(input.safe),
        warning: numberArray2DToStringArray2D(input.warning),
        unsafe: numberArray2DToStringArray2D(input.unsafe),
    };
};

export const convertStringMultiRangeSafeValToNumbers = (
    input: StringMultiRangeSafeVal,
): MultiRangeSafeVal => {
    try {
        return {
            base: input.base,
            operatingRange: stringArrayToNumberArray(input.operatingRange),
            safe: stringArray2DToNumberArray2D(input.safe),
            warning: stringArray2DToNumberArray2D(input.warning),
            unsafe: stringArray2DToNumberArray2D(input.unsafe),
        };
    } catch (error) {
        console.error(
            "there was error converting string multirange val to num",
            error,
        );
        throw new Error("error on string conversion");
    }
};
