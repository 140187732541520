import { memo, useCallback } from "react";
import type AccordionProps from "./utilities/AccordionProps.props";

/**
 * An accordion that can exist within list item tags
 */
export default memo(function Accordion<T>({
    children,
    classes,
    isOpen,
    setIsOpen,
    renderHeader,
    data,
}: AccordionProps<T>) {
    const handleOpen = useCallback(() => {
        setIsOpen(isOpen);
    }, [isOpen, setIsOpen]);

    return (
        <>
            <div className={classes?.wrapper}>
                {renderHeader({ item: data, onClickItem: handleOpen })}
            </div>
            {isOpen && children}
        </>
    );
});
