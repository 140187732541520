import FeatureDisplayState from "types/OpenLayers/FeatureDisplayState";

/**
 * Defines color families for zones. Each color family consists of different variations:
 * - background: The background color of the zone
 * - active: The color of the zone when active
 * - selected: The color of the zone when selected
 * - border: The color used for the zone border.
 *
 * @typedef {FeatureDisplayState} DeviceColorFamily
 * @property {string} background - Background color
 * @property {string} active - Active color
 * @property {string} selected - Selected color
 * @property {string} border - Border color in Hex format.
 *
 * @type {{[health: string]: FeatureDisplayState}}
 */
const deviceColorFamilies: {
    [health: string]: FeatureDisplayState;
} = {
    safe: {
        default: {
            background: "#7C9CEF",
            border: "#2E61E6",
        },
        hover: {
            background: "#6288EC",
            border: "#2E61E6",
        },
        selected: {
            background: "#2E61E6",
            border: "#2E61E6",
        },
    }, // blue
    warning: {
        default: {
            background: "#F8BD5D",
            border: "#E8920A",
        },
        hover: {
            background: "#F7AF3C",
            border: "#E8920A",
        },
        selected: {
            background: "#E8920A",
            border: "#E8920A",
        },
    }, // orange
    unsafe: {
        default: {
            background: "#DF8076",
            border: "#C43B2D",
        },
        hover: {
            background: "#D9675A",
            border: "#C43B2D",
        },
        selected: {
            background: "#C43B2D",
            border: "#C43B2D",
        },
    }, // red
    offline: {
        default: {
            background: "#A5A5A5",
            border: "#707070",
        },
        hover: {
            background: "#939393",
            border: "#707070",
        },
        selected: {
            background: "#707070",
            border: "#707070",
        },
    }, // grey
};

export default deviceColorFamilies;
