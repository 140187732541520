import DeviceDisplayDrawerItem from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerItem/DeviceDisplayDrawerItem.type";
import { memo, useMemo } from "react";
import type CurrentDeviceCardContentCurrentProps from "../utilities/CurrentDeviceCardContentCurrent.props";
import CurrentDeviceCardContentStatusDrawerItem from "./CurrentDeviceCardContentStatusDrawerItem/CurrentDeviceCardContentStatusDrawerItem";

export default memo(function CurrentDeviceCardContentStatus({
    device,
}: CurrentDeviceCardContentCurrentProps): JSX.Element {
    const filteredDrawers: DeviceDisplayDrawerItem[] = useMemo(
        () =>
            device.display.drawerContent.filter(
                (drawerContentItem) =>
                    drawerContentItem && !drawerContentItem.hiddenFromDashboard,
            ),
        [device.display.drawerContent],
    );

    return (
        <ul className="flex flex-1 flex-col w-full">
            {filteredDrawers.map(
                (drawer): JSX.Element => (
                    <CurrentDeviceCardContentStatusDrawerItem
                        key={device._id + drawer.name + drawer.type}
                        device={device}
                        drawer={drawer}
                    />
                ),
            )}
        </ul>
    );
});
