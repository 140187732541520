import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import { Circle, Fill, Icon, Stroke, Style } from "ol/style";
import deviceColorFamilies from "stores/classes/Openlayers/utils/deviceColorFamily.data";
import FeatureDisplayState from "types/OpenLayers/FeatureDisplayState";
import FeatureStyleSetterProps from "types/OpenLayers/FeatureStyleSetterProps";

/**
 * Takes the health status and current interactive state of the feature and
 * returns the relevant color set/family.
 * @param {string | HEALTH_STATUS} key - Either offline or HEALTH_STATUS enum
 * @param {FeatureDisplayState} state - represents the user interaction of default (no interaction), hover, or selected and returns a object containing relevant backgroud and border color.
 * @returns
 */
const deviceColorPicker = (
    key: string | HEALTH_STATUS,
    state: keyof FeatureDisplayState,
): { background: string; border: string } => {
    try {
        switch (key) {
            case HEALTH_STATUS.SAFE:
                return deviceColorFamilies["safe"][state];
            case HEALTH_STATUS.UNSAFE:
                return deviceColorFamilies["unsafe"][state];
            case HEALTH_STATUS.WARNING:
                return deviceColorFamilies["warning"][state];
            default:
                return deviceColorFamilies["offline"][state];
        }
    } catch (err) {
        console.error("Could not select relevant color from family", err);
        throw new Error("Could not select relevant color from family");
    }
};

export const getDeviceStyle = ({
    feature,
    status,
    state,
}: FeatureStyleSetterProps): Style[] => {
    try {
        const { background, border } = deviceColorPicker(status, state);
        const device: Device = feature.getProperties() as Device;

        return [
            //Circle Style
            new Style({
                image: new Circle({
                    radius: 25, //adjust for circle size
                    fill: new Fill({
                        color: background,
                    }),
                    stroke: new Stroke({
                        width: 4,
                        color: border,
                    }),
                }),
            }),
            // Icon Style
            new Style({
                image: new Icon({
                    anchor: [0.5, 0.5], //center of circle
                    src:
                        process.env.REACT_APP_ICON_BASE_URL +
                        device.display.image,
                    scale: 0.1, // Adjust this scale value so the icon fits inside circle
                    crossOrigin: "anonymous",
                }),
            }),
        ];
    } catch (err) {
        console.error("Could not update device style", err);
        throw new Error("Could not update device style");
    }
};
