import Accordion from "components/Accordion/Accordion";
import DisplayAngleIcon from "components/DisplayAngleIcon/DisplayAngleIcon";
import { useNavigateToLocation } from "hooks/useNavigateToLocation";
import useToggleToAccordion from "hooks/useToggleToAccordion/useToggleToAccordion";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import { CLASS_NAMES } from "styles/classNameConstants";
import extendClassName from "utils/extendClassName";

import type LocationProps from "./utilities/LocationProps";
type Locations = {
    id: string;
    text: string;
    sublocations: Sublocation[];
};

type Sublocation = {
    id: string;
    text: string;
};

//We want to alphabetically sort all sublocations if the belong to the PSNS MS org
const includeAlphabeticalSort = ["14ee2d03-878b-47bc-b678-ecf97723c488"];

/**
 * The container for the Locations Content from the LeftNavDrawer.
 * It includes list of locations and their relevant sublocations as an accordion of
 * list items.
 */
export default observer(function Locations({
    organizationStore,
    setCurrentTab,
    setDrawerOpen,
    setDrawerItemClicked,
}: LocationProps) {
    const { navigateToLocation } = useNavigateToLocation();
    const [searchParams] = useSearchParams();
    const { handleToggle, isOpen, setIsOpen } = useToggleToAccordion();
    const locations = useMemo(
        () =>
            Object.entries(organizationStore.organization?.locations || {})
                .map(([id, loc]) => ({
                    id,
                    text: loc.name,
                    listOrderNumber: loc.listOrderNumber,
                    sublocations: Object.entries(loc.sublocations)
                        .map(([sublocationID, sublocation]) => ({
                            id: sublocationID,
                            text: sublocation.name,
                            listOrderNumber: sublocation.listOrderNumber,
                        }))
                        .sort((a, b) => a.listOrderNumber - b.listOrderNumber),
                }))
                .sort((a, b) => a.listOrderNumber - b.listOrderNumber),
        [organizationStore.organization],
    );

    /**
     * Renders the list of locations and sublocations.
     */
    const renderSubNavList = useCallback(
        (locId: string, sublocations: Sublocation[]): JSX.Element => {
            const sublocationList =
                organizationStore.orgId &&
                includeAlphabeticalSort.includes(organizationStore.orgId)
                    ? sublocations.sort((a, b) => a.text.localeCompare(b.text))
                    : sublocations;

            return (
                <ul className="max-h-[80%] overflow-y-auto shadow-inner">
                    {sublocationList.map(
                        ({ id: sublId, text }: Sublocation) => {
                            return (
                                <li
                                    key={text + sublId}
                                    className={extendClassName(
                                        CLASS_NAMES.listItem.leftNav.base,
                                        CLASS_NAMES.listItem.leftNav.default,
                                        sublId === searchParams.get("sublId")
                                            ? CLASS_NAMES.listItem.leftNav
                                                  .active
                                            : "",
                                    )}
                                    onClick={(): void => {
                                        setDrawerItemClicked(true);
                                        setDrawerOpen(false);
                                        setTimeout(
                                            (): void => setCurrentTab(null),
                                            600,
                                        ); // this timeout exists to coincide with the animation delay in the LeftNavDrawer
                                        navigateToLocation("maps", {
                                            orgId: organizationStore.orgId,
                                            locId: locId,
                                            sublId: sublId,
                                        });
                                    }}
                                >
                                    {text}
                                </li>
                            );
                        },
                    )}
                </ul>
            );
        },
        [organizationStore.orgId, searchParams],
    );

    //Determines which location subnav to open depending on whether an active sublId is present within
    const expandCurrentLocation = useCallback(() => {
        const currentLocId = searchParams.get("locId");
        if (locations.length === 1) {
            // If there's only one location, always expand it
            setIsOpen({ [locations[0].id]: true });
        } else if (currentLocId) {
            // If a location is specified in the URL, expand that one
            //This will also cover if a sublId is active within the locId without being explicit about it.
            setIsOpen({ [currentLocId]: true });
        } else if (locations.length > 1) {
            //if no location is specified and there are multi locations
            //expand the first accordion
            setIsOpen({ [locations[0].id]: true });
        } else {
            //cleanup for if there are no locations.
            setIsOpen({});
        }
    }, [locations, searchParams, setIsOpen]);

    useEffect(() => {
        expandCurrentLocation();
    }, [expandCurrentLocation]);

    return (
        <div className="text-obsidian h-full overflow-hidden">
            {organizationStore.organization?.locations
                ? locations.map(
                      ({ text, id: locId, sublocations }: Locations) => {
                          return (
                              <Accordion
                                  key={locId}
                                  isOpen={isOpen[locId]}
                                  setIsOpen={(): void => {
                                      handleToggle(locId);
                                  }}
                                  classes={{
                                      wrapper: extendClassName(
                                          CLASS_NAMES.accordion.header.default,
                                          locId === searchParams.get("locId") &&
                                              !searchParams.get("sublId")
                                              ? CLASS_NAMES.accordion.header
                                                    .active
                                              : "",
                                      ),
                                  }}
                                  data={text}
                                  renderHeader={({
                                      onClickItem,
                                  }): JSX.Element => (
                                      <span
                                          data-title={text}
                                          className="pl-[20px] py-2 flex flex-row space-between w-full"
                                          onClick={(): void => {
                                              onClickItem(locId);
                                              navigateToLocation("maps", {
                                                  orgId: organizationStore.orgId,
                                                  locId: locId,
                                              });
                                          }}
                                      >
                                          <span className="text-base font-semibold">
                                              {text}
                                          </span>

                                          <button
                                              className={
                                                  "ml-auto min-w-[2em] pointer-events-none"
                                              }
                                              onClick={(): void =>
                                                  onClickItem(locId)
                                              }
                                          >
                                              {
                                                  <DisplayAngleIcon
                                                      isOpen={isOpen[locId]}
                                                  />
                                              }
                                          </button>
                                      </span>
                                  )}
                              >
                                  {renderSubNavList(locId, sublocations)}
                              </Accordion>
                          );
                      },
                  )
                : "There are no locations available."}
        </div>
    );
});
