/* eslint-disable  @typescript-eslint/ban-ts-comment */
/* eslint-disable  @typescript-eslint/no-explicit-any */
import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import { useCallback, useMemo, useState } from "react";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import FLEET_SORT_OPTIONS from "./FleetSortOptions.enum";
import UseSort from "./UseSort.interface";
import type UseSortOption from "./UseSortOption.interface";

function retrieveUsingDotString<T = unknown>(
    str: string,
    obj: Record<string, unknown>,
): T {
    return str.split(".").reduce((current, i): Record<string, unknown> => {
        return (current as Record<string, unknown>)[i as string] as Record<
            string,
            unknown
        >;
    }, obj) as unknown as T;
}

//Working on establishing a more robust typing system
//Perhaps a better typing would be "unknown"

function sort<T extends Device | ZoneWithOverallHealth>(
    path: string,
    measure?: (input: string | number) => string | number,
): (a: T, b: T) => 1 | 0 | -1 {
    return (A: T, B: T): 1 | 0 | -1 => {
        let a = retrieveUsingDotString<number | string>(path, A);
        let b = retrieveUsingDotString<number | string>(path, B);
        if (measure !== undefined) {
            a = measure(a);
            b = measure(b);
        }
        return a > b ? 1 : b > a ? -1 : 0;
    };
}
const useSort = <T extends Device | ZoneWithOverallHealth>(
    items: T[],
    options: Readonly<UseSortOption[]>,
): UseSort<T> => {
    const [settings, setSettings] = useState<FLEET_SORT_OPTIONS>();
    const [reverse, setReverse] = useState(false);

    /**
     * returns an object from the OPTIONS array that defines what the function is currently sorting by,
     * note that options is an array of all possible sorting methods vs activeSort being the sort defined by the user
     * if the currentOption is not set or not found we default to sorting by devices with the highest error
     */
    const currentOption = useMemo<UseSortOption>(
        () => options.find(({ type }) => type === settings) || options[1],
        [settings],
    );

    /**
     * sets settings array by the sort option that was clicked
     */
    const setSortOption = useCallback(
        (sortType: FLEET_SORT_OPTIONS, reverse: boolean) => {
            setSettings(sortType);
            setReverse(reverse);
        },
        [settings],
    );

    const sortWith = useMemo(
        () =>
            sort<T>(
                currentOption.sort.path,
                currentOption!.sort.measure as never,
            ),
        [settings],
    );
    /**
     * returns an array of device objects, if the user clicked the sort button twice, it sends the devices in reverse order
     */
    const sorted = useMemo<T[]>(() => {
        const temp = items.slice().sort(sortWith);
        if (reverse) return temp.reverse();
        return temp;
    }, [items, sortWith, reverse, settings]);

    return {
        sorted: sorted,
        setSortOption: setSortOption,
        reverse: reverse,
        setReverse: setReverse,
        currentOption: currentOption,
    };
};

export default useSort;
