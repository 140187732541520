import { useEffect, useRef } from "react";

interface UseDynamicTableHeightResult {
    containerRef: React.RefObject<HTMLDivElement>;
    tableRef: React.RefObject<HTMLTableElement>;
}

const useDynamicTableHeight = (
    percentage: number,
): UseDynamicTableHeightResult => {
    const containerRef = useRef<HTMLDivElement>(null);
    const tableRef = useRef<HTMLTableElement>(null);

    useEffect(() => {
        const container = containerRef.current;
        const table = tableRef.current;

        if (container && table) {
            const containerHeight = container.offsetHeight;
            const tableHeight = (containerHeight * percentage) / 100;
            table.style.height = `${tableHeight}px`;
        }
    }, [percentage]);

    return { containerRef, tableRef };
};

export default useDynamicTableHeight;
