import DisplayAngleIcon from "components/DisplayAngleIcon/DisplayAngleIcon";
import useQuery from "hooks/useQuery/useQuery";
import { Fragment, memo, useMemo, useState } from "react";
import extendClassName from "utils/extendClassName";

interface ZoneReference {
    id: string;
    name: string;
}

interface ZoneReferenceContainer {
    zoneReferences: ZoneReference[];
}

/**
 * A component that shows a list of zone ID references, currently from the CurrentDeviceDrawerHeader
 */
export default memo(function ZoneReferenceContainer({
    zoneReferences,
}: ZoneReferenceContainer): JSX.Element {
    const { setParams } = useQuery();
    const [referencesIsExpanded, setExpandReferences] = useState(false);

    const references = useMemo((): JSX.Element => {
        return (
            <>
                {zoneReferences.map(({ id, name }, i) => (
                    <Fragment key={id}>
                        <span
                            className="hover:cursor-pointer hover:underline has-ignoreClass-zone-drawer"
                            onClick={(): void =>
                                setParams({
                                    currentDevice: null,
                                    currentZone: id,
                                    currentDrawerTab: null,
                                })
                            }
                        >
                            {name}
                        </span>
                        {i < zoneReferences.length - 1 && ", "}
                    </Fragment>
                ))}
            </>
        );
    }, [zoneReferences, setParams]);

    return (
        <div className="w-full relative">
            <div className="overflow-hidden text-cobalt pr-4">
                <div
                    className={extendClassName(
                        `pr-3 transition-all duration-300 ease-in-out `,
                        referencesIsExpanded
                            ? "max-h-none"
                            : "max-h-6 overflow-hidden truncate",
                    )}
                >
                    {references}
                    {!referencesIsExpanded &&
                        zoneReferences.length > 1 &&
                        "..."}
                </div>
            </div>

            {zoneReferences.length > 1 && (
                <div className="flex justify-end">
                    <button
                        className="group flex flex-row items-center justify-center bg-white text-gray-500 hover:text-gray-700"
                        onClick={(): void =>
                            setExpandReferences(!referencesIsExpanded)
                        }
                    >
                        <span className="opacity-0 group-hover:opacity-100 w-auto mr-1">
                            {!referencesIsExpanded ? "see more" : "see less"}
                        </span>
                        <span className="px-1 text-gray-500 w-2 flex items-center justify-center p-4 h-2">
                            <DisplayAngleIcon
                                isOpen={!referencesIsExpanded}
                                closedIcon={"chevron-right"}
                                openIcon={"chevron-down"}
                            />
                        </span>
                    </button>
                </div>
            )}
        </div>
    );
});
