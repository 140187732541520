import { useState } from "react";
import AccordionState from "./AccordionState.props";
import UseToggleToAccordionProps from "./useToggleToAccordion.props";

/**
 * Because most accordiond occur in arrays, this hook allows the ease of either controlling each one, or all,
 * by way of it's id.
 * @returns { isOpen, setIsOpen, handleToggle, initialState }
 */
const useToggleToAccordion = (): UseToggleToAccordionProps => {
    const [isOpen, setIsOpen] = useState<AccordionState>({} as AccordionState);

    /**
     * sets the initial State of the accordion to be open by default
     * @param id
     */
    const initialAccordionState = (id: string): void => {
        setIsOpen((previousState) => ({
            ...previousState,
            [id]: true,
        }));
    };

    /**
     * Toggles open and close the accordion via it's id
     * @param id
     */
    const handleToggle = (id: string): void => {
        setIsOpen((previousState: AccordionState) => ({
            ...previousState,
            [id]: !previousState[id],
        }));
    };

    return { isOpen, setIsOpen, handleToggle, initialAccordionState };
};

export default useToggleToAccordion;
