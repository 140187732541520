import type DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import { memo, useMemo } from "react";
import CurrentRackShelfItem from "./CurrentRackShelfItem/CurrentRackShelfItem";
import type CurrentRackShelfProps from "./utilities/CurrentRackShelf.props";

export default memo(function CurrentRackShelf({
    shelf,
}: CurrentRackShelfProps): JSX.Element {
    const metrics = useMemo(() => {
        const metrics: Record<string, DevicesMetricsMetric> = {};
        for (const key in shelf.metrics) {
            if (key !== "metricsUpdateDate") {
                metrics[key] = shelf.metrics[key] as DevicesMetricsMetric;
            }
        }

        return metrics;
    }, [shelf]);

    return (
        <ul className="flex flex-col h-full w-52 ml-2 m-auto border-1 p-2 pt-0">
            <CurrentRackShelfItem
                key="swcc location"
                label="swcc location"
                value={"what is this??"}
            />
            <CurrentRackShelfItem
                key="m1 locator"
                label="Position"
                value={
                    shelf.headers.generalPosition ||
                    shelf.headers.sensorLocation
                }
            />
            <CurrentRackShelfItem
                key="gcss ninn"
                label="GCSS"
                value={String(metrics.gcss?.value)}
            />
            <CurrentRackShelfItem
                key="mcpic ninn"
                label="MCPIC"
                value={String(metrics.mcpic?.value)}
            />
            <CurrentRackShelfItem
                key="rfid ninn"
                label="RFID"
                value={String(metrics.rfid?.value)}
            />
            <CurrentRackShelfItem
                key="m1 update date"
                label="M1 updated"
                value={shelf.metrics.metricsUpdateDate}
            />
            <CurrentRackShelfItem
                key="item on shelf"
                label="Item detected"
                value={metrics.occupied?.value ? "True" : "False"}
            />
        </ul>
    );
});
