import type { Map } from "ol";
import { useEffect, useRef } from "react";
import type FeatureManager from "stores/classes/Openlayers/FeatureManager.class";
import ManagedFeatureUnion from "types/OpenLayers/ManagedFeatureUnion.type";

interface useMapOnClickHandlerProps {
    featureManager: FeatureManager | null;
    map: Map | null;
    /**
     * An action to be performed when the user clicks on a feature(s)
     * @param {ManagedFeatureUnion} feature
     * @returns {void}
     */
    clickedOnFeature: (feature: ManagedFeatureUnion) => void;
    /**
     * An action to be performed when the user clicks on the map, but not a feature
     * @returns {void}
     */
    clickedOnMap: (previouslyClickedFeature: ManagedFeatureUnion) => void;
}

/**
 * Listens for click events from the map and if the mouse position is within a feature
 *  performs the specified callback.
 * */
export default function useMapOnClickHandler({
    featureManager,
    map,
    clickedOnFeature,
    clickedOnMap,
}: useMapOnClickHandlerProps): void {
    const currentlySelectedFeatureIdRef = useRef<ManagedFeatureUnion | null>(
        null,
    );

    useEffect(() => {
        if (!featureManager || !map) return;

        featureManager.handleOnClick((clickedFeature) => {
            if (clickedFeature) {
                // Deselect the previously selected feature (if any)
                if (currentlySelectedFeatureIdRef.current) {
                    featureManager.updateFeatureStyle(
                        currentlySelectedFeatureIdRef.current._id,
                        "default",
                    );
                }

                // Update the style of the clicked feature to "selected"
                featureManager.updateFeatureStyle(
                    clickedFeature._id,
                    "selected",
                );
                currentlySelectedFeatureIdRef.current = clickedFeature;
                clickedOnFeature(clickedFeature);
            } else {
                // Deselect the currently selected feature (if any)
                if (currentlySelectedFeatureIdRef.current) {
                    featureManager.updateFeatureStyle(
                        currentlySelectedFeatureIdRef.current._id,
                        "default",
                    );
                    clickedOnMap(currentlySelectedFeatureIdRef.current);
                    currentlySelectedFeatureIdRef.current = null;
                }
            }
        });

        // Cleanup: Remove the click event listener
        return (): void => featureManager.removeOnClick();
    }, [featureManager, map, clickedOnFeature, clickedOnMap]);
}
