// import SelectField from "components/SelectField/SelectField";
import { memo } from "react";
import CurrentDeviceCardContentFunctionItem from "./CurrentDeviceCardContentFunctionItem/CurrentDeviceCardContentFunctionItem";
import CurrentDeviceCardContentFunctionProps from "./CurrentDeviceCardContentFunctionProps.props";

export default memo(function CurrentDeviceCardContentFunction({
    device,
    executionFunctions,
    intervalRef,
    isIntervalPaused,
    pauseInterval,
    toggleInterval,
    stopInterval,
    startInterval,
}: CurrentDeviceCardContentFunctionProps): JSX.Element | null {
    // const [activeExecutionFunction, setActiveExecutionFunction] = useState<
    //     string | null
    // >(null);

    /**
     * TODO:
     * This is a would-be hack for displaying potentially multiple
     * Execution functions under the 'More' dropdown (we say Menu) in the current
     * iteration of the wireframe.
     *
     * The current typing is an object that must be mapped to match the MenuItem type.
     */
    // const convertExecutionFunctionsToArr = useCallback(
    //     (
    //         executionFunctions: DeviceDisplayExecutionFunctions
    //     ): MenuItem<DeviceDisplayExecutionFunction>[] => {
    //         return executionFunctions.map(
    //             ({ type, id, note, executionReferenceId, properties }) => {
    //                 return {
    //                     text: note,
    //                     data: {
    //                         type: type,
    //                         id: id,
    //                         note: note,
    //                         executionReferenceId: executionReferenceId,
    //                         properties: properties,
    //                     },
    //                 };
    //             }
    //         );
    //     },
    //     [executionFunctions]
    // );

    /**
     * There can multiple executionFunctions for each device. We want to
     * loop through each one and show them appropriately.
     */
    return (
        <div className="flex flex-1 flex-col w-10 justify-between content-end">
            {executionFunctions.map(
                ({
                    type,
                    notes,
                    executionReferenceId,
                    properties,
                    id,
                }): JSX.Element => (
                    <CurrentDeviceCardContentFunctionItem
                        key={id}
                        deviceID={device._id}
                        id={id}
                        type={type}
                        notes={notes}
                        metric={device.metrics[executionReferenceId]}
                        executionReferenceId={executionReferenceId}
                        properties={properties}
                        intervalRef={intervalRef}
                        isIntervalPaused={isIntervalPaused}
                        pauseInterval={pauseInterval}
                        startInterval={startInterval}
                        toggleInterval={toggleInterval}
                        stopInterval={stopInterval}
                    />
                ),
            )}
            {/* <SelectField
                className="font-nunito"
                title={"More"}
                list={convertExecutionFunctionsToArr(executionFunctions)}
                onChange={setActiveExecutionFunction}
            /> */}
        </div>
    );
});
