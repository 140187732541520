import { memo } from "react";
import extendClassName from "utils/extendClassName";
import HOURS from "./utilities/HOURS.data";
import ZoneScheduleCalendarTableTimeData from "./ZoneScheduleCalendarTableTimeData/ZoneScheduleCalendarTableTimeData";

export default memo(function ZoneScheduleCalendarTable(): JSX.Element {
    return (
        <table className="flex flex-1 flex-col border-collapse border-r-1 border-obsidian relative table-fixed w-full">
            <thead></thead>
            <tbody>
                <tr
                    key="header"
                    className="sticky top-0"
                    style={{ zIndex: 200 }}
                >
                    <td key="filler" className="w-6 h-4"></td>

                    {["Occupancy", "Recommended Temperature"].map((text, i) => (
                        <td
                            key={text}
                            className={extendClassName(
                                "w-70 bg-white p-0 sticky",
                                i ? "right-0" : "left-0",
                            )}
                        >
                            <div
                                className={extendClassName(
                                    "border-1 border-obsidian h-4 text-center flex justify-center items-center",
                                    i ? "border-l-0" : "border-r-1",
                                )}
                            >
                                <span>{text}</span>
                            </div>
                        </td>
                    ))}
                </tr>
                {HOURS.map(({ time, id }) => (
                    <tr key={id}>
                        <ZoneScheduleCalendarTableTimeData time={time} />
                        <td className="w-93 border-t-1 border-l-1 border-gray-700"></td>
                        <td className="w-93 border-t-1 border-l-1 border-gray-700"></td>
                    </tr>
                ))}
            </tbody>
        </table>
    );
});
