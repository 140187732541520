import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import { Feature } from "ol";
import { Polygon } from "ol/geom";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import { transformPolygonPointsToCoordinates } from "../Zones/utilities/transformPolygonPointToCoordinate";
import { getSublocationOverlayStyle } from "./getSublocationOverlayStyle";

/**
 * Create an OpenLayers feature for a given sublocation overlay.
 *
 * This function first transform the position of the overlay to fit within the conventions
 * of the OpenLayers map and then creates a feature
 * object is attached as properties to the feature, allowing for easier styling and access
 * later
 * @param {LocationMapOverlayDetail} sublocationOverlay - The overlay object containing the coordinates and other important information
 * @returns {Feature} - The resulting OpenLayers feature with the correct coordinates and the overlay's properties attached.
 */
export const createSublocationOverlayFeature = (
    sublocationOverlay: LocationMapOverlayDetail,
    map: MapDisplayDetails,
    state: FeatureInteractiveState,
): Feature => {
    const feature = new Feature({
        geometry: new Polygon(
            transformPolygonPointsToCoordinates(sublocationOverlay.points, map),
        ),
    });

    //Specifically set the zone id as the feature id for easier retrival
    feature.setId(sublocationOverlay.sub_location_id);
    // feature.setGeometryName(zone.name);

    // Attach the entire device object to the feature for easier styling/access
    feature.setProperties(sublocationOverlay);

    //Set custom featureType for ease of access
    feature.set("featureType", "SublocationOverlay");
    feature.set("layerName", "SublocationOverlay");

    const style = getSublocationOverlayStyle({
        feature,
        status: HEALTH_STATUS.SAFE,
        state,
    });
    feature.setStyle(style);

    return feature;
};
