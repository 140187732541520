import useDynamicTableHeight from "hooks/useDynamicListHeight";
import useNotificationSort from "hooks/useNotificationSort";
import { useSortableTable } from "hooks/useSortTable/useSortableTable";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo } from "react";
import rootStore from "stores/rootStore";
import EmptyAlertView from "./EmptyAlertView";
import NotificationBody from "./NotificationBody/NotificationBody";
import NotificationTableHeader from "./NotificationTableHeader/NotificationTableHeader";
import NOTIFICATION_SORT_OPTIONS from "./NotificationTableHeader/util/NOTIFICATION_SORT_OPTION.enum";
import notificationSortOptionsData from "./NotificationTableHeader/util/notificationSortOptionsData.data";
import useTableReadyNotifications from "./hooks/useTableReadyNotifications";

interface NotificationContainerProps {
    orgId: string;
}

export default observer(function NotificationContainer({
    orgId,
}: NotificationContainerProps): JSX.Element {
    const notificationStore = rootStore.notificationStore;

    // This hook runs whenever the items in the store change or the number of rows changes.
    // It checks whether the number of rows required to display all the items exceeds the maximum
    // number of rows that can fit in the container, and resizes the container accordingly.
    const { containerRef, tableRef } = useDynamicTableHeight(80);

    const mappedNotifications = useTableReadyNotifications(
        notificationStore.notifications,
    );

    const loadedNotifications = useMemo(
        () =>
            mappedNotifications.loading || mappedNotifications.error
                ? []
                : mappedNotifications.notifications,
        [
            mappedNotifications.loading,
            mappedNotifications.error,
            mappedNotifications.notifications,
        ],
    );

    const { sorted, setSortOption, reverse, setReverse } =
        useNotificationSort(loadedNotifications);

    // Call useSortableTable and pass in the necessary props
    const {
        columnHeader,
        handleSetSort,
        arrowType,
        isColumnSelected,
        handleHover,
    } = useSortableTable({
        options: notificationSortOptionsData,
        initialSort: {
            title: "Time",
            type: NOTIFICATION_SORT_OPTIONS.TIME,
            ascending: false,
        },
        setSortOption: (
            type: NOTIFICATION_SORT_OPTIONS,
            ascending: boolean,
        ) => {
            setReverse(ascending);
            setSortOption(type);
        },
        reverse,
    });

    useEffect(() => {
        if (!orgId) notificationStore.clear();
    }, [orgId]);

    if ((sorted.length === 0 && !mappedNotifications.loading) || !orgId) {
        return <EmptyAlertView />;
    }

    return (
        <div
            className="overflow-y-scroll relative mx-14 my-6 md:mb-6 border-1 border-gray-700"
            ref={containerRef}
        >
            <table
                className="table-auto border-collapse w-full min-w-[600px]"
                ref={tableRef}
            >
                <NotificationTableHeader
                    options={notificationSortOptionsData}
                    columnHeader={columnHeader}
                    handleSetSort={handleSetSort}
                    arrowType={arrowType}
                    isColumnSelected={isColumnSelected}
                    handleHover={handleHover}
                />

                <NotificationBody
                    loading={mappedNotifications.loading}
                    error={mappedNotifications.error}
                    sortedNotifications={sorted}
                />
            </table>
        </div>
    );
});
