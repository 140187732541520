import Button from "components/Button/Button";
import Icon from "components/Icons/Icon/Icon";
import dayjs from "dayjs";
import { memo, useCallback, useContext, useMemo } from "react";
import isToday from "../../CurrentZoneDrawerContent/ZoneSchedule/utilities/isToday.func";
import ZoneCurrentDayContext from "../utilities/ZoneCurrentDayContext";

export default memo(function ZoneScheduleCalendarControls(): JSX.Element {
    const { setCurrentDay, currentDay } = useContext(ZoneCurrentDayContext);

    const formattedCurrentDay = useMemo(
        () => dayjs(currentDay).format("MMMM D, YYYY"),
        [currentDay],
    );

    const previous = useCallback((): void => {
        setCurrentDay(dayjs(currentDay).subtract(1, "day").toDate());
    }, [currentDay, setCurrentDay]);

    const next = useCallback((): void => {
        setCurrentDay(dayjs(currentDay).add(1, "day").toDate());
    }, [currentDay, setCurrentDay]);

    const today = useMemo(() => new Date(Date.now()), []);

    const isTodayMinusSeven = useMemo(() => {
        return (
            dayjs(currentDay).format("DD/MM/YYYY") ===
            dayjs(today).subtract(7, "day").format("DD/MM/YYYY")
        );
    }, [currentDay, today]);

    return (
        <div className="w-75 px-16 flex justify-center items-center ml-auto">
            <Button
                variant="outlined"
                onClick={(): void => setCurrentDay(today)}
            >
                Today
            </Button>

            <div className="flex justify-between mx-16 w-8.5">
                <button
                    className={`${isTodayMinusSeven ? "hidden" : ""}`}
                    onClick={previous}
                >
                    <Icon name="angle-left" size={"md"} />
                </button>

                <button
                    className={`${isToday(currentDay) ? "hidden" : ""}`}
                    onClick={next}
                >
                    <Icon name="angle-right" size={"md"} />
                </button>
            </div>

            <b>{formattedCurrentDay}</b>
        </div>
    );
});
