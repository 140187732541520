import { AxiosError, AxiosResponse } from "axios";
import { Buffer } from "buffer";
import rootStore from "stores/rootStore";
import DeviceNotification from "types/Notification.type";
import BaseService from "./BaseService";
import KibanaService from "./KibanaService";

/**
 * api service for organization centric calls
 */
class OrganizationService {
    /**
     * get a sublocations map as a src string
     *
     * @async
     * @param orgId - the organization id
     * @param locId - the location id
     * @param sublId - the sublocation id
     * @param name - the current map key, the key of sublocation.maps[KEY HERE];
     * @return the map content string, can be used directly like: image.src = mapContentString
     */
    public async getSublocationMap(
        orgId: string,
        locId: string,
        sublId: string,
        name: string,
        signal?: AbortSignal,
    ): Promise<string> {
        try {
            const { data } = await BaseService.request<string>(
                `/organizations/maps?orgId=${orgId}&locId=${locId}&sublId=${sublId}&name=${name}`,
                { signal },
                {
                    responseType: "arraybuffer",
                },
            );

            return `data:image/jpeg;base64,${Buffer.from(
                data,
                "binary",
            ).toString("base64")}`;
        } catch (error) {
            if ((error as AxiosError).code !== "ERR_CANCELED")
                rootStore.snackbarStore.enqueueSnackbar?.(
                    `Failed to load map for sublocation ${sublId}.`,
                    {
                        variant: "error",
                    },
                );
            throw error;
        }
    }

    /**
     * get a locations map as a src string
     *
     * @async
     * @param orgId - the organization id
     * @param locId - the location id
     * @param name - the current map key, the key of location.maps[KEY HERE];
     * @return the map content string, can be used directly like: image.src = mapContentString;
     */
    public async getLocationMap(
        orgId: string,
        locId: string,
        name: string,
        signal?: AbortSignal,
    ): Promise<string> {
        try {
            const { data } = await BaseService.request<string>(
                `/organizations/location-maps?orgId=${orgId}&locId=${locId}&name=${name}`,
                { signal },
                {
                    responseType: "arraybuffer",
                },
            );

            return `data:image/jpeg;base64,${await Buffer.from(
                data,
                "binary",
            ).toString("base64")}`;
        } catch (error) {
            if ((error as AxiosError).code !== "ERR_CANCELED")
                rootStore.snackbarStore.enqueueSnackbar?.(
                    `Failed to load map for location ${locId}.`,
                    {
                        variant: "error",
                    },
                );
            throw error;
        }
    }

    public async getNotifications(
        orgID: string,
    ): Promise<DeviceNotification[]> {
        try {
            const { data } = await BaseService.request<DeviceNotification[]>(
                `/organizations/${orgID}/notifications`,
            );
            return Array.isArray(data) ? data : [];
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to load notifications.`,
                {
                    variant: "error",
                },
            );
            return [];
        }
    }

    /**
     * Called when rendering an iframe that requires auth. Contains the cookie
     * that will be set as a header for the iframe
     * @returns
     */
    public async getAnalyticsProxy(): Promise<AxiosResponse> {
        try {
            const res =
                await KibanaService.request<AxiosResponse>(`/app/kibana`);
            return res;
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Unable to retrieve proper information, cannot complete call to analytic link.`,
                {
                    variant: "error",
                },
            );
            console.error(
                "Error occurred while retrieving data from Kibana service.",
                e,
            );
            throw e;
        }
    }
}

export default new OrganizationService();
