import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import useSort from "./useSort";
import UseSort from "./UseSort.interface";
import ZoneFleetSortOptions from "./ZoneFleetSortOptions.data";

export default function useZoneFleetSort(
    zones: ZoneWithOverallHealth[],
): UseSort<ZoneWithOverallHealth> {
    return useSort(zones, ZoneFleetSortOptions);
}
