import Icon from "components/Icons/Icon/Icon";
import CurrentTab from "components/TabListItem/CurrentTab.type";
import TabListItem from "components/TabListItem/TabListItem";
import useQuery from "hooks/useQuery/useQuery";
import { memo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import CURRENT_ZONE_SIMPLE_TAB from "../utilities/CURRENT_ZONE_SIMPLE_TAB.enum";
import ZONE_SIMPLE_TAB_VALUES from "../utilities/ZONE_SIMPLE_TAB.values";
import CurrentZoneDrawerTabsProps from "./utilities/CurrentZoneDrawerTabs.props";

export default memo(function CurrentZoneDrawerTabs({
    currentTab,
    setCurrentTab,
}: CurrentZoneDrawerTabsProps): JSX.Element {
    const { setParams } = useQuery();

    return (
        <div className={CLASS_NAMES.drawer.tabsContainer}>
            <button
                className="w-1/3 p-1 hover:bg-gray-200"
                onClick={(): void =>
                    setParams({
                        currentZone: null,
                        currentDrawerTab: null,
                    })
                }
            >
                <Icon name="xmark" size={"sm"} fill="#141e26" />{" "}
            </button>
            <ul className={CLASS_NAMES.iconTabs.container}>
                {ZONE_SIMPLE_TAB_VALUES.map(
                    ({
                        name,
                        value,
                        icon,
                    }: CurrentTab<CURRENT_ZONE_SIMPLE_TAB>): JSX.Element => (
                        <TabListItem<CURRENT_ZONE_SIMPLE_TAB>
                            key={name}
                            currentTab={currentTab}
                            setCurrentTab={setCurrentTab}
                            icon={icon}
                            value={value}
                        />
                    ),
                )}
            </ul>
        </div>
    );
});
