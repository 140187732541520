import { memo } from "react";
interface TableBodyWithSingleRowProps {
    message: string;
}
export default memo(function TableBodyWithSingleRow({
    message,
}: TableBodyWithSingleRowProps) {
    return (
        <tbody>
            <tr className="text-center w-full h-full">
                <td className="w-full p-8" colSpan={100}>
                    {message}
                </td>
            </tr>
        </tbody>
    );
});
