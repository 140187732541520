import { animated, easings, useSpring } from "@react-spring/web";
import useClickOutside from "hooks/useClickOutside";
import { useResizeObserver } from "hooks/useResizeObserver";
import { observer } from "mobx-react-lite";
import { useMemo, useRef, useState } from "react";
import extendClassName from "utils/extendClassName";
import LeftDrawerContent from "./LeftDrawerContent/LeftDrawerContent";
import LeftDrawerTabs from "./LeftDrawerTabs/LeftDrawerTabs";
import type LEFT_DRAWER_TAB from "./LeftDrawerTabs/utilities/LEFT_DRAWER_TAB.enum";
import LeftNavDrawerProps from "./utilities/LeftNavDrawerProps";

export default observer(function LeftNavDrawer({
    organizationStore,
}: LeftNavDrawerProps): JSX.Element {
    const [currentTab, setCurrentTab] = useState<LEFT_DRAWER_TAB | null>(null);
    // Drawer open state -- changing this toggles the open/close animation.
    // The drawer can be set closed by either clicking outside the drawer,
    // or by clicking a drawer item
    const [isDrawerOpen, setDrawerOpen] = useState(false);
    // Second bool to distinguish inside clicks from outside clicks. Inside
    // clicks will close with a 500ms delay, outside clicks will close immediately.
    const [drawerItemClicked, setDrawerItemClicked] = useState(false);
    const drawerRef = useRef(null);

    const { setRef } = useClickOutside(
        () => {
            setDrawerOpen(false);
            setCurrentTab(null);
        },
        () => null,
        "has-ignoreClass-left-drawer",
        isDrawerOpen,
    );

    const tabsRef = useRef(null);

    const { width: drawerWidth } = useResizeObserver(drawerRef);
    const { width: tabsWidth } = useResizeObserver(tabsRef);

    const marginLeft = useMemo(() => `ml-[${tabsWidth}px]`, [tabsWidth]);

    // Calculate delay based on whether a sublocation is selected
    const closeDelay = useMemo(() => {
        const delay = drawerItemClicked ? 500 : 0;
        setDrawerItemClicked(false);
        return delay;
    }, [isDrawerOpen]);

    // Animation for the drawer. Initially offscreen (-1000) to prevent it from appearing open.
    // Once dimensions are known, 'left' is set based on 'isDrawerOpen' state.
    const props = useSpring({
        left:
            drawerWidth && tabsWidth
                ? isDrawerOpen
                    ? tabsWidth
                    : -1 * (drawerWidth + tabsWidth)
                : -1000,
        delay: closeDelay,
        config: { duration: 200, easing: easings.easeOutQuad },
    });

    return (
        <>
            <div
                ref={setRef}
                className="flex flex-col border-r-1 border-grey-700 h-full"
            >
                <div
                    ref={tabsRef}
                    className={extendClassName(
                        "flex flex-row h-full justify-center z-[92] bg-gray-100 min-w-[4em] lg:w-[92px]",
                    )}
                >
                    <LeftDrawerTabs
                        organizationStore={organizationStore}
                        currentTab={currentTab}
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        setCurrentTab={setCurrentTab}
                    />
                </div>
                <animated.div
                    ref={drawerRef}
                    style={props}
                    //Height should be full height of remaining container minus the top-offset
                    className="flex-1 top-[4.75rem] h-[calc(100%-4.75rem)] w-auto absolute z-[91]"
                >
                    <LeftDrawerContent
                        organizationStore={organizationStore}
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                        setDrawerItemClicked={setDrawerItemClicked}
                    />
                </animated.div>
            </div>
            {isDrawerOpen ? (
                <div
                    className={
                        `bg-transparent w-full h-full absolute z-10 ` +
                        marginLeft
                    }
                ></div>
            ) : null}
        </>
    );
});
