import Button from "components/Button/Button";
import { memo } from "react";
import CurrentDeviceCardContentEditControllerProps from "./utilities/CurrentDeviceCardContentEditController.props";

export default memo(function CurrentDeviceCardContentEditController({
    error,
    editing,
    setEditing,
    saving,
    saveable,
    save,
    onCancel,
    disabled,
    text: { cancel, saveText, edit },
}: CurrentDeviceCardContentEditControllerProps): JSX.Element {
    return (
        <div className="w-full mt-auto py-2 flex flex-col justify-center items-center border-t-1 border-obsidian">
            {error ? (
                <p className="text-orange text-center mb-4">{error}</p>
            ) : null}

            {!editing ? (
                <Button
                    key="edit-thresholds"
                    variant={"contained"}
                    onClick={(): void => setEditing(true)}
                    disabled={disabled}
                >
                    {edit}
                </Button>
            ) : (
                <div className="flex flex-1 justify-center items-center gap-4">
                    <Button
                        variant={"text"}
                        className="has-ignoreClass-device-drawer has-ignoreClass-zone-drawer"
                        onClick={(): void => onCancel()}
                        disabled={saving}
                    >
                        {cancel}
                    </Button>
                    <Button
                        variant={"contained"}
                        disabled={saving || !saveable}
                        onClick={(): void => save()}
                    >
                        {saveText}
                    </Button>
                </div>
            )}
        </div>
    );
});
