import Icon from "components/Icons/Icon/Icon";
import { memo } from "react";

type DisplayAngleIconProps = {
    isOpen: boolean;
    openIcon?: string;
    closedIcon?: string;
    className?: string;
};

/**
 * Based on the state passed, will return an angle icon state.
 * Typically if something is considerd closed, we display the right angle.
 * If it is open, the down angle will be shown.
 * If no icons are provided for the open and close state, the typical chevron will be used.
 */
export default memo(function DisplayAngleIcon({
    isOpen,
    openIcon = "chevron-down",
    closedIcon = "chevron-right",
    className,
}: DisplayAngleIconProps): JSX.Element {
    return (
        <Icon
            name={isOpen ? openIcon : closedIcon}
            width={16}
            height={16}
            className={className || "" + " pointer-events-none"}
        />
    );
});
