enum UpdateURLQuery {
    ORGID = "orgId",
    LOCID = "locId",
    SUBLID = "sublId",
    CURRENT_DEVICE = "currentDevice",
    CURRENT_ZONE = "currentZone",
    CURRENT_DRAWER_TAB = "currentDrawerTab",
    FILTER = "filter",
}

export default UpdateURLQuery;

export const UpdateURLQueryValues = Object.freeze(
    Object.values(UpdateURLQuery),
);
