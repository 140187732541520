import type Device from "@srtlabs/m1_types/lib/Device/Device.type";
import type DeviceDisplayDrawerItem from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerItem/DeviceDisplayDrawerItem.type";
import CurrentDeviceCardContentStatusDrawerItem from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CurrentDeviceCardContentStatusDrawerItem";
import useDevices from "hooks/useDevices.hook";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import { v4 } from "uuid";
import type ZoneStatusProps from "./utilities/ZoneStatus.props";
import ZONE_DRAWER_ANIMATION_SPEED from "components/ZoneDrawerContainer/utilities/ZONE_DRAWER_ANIMATION_SPEED";
import DelayedRender from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneSimple/CurrentZoneDrawerContent/ZoneStatus/utilities/DelayedRender";

export default observer(function ZoneStatus({
    zone,
}: ZoneStatusProps): JSX.Element {
    const deviceIds = useMemo(
        () => zone.display.drawerContent.map(({ device }) => device),
        [zone],
    );

    const { data: devices, pendingFetchData } = useDevices(deviceIds);

    const drawers = useMemo(
        () =>
            pendingFetchData
                ? []
                : (zone.display.drawerContent
                      .map(({ device: deviceID, drawerItemID }) => {
                          const device: Device | null =
                              devices.get(deviceID) || null;
                          if (device) {
                              const drawer = device.display.drawerContent.find(
                                  ({ drawerItemID: id }) => id == drawerItemID,
                              );

                              if (drawer && !drawer.hiddenFromDashboard) {
                                  return {
                                      drawer,
                                      device,
                                  };
                              }
                          }

                          return null;
                      })
                      .filter((item) => item != null) as {
                      drawer: DeviceDisplayDrawerItem;
                      device: Device;
                  }[]),
        [zone, pendingFetchData, devices],
    );

    // Count how many items have been rendered before the animation completes
    let renderedItems = 0;
    // Render only this many items until drawer animation completes, then render the rest
    const INITIAL_RENDERED_ITEMS = 10;

    return (
        <>
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>
                    Zone Status
                </h2>
            </div>

            <ul className="flex flex-1 flex-col">
                {drawers.length == 0 ? (
                    <li className="w-93 my-6 py-4 px-12 justify-center items-center text-center">
                        <h1>No devices added</h1>
                    </li>
                ) : (
                    drawers.map(({ drawer, device }) => {
                        const keyUUID = v4();
                        return (
                            // When loading a drawer with many items, the animation can
                            // stutter. We want to render only the first few items until
                            // the animation completes, then render the rest.
                            <DelayedRender
                                key={drawer.drawerItemID + keyUUID + "-delayed"}
                                renderDelay={
                                    renderedItems++ < INITIAL_RENDERED_ITEMS
                                        ? 0
                                        : ZONE_DRAWER_ANIMATION_SPEED + 10
                                }
                                // Approx height of drawer item (so scroll bar doesn't jump)
                                skeletonStyle={"h-[138px]"}
                            >
                                <CurrentDeviceCardContentStatusDrawerItem
                                    key={drawer.drawerItemID + keyUUID}
                                    device={device}
                                    drawer={drawer}
                                />
                            </DelayedRender>
                        );
                    })
                )}
            </ul>
        </>
    );
});
