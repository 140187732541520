import { memo } from "react";

export default memo(function Admin(): JSX.Element {
    // put automation here
    return (
        <div>
            {[0, 1].map(
                (num): JSX.Element => (
                    <h1> Admin {num} </h1>
                ),
            )}
        </div>
    );
});
