import MultiRangeUnitConversion from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MultiRangeUnitConversion.type";
import convertUnits from "utils/unitConversions/unitConversions";

/**
 * Convert all values of a multiRangeUnitConversion object from base units into target units
 * @param dimension MultiRangeUnitConversion dimension with values in base units
 * @param targetUnit Unit to convert multiRange values into
 * @returns A MultiRangeUnitConversion dimension with all values converted to target units
 */
export default function convertMultiRangeUnitConversionDimension(
    dimension: MultiRangeUnitConversion,
    targetUnit: string,
): MultiRangeUnitConversion {
    return {
        ...dimension,
        operatingRange: dimension.operatingRange.map((val) =>
            convertUnits(val, dimension.baseUnits, targetUnit),
        ),
        safe: dimension.safe.map((arr) =>
            arr.map((val) =>
                convertUnits(val, dimension.baseUnits, targetUnit),
            ),
        ),
        unsafe: dimension.unsafe.map((arr) =>
            arr.map((val) =>
                convertUnits(val, dimension.baseUnits, targetUnit),
            ),
        ),
        warning: dimension.warning.map((arr) =>
            arr.map((val) =>
                convertUnits(val, dimension.baseUnits, targetUnit),
            ),
        ),
        baseUnits: targetUnit,
    };
}
