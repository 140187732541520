import CurrentDeviceCard from "components/CurrentDeviceCard/CurrentDeviceCard";
import ZoneDrawerContainer from "components/ZoneDrawerContainer/ZoneDrawerContainer";
import useNotifications from "hooks/useNotifications";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import rootStore from "stores/rootStore";
import AppBar from "./AppBar/AppBar";
import HomeContentContainer from "./HomeContentContainer/HomeContentContainer";
import LeftNavDrawer from "./LeftNavDrawerContainer/LeftNavDrawerContainer";
import ListViewContainer from "./ListViewContainer/ListViewContainer";

export default observer(function Home(): JSX.Element {
    //Logic to periodically refetch org wide notifications to be if orgId available
    const { orgId } = useQuery();
    const notificationStore = rootStore.notificationStore;
    const notifications = useNotifications(orgId);

    //Updates the notification store whenever a refetch is made,
    //setting polled notifications only
    //there is a different observable for websocket stores
    useEffect(() => {
        notificationStore.setPolledNotifications(notifications ?? []);
    }, [notifications, notificationStore]);

    return (
        <div className="w-full h-full bg-white dark text-obsidian overflow-y-hidden flex flex-col flex-1">
            <AppBar
                userName={rootStore.userStore.name}
                notifications={rootStore.notificationStore.notifications}
                orgId={rootStore.organizationStore.orgId}
                sublId={rootStore.organizationStore.sublId}
                locId={rootStore.organizationStore.locId}
                isMobile={rootStore.viewportStore.isMobile}
            />
            <div className="flex flex-1 flex-row relative w-auto h-full overflow-hidden">
                <LeftNavDrawer
                    organizationStore={rootStore.organizationStore}
                />
                <div className="h-full flex flex-1 flex-col overflow-hidden relative">
                    <ListViewContainer
                        organizationStore={rootStore.organizationStore}
                    />
                    <ZoneDrawerContainer />
                    <CurrentDeviceCard />
                    <HomeContentContainer />
                </div>
            </div>
        </div>
    );
});
