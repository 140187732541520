const isEditableType = (type: string): boolean =>
    type === "range-float" ||
    type === "range-integer" ||
    type === "multiRangeSafeVal" ||
    type === "inAndOutOfRangeToString" ||
    type === "booleanToString" ||
    type === "linearScaledMultiRangeSafeVal" ||
    type === "minMaxSafeValImperialTemperature" ||
    type === "multiRangeUnitConversion";

export default isEditableType;
