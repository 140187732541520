import useDelayedUpdate from "hooks/useDelayedUpdate";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { Coordinate } from "ol/coordinate";
import { useCallback, useEffect, useRef, useState } from "react";
import rootStore from "stores/rootStore";
import LocationCanvasContainerProps from "types/OpenLayers/LocationCanvasContainerProps.type";
import useUpdateMapSize from "utils/OpenLayers/utilities/useUpdateMapSize";
import toggleSetParamsId from "utils/toggleSetParamsId";
import MapController from "../MapController/MapController";
import MapToolTip from "../MapToolTip/MapToolTip";
import checkIsMapIsReady from "../utilities/checkIfMapIsReady";
import featureToParamMap from "../utilities/featureToParamMap";
import { updateLocationMapFeatures } from "../utilities/useLocationMapSetup";
import useMapOnHoverListener from "../utilities/useMapHoverListener";
import useMapOnClickHandler from "../utilities/useMapOnClickHandler";
import { createLayersIfNeeded } from "../utilities/useMapSetup";
import useUpdateFeatureOnExternalTrigger from "../utilities/useUpdateFeatureOnExternalTrigger";

export default observer(function LocationMapCanvasContainer({
    imageSrc,
    dimensions,
    isImageLoading,
    setLayersInitializing,
    map,
    mapViewMode,
    data,
}: LocationCanvasContainerProps) {
    const openLayersStore = rootStore.openLayersStore;
    const mapRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [tooltip, setTooltip] = useState({
        visible: false,
        content: "",
        coordinate: [0, 0] as Coordinate,
    });

    const { locId, sublId, currentZone, currentDevice, setParams } = useQuery();

    useUpdateMapSize(openLayersStore.map);

    const delayedSetParams = useDelayedUpdate(100, setParams);

    useMapOnClickHandler({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        clickedOnFeature: (feature): void => {
            const param = featureToParamMap[feature.type];
            if (param === "sublId") {
                delayedSetParams({
                    [param]: feature._id,
                });
            } else if (param === "currentZone") {
                setParams({
                    [param]: toggleSetParamsId(feature._id, currentZone),
                    currentDevice: null,
                });
            } else if (param === "currentDevice") {
                setParams({
                    [param]: toggleSetParamsId(feature._id, currentDevice),
                    currentZone: null,
                });
            }
        },
        clickedOnMap: () => {
            //Close both drawers if clicking on the map
            setParams({
                currentDevice: null,
                currentZone: null,
                currentDrawerTab: null,
            });
        },
    });

    useMapOnHoverListener({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        mapViewMode,
        onHoverIn: ({ content, coordinate }) =>
            setTooltip({
                visible: true,
                coordinate,
                content,
            }),
        onHoverOut: () => setTooltip(tooltip),
    });

    useUpdateFeatureOnExternalTrigger({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        trigger: {
            active: !!currentZone,
            featureId: currentZone,
            newState: "selected",
        },
    });

    // Utility function to handle the setting up and initialization of the OpenLayers map
    const initializeOpenLayersMap = useCallback(() => {
        const extent = [0, 0, dimensions.width, dimensions.height];
        openLayersStore.setMapImageLayer(imageSrc, extent);
        openLayersStore.setMapDisplayDetails(map);
        openLayersStore.initializeLocationMap(mapRef.current!, extent);
    }, [dimensions, isImageLoading, mapRef, imageSrc, map]);

    const cleanUp = (): void => {
        openLayersStore.resetMap();
    };

    const renderMapState = useCallback((): JSX.Element => {
        if (openLayersStore.isLoading) {
            return <span>Initializing map...</span>;
        } else {
            return (
                <>
                    {/* Other map-related components */}
                    <MapToolTip
                        toolTipRef={tooltipRef}
                        visible={tooltip.visible}
                        coordinate={tooltip.coordinate}
                        content={tooltip.content}
                    />
                    <MapController
                        zoomIn={openLayersStore.zoomIn}
                        zoomOut={openLayersStore.zoomOut}
                        isAtMaxZoom={openLayersStore.isAtMaxZoom}
                        isAtMinZoom={openLayersStore.isAtMinZoom}
                    />
                </>
            );
        }
    }, [openLayersStore.isLoading, tooltip]);

    /**
     * Initializes the map and subsequent layer and feature managers when the trigger effect changes. This will allow us to
     * manipulate the map
     */
    useEffect(() => {
        if (!openLayersStore.map || openLayersStore.mapDisplayDetails !== map)
            initializeOpenLayersMap();
        return (): void => cleanUp();
    }, [locId, sublId]);

    /**
     * Once the map is created, intializes the layers and features for the decisive map.
     */
    useEffect(() => {
        // Show loading screen while layers/features are initializing
        setLayersInitializing(true);

        checkIsMapIsReady(data, openLayersStore.map);

        // Create layers if they don't exist
        createLayersIfNeeded(openLayersStore, mapViewMode);

        // Update features based on the mapViewMode and data
        updateLocationMapFeatures({
            openLayersStore,
            data,
            mapViewMode,
            map,
        }).then(() => {
            // Turn loading screen off when done initializing
            setLayersInitializing(false);
        });
    }, [data]);

    return (
        <div
            id="map-canvas-container"
            ref={mapRef}
            className="flex-1 relative w-full h-full has-ignoreClass-device-drawer has-ignoreClass-zone-drawer"
        >
            <div className="absolute inset-0 flex flex-1 h-full items-center justify-center">
                {renderMapState()}
            </div>
        </div>
    );
});
