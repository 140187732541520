import ZoneHVACFormProps from "./ZoneHVACFormProps.props";
import { memo, useCallback } from "react";
import ZoneFormProps from "../ZoneSettingFormProps.props";
import InputField from "components/InputField/InputField";
export default memo(function ZoneHVAC({
    errors,
    formData,
    setFormData,
}: ZoneFormProps): JSX.Element {
    const handleHvacChange = useCallback(
        (
            key: keyof ZoneHVACFormProps,
            occupiedKey: "occupied" | "unoccupied",
            value: number,
        ): void => {
            //We have to convert the ZoneHVAC typing to match RecommendedTemperature
            const newForm = { ...formData };
            newForm.hvac = { ...formData.hvac };
            newForm.hvac[key][occupiedKey] = value;
            setFormData(newForm);
        },
        [formData],
    );

    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
                <p className="pl-4 text-sm">Setpoint Temperatures (&#176;F)</p>
            </div>
            <div className="col-span-6">
                <div className="mx-2 flex flex-col">
                    {Object.entries(formData.hvac).map(
                        ([key, hvacItem]): JSX.Element => (
                            <div key={key} className="flex flex-col w-full">
                                <h3 className="capitalize my-1">{key}</h3>

                                <div className="flex flex-row w-full justify-between">
                                    {Object.entries(hvacItem).map(
                                        ([occupiedKey, value]): JSX.Element => (
                                            <InputField
                                                key={occupiedKey}
                                                label={occupiedKey}
                                                type="number"
                                                value={value as number}
                                                error={
                                                    errors.hvac[
                                                        key as keyof typeof formData.hvac
                                                    ][
                                                        occupiedKey as
                                                            | "occupied"
                                                            | "unoccupied"
                                                    ]
                                                }
                                                classes={{
                                                    label: "my-2 w-1/3",
                                                    span: "mb-4 px-0",
                                                }}
                                                onChange={({
                                                    target: { value },
                                                }): void => {
                                                    const num = Number(value);

                                                    if (!isNaN(num)) {
                                                        handleHvacChange(
                                                            key as keyof ZoneHVACFormProps,
                                                            occupiedKey as
                                                                | "occupied"
                                                                | "unoccupied",
                                                            num,
                                                        );
                                                    }
                                                }}
                                            />
                                        ),
                                    )}
                                </div>
                            </div>
                        ),
                    )}
                </div>
            </div>
        </div>
    );
});
