enum DeviceIconSize {
    XXXLARGE,
    XXLARGE,
    XLARGE,
    LARGE,
    MEDIUM,
    SMALL,
    XSMALL,
}

export default DeviceIconSize;
