import { RefObject, useEffect, useState } from "react";

/**
 * useResizeObserver hook
 *
 * This hook accepts a ref and returns the width and height of the element that the ref is assigned to.
 * It will continuously update the width and height value as the referenced element's dimensions change.
 *
 * @param {RefObject<HTMLElement | null>} ref A ref object containing an HTML element.
 * @returns {object} The width and height of the element.
 */
export const useResizeObserver = (
    ref: RefObject<HTMLElement | null>,
): { width: number; height: number } => {
    // Create state variables to hold the width and height of the element
    const [dimensions, setDimensions] = useState({ width: 0, height: 0 });

    // Use a useEffect hook to add a ResizeObserver to the element when it mounts
    useEffect(() => {
        // Get a reference to the element from the ref
        const element = ref.current;

        // If the element exists, start observing it
        if (element) {
            // Create a new ResizeObserver
            const observer = new ResizeObserver((entries) => {
                // Loop through all entries (there should be just one)
                for (const entry of entries) {
                    // Set the dimensions state variable to the observed width and height
                    setDimensions({
                        width: entry.contentRect.width,
                        height: entry.contentRect.height,
                    });
                }
            });

            // Start observing the element
            observer.observe(element);

            // When the component unmounts, stop observing the element
            return (): void => {
                observer.unobserve(element);
            };
        }
    }, []); // Empty dependency array means this runs once when the component mounts

    // Return the dimensions
    return dimensions;
};
