import SortFlip from "components/Icons/SortFlip/SortFlip";
import SORT_DIRECTION from "components/Icons/SortFlip/utilities/SORT_DIRECTION.enum";

//returns either a bold or light fontawesome arrow depending on if selected column is active
export default function selectBoldArrow(
    ascending: boolean,
    activeType: boolean,
): JSX.Element {
    return activeType ? (
        <SortFlip
            name="sort-duotone"
            direction={
                //Ascending is defined as A to Z, 0 to 9
                ascending ? SORT_DIRECTION.UP : SORT_DIRECTION.DOWN
            }
            size={"sm"}
        />
    ) : (
        <SortFlip
            name="sort-duotone"
            direction={ascending ? SORT_DIRECTION.UP : SORT_DIRECTION.DOWN}
            size={"sm"}
        />
    );
}
