import type Device from "@srtlabs/m1_types/lib/Device/Device.type";
import { memo, useEffect, useMemo, useState } from "react";
import CurrentRackShelf from "./CurrentRackShelf/CurrentRackShelf";
import RackStatusShelfContainerShelfInfo from "./RackStatusShelvesContainer/RackStatusShelfContainer/utilities/RackStatusShelfContainerShelfInfo.type";
import RackStatusShelvesContainer from "./RackStatusShelvesContainer/RackStatusShelvesContainer";
import type RackStatusProps from "./utilities/RackStatus.props";
import validIndividualDevice from "./utilities/validIndividualDevice";
import DeviceService from "services/DeviceService";
import { useQueries } from "@tanstack/react-query";
import Shelf from "types/Shelf.type";

export default memo(function RackStatus({
    device,
    drawer,
}: RackStatusProps): JSX.Element {
    const shelvesInfo = useMemo(() => {
        const devices: RackStatusShelfContainerShelfInfo[] = [];
        if (device?.individualDevices) {
            let i = 0;
            for (const key in device.individualDevices) {
                const isShelfDevice = key.startsWith("shelf-");

                if (isShelfDevice) {
                    if (validIndividualDevice(device.individualDevices[key])) {
                        devices.push({
                            id: device.individualDevices[key].deviceID,
                            valid: true,
                            key: ++i,
                        });
                    } else {
                        devices.push({
                            id: "",
                            valid: false,
                            key: ++i,
                        });
                    }
                }
            }
        }

        return devices;
    }, [device]);

    const fetchShelfData = (
        shelfId: string,
        signal?: AbortSignal,
    ): Promise<Device> => {
        return DeviceService.getDevice(shelfId, signal);
    };

    const [currentShelfID, setCurrentShelfID] = useState<string | null>(null);

    // Using useQueries to fetch data for each shelf
    const useShelfQueries = (): {
        /**
         * Data to be returned from each query
         */
        data: Shelf[];

        /**
         * Indicates if there is any remaining queries in the fetch that have yet to be completed
         */
        pendingFetchData: boolean;
    } =>
        useQueries({
            queries: shelvesInfo.map((shelf) => ({
                queryKey: ["device", shelf.id],
                queryFn: async (): Promise<Shelf> => {
                    const device = await fetchShelfData(shelf.id);

                    return {
                        id: shelf.id,
                        value: device,
                        key: shelf.key,
                        valid: shelf.valid,
                    };
                },
                enabled: shelf.valid, // only fetch if shelf is valid
                staleTime: 1000 * 60 * 5, // cache time, example 5 minutes
            })),
            combine: (results) => {
                return {
                    data: results
                        .map((result) => result.data)
                        .filter((shelf): shelf is Shelf => shelf !== undefined),
                    pendingFetchData: results.some(
                        (result) => result.isPending,
                    ),
                };
            },
        });

    const { data: shelvesData, pendingFetchData } = useShelfQueries();

    useEffect(() => {
        // if there is not a current shelf id, or if there are valid shelves, and non of them are the current shelf id
        if (
            !currentShelfID ||
            (shelvesInfo.some(({ valid }): boolean => valid) &&
                !shelvesInfo.some(({ id }): boolean => id === currentShelfID))
        ) {
            for (const shelf of shelvesInfo) {
                if (shelf.valid) {
                    setCurrentShelfID(shelf.id);
                    return;
                }
            }
        }
    }, [currentShelfID, shelvesInfo]);

    const shelves = useMemo(
        () => (pendingFetchData ? [] : Object.entries(shelvesData)),
        [shelvesData, pendingFetchData],
    );

    const currentShelf = useMemo(
        () =>
            shelvesData.find((shelf) => shelf?.id === currentShelfID)?.value ||
            null,
        [currentShelfID, shelvesData],
    );

    return (
        <div className="w-full flex flex-col mx-auto px-4">
            <div className="text-center py-4">
                <h5>{drawer?.name || "Rack Status"}</h5>
            </div>

            <div className="flex-1 flex flex-row">
                <RackStatusShelvesContainer
                    shelves={shelves}
                    currentShelfID={currentShelfID}
                    setCurrentShelfID={setCurrentShelfID}
                />

                {currentShelf ? (
                    <CurrentRackShelf shelf={currentShelf} />
                ) : (
                    <></>
                )}
            </div>
        </div>
    );
});
