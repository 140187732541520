const colors = {
    left: {
        true: "bg-calendar-left",
    },
    right: {
        false: "bg-calendar-right-unoccupied",
        true: "bg-calendar-right-occupied",
    },
};

export default colors;
