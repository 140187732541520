import ZoneEventsSchema from "@srtlabs/m1_types/lib/Cassandra/ZoneEvents/ZoneEvents.schema";
import type ZoneEvents from "@srtlabs/m1_types/lib/Cassandra/ZoneEvents/ZoneEvents.type";
import type Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import Joi, { Schema } from "joi";
import rootStore from "stores/rootStore";
import DeviceEvents from "types/DeviceLog.type";
import ZoneLog from "types/ZoneLog.type";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import findProblemsWithZone from "utils/findProblemsWithZone.util";
import BaseService from "./BaseService";

/**
 * Api service for zone centric calls
 */
class ZoneService {
    /**
     * takes a zone id and returns the zone
     */
    public async getZone(
        zoneID: string,
        signal?: AbortSignal,
    ): Promise<ZoneWithOverallHealth> {
        try {
            const { data } = await BaseService.request<Zone>(
                `/zones/${zoneID}`,
                { signal },
            );
            const updatedZone = await findProblemsWithZone(data);
            //Why did we add this?
            // const { error } = ZoneSchema.validate(data, { abortEarly: false });
            // if (error) throw error;

            return updatedZone;
        } catch (error) {
            console.error(error);
            throw new Error("Unable to retrieve zone");
        }
    }

    /**
     * updates a zone, takes the zone as the argument and makes a put call to update it, then returns the updated zone
     */
    public async put(
        zone: ZoneWithOverallHealth,
    ): Promise<ZoneWithOverallHealth> {
        try {
            //The above zone type is only maintainable on the frontend.
            //If we want to submit data we must strip this property and pass back the original zone format.
            //This is why you see an underscore here for overallHealth
            // eslint-disable-next-line @typescript-eslint/no-unused-vars
            const { overallHealth: _, ...zoneWithoutOverallHealth } = zone;
            const { data } = await BaseService.request<Zone>(
                `/zones/${zone._id}`,
                {
                    method: "put",
                    data: zoneWithoutOverallHealth,
                },
            );
            const updatedZone = await findProblemsWithZone(data);

            //Again why?
            // const { error } = ZoneSchema.validate(data, { abortEarly: false });
            // if (error) throw error;
            return updatedZone;
        } catch (e) {
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to save: ${zone.name}`,
                { variant: "error" },
            );

            throw e;
        }
    }

    /**
     * TODO: update this to use the DeviceService internally
     * gets the logs for all the devices that belong to the zone
     */
    public async getDeviceLogs(zone: Zone): Promise<ZoneLog[]> {
        return (
            await Promise.allSettled(
                zone.devices.map(async (deviceID): Promise<DeviceEvents[]> => {
                    const { data } = await BaseService.request<DeviceEvents[]>(
                        `/devices/${deviceID}/logs`,
                    );

                    // TODO: get proper types for this
                    const { error } = Joi.array().validate(data, {
                        abortEarly: false,
                    });

                    if (error) {
                        if (process.env.NODE_ENV !== "development") {
                            error.message =
                                "Logs are invalid, contact srt support";
                        }

                        throw error;
                    }

                    return data;
                }),
            )
        ).map((prom) => {
            if (prom.status === "rejected") {
                return {
                    value: [],
                    error: prom.reason.message,
                };
            } else {
                return {
                    value: prom.value,
                    error: "",
                };
            }
        });
    }

    /**
     * gets all the zone events that belong to the zone, along with a limit argument,
     * we default to 300 max events
     */
    public async getZoneEvents(zone: Zone, limit = 300): Promise<ZoneEvents[]> {
        const { data } = await BaseService.request<ZoneEvents[]>(
            `/zones/${zone._id}/events?limit=${limit}`,
        );

        const { error } = Joi.array()
            .items(ZoneEventsSchema as unknown as Schema)
            .validate(data, { abortEarly: false });

        if (error) throw error;

        return data;
    }
}

export default new ZoneService();
