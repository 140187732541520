import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import ExecutionFunctionContainer from "components/ExecutionFunctionContainer/ExecutionFunctionContainer";
import useZones from "hooks/useZones.hook";
import { memo, useMemo } from "react";
import executablesStore from "stores/executablesStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import ZoneReferenceContainer from "./ZoneReferenceContainer/ZoneReferenceContainer";
import type CurrentDeviceCardHeaderProps from "./utilities/CurrentDeviceCardHeader.props";
interface ZoneReference {
    id: string;
    name: string;
}

export default memo(function CurrentDeviceCardHeader({
    device,
}: CurrentDeviceCardHeaderProps): JSX.Element {
    /**
     * Matches the device's zone references to those in the
     * ZoneStore and returns a list of the id and name for each
     */

    const { data, pendingFetchData } = useZones(device.references.zoneIDs);

    const zoneReferences = useMemo((): ZoneReference[] => {
        if (data && !pendingFetchData) {
            return Array.from(data.values())
                .map((zone) => {
                    return { id: zone._id, name: zone.name };
                })
                .filter(({ id }) => device.references.zoneIDs.includes(id));
        } else {
            return [];
        }
    }, [data, pendingFetchData]);

    const hasZoneReference = useMemo(
        () => zoneReferences.length > 0,
        [zoneReferences],
    );

    return (
        <header className="flex flex-row items-start w-full bg-white border-b-1 border-t-0 border-grey-700 h-min py-4 relative">
            <div className="ml-[1em] flex-shrink-0">
                <DeviceIcon
                    headers={device.headers}
                    overallHealth={device.deviceHealth.overAllHealth}
                    offline={device.state.offline}
                    image={device.display.image}
                />
            </div>
            <div className="flex flex-col ml-[1em] min-w-0 flex-1">
                <h1
                    className={`${CLASS_NAMES.drawer.drawerHeaderTitle} truncate`}
                >
                    {device.headers.deviceName}{" "}
                    {hasZoneReference &&
                    device.headers.generalPosition.length > 0 ? (
                        <> | {device.headers.generalPosition}</>
                    ) : (
                        ""
                    )}
                </h1>

                <div className="flex flex-row items-start font-sans min-w-0">
                    {device.headers.dataType ? (
                        <p className="flex-shrink-0">
                            {device.headers.dataType}{" "}
                        </p>
                    ) : null}
                    {(hasZoneReference && device.headers.dataType) ||
                    (device.headers.generalPosition &&
                        device.headers.dataType) ? (
                        <span
                            className={`${CLASS_NAMES.verticalSeperator} flex-shrink-0`}
                        >
                            |
                        </span>
                    ) : null}
                    <div className="flex-1 min-w-0">
                        {hasZoneReference ? (
                            <ZoneReferenceContainer
                                zoneReferences={zoneReferences}
                            />
                        ) : (
                            <p className="truncate">
                                {device.headers.generalPosition}
                            </p>
                        )}
                    </div>
                </div>
            </div>
            {device.type === "mobileDevice" ? (
                <div className="flex-shrink-0">
                    <ExecutionFunctionContainer
                        device={device}
                        executablesStore={executablesStore}
                    />
                </div>
            ) : null}
        </header>
    );
});
