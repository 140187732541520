import CurrentTab from "components/TabListItem/CurrentTab.type";
import CURRENT_ZONE_TAB from "./CURRENT_ZONE_HVAC_TAB.enum";

const ZONE_HVAC_TAB_VALUES: Readonly<CurrentTab<CURRENT_ZONE_TAB>[]> =
    Object.freeze([
        {
            icon: "gauge-low",
            name: "Status",
            value: CURRENT_ZONE_TAB.STATUS,
        },
        {
            name: "Info",
            value: CURRENT_ZONE_TAB.INFO,
            icon: "circle-info",
        },
        {
            icon: "calendar",
            name: "Schedule",
            value: CURRENT_ZONE_TAB.SCHEDULE,
        },
        {
            icon: "clock-rotate-left",
            name: "History",
            value: CURRENT_ZONE_TAB.EVENTS,
        },
        {
            icon: "bell",
            name: "Alerts",
            value: CURRENT_ZONE_TAB.ALERTS,
        },
        {
            icon: "gear",
            name: "Settings",
            value: CURRENT_ZONE_TAB.SETTINGS,
        },
    ]);

export default ZONE_HVAC_TAB_VALUES;
