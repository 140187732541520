import { autorun } from "mobx";
import BaseService from "services/BaseService";
import UserService from "services/UserService";
import RootStore from "./classes/RootStore.class";
import rootStore from "./rootStore";

// import { configure } from "mobx";

// configure({
//     enforceActions: "never",
// });

const isMaintenanceMode = process.env.REACT_APP_MAINTENANCE_MODE === "true";

/**
 * Automatically manages reactive store values and state, (mobx stores)
 * use by running AutoRunManager.run()
 */
class AutoRunManager {
    private _running = false;
    private rootStore: RootStore;

    /**
     * holds all the mobx autorun cleanup callbacks,
     * to remove all the current autoruns,
     * use this._cleanup()
     */
    private _cleanupFunctions: (() => void)[] = [];

    /**
     * Creates the mobx autorun functions
     * when first ran sets this._running to be true, and if it was already true, returns, as there
     * (because should not be multiple autoruns doing the same thing running at the same time)
     *
     * stores all the autoruns in this._cleanupFunctions
     * @see AutoRunManager._cleanupFunctions
     */
    public run(): void {
        if (this._running || isMaintenanceMode) return;
        this._running = true;

        this._cleanupFunctions = [
            /**
             * when the apiStore gets a new jwt token added to it, update the BaseService token
             * and if the token is truthy, attempt to get the user and set the user
             * if we fail, uses the snackbarStore to give a onscreen notification that we failed,
             * and logout after a moment
             */
            autorun((): void => {
                BaseService.setToken(this.rootStore.apiStore.token);

                if (this.rootStore.apiStore.token) {
                    UserService.getMe()
                        .then((user) => {
                            this.rootStore.userStore.setUser(user);
                        })
                        .catch((e) => {
                            console.error(e);
                            // give user time to read the message that we failed to grab their user info
                            this.rootStore.snackbarStore.enqueueSnackbar?.(
                                "Failed to retrieve user data. Logging out",
                                {
                                    variant: "error",
                                },
                            );
                            setTimeout((): void => {
                                this.rootStore.userStore.logout();
                            }, 2000);
                        });
                    //Something forces a reload of the app, so we must reinitialie the reaction
                    this.rootStore.initializeAllReactions();
                }
            }),

            /**
             * whenever the rootStore.userStore gets it access updated, use it as an argument for  organizationStore._setAccess
             *
             * @see OrganizationStore._setAccess
             * @see UserStore
             */
            autorun((): void => {
                this.rootStore.organizationStore._setAccess(
                    this.rootStore.userStore.access,
                );
            }),
        ];
    }

    /**
     * cleans up all the mobx autorun functions
     * once done, sets this._running to false, as we are no longer running any reactions
     */
    public cleanup(): void {
        this._cleanupFunctions.forEach((callback): void => {
            callback();
        });
        this._running = false;
    }

    constructor(rootStore: RootStore) {
        this.rootStore = rootStore;
    }
}

export default new AutoRunManager(rootStore);
