import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import Text from "components/Text/Text";
import { observer } from "mobx-react-lite";
import { useMemo, useState } from "react";
import formatDate from "utils/formatDate";
import convertUnits, { round } from "utils/unitConversions/unitConversions";
import MultiRangeUnitConversionHeader from "./MultiRangeUnitConversionHeader/MultiRangeUnitConversionHeader";
import MultiRangeUnitConversionMetric from "./MultiRangeUnitConversionMetric/MultiRangeUnitConversionMetric";
import MultiRangeUnitConversionMetricContainerProps from "./utilities/MultiRangeUnitConversionMetricContainer.props";
import convertMultiRangeUnitConversionDimension from "./utilities/convertMultiRangeUnitConversionDimension";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";

export default observer(
    function CardContentStatusDrawerItemGenericMetricContainer({
        device,
        drawer,
        metric,
    }: MultiRangeUnitConversionMetricContainerProps): JSX.Element {
        const [unit, setUnit] = useState(drawer.unit);

        const dimension =
            device.state.dimensions[drawer.referenceID][
                "multiRangeUnitConversion"
            ]!.multiRangeUnitConversion;

        const convertedMetricValue = useMemo(() => {
            return round(
                convertUnits(Number(metric.value), dimension.baseUnits, unit),
                1,
            );
        }, [unit, device, drawer]);

        const convertedDimension = useMemo(() => {
            return convertMultiRangeUnitConversionDimension(dimension, unit);
        }, [unit, device, drawer]);

        if (!device.state.dimensions[drawer.referenceID][drawer.type]) {
            return (
                <Text className="m-auto font-bold">
                    Error! Dimension Undefined.
                </Text>
            );
        }

        return (
            <div className="flex flex-row flex-1 bg-white">
                <div className="h-full grid grid-rows-2 px-2 pt-3">
                    <div className="grid grid-cols-2 h-[4em]">
                        <div className="col-span-1 flex justify-center items-center">
                            <div>
                                <DeviceIcon
                                    headers={device.headers}
                                    overallHealth={
                                        device.deviceHealth[
                                            drawer.referenceID
                                        ] as HEALTH_STATUS
                                    }
                                    offline={device.state.offline}
                                    image={drawer.image}
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <MultiRangeUnitConversionHeader
                                value={String(convertedMetricValue)}
                                unit={unit}
                                baseUnit={dimension.baseUnits}
                                setUnit={setUnit}
                            />
                        </div>
                    </div>
                    <span className="w-1/2 p-2 text-xs whitespace-wrap text-center text-ellipsis font-sans font-semibold">
                        {drawer.name}
                    </span>
                </div>

                <div className="flex flex-1 flex-col justify-between text-gray-900">
                    <div className="flex flex-1">
                        <MultiRangeUnitConversionMetric
                            value={convertedMetricValue}
                            unit={unit}
                            {...convertedDimension}
                        />
                    </div>

                    <span className="w-full p-2 text-xs whitespace-nowrap mx-auto text-center text-obsidian">
                        Updated: {formatDate(metric.updateDate)}
                    </span>
                </div>
            </div>
        );
    },
);
