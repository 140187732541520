/**
 * Type that differentiates layer and feature type for openlayers map
 */

enum MAP_VIEW_TYPE {
    SUBLOCATION_OVERLAY = "SublocationOverlay",
    ZONE = "Zone",
    DEVICE = "Device",
}

export default MAP_VIEW_TYPE;
