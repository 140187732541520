import type HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import DEVICE_DRAWER_TABS from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardTabs/utilities/DEVICE_DRAWER_TABS.enum";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useCallback } from "react";
import extendClassName from "utils/extendClassName";
import { v4 } from "uuid";
import CardContentStatusDrawerItemGenericMetricContainer from "./CardContentStatusDrawerItemGenericMetricContainer/CardContentStatusDrawerItemGenericMetricContainer";
import CurrentDeviceCardContentStatusDrawerItemStatusBar from "./CurrentDeviceCardContentStatusDrawerItemStatusBar/CurrentDeviceCardContentStatusDrawerItemStatusBar";
import type CardContentStatusDrawerItemGenericProps from "./utilities/CardContentStatusDrawerItemGeneric.props";

const hoverStyle = "hover:border-cobalt hover:border-2 hover:drop-shadow-md";

export default observer(function CardContentStatusDrawerItemGeneric({
    device,
    metric,
    drawer,
}: CardContentStatusDrawerItemGenericProps): JSX.Element {
    const { setParams, currentZone } = useQuery();

    const navigateFromZoneToDevice = useCallback(() => {
        if (currentZone) {
            setParams({
                currentDevice: device._id,
                currentZone: null,
                currentDrawerTab: DEVICE_DRAWER_TABS.STATUS,
            });
        }
    }, [currentZone, device._id]);

    return !metric ? (
        <p>No metrics available for display.</p>
    ) : (
        <li
            key={v4()}
            className={extendClassName(
                "flex flex-row overflow-hidden my-2 mx-4 border-[#D4D4D4] border-2 has-ignoreClass-device-drawer has-ignoreClass-zone-drawer ",
                currentZone ? hoverStyle : "",
            )}
            onClick={(): void => navigateFromZoneToDevice()}
        >
            <CurrentDeviceCardContentStatusDrawerItemStatusBar
                offline={device.state.offline}
                health={
                    device.deviceHealth[drawer.referenceID] as HEALTH_STATUS
                }
            />

            <CardContentStatusDrawerItemGenericMetricContainer
                device={device}
                drawer={drawer}
                metric={metric}
            />
        </li>
    );
});
