import DEVICE_DRAWER_TABS from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardTabs/utilities/DEVICE_DRAWER_TABS.enum";
import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import DeviceIconSize from "components/DeviceIcon/utilities/DeviceIconSize.enum";
import EventTimeCol from "components/Tables/Columns/EventTimeCol/EventTimeCol";
import RowStyleT from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/RowStyle.props";
import setTableRowStyle from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/setTableRowStyleReduce";
import useDevice from "hooks/useDevice.hook";
import useQuery from "hooks/useQuery/useQuery";
import { memo, useCallback, useEffect, useReducer } from "react";
import extendClassName from "utils/extendClassName";
import NotificationListItemRowProps from "./NotificationListItemRow.props";

const initialState: RowStyleT = {
    border: "shadow-inner-offline shadow-md",
    background: "",
};

const containerDivClassName = "h-[5rem] overflow-y-auto ";

export default memo(function NotificationListItemRow({
    notification,
}: NotificationListItemRowProps): JSX.Element {
    const { setParams } = useQuery();
    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialState,
    );
    const { data: device } = useDevice({
        deviceId: notification.device._id,
    });
    const routeToDevice = useCallback((deviceID: string) => {
        setParams({
            currentDevice: deviceID,
            currentDrawerTab: DEVICE_DRAWER_TABS.STATUS,
        });
    }, []);

    /**
     * EVENT_TYPE and HEALTH_STATUS are essentially the same,
     * the difference being that health status are usually live data while
     * events are health status that occured at the time of the event.
     */

    useEffect(() => {
        dispatch({
            event_type: notification.status,
        });
    }, [notification.status]);

    return (
        <tr
            className="has-ignoreClass-device-drawer has-ignoreClass-zone-drawer h-[80px] overflow-y-auto border-gray-700 border-l-0 border-r-0 border-t-1 cursor-pointer text-obsidian bg-white first:border-t-0 last:border-b-0 transition-colors hover:bg-gray-800 hover:bg-opacity-10 duration-300 w-full"
            key={notification.id}
            onClick={(): void => routeToDevice(notification.device._id)}
        >
            {/* STATUS */}
            <td
                className={extendClassName(
                    "capitalize py-0 border-r-1 border-gray-700 ",
                    tableRowStyle.border,
                    " ",
                    tableRowStyle.background,
                )}
            >
                <div
                    className={extendClassName(
                        "pl-4 flex items-center ",
                        containerDivClassName,
                    )}
                >
                    {notification.status}
                </div>
            </td>
            {/* Device Name */}
            <td className="pl-4 py-0 h-full border-r-1 border-gray-700 h-[80px] ">
                <div
                    className={extendClassName(
                        "flex items-center ",
                        containerDivClassName,
                    )}
                >
                    <span className="flex justify-start items-center gap-2">
                        <span>
                            {device && (
                                <DeviceIcon
                                    headers={device.headers}
                                    overallHealth={
                                        device.deviceHealth.overAllHealth
                                    }
                                    offline={device.state.offline}
                                    image={device.display.image}
                                    containerSize={DeviceIconSize.XSMALL}
                                />
                            )}
                        </span>{" "}
                        {device && device.headers.deviceName}
                    </span>
                </div>
            </td>
            {/* Message */}
            <td className="px-4 py-0 h-[80px] border-r-1 border-gray-700 w-[40%] overflow-y-auto">
                <div
                    className={extendClassName(
                        "flex md:items-center ",
                        containerDivClassName,
                    )}
                >
                    {notification.message}
                </div>
            </td>

            {/* SubLocation */}
            <td className="px-4 py-0 h-[80px] border-r-1 border-gray-700 truncate">
                <div
                    className={extendClassName(
                        "flex items-center ",
                        containerDivClassName,
                    )}
                >
                    {device && device.headers.sublocation}
                </div>
            </td>
            {/* Time */}

            <EventTimeCol eventTime={notification.event_time} />
        </tr>
    );
});
