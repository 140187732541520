import type BOOLEAN_TO_STRING_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/BOOLEAN_TO_STRING_DIMENSION/BOOLEAN_TO_STRING_DIMENSION.type";
import { memo, useCallback } from "react";
import type EditDrawerProps from "../utilities/EditDrawer.props";
import InputField from "components/InputField/InputField";

export default memo(function BooleanToString({
    disabled,
    onChange,
    options,
}: EditDrawerProps<boolean, BOOLEAN_TO_STRING_DIMENSION>): JSX.Element {
    const handleChange = useCallback(
        (type: "true" | "false", value: string) => {
            options.booleanToString[type] = value;

            onChange({
                ...options,
            });
        },
        [options, onChange],
    );

    return (
        <div className="flex flex-col flex-1">
            <InputField
                key="true"
                label="When true"
                classes={{ label: "my-2" }}
                disabled={disabled}
                value={options.booleanToString?.true || ""}
                onChange={({ target: { value } }): void =>
                    handleChange(true as unknown as "true", value)
                }
            />

            <InputField
                key="false"
                label="When false"
                classes={{ label: "my-2" }}
                disabled={disabled}
                value={options.booleanToString?.false || ""}
                onChange={({ target: { value } }): void =>
                    handleChange(false as unknown as "false", value)
                }
            />
        </div>
    );
});
