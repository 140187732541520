import IconSize from "types/IconSize";

function getIconSize(size: IconSize | number): number {
    try {
        //If we already have a number, return that as the size.
        //TODO: Make it make sense.
        if (Number.isNaN(size)) {
            return size as number;
        }
        //The new size value based on the string indicated.
        let conversion: number;
        switch (size) {
            case "xs":
                conversion = 12;
                break;
            case "sm":
                conversion = 24;
                break;
            case "md":
                conversion = 35;
                break;
            case "lg":
                conversion = 48;
                break;
            case "xl":
                conversion = 64;
                break;
            case "2xl":
                conversion = 128;
                break;

            default:
                conversion = 35;
        }
        return conversion;
    } catch (error) {
        console.error("Could get Icon size", error);
        throw new Error("could not get icon size");
    }
}

export default getIconSize;
