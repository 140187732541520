import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { useSearchParams } from "react-router-dom";
import LeftNavDrawerTab from "./LeftNavDrawerTab/LeftNavDrawerTab";
import type LeftDrawerTabsProps from "./utilities/LeftDrawerTabs.props";
import LEFT_DRAWER_TAB from "./utilities/LEFT_DRAWER_TAB.enum";
import LEFT_DRAWER_TAB_VALUES from "./utilities/LEFT_DRAWER_TAB_VALUES.value";
import type LEFT_DRAWER_TAB_VALUE_T from "./utilities/LEFT_DRAWER_TAB_VALUE_T.type";
import extendClassName from "utils/extendClassName";
import { CLASS_NAMES } from "styles/classNameConstants";

export default observer(function LeftDrawerTabs({
    currentTab,
    setCurrentTab,
    organizationStore,
    isDrawerOpen,
    setDrawerOpen,
}: LeftDrawerTabsProps): JSX.Element {
    const [searchParams] = useSearchParams();
    const analyticsAvailable = useMemo(() => {
        return Boolean(
            organizationStore.sublocation?.analyticsDashboardLinks.length,
        );
    }, [organizationStore.sublocation]);
    const analyticsAvailableLocation = useMemo(() => {
        return Boolean(
            organizationStore.location?.analyticsDashboardLinks.length,
        );
    }, [organizationStore.location]);

    const visibleIcons = useMemo(() => {
        return LEFT_DRAWER_TAB_VALUES.filter(({ tabType }) => {
            switch (tabType) {
                case LEFT_DRAWER_TAB.LOGS:
                    // this is set to false because product said that this should only show up when there is a link,
                    // as there are no links to any sublocation logs even schema'd yet, this is just being turned off
                    //
                    // return Boolean(organizationStore.sublocation);
                    return false;
                case LEFT_DRAWER_TAB.ANALYTICS:
                    if (
                        Boolean(organizationStore.sublocation) &&
                        analyticsAvailable
                    ) {
                        return true;
                    } else if (
                        Boolean(organizationStore.location) &&
                        analyticsAvailableLocation
                    ) {
                        return true;
                    } else if (searchParams.get("analyticId")) {
                        return true;
                    } else {
                        return false;
                    }
                default:
                    // everything else should always show
                    return true;
            }
        });
    }, [
        organizationStore.sublocation,
        organizationStore.location,
        analyticsAvailable,
        searchParams,
    ]);

    return (
        <ul
            className={extendClassName(
                CLASS_NAMES.iconTabs.container,
                " pt-[76px] pb-[54px]",
                "border-r-1 border-gray-700",
            )}
        >
            {visibleIcons.map(
                ({
                    tabType,
                    iconName,
                    label,
                }: LEFT_DRAWER_TAB_VALUE_T): JSX.Element => (
                    <LeftNavDrawerTab
                        key={label}
                        tabType={tabType}
                        icon={iconName}
                        label={label}
                        isDrawerOpen={isDrawerOpen}
                        setDrawerOpen={setDrawerOpen}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                    />
                ),
            )}
        </ul>
    );
});
