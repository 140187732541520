import { makeAutoObservable, observable } from "mobx";

class ViewportStore {
    @observable
    isMobile = false;

    @observable
    viewSelectorHeight = 44; //size of <ViewSelector />

    constructor() {
        makeAutoObservable(this);
    }

    public setIsMobile(value: boolean): void {
        this.isMobile = value;
    }

    public setViewSelectorHeight(num: number): void {
        this.viewSelectorHeight = Math.round(num);
    }
}

export default ViewportStore;
