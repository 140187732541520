import { memo } from "react";
import { useFieldArray } from "react-hook-form";
import AddRangeButton from "../AddRangeButton/AddRangeButton";
import MinMaxRangeField from "../MinMaxRangeField/MinMaxRangeField";
import HealthRangeContainerProps from "./HealthRangeContainerProps.type";

/**
 * A container for grouping different lists of health ranges,
 * wherein each status @see HEALTH_STATUS, can contain an array of seperate ranges [number, number]
 * currently depicted only as number[] for now.
 */
export default memo(function HealthRangeContainer({
    health,
    control,
    trigger,
    errors,
}: HealthRangeContainerProps): JSX.Element {
    const { fields, append, remove } = useFieldArray({
        control,
        name: health,
    });

    /**
     * Adds a new range to the correlating health container.
     * Should optiminally take into account the values preceeding it and after it.
     */
    const createNewRange = (): void => {
        append([["0", "0"]]);
    };
    return (
        <li className="flex flex-1 flex-col border-t-1 border-primary-lightGray mt-4">
            <span className="capitalize mt-2">{health}</span>
            <ul className="flex flex-1 flex-col w-full">
                {fields.map((range, index) => {
                    return (
                        <li
                            className="flex flex-row inline-block"
                            key={range.id}
                        >
                            <MinMaxRangeField
                                index={index}
                                control={control}
                                baseName={health}
                                onRemove={(): void => remove(index)}
                                trigger={trigger}
                                errors={errors}
                            />
                        </li>
                    );
                })}
                <AddRangeButton addRange={createNewRange} label={health} />
            </ul>
        </li>
    );
});
