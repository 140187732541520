import MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE_DIMENSION/MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE_DIMENSION.type";
import InputField from "components/InputField/InputField";
import { memo, useCallback, useEffect, useState } from "react";
import {
    celsiusToFahrenheit,
    fahrenheitToCelsius,
} from "utils/temperatureConversion";
import type EditDrawerProps from "../utilities/EditDrawer.props";

const onlyDigitDotsAndDashes = /^[+-]?[0-9]{1,9}(?:\.[0-9]{1,2})?$/;

export default memo(function MinMaxSafeImperialRangeEditDrawer({
    disabled,
    onChange,
    options,
}: EditDrawerProps<
    number,
    MIN_MAX_SAFE_VAL_IMPERIAL_TEMPERATURE_DIMENSION
>): JSX.Element {
    const [safeInputs, setSafeInputs] = useState({
        safeMinInput: options.minMaxSafeValImperialTemperature.safeMin
            ? celsiusToFahrenheit(
                  options.minMaxSafeValImperialTemperature.safeMin,
              ).toString()
            : "",
        safeMaxInput: options.minMaxSafeValImperialTemperature.safeMax
            ? celsiusToFahrenheit(
                  options.minMaxSafeValImperialTemperature.safeMax,
              ).toString()
            : "",
        safeMinInputError: "",
        safeMaxInputError: "",
    });
    const [operatingInputs, setOperatingInputs] = useState({
        rangeMinInput: options.minMaxSafeValImperialTemperature.rangeMin
            ? celsiusToFahrenheit(
                  options.minMaxSafeValImperialTemperature.rangeMin,
              ).toString()
            : "",
        rangeMaxInput: options.minMaxSafeValImperialTemperature.rangeMax
            ? celsiusToFahrenheit(
                  options.minMaxSafeValImperialTemperature.rangeMax,
              ).toString()
            : "",
        rangeMinInputError: "",
        rangeMaxInputError: "",
    });
    const {
        rangeMinInput,
        rangeMaxInputError,
        rangeMaxInput,
        rangeMinInputError,
    } = operatingInputs;
    const { safeMinInput, safeMaxInput, safeMaxInputError, safeMinInputError } =
        safeInputs;

    const handleMinMaxUpdate = useCallback(
        (key: "safeMinInput" | "safeMaxInput", value: string) => {
            let error = "";

            if (value === "") {
                error = "This field cannot be empty";
            }

            // Validate the input against the regex pattern
            if (!onlyDigitDotsAndDashes.test(value)) {
                error = "This field can only have numbers";
            }

            // Validate the min and max values against each other
            if (
                key === "safeMinInput" &&
                parseFloat(value) > parseFloat(safeMaxInput)
            ) {
                error = "Min value cannot be greater than Max value";
            } else if (
                key === "safeMaxInput" &&
                parseFloat(value) < parseFloat(safeMinInput)
            ) {
                error = "Max value cannot be less than Min value";
            }

            setSafeInputs((prevInputs) => ({
                ...prevInputs,
                [key]: value,
                [`${key}Error`]: error,
            }));

            if (error === "") {
                setSafeInputs((prevValues) => ({
                    ...prevValues,
                    [key.replace("Input", "")]: parseFloat(value),
                }));
            }
        },
        [safeMinInput, safeMaxInput],
    );

    const handleOperatingRangeChange = useCallback(
        (key: "rangeMinInput" | "rangeMaxInput", value: string) => {
            let error = "";

            if (value === "") {
                error = "This field cannot be empty";
            }

            // Validate the input against the regex pattern
            if (!onlyDigitDotsAndDashes.test(value)) {
                error = "This field can only have numbers";
            }

            // Validate the min and max values against each other
            if (
                key === "rangeMinInput" &&
                parseFloat(value) > parseFloat(rangeMaxInput)
            ) {
                error = "Min value cannot be greater than Max value";
            } else if (
                key === "rangeMaxInput" &&
                parseFloat(value) < parseFloat(rangeMinInput)
            ) {
                error = "Max value cannot be less than Min value";
            }

            setOperatingInputs((prevInputs) => ({
                ...prevInputs,
                [key]: value,
                [`${key}Error`]: error,
            }));

            if (error === "") {
                setOperatingInputs((prevValues) => ({
                    ...prevValues,
                    [key.replace("Input", "")]: parseFloat(value),
                }));
            }
        },
        [rangeMinInput, rangeMaxInput, rangeMaxInputError, rangeMinInputError],
    );

    useEffect(() => {
        const safeMinValue = parseFloat(safeMinInput) || 0;
        const safeMaxValue = parseFloat(safeMaxInput) || 0;
        const rangeMinValue = parseFloat(rangeMinInput) || 0;
        const rangeMaxValue = parseFloat(rangeMaxInput) || 0;

        onChange({
            ...options,
            minMaxSafeValImperialTemperature: {
                safeMin: fahrenheitToCelsius(safeMinValue),
                safeMax: fahrenheitToCelsius(safeMaxValue),
                rangeMin: fahrenheitToCelsius(rangeMinValue),
                rangeMax: fahrenheitToCelsius(rangeMaxValue),
            },
        });
    }, [safeMinInput, safeMaxInput, rangeMaxInput, rangeMinInput]);

    if (!options.minMaxSafeValImperialTemperature)
        return (
            <h3>
                {" "}
                Display error, please contact SRT support. NO MINMAXSAFERANGE{" "}
            </h3>
        );

    return (
        <>
            <div className="w-full flex">
                <InputField
                    label="Safe Min"
                    type="number"
                    classes={{ label: "my-2 mr-1 flex-1" }}
                    disabled={disabled}
                    value={safeMinInput}
                    onChange={({ target: { value } }): void =>
                        handleMinMaxUpdate("safeMinInput", value)
                    }
                    error={safeMinInputError}
                />

                <InputField
                    label="Safe Max"
                    type="number"
                    classes={{ label: "my-2 ml-1 flex-1" }}
                    disabled={disabled}
                    error={safeMaxInputError}
                    value={safeMaxInput}
                    onChange={({ target: { value } }): void =>
                        handleMinMaxUpdate("safeMaxInput", value)
                    }
                />
            </div>

            <div className="flex flex-col w-full mt-4">
                <span>Operating Range</span>
                <div className="flex flex-1 mt-2">
                    <InputField
                        label="Min"
                        value={rangeMinInput}
                        classes={{ label: "my-2 mr-1 flex-1" }}
                        disabled={disabled}
                        error={rangeMinInputError}
                        onChange={({ target: { value } }): void =>
                            handleOperatingRangeChange("rangeMinInput", value)
                        }
                    />

                    <InputField
                        label="Max"
                        value={rangeMaxInput}
                        classes={{ label: "my-2 ml-1 flex-1" }}
                        disabled={disabled}
                        error={rangeMaxInputError}
                        onChange={({ target: { value } }): void =>
                            handleOperatingRangeChange("rangeMaxInput", value)
                        }
                    />
                </div>
            </div>
        </>
    );
});
