import LinearScaledMultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScaledMultiRangeSafeVal.type";
import LinearScaledMultiRangeSafeValSchema from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION.schema";
export default function isValidLinearScaledMultiRangeSafeVal(dimension?: {
    linearScaledMultiRangeSafeVal?: LinearScaledMultiRangeSafeVal;
}): boolean {
    const configuration = dimension?.linearScaledMultiRangeSafeVal || null;
    if (configuration) {
        const { error } =
            LinearScaledMultiRangeSafeValSchema.validate(dimension);
        const valid = error == null;
        if (valid) {
            return true;
        }
    }
    return false;
}
