import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import { memo } from "react";
import FleetListItemRow from "../FleetListItem/FleetListItemRow/FleetListItemRow";
import FleetListItemContainer from "../FleetListItemContainer/FleetListItemContainer";
import type FleetListProps from "./utilities/FleetList.props";

export default memo(function FleetList<T extends Device | Zone>({
    items,
    onClickItem,
    isLoading,
    renderRow,
}: FleetListProps<T>): JSX.Element {
    return (
        <FleetListItemContainer>
            {isLoading ? (
                <tr className="text-center w-full h-full">
                    <td className="w-full p-8" colSpan={100}>
                        Loading...
                    </td>
                </tr>
            ) : items.length > 0 ? (
                <>
                    {items.map((item) => (
                        <FleetListItemRow key={item._id}>
                            {renderRow({ item, onClickItem })}
                        </FleetListItemRow>
                    ))}
                </>
            ) : (
                <tr className="text-center w-full h-full">
                    <td className="w-full p-8" colSpan={100}>
                        There are no items to display with this view.
                    </td>
                </tr>
            )}
        </FleetListItemContainer>
    );
});
