const enum FLEET_SORT_OPTIONS {
    ERROR = "error",
    STATUS = "offline",
    POSITION = "position",
    SUBLOCATION = "sublocation",
    MODEL = "model",
    NAME = "name",
    MANUFACTURER = "manufacturer",
    MESSAGE = "message",
    FUNCTIONS = "functions",
    TYPE = "dataType",
    SENSOR_LOCATION = "sensorLocation",
    INFO = "info",
    TIME = "time",
    LOCATION = "location",
}

export default FLEET_SORT_OPTIONS;
