import RelatedNotificationListContainer from "components/RelatedNotificationsList/RelatedNotificationContainer";
import { memo } from "react";
import rootStore from "stores/rootStore";
import DEVICE_DRAWER_TABS from "../CurrentDeviceCardTabs/utilities/DEVICE_DRAWER_TABS.enum";
import CurrentDeviceCardContentEdit from "./CurrentDeviceCardContentEdit/CurrentDeviceCardContentEdit";
import CurrentDeviceCardContentInfo from "./CurrentDeviceCardContentInfo/CurrentDeviceCardContentInfo";
import CurrentDeviceCardContentLogs from "./CurrentDeviceCardContentLogs/CurrentDeviceCardContentLogs";
import CurrentDeviceCardContentMap from "./CurrentDeviceCardContentMap/CurrentDeviceCardContentMap";
import CurrentDeviceCardContentStatus from "./CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatus";
import type CurrentDeviceCardContentProps from "./utilities/CurrentDeviceCardContent.props";

export default memo(function CurrentDeviceCardContent({
    device,
    currentTab,
}: CurrentDeviceCardContentProps): JSX.Element {
    if (!currentTab || !device) return <></>;

    return (
        <>
            {currentTab === DEVICE_DRAWER_TABS.STATUS ? (
                <CurrentDeviceCardContentStatus device={device} />
            ) : currentTab === DEVICE_DRAWER_TABS.MAP ? (
                <CurrentDeviceCardContentMap device={device} />
            ) : currentTab === DEVICE_DRAWER_TABS.SETTINGS ? (
                <CurrentDeviceCardContentEdit device={device} />
            ) : currentTab === DEVICE_DRAWER_TABS.INFO ? (
                <CurrentDeviceCardContentInfo
                    device={device}
                    snackbarStore={rootStore.snackbarStore}
                />
            ) : currentTab === DEVICE_DRAWER_TABS.ALERTS ? (
                <RelatedNotificationListContainer
                    notificationSrcIds={[device._id]}
                />
            ) : // <CurrentDeviceCardContentAlerts device={device} />
            currentTab === DEVICE_DRAWER_TABS.EVENTS ? (
                <CurrentDeviceCardContentLogs device={device} />
            ) : (
                <></>
            )}
        </>
    );
});
