import * as Yup from "yup";
import healthStatusSchema from "./healthStatus.schema";
import operatingRangeSchema from "./operatingRange.schema";
import { multiRangeSchemaValidation } from "./utils.schema";
import StringMultiRangeSafeVal from "types/StringMultiRangeSafeVal";

const multiRangeSchema: Yup.ObjectSchema<StringMultiRangeSafeVal> =
    Yup.object().shape({
        unsafe: multiRangeSchemaValidation,
        warning: multiRangeSchemaValidation,
        safe: multiRangeSchemaValidation,
        base: healthStatusSchema,
        operatingRange: operatingRangeSchema,
    });

export default multiRangeSchema;
