import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import * as Yup from "yup";

/**
 * Ensures that the value is either SAFE, UNSAFE, or WARNING
 * @see HEALTH_STATUS
 */
const healthStatusSchema = Yup.string()
    .oneOf(Object.values(HEALTH_STATUS))
    .required("This field is required");

export default healthStatusSchema;
