import DisplayAngleIcon from "components/DisplayAngleIcon/DisplayAngleIcon";
import useClickOutside from "hooks/useClickOutside";
import { memo, useCallback, useState } from "react";
import extendClassName from "utils/extendClassName";
import SelectFieldProps from "./utilities/SelectField.props";

const selectFieldClassName =
    "appearance-none w-full border-1 border-gray-700 p-2 leading-tight focus:outline-none bg-white";

export default memo(function SelectField<T>({
    list,
    title,
    onChange,
    disabled = false,
}: SelectFieldProps<T>): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    const { setRef } = useClickOutside(
        (): void => setIsOpen(false),
        () => null,
        "has-ignoreClass-selectField",
        isOpen,
    );

    const handleOpen = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    return (
        <div ref={setRef} className={"h-auto relative"}>
            <div
                onClick={handleOpen}
                className={extendClassName(
                    "flex justify-between",
                    selectFieldClassName,
                    isOpen ? "rounded-b-none" : "",
                )}
            >
                {title}

                <button type="button" onClick={handleOpen} disabled={disabled}>
                    {<DisplayAngleIcon isOpen={isOpen} />}
                </button>
            </div>

            {isOpen && (
                <ul
                    className={extendClassName(
                        "rounded-md rounded-t-none",
                        "border-1 border-gray-700 w-100",
                    )}
                >
                    {list.map(
                        ({ text, data }, index): JSX.Element => (
                            <li
                                key={index}
                                className={extendClassName(
                                    "font-inherit px-4 py-1 bg-white hover:bg-gray-700 transition-colors duration-300",
                                )}
                                onClick={(): void => {
                                    handleOpen();
                                    onChange(data, index);
                                }}
                            >
                                {text}
                            </li>
                        ),
                    )}
                </ul>
            )}
        </div>
    );
});
