import RowStyleT from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/RowStyle.props";
import setTableRowStyle from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/setTableRowStyleReduce";
import { memo, useReducer } from "react";
import rootStore from "stores/rootStore";
import extendClassName from "utils/extendClassName";
import ZoneRowItemProps from "./ZoneRowItemProps";

const initialState: RowStyleT = {
    border: "shadow-inner-offline shadow-md",
    background: "bg-white",
};

export default memo(function ZoneRowItem({
    zone,
    onClickOfZone,
}: ZoneRowItemProps): JSX.Element {
    const [tableRowStyle] = useReducer(setTableRowStyle, initialState);

    const { _id, name } = zone;

    return (
        <tr
            className={extendClassName(
                "h-5 border-gray-700 border-l-0 border-r-0 border-t-1 first:border-t-0 last:border-b-0 transition-colors hover:bg-gray-800 hover:bg-opacity-30 hover:bg-opacity-10 duration-300 cursor-pointer text-obsidian bg-white has-ignoreClass-device-drawer has-ignoreClass-zone-drawer",
                tableRowStyle.border,
                tableRowStyle.background,
            )}
            onClick={(): void => onClickOfZone(_id)}
        >
            {/* Zone Name */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700 truncate">
                {" "}
                {name}
            </td>

            {/* Location */}
            <td className="px-4 py-0 h-full border-r-1 border-gray-700 truncate">
                {" "}
                {rootStore.organizationStore.getSublocationNameByZoneId(
                    zone._id,
                )}
            </td>
        </tr>
    );
});
