export default function latestTime(day: Date): Date {
    const d = new Date(day);

    d.setHours(24);
    d.setMinutes(60);
    d.setSeconds(0);
    d.setMilliseconds(0);

    return d;
}
