import { memo, KeyboardEvent } from "react";
import extendClassName from "utils/extendClassName";
import preventArrowKeyPress from "utils/preventArrowKeyPress";
import type InputFieldProps from "./utilities/InputField.props";
import preventAlphabetForNumInput from "utils/preventAlphabetForNumInput";

const defaultInputClassName =
    "appearance-none w-full border-1 border-gray-700 p-2 leading-tight focus:outline-none bg-white";
const defaultErrorClassName = "text-orange text-xs italic m-2";
const defaultLabelClassName =
    "p-0 relative appearance-none border-1 border-white border-opacity-30 rounded focus:shadow-outline";
const defaultSpanClassName = "capitalize py-2 text-sm text-gray-700";

export default memo(function InputField({
    label,
    onChange = (): void => {
        /* */
    },
    error,
    classes,
    type,
    disabled = false,
    ...rest
}: InputFieldProps): JSX.Element {
    /**
     * Depending on the type of the form, will prevent certain keypresses
     */
    const preventPress = (event: KeyboardEvent<HTMLInputElement>): void => {
        if (type === "text") {
            return;
        }
        if (type === "number") {
            preventArrowKeyPress(event);
            preventAlphabetForNumInput(event);
        }
    };

    return (
        <label
            className={extendClassName(defaultLabelClassName, classes?.label)}
        >
            <span
                className={extendClassName(defaultSpanClassName, classes?.span)}
            >
                {label}
            </span>
            <input
                {...rest}
                disabled={disabled}
                type={"text"}
                onWheel={(e): void => e.currentTarget.blur()}
                onKeyUp={(e): void => preventPress(e)}
                onKeyDown={(e): void => preventPress(e)}
                className={extendClassName(
                    defaultInputClassName,
                    classes?.input,
                    disabled ? "text-gray-700" : "",
                )}
                onChange={onChange}
            />

            {error ? (
                <p
                    className={extendClassName(
                        defaultErrorClassName,
                        classes?.error,
                    )}
                >
                    {error}
                </p>
            ) : null}
        </label>
    );
});
