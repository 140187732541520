import { AxiosRequestConfig } from "axios";

// eslint-disable-next-line
export const BASE_WEB_URL = `${
    process.env.REACT_APP_MAKE_SECURE_CONNECTION === "true"
        ? "https://"
        : "http://"
    // eslint-disable-next-line
}${process.env.REACT_APP_WEB_SERVER_DOMAIN}`;
// eslint-disable-next-line
export const BASE_WS_URL = `${
    process.env.REACT_APP_MAKE_SECURE_CONNECTION === "true" ? "wss://" : "ws://"
    // eslint-disable-next-line
}${process.env.REACT_APP_WEB_SERVER_DOMAIN}`;

export interface FetchParams {
    url: string;
    method:
        | "get"
        | "GET"
        | "post"
        | "POST"
        | "put"
        | "PUT"
        | "delete"
        | "DELETE"
        | "patch"
        | "PATCH";
    data?: Record<string, unknown>;
    headers?: Record<string, unknown>;
    other?: AxiosRequestConfig;
}

const getQueryParams = (str = ""): Record<string, string> => {
    const obj: Record<string, string> = {};
    str.split("?")
        .filter((v) => !!v)
        .forEach((query) => {
            const [key, value] = query.split("=");

            obj[decodeURIComponent(key)] = decodeURIComponent(value);
        });

    return obj;
};

export { getQueryParams };
