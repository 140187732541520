import Icon from "components/Icons/Icon/Icon";
import getIconSize from "components/Icons/Icon/getIconSize.util";
import { memo, useMemo } from "react";
import IconSize from "types/IconSize";
import type NumberMetricProps from "./utilities/NumberMetric.props";

export default memo(function NumberMetric({
    value,
    safeMin,
    safeMax,
    rangeMax,
    rangeMin,
    unit,
}: NumberMetricProps): JSX.Element {
    const halfIconWidth = (width: IconSize): number => getIconSize(width) / 2;
    const clamp = (value: number, min: number, max: number): number =>
        Math.min(Math.max(value, min), max);
    const safeBarStart = useMemo(() => {
        if (safeMin <= rangeMin) {
            return 0;
        } else if (safeMin >= rangeMax) {
            return 100;
        } else {
            return ((safeMin - rangeMin) / (rangeMax - rangeMin)) * 100;
        }
    }, [safeMin, rangeMax, rangeMin]);

    const safeBarEnd = useMemo(() => {
        if (safeMax <= rangeMin) {
            return 0;
        } else if (safeMax >= rangeMax) {
            return 100;
        } else {
            return ((safeMax - rangeMin) / (rangeMax - rangeMin)) * 100;
        }
    }, [safeMax, rangeMax, rangeMin, safeBarStart]);

    const markerPosition = useMemo(() => {
        if (rangeMin === rangeMax) return 50;
        const range = rangeMax - rangeMin;
        const valueInRange = value - rangeMin;
        const percentage = (valueInRange / range) * 100;
        return clamp(percentage, 0, 100);
    }, [value, rangeMax, rangeMin]);

    if (isNaN(value)) {
        return <h1>Error. value is NaN</h1>;
    }

    return (
        <div className="flex flex-1 justify-center items-center pr-4 pl-2">
            <div className="relative w-full h-1 bg-primary-lightGray">
                <Icon
                    name="caret-down"
                    className="text-obsidian absolute bottom-1.75 "
                    size={"sm"}
                    style={{
                        left: `calc(${markerPosition}% - ${halfIconWidth(
                            "sm",
                        )}px)`,
                    }}
                />
                <div
                    className="absolute h-1 bg-comp-4"
                    style={{
                        left: `${safeBarStart}%`,
                        right: `${100 - safeBarEnd}%`,
                    }}
                />

                <span className="float-left text-xxs font-bold absolute left-0 top-1.25">
                    {rangeMin}
                </span>
                <span className="float-left text-xxs font-bold absolute right-0 top-1.25">
                    {rangeMax}
                </span>
                <span className="float-left text-xxs font-bold absolute left-1/2 top-1.25">
                    {unit === "none" ? "" : unit}
                </span>
            </div>
        </div>
    );
});
