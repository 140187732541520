import { useState, useCallback } from "react";
import selectBoldArrow from "views/Home/HomeContentContainer/Fleet/FleetControls/utilities/selectBoldArrow.utils";
import HoverState from "./HoverState.props";
import UseSortableTableOutput from "./UseSortableTableOutput.props";
import UseSortableTableProps from "./UseSortableTableProps.props";
import SortColProps from "./SortColProps.props";

/**
 * A custom React hook that adds sorting functionality to a table.
 * @template T - The type of the column headers in the table.
 * @param {UseSortableTableProps<T>} props - An object containing the properties for the hook.
 * @param {string} props.options - An array of objects containing the column header title and sort type.
 * @param {SortColProps} props.initialSort - The initial sort configuration for the table.
 * @param {Function} props.setSortOption - A callback function that sets the sort option for the table.
 * @param {boolean} props.reverse - A boolean value that indicates whether the table should be sorted in reverse order.
 * @returns {UseSortableTableOutput<T>} - An object containing the sorted data, sort configuration, and functions to update the sort configuration.
 */
export function useSortableTable<T extends string, U>({
    initialSort,
    setSortOption,
    reverse,
}: UseSortableTableProps<T, U>): UseSortableTableOutput<T> {
    // Set up the columnHeader state using useState
    const [columnHeader, setColumnHeader] =
        useState<SortColProps<T>>(initialSort);

    // Set up the isHovering state using useState
    const [isHovering, setHover] = useState<HoverState<T>>({});

    // Define a function to check if a column is selected
    const isColumnSelected = useCallback(
        (type: T) => columnHeader.type === type,
        [columnHeader.type],
    );

    // Define a function to handle hovering over a column
    const handleHover = useCallback((type: T, hovering: boolean) => {
        setHover((previousState: HoverState<T>) => ({
            ...previousState,
            [type]: hovering,
        }));
    }, []);

    /**
     * Determines the direction and boldness of the sorting arrow for a table column.
     * @template T - The type of the table column being sorted.
     * @param {T extends keyof HoverState<T> ? T : never} type - The type of the column being sorted.
     * @returns {JSX.Element} - The React element that represents the sorting arrow for the column.
     */
    const arrowType = useCallback(
        //Type translation: ensures that it can only be a valid key of HoverState<T>, and if it's not, it can't be used at all.
        (type: T): JSX.Element => {
            if (isHovering[type] && isColumnSelected(type)) {
                // If hovering over an active column show opposite arrow state
                return selectBoldArrow(!columnHeader.ascending, true);
            } else if (!isHovering[type] && isColumnSelected(type)) {
                // Reverse arrow state once not hovering
                return selectBoldArrow(columnHeader.ascending, true);
            } else {
                // If the column is not selected show a light arrow with ascending to be true
                return selectBoldArrow(true, false);
            }
        },
        [columnHeader, isColumnSelected, selectBoldArrow, isHovering],
    );

    const handleSetSort = useCallback(
        ({ title, type }: { title: string; type: T }) => {
            //check if the clicked column is already selected for sorting
            const isSameType = isColumnSelected(type);

            //toggle the sort direction between ascending and descending if the same
            //column is clicked again
            const newAscending = isSameType ? !columnHeader.ascending : true;

            // update the sort config of the table
            setColumnHeader({ title, type, ascending: newAscending });

            // call setSortOption to update the sort order of the table data
            setSortOption(type, !reverse);
        },
        [columnHeader, isColumnSelected, setSortOption, reverse],
    );

    // Return the state and functions to be used in the table
    return {
        columnHeader,
        handleSetSort,
        arrowType,
        isColumnSelected,
        handleHover,
    };
}
