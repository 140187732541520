import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import { Point } from "ol/geom";
import { FeatureUpdateFunction } from "stores/classes/Openlayers/utils/FeatureUpdateFunctions.util";
import { getDeviceStyle } from "./getDeviceStyle";
import transformDevicePosition from "./utilities/transformDevicePosition";
import getFeatureStatus from "stores/classes/Openlayers/utils/getFeatureStatus";

/**
 * Updates device feature's geometry. This can include coordinates. Also updates style.
 */
const updateDeviceFeature: FeatureUpdateFunction<
    Device | GroupedDevice | MobileDevice
> = async (device, map, state, existingFeature) => {
    const status = await getFeatureStatus("Device", device);

    const transformedPosition = transformDevicePosition(
        { x: device.position.x, y: device.position.y },
        map,
    );

    return {
        geometry: new Point(transformedPosition),
        properties: {
            ...device,
            featureType: "Device",
            layerName: "Device",
        },
        style: getDeviceStyle({ feature: existingFeature, status, state }),
    };
};

export default updateDeviceFeature;
