import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import EventLogsTable from "components/Tables/EventLogsTable/EventLogsTable";
import { memo, useMemo } from "react";
import DeviceNotification from "types/Notification.type";
import RelatedNotificationRowItem from "./RelatedNotificationRowItem";

interface RelatedNotificationsTableProps<T extends DeviceNotification> {
    items: T[];
}

export default memo(function RelatedNotificationsTableDisplay<
    T extends DeviceNotification,
>({ items }: RelatedNotificationsTableProps<T>): JSX.Element {
    const sorted = useMemo(() => {
        return items.sort((a, b) => {
            const timeA = new Date(a.event_time);
            const timeB = new Date(b.event_time);
            return timeB.getTime() - timeA.getTime();
        });
    }, [items]);

    return (
        <EventLogsTable>
            {sorted.map((item) => {
                return (
                    <RelatedNotificationRowItem
                        health={item.notification_type as HEALTH_STATUS}
                        timeStamp={item.event_time}
                        message={item.message}
                    />
                );
            })}
        </EventLogsTable>
    );
});
