import { CLASS_NAMES } from "styles/classNameConstants";

/**
 * Depending on the current tab and drawer state, updates the style of the tabs between active and inactive
 * @returns
 */
export default function baseClassNavigationTabs(
    currentTab: string | null,
    tabType: string,
    isDrawerOpen?: boolean,
    disabled?: boolean,
): string {
    const active =
        " text-obsidian shadow-inner-blue-sm sm:shadow-inner-blue bg-gradient-to-88 from-[#F8F8F8] via-[#E0DDDD] via-[88%] via-[#CBCBCB] via-[94%] to-[#C7C7C7]";
    const inactive = " text-[#626262] cursor-pointer hover:text-obsidian";
    if (disabled) {
        return CLASS_NAMES.iconTabs.disabled;
    }
    return (currentTab === tabType && isDrawerOpen) || currentTab === tabType
        ? active
        : inactive;
}
