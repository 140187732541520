import { FleetSort } from "./FleetColHeaders.data";
import FLEET_SORT_OPTIONS from "./FleetSortOptions.enum";

const zoneFleetColHeaders: FleetSort[] = [
    {
        title: "Name",
        type: FLEET_SORT_OPTIONS.NAME,
    },
    {
        title: "Location",
        type: FLEET_SORT_OPTIONS.LOCATION,
    },
];

export default zoneFleetColHeaders;
