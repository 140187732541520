import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import rootStore from "stores/rootStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import DeviceNotification from "types/Notification.type";
import { RelatedNotificationContentContainerProps } from "./RelatedNotifications.type";
import RelatedNotificationTableDisplay from "./RelatedNotificationTableDisplay";

export default observer(function RelatedNotificationContainer({
    notificationSrcIds,
}: RelatedNotificationContentContainerProps): JSX.Element {
    const relatedNotifications: DeviceNotification[] = useMemo(() => {
        return notificationSrcIds
            .map((id) =>
                rootStore.notificationStore.getNotificationsByDeviceId(id),
            )
            .flat();
    }, [notificationSrcIds, rootStore.notificationStore.notifications]);

    return (
        <div className="h-full flex flex-col">
            <div className={CLASS_NAMES.drawer.content.titleContainer}>
                <h2 className={CLASS_NAMES.drawer.content.title}>Alerts</h2>
            </div>
            <div className={CLASS_NAMES.drawer.logsContainer}>
                {relatedNotifications && relatedNotifications.length > 0 ? (
                    <RelatedNotificationTableDisplay
                        items={relatedNotifications}
                    />
                ) : (
                    <p className="py-4 px-12 h-min">
                        There are no alerts available at this time.
                    </p>
                )}
            </div>
        </div>
    );
});
