import { OccupancyOverride } from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
import { memo, useCallback } from "react";
import HourMinuteManager from "utils/HourMinuteManager";
import ZoneFormProps from "../ZoneSettingFormProps.props";
import InputField from "components/InputField/InputField";

const timeOptions: { label: string; key: keyof typeof SCHEDULE_SETTNGS }[] = [
    {
        label: "Start Time",
        key: "userDefinedStartTime",
    },
    {
        label: "End Time",
        key: "userDefinedEndTime",
    },
];

const SCHEDULE_SETTNGS: OccupancyOverride = {
    userDefinedStartTime: "",
    userDefinedEndTime: "",
};

export default memo(function ZoneOccupancy({
    errors,
    formData,
    setFormData,
}: ZoneFormProps): JSX.Element {
    const handleChange = useCallback(
        (key: keyof typeof SCHEDULE_SETTNGS, value: string): void => {
            const newForm = { ...formData };
            newForm.occupancy[key] = HourMinuteManager.toUTC(value);
            setFormData(newForm);
        },
        [formData],
    );

    return (
        <div className="grid grid-cols-12 gap-4">
            <div className="col-span-6">
                <h2 className="text-lg py-4 h-min">Zone Occupancy Settings</h2>
                <p className="pl-4 text-sm w-10">
                    Set Zone to Occupied Daily Between These Times
                </p>
            </div>
            <div className="col-span-6">
                <div className="flex flex-col pt-12">
                    {timeOptions.map(
                        ({ label, key }): JSX.Element => (
                            <InputField
                                label={label}
                                type="time"
                                key={key}
                                classes={{ label: "flex-1 my-2" }}
                                value={HourMinuteManager.toLocale(
                                    formData.occupancy[key],
                                )}
                                error={errors.occupancy[key]}
                                onChange={({ target: { value } }): void => {
                                    handleChange(key, value);
                                }}
                            />
                        ),
                    )}
                </div>

                <div className="w-full">
                    {errors.occupancy.other ? (
                        <p className="text-sm flex-end text-red">
                            {errors.occupancy.other}
                        </p>
                    ) : null}
                </div>
            </div>
        </div>
    );
});
