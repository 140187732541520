import { Coordinate } from "ol/coordinate";

/**
 *
 * @param shapes Takes an array of points that form to make a single or multiple polygons
 * @returns Points that should be top left of polygon
 */
export default function findTopLeftPoint(shapes: Coordinate[]): Coordinate {
    //Collect all y values and sort in least to greatest order
    const ys = shapes.map(([, y]) => y).sort();

    //Find the midpoint of the y values
    const midY = (ys[0] + ys[ys.length - 1]) / 2;

    //
    const topPoints = shapes.filter(([, y]) => y > midY);
    let topLeft = topPoints[0];
    for (const [x, y] of topPoints) {
        if (x < topLeft[x]) {
            topLeft = [x, y];
        } else if (x === topLeft[0] && y > topLeft[1]) {
            topLeft = [x, y];
        }
    }
    return topLeft as unknown as Coordinate;
}
