import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import Icon from "../Icon/Icon";
import SortProps from "./utilities/Sort.props";
import SORT_DIRECTION from "./utilities/SORT_DIRECTION.enum";

/**
 * FontAwesome Regular icon that displays a flipped icon based on given direction
 */
export default memo(function SortFlip({
    direction = SORT_DIRECTION.UP,
    className,
    style,
    size,
}: SortProps) {
    const rotation = useMemo(() => {
        // if (direction === SORT_DIRECTION.UP) {
        //     return "scale-y-flip";
        // }
        if (direction === SORT_DIRECTION.DOWN) {
            return "scale-y-[-1]";
        }

        return "";
    }, [direction]);

    return (
        <Icon
            name="sort-duotone"
            style={style}
            className={extendClassName(
                className || "",
                "transform " + rotation,
            )}
            size={size}
        />
    );
});
