import EventTimeCol from "components/Tables/Columns/EventTimeCol/EventTimeCol";
import setTableRowStyle from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/setTableRowStyleReduce";
import { memo, useCallback, useEffect, useReducer, useState } from "react";
import DeviceLog from "types/DeviceLog.type";
import EVENT_TYPE from "types/EVENT_TYPE";
import extendClassName from "utils/extendClassName";
import { initialLogState } from "utils/initialStates";

export default memo(function LogRowItem({
    event_id,
    event_time,
    message,
    event_type,
}: DeviceLog): JSX.Element {
    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialLogState,
    );

    const [isOpen, setIsOpen] = useState(false);

    const [deviceStatus, setDeviceStatus] = useState<EVENT_TYPE | "offline">();

    //Handles the opening and closing of the click-to-expand area
    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    useEffect(() => {
        const event = message.includes("offline")
            ? "offline"
            : (event_type as EVENT_TYPE);
        dispatch({ event_type: event });
    }, [event_type, message]);

    /**
     * Device status can change over time.
     */
    useEffect(() => {
        if (
            event_type === EVENT_TYPE.RECOMMENDATION ||
            event_type === EVENT_TYPE.WARNING
        ) {
            setDeviceStatus(EVENT_TYPE.WARNING);
            return;
        } else {
            const event = message.includes("offline")
                ? "offline"
                : (event_type as EVENT_TYPE);
            setDeviceStatus(event);
        }
    }, [event_type, message]);
    return (
        <>
            <tr
                className={extendClassName(
                    "text-sm bg-white border-1 border-gray-700 h-[70px] w-full",
                    tableRowStyle.background,
                )}
                onClick={(): void => handleToggle()}
                data-id={event_id}
                key={event_id}
            >
                <td
                    key="status"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 capitalize font-semibold",
                        tableRowStyle.border,
                        tableRowStyle.background,
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    {deviceStatus}
                </td>
                <td
                    key="message"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 cursor-pointer",
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    <div className=" truncate-2-lines">{message}</div>
                </td>

                <EventTimeCol eventTime={event_time} drawerIsOpen={isOpen} />
            </tr>
            {isOpen ? (
                <tr className="text-sm bg-white border-1 border-gray-700 h-[70px] w-full">
                    <td className="p-4 shadow-inner-md" colSpan={12}>
                        {message.length > 70 ? (
                            <p className="mb-1">{message}</p>
                        ) : (
                            <p>There are no additional messages.</p>
                        )}
                    </td>
                </tr>
            ) : null}
        </>
    );
});
