import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import Button from "components/Button/Button";
import { memo } from "react";

type AddRangeButtonProps = {
    addRange: (range: HEALTH_STATUS) => void;
    label: HEALTH_STATUS;
};

export default memo(function AddRangeButton({
    addRange,
    label,
}: AddRangeButtonProps) {
    return (
        <Button
            variant="contained"
            className="flex items-center justify-center mx-auto mt-4"
            type="button"
            onClick={(): void => {
                addRange(label);
            }}
        >
            <span className="whitespace-nowrap">Add {label}</span>
        </Button>
    );
});
