import * as Yup from "yup";
import healthStatusSchema from "./healthStatus.schema";
import operatingRangeSchema from "./operatingRange.schema";
import { multiRangeSchemaValidation } from "./utils.schema";
import StringMultiRangeUnitConversion from "types/StringMultiRangeUnitConversion";

const multiRangeSchema: Yup.ObjectSchema<StringMultiRangeUnitConversion> =
    Yup.object().shape({
        unsafe: multiRangeSchemaValidation,
        warning: multiRangeSchemaValidation,
        safe: multiRangeSchemaValidation,
        base: healthStatusSchema,
        baseUnits: Yup.string().required(),
        operatingRange: operatingRangeSchema,
    });

export default multiRangeSchema;
