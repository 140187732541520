import * as Yup from "yup";

export const validNumStrRegex = /^(-?[0-9]+([.][0-9]*)?|([.][0-9]+))?$|^$/;

/**
 * Validates a string to ensure it's a valid number.
 *
 * @example
 * numberStringSchema.validate("-123.456") // true
 * numberStringSchema.validate("1da")       // Throws "Must be a valid number"
 *
 * @type {Yup.StringSchema}
 */
export const numberStringSchema = Yup.string().test(
    "validNum",
    "Must be a valid number",
    (value, { createError, path }) => {
        if (value === undefined) {
            return createError({
                path: path,
                message: "Must be valid number",
            });
        }

        if (!validNumStrRegex.test(value)) {
            return createError({
                path: path,
                message: "Must be valid number",
            });
        }

        return true;
    },
);

/**
 * Ensures both min and max values are either filled or both empty.
 * Invalid if only one of them has a value.
 */
export const bothOrNone = Yup.array()
    .of(Yup.number().typeError("Please enter a valid number"))
    .length(2)
    .test("bothOrNone", "Both fields must be filled out", (arr) => {
        const [min, max] = arr || [];
        if (!min && !max) return false; // both are empty
        if (min && max) return false; // both are filled
        return true; // one is empty
    });

/**
 * For a given array of 2, ensures the values cannot be empty strings
 */
export const noEmptyStringsInArray = Yup.array()
    .of(numberStringSchema.required("This field cannot be empty"))
    .length(2)
    .required();

export const isMinEqualToMaxRange = noEmptyStringsInArray.test(
    "isMinEqualToMaxRange",
    (range) => `${range.path} does not have a valid range`,
    (arr, { createError, path }) => {
        const [min, max] = arr;
        const minValue = parseFloat(min);
        const maxValue = parseFloat(max);
        if (minValue === maxValue) {
            return createError({
                path: `${path}[0]`,
                message: "Min and max values cannot equal each other",
            });
        }
        return true;
    },
);

export const isMaxEqualToMinRange = noEmptyStringsInArray.test(
    "isMaxEqualToMinRange",
    (range) => `${range.path} does not have a valid range`,
    (arr, { createError, path }) => {
        const [min, max] = arr;
        const minValue = parseFloat(min);
        const maxValue = parseFloat(max);
        if (maxValue === minValue) {
            return createError({
                path: `${path}[1]`,
                message: "Min and max values cannot equal each other",
            });
        }
        return true;
    },
);

export const isMinMoreThanMax = noEmptyStringsInArray.test(
    "isMinMoreThanMax",
    (range) => `${range.path} does not have a valid range`,
    (arr, { createError, path }) => {
        const [min, max] = arr;
        const minValue = parseFloat(min);
        const maxValue = parseFloat(max);

        if (minValue > maxValue) {
            return createError({
                path: `${path}[0]`,
                message: "Minimum must be less than maximum",
            });
        }
        return true;
    },
);

export const isMaxLessThanMin = noEmptyStringsInArray.test(
    "isMaxLessThanMin",
    (range) => `${range.path} does not have a valid range`,
    (arr, { createError, path }) => {
        const [min, max] = arr;
        const minValue = parseFloat(min);
        const maxValue = parseFloat(max);

        if (maxValue < minValue) {
            return createError({
                path: `${path}[1]`,
                message: "Maximum must be more than minimum",
            });
        }
        return true;
    },
);

/**
 * Validates that the max value is not greater than the min value and vice versa.
 * This test deliberately involves redundancies in order to provide explicit user messaging.
 * Helps ensure proper range ordering.
 */
export const isValidRange = noEmptyStringsInArray
    .concat(isMinEqualToMaxRange)
    .concat(isMaxEqualToMinRange)
    .concat(isMinMoreThanMax)
    .concat(isMaxLessThanMin);

export const multiRangeSchemaValidation = Yup.array()
    .of(noEmptyStringsInArray)
    .of(isValidRange)
    .defined();
