import { action, computed, makeAutoObservable, observable } from "mobx";
import OrganizationService from "services/OrganizationService";
import DeviceNotification from "types/Notification.type";

/**
 * notification service handles the notification bad and notifications from cassandra
 */
export default class NotificationStore {
    //UPDATE: This has been updated to postgres
    /**
     * the notifications that we grabbed from cassandra
     * @see DeviceNotification
     */
    @observable
    public polledNotifications: DeviceNotification[] = [];

    /**
     * the state of the notification drawer
     */
    @observable
    public isNotificationDrawerOpen = false;

    @computed
    get notifications(): DeviceNotification[] {
        const combined = [...this.polledNotifications];
        const uniqueMap = new Map(
            combined.map((notification) => [notification.id, notification]),
        );
        return Array.from(uniqueMap.values());
    }

    @action
    public getNotificationsByDeviceId(deviceId: string): DeviceNotification[] {
        return this.notifications.filter(
            (notification) => notification.device_id === deviceId,
        );
    }

    /**
     * toggles open/close notification drawer
     */
    @action
    public toggleNotification = (): void => {
        this.isNotificationDrawerOpen = !this.isNotificationDrawerOpen;
    };

    /**
     * closes notification drawer
     *
     */
    @action
    public closeNotificationDrawer = (): void => {
        this.isNotificationDrawerOpen = false;
    };

    /**
     * removes a notification by its id from @see this.notifications
     */
    @action
    public removeNotification = (id: string): void => {
        const filteredPolled = this.polledNotifications.filter(
            (notification) => notification.id != id,
        );
        this.setPolledNotifications(filteredPolled);
    };

    /**
     * sets @see this.notifications as a whole
     * takes an array of @see DeviceNotification
     */
    @action
    public setPolledNotifications = (messages: DeviceNotification[]): void => {
        this.polledNotifications = messages;
    };

    /**
     * Immediate fetch that exists outside of react-query cache to manually set notifications in store.
     * @param orgId
     */
    @action
    public fetchNotifications = async (orgId: string): Promise<void> => {
        const notifications = await OrganizationService.getNotifications(orgId);
        this.setPolledNotifications(notifications);
    };
    /**
     * removes all notifications and sets notification to be the simple array
     */
    @action
    public clear = (): void => {
        this.polledNotifications = [];
    };

    public constructor() {
        makeAutoObservable(this);
    }
}
