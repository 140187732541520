import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import { Feature } from "ol";
import Point from "ol/geom/Point";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import { getDeviceStyle } from "./getDeviceStyle";
import transformDevicePosition from "./utilities/transformDevicePosition";

/**
 * Creates an OpenLayers feature for a given device.
 *
 * This function first transforms the position of the device to fit within the conventions
 * of the OpenLayers map and then creates a feature (https://srtlabs.atlassian.net/wiki/spaces/TD/pages/187498497/FAQ#Why-is-it-called-a-feature%3F) with that position. The entire device
 * object is attached as properties to the feature, allowing for easier styling and access
 * later on.
 *
 * @param {Device | GroupedDevice | MobileDevice} device - The device object containing position and other necessary information.
 * @param {MapDisplayDetails}  map - The map configuration used to transform the device's position.
 *
 * @returns {Feature} - The resulting OpenLayers feature with the transformed position and the
 *                      device's properties attached.
 */
export const createDeviceFeature = (
    device: Device | GroupedDevice | MobileDevice,
    map: MapDisplayDetails,
    state: FeatureInteractiveState,
): Feature => {
    const transformedPosition = transformDevicePosition(
        { x: device.position.x, y: device.position.y },
        map,
    );
    const feature = new Feature({
        geometry: new Point(transformedPosition),
    });

    const status = device.state.offline
        ? "offline"
        : device.deviceHealth.overAllHealth;

    //Specifically set the device id as the feature id for easier retrival
    feature.setId(device._id);
    // feature.setGeometryName(device.headers.deviceName); <--breaks display for some reason
    // Attach the entire device object to the feature for easier styling/access
    feature.setProperties(device);

    //Set custom featureType for ease of access
    feature.set("featureType", "Device");
    feature.set("layerName", "Device");

    const style = getDeviceStyle({ feature, status, state });
    feature.setStyle(style);
    return feature;
};
