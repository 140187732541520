/**
 * Exists solely for toggling ids in setParams. The idea is that it just compares
 * the passed id to the currently set id in Params and returns null if they match (which will close the true if "currentDevice" | "currentZone")
 * or returns the id (thus opening the drawer)
 * @param id
 * @param currentId
 * @returns
 */
export default function toggleSetParamsId(
    id: string | null,
    currentId: string | null,
): string | null {
    return id === currentId ? null : id;
}
