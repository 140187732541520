import { memo, useEffect, useRef, useState } from "react";
import type ZoneScheduleCalendarProps from "./utilities/ZoneScheduleCalendar.props";
import ZoneScheduleCalendarEventsAndRecomendations from "./ZoneScheduleCalendarEventsAndRecomendations/ZoneScheduleCalendarEventsAndRecomendations";
import ZoneScheduleCalendarTable from "./ZoneScheduleCalendarTable/ZoneScheduleCalendarTable";

export default memo(function ZoneScheduleCalendar({
    events,
}: ZoneScheduleCalendarProps): JSX.Element {
    const [currentTimeBarRef, setCurrentTimeBarRef] =
        useState<null | HTMLDivElement>(null);
    const [scrolled, setScrolled] = useState(false);
    const container = useRef<null | HTMLDivElement>(null);

    useEffect(() => {
        if (container) {
            if (currentTimeBarRef && !scrolled) {
                container.current?.scrollTo({
                    top:
                        currentTimeBarRef.offsetTop -
                        container.current.offsetHeight / 2, //This formula will always scroll to have the time reference line in the center of the container div
                    behavior: "smooth",
                });
                setScrolled(true);
            }
        } else {
            setScrolled(false); //If there is no container reference we need to reset the scroll feature.
        }
    }, [currentTimeBarRef, scrolled, container.current]);

    return (
        <div className="flex flex-col m-auto flex-1 h-0 overflow-y-hidden">
            <div
                ref={container}
                className="flex-1 overflow-y-scroll relative bg-gray-100 m-auto border-t-0 border-b-1 border-r-1 border-obsidian"
            >
                <ZoneScheduleCalendarTable />
                <ZoneScheduleCalendarEventsAndRecomendations
                    events={events}
                    setCurrentTimeBarRef={setCurrentTimeBarRef}
                />
            </div>
        </div>
    );
});
