import useToggleButtonGroup from "components/ToggleButtonGroup/useToggleButtonGroup";
import { ReactNode, memo } from "react";
import extendClassName from "utils/extendClassName";

interface ToggleButtonProps<T> {
    value: T;
    children: ReactNode;
}
// const disabledClassName =
//     "bg-gray-700 text-white font-semibold py-2 px-4 rounded-md";

export default memo(function ToggleButton<T>({
    value,
    children,
}: ToggleButtonProps<T>): JSX.Element {
    const { value: groupValue, onChange } = useToggleButtonGroup<T>();

    const isSelected = Array.isArray(groupValue)
        ? groupValue.includes(value)
        : groupValue === value;

    const handleClick = (): void => {
        onChange(value);
    };
    return (
        <button
            onClick={handleClick}
            className={extendClassName(
                "px-4 py-2 text-sm font-sans font-semibold",
                "border border-solid border-transparent",
                isSelected
                    ? "bg-cobalt text-white"
                    : "bg-white text-obsidian hover:border-cobalt hover:border-[1px] hover:text-cobalt hover:border-solid",
                "border-[1px] border-t border-b border-r border-gray-300",
                "first:rounded-l-md first:border-l last:rounded-r-md",
            )}
        >
            {children}
        </button>
    );
});
