import Spinner from "components/Icons/Spinner/Spinner";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import rootStore from "stores/rootStore";
import { CLASS_NAMES } from "styles/classNameConstants";
import ZoneDrawerContent from "./CurrentZoneDrawerContent/ZoneDrawerContent";
import CurrentZoneDrawerHeader from "./CurrentZoneDrawerHeader/CurrentZoneDrawerHeader";
import CurrentZoneDrawerTabs from "./CurrentZoneDrawerTabs/CurrentZoneDrawerTabs";
import CURRENT_ZONE_SIMPLE_TAB from "./utilities/CURRENT_ZONE_SIMPLE_TAB.enum";
import type ZoneSimpleDrawerProps from "./utilities/ZoneSimpleDrawer.props";

const zoneSimpleAsList = Object.values(CURRENT_ZONE_SIMPLE_TAB);

export default observer(function ZoneSimple({
    zone,
}: ZoneSimpleDrawerProps): JSX.Element {
    const { currentZone, currentDrawerTab } = useQuery();
    const [currentTab, setCurrentTab] = useState(
        CURRENT_ZONE_SIMPLE_TAB.STATUS,
    );

    /**
     * Handles whatever the default tab will be when the zone drawer is open.
     * Sets the last tab clicked by the user if available, otherwise the default tab
     * is @see {CURRENT_ZONE_SIMPLE_TAB.STATUS}
     * Recently added tab options should be added to @see {zoneSimpleAsList}
     */
    useEffect(() => {
        if (
            currentDrawerTab &&
            zoneSimpleAsList.includes(
                currentDrawerTab as CURRENT_ZONE_SIMPLE_TAB,
            ) &&
            currentZone
        ) {
            setCurrentTab(currentDrawerTab as CURRENT_ZONE_SIMPLE_TAB);
        } else {
            setCurrentTab(CURRENT_ZONE_SIMPLE_TAB.STATUS);
        }
    }, [currentDrawerTab, currentZone]);

    return (
        <>
            <CurrentZoneDrawerTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />

            <div className={CLASS_NAMES.drawer.overallContentWrapper}>
                {zone ? (
                    <div className={CLASS_NAMES.drawer.overallContentContainer}>
                        <CurrentZoneDrawerHeader
                            zone={zone}
                            currentTab={currentTab}
                            organizationStore={rootStore.organizationStore}
                        />

                        <div className="flex flex-col flex-1 overflow-y-auto h-full">
                            <ZoneDrawerContent
                                currentTab={currentTab}
                                zone={zone}
                            />
                        </div>
                    </div>
                ) : (
                    <>
                        <Spinner />
                        <p>Loading...</p>
                    </>
                )}
            </div>
        </>
    );
});
