import type IN_AND_OUT_OF_RANGE_TO_STRING_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/IN_AND_OUT_OF_RANGE_TO_STRING_DIMENSION/IN_AND_OUT_OF_RANGE_TO_STRING_DIMENSION.type";
import { memo, useCallback } from "react";
import type EditDrawerProps from "../utilities/EditDrawer.props";
import InputField from "components/InputField/InputField";

export default memo(function InAndOutOfRangeToStringEditDrawer({
    drawer,
    disabled,
    onChange,
    options,
}: EditDrawerProps<
    number,
    IN_AND_OUT_OF_RANGE_TO_STRING_DIMENSION
>): JSX.Element {
    const handleSliderChange = useCallback(
        (min: number, max: number): void => {
            if (options.inAndOutOfRangeToString) {
                onChange({
                    ...options,
                    inAndOutOfRangeToString: {
                        inside: options.inAndOutOfRangeToString!.inside,
                        outside: options.inAndOutOfRangeToString!.outside,
                        range: {
                            ...options.inAndOutOfRangeToString!.range,
                            safeMin: min,
                            safeMax: max,
                        },
                    },
                });
            }
        },
        [onChange, options],
    );

    const handleOperatingRangeChange = useCallback(
        (key: "rangeMin" | "rangeMax", value: number): void => {
            const copy = JSON.parse(JSON.stringify(options));
            copy.inAndOutOfRangeToString!.range[key] = value;

            onChange(copy);
        },
        [onChange],
    );

    const handleStringChange = useCallback(
        (key: "inside" | "outside", value: string): void => {
            const copy = JSON.parse(JSON.stringify(options));
            copy.inAndOutOfRangeToString![key] = value;

            onChange(copy);
        },
        [onChange],
    );

    return (
        <div className="flex flex-col w-full">
            <div className="flex flex-col w-full mt-2 mb-2">
                <span className="whitespace-nowrap text-sm">
                    Display Text for Ranges
                </span>

                <div className="flex w-full flex-row flex-wrap">
                    <InputField
                        value={options.inAndOutOfRangeToString!.inside}
                        type="string"
                        label="Inside Range Text"
                        classes={{ label: "flex-1" }}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleStringChange("inside", value)
                        }
                    />

                    <InputField
                        value={options.inAndOutOfRangeToString!.outside}
                        type="string"
                        label="Outside Range Text"
                        classes={{ label: "flex-1" }}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleStringChange("outside", value)
                        }
                    />
                </div>
            </div>

            <div className="flex flex-col w-full mt-2 mb-2">
                <span className="whitespace-nowrap text-sm">
                    Inside Range {drawer.unit ? `(${drawer.unit})` : null}
                </span>
                <div className="flex flex-row w-full">
                    <InputField
                        label="Inside Min"
                        type="number"
                        classes={{ label: "flex-1" }}
                        value={options.inAndOutOfRangeToString.range.safeMin}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleSliderChange(
                                Number(value),
                                options.inAndOutOfRangeToString.range.safeMax,
                            )
                        }
                    />
                    <InputField
                        label="Inside Max"
                        type="number"
                        classes={{ label: "flex-1" }}
                        value={options.inAndOutOfRangeToString.range.safeMax}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleSliderChange(
                                options.inAndOutOfRangeToString.range.safeMin,
                                Number(value),
                            )
                        }
                    />
                </div>
            </div>

            <div className="flex flex-col w-full mt-2">
                <span className="whitespace-nowrap text-sm">
                    Operating Range {drawer.unit ? `(${drawer.unit})` : null}
                </span>

                <div className="flex w-full flex-row flex-wrap">
                    <InputField
                        value={options.inAndOutOfRangeToString!.range.rangeMin}
                        type="number"
                        label="Min"
                        classes={{ label: "flex-1" }}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleOperatingRangeChange(
                                "rangeMin",
                                Number(value),
                            )
                        }
                    />

                    <InputField
                        value={options.inAndOutOfRangeToString!.range.rangeMax}
                        type="number"
                        label="Max"
                        classes={{ label: "flex-1" }}
                        disabled={disabled}
                        onChange={({ target: { value } }): void =>
                            handleOperatingRangeChange(
                                "rangeMax",
                                Number(value),
                            )
                        }
                    />
                </div>
            </div>
        </div>
    );
});
