import DeviceIcon from "components/DeviceIcon/DeviceIcon";
import { observer } from "mobx-react-lite";
import formatDate from "utils/formatDate";
import CurrentDeviceCardContentStatusDrawerItemHeader from "./CurrentDeviceCardContentStatusDrawerItemHeader/CurrentDeviceCardContentStatusDrawerItemHeader";
import CurrentDeviceCardContentStatusDrawerItemMetric from "./CurrentDeviceCardContentStatusDrawerItemMetric/CurrentDeviceCardContentStatusDrawerItemMetric";
import type CardContentStatusDrawerItemGenericMetricContainerProps from "./utilities/CardContentStatusDrawerItemGenericMetricContainer.props";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";

export default observer(
    function CardContentStatusDrawerItemGenericMetricContainer({
        device,
        drawer,
        metric,
    }: CardContentStatusDrawerItemGenericMetricContainerProps): JSX.Element {
        return (
            <div className="flex flex-row flex-1 bg-white">
                <div className="h-full grid grid-rows-2 px-2 pt-3">
                    <div className="grid grid-cols-2 h-[4em]">
                        <div className="col-span-1 flex justify-center items-center">
                            <div>
                                <DeviceIcon
                                    headers={device.headers}
                                    overallHealth={
                                        device.deviceHealth[
                                            drawer.referenceID
                                        ] as HEALTH_STATUS
                                    }
                                    offline={device.state.offline}
                                    image={drawer.image}
                                />
                            </div>
                        </div>
                        <div className="col-span-1">
                            <CurrentDeviceCardContentStatusDrawerItemHeader
                                value={String(metric.value)}
                                unit={drawer.unit}
                                type={drawer.type}
                            />
                        </div>
                    </div>
                    <span className="w-1/2 p-2 text-xs whitespace-wrap text-center text-ellipsis font-sans font-semibold">
                        {drawer.name}
                    </span>
                </div>

                <div className="flex flex-1 flex-col justify-between text-gray-900">
                    <div className="flex flex-1">
                        <CurrentDeviceCardContentStatusDrawerItemMetric
                            device={device}
                            metric={metric}
                            dimensions={device.state.dimensions}
                            drawer={drawer}
                        />
                    </div>

                    <span className="w-full p-2 text-xs whitespace-nowrap mx-auto text-center text-obsidian">
                        Updated: {formatDate(metric.updateDate)}
                    </span>
                </div>
            </div>
        );
    },
);
