import { Feature } from "ol";
import { Geometry } from "ol/geom";
import { Style } from "ol/style";
import { StyleFunction } from "ol/style/Style";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import FeatureType from "types/OpenLayers/FeatureType.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import updateDeviceFeature from "utils/OpenLayers/Features/Device/updateDeviceFeature";
import updateSublocationOverlayFeature from "utils/OpenLayers/Features/SublocationOverlay/updateSublocationOverlayFeature";
import updateZoneFeature from "utils/OpenLayers/Features/Zones/updateZoneFeature";

export type FeatureUpdateData = {
    geometry?: Geometry;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    properties?: { [key: string]: any };
    style?: Style | Style[] | StyleFunction;
};

export type FeatureUpdateFunction<T> = (
    device: T,
    map: MapDisplayDetails,
    state: FeatureInteractiveState,
    existingFeature: Feature<Geometry>,
) => FeatureUpdateData | Promise<FeatureUpdateData>;

/**
 * A collection of functions suitable for updating solely the feature's geometry (including its style) for openlayers features.
 */
const featureUpdateFunctions: Record<
    FeatureType,
    //Any for now until I can figure out how to type properly
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    FeatureUpdateFunction<any>
> = {
    Device: updateDeviceFeature,
    GroupedDevice: updateDeviceFeature,
    MobileDevice: updateDeviceFeature,
    Zone: updateZoneFeature,
    SublocationOverlay: updateSublocationOverlayFeature,
};

export default featureUpdateFunctions;
