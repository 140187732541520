import M1LogoTransparent from "components/Icons/M1LogoTransparent/M1LogoTransparent";
import Spinner from "components/Icons/Spinner/Spinner";
import { PropsWithChildren } from "react";

type M1LoadingTemplate = PropsWithChildren<{ loading: boolean }>;

export default function M1LoadingTemplate({
    children,
    loading = false,
}: M1LoadingTemplate): JSX.Element {
    return (
        <div className="w-screen h-full flex flex-col justify-center items-center bg-white text-[#3C3C3C] font-sans text-center">
            <div className="w-10 mb-4">
                <M1LogoTransparent />
            </div>
            {loading ? (
                <div className="my-4">
                    <Spinner variant="spinner-third" fill={"#A3ACB7"} />
                </div>
            ) : null}
            {children}
        </div>
    );
}
