import AnalyticsDashboardLinkLocation from "@srtlabs/m1_types/lib/Organization/Locations/Location/AnalyticsDashboardLink/AnalyticsDashboardLink.type";
import AnalyticsDashboardLink from "@srtlabs/m1_types/lib/Organization/Locations/Location/Sublocations/Sublocation/AnalyticsDashboardLink/AnalyticsDashboardLink.type";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { CLASS_NAMES } from "styles/classNameConstants";
import extendClassName from "utils/extendClassName";
import { orgIds } from "utils/orgIds";
import serialize from "utils/serialize";
import type AnalyticsProps from "./utilities/AnalyticsProps";
import DownloadButton from "./utilities/DownloadReport";

export default observer(function Analytics({
    organizationStore,
    setCurrentTab,
    setDrawerOpen,
    setDrawerItemClicked,
}: AnalyticsProps): JSX.Element {
    const { orgId, sublId, locId } = useQuery();
    const [searchParams] = useSearchParams();
    const navigate = useNavigate();
    const routeToAnalytics = useCallback(
        (params: Record<string, string>, link: string) => {
            setDrawerItemClicked(true);
            setDrawerOpen(false);
            setCurrentTab(null);
            if (orgId && orgIds.includes(orgId)) {
                window.open(link, "_blank")?.focus();
            } else {
                navigate(`/home/analytics?orgId=${orgId}&` + serialize(params));
            }
        },
        [navigate, orgId],
    );

    const analyticLinks = useMemo((): AnalyticsDashboardLink[] | null => {
        return (
            organizationStore.sublocation?.analyticsDashboardLinks
                .slice()
                .sort((a, b) => a.listOrderNumber - b.listOrderNumber) || null
        );
    }, [organizationStore.sublocation]);
    const analyticsAvailableLocation = useMemo(():
        | AnalyticsDashboardLinkLocation[]
        | null => {
        return (
            organizationStore.location?.analyticsDashboardLinks
                .slice()
                .sort((a, b) => a.listOrderNumber - b.listOrderNumber) || null
        );
    }, [organizationStore.location]);

    // Only show the temporary PDF download components on local/dev
    const devEnvironment =
        process.env.REACT_APP_WEB_SERVER_DOMAIN?.startsWith("local-") ||
        process.env.REACT_APP_WEB_SERVER_DOMAIN?.startsWith("dev-");

    return (
        <ul className="text-[#626262] text-base py-2">
            {analyticLinks ? (
                analyticLinks.map(({ name, link }) => (
                    <li
                        key={name + link}
                        data-title={name}
                        className={extendClassName(
                            CLASS_NAMES.listItem.leftNav.base,
                            CLASS_NAMES.listItem.leftNav.default,
                            searchParams.get("analyticId") === link
                                ? CLASS_NAMES.listItem.leftNav.active
                                : "",
                        )}
                        onClick={(): void =>
                            routeToAnalytics(
                                {
                                    locId: locId!,
                                    sublId: sublId!,
                                    analyticId: link!,
                                },
                                link,
                            )
                        }
                    >
                        <span
                            className={
                                searchParams.get("analyticId") === link
                                    ? "pl-[25px]"
                                    : ""
                            }
                        >
                            {name}
                        </span>
                    </li>
                ))
            ) : analyticsAvailableLocation ? (
                analyticsAvailableLocation.map(({ name, link }) => (
                    <li
                        key={name + link}
                        data-title={name}
                        className={extendClassName(
                            CLASS_NAMES.listItem.leftNav.base,
                            CLASS_NAMES.listItem.leftNav.default,
                            searchParams.get("analyticId") === link
                                ? CLASS_NAMES.listItem.leftNav.active
                                : "",
                        )}
                        onClick={(): void =>
                            routeToAnalytics(
                                {
                                    locId: locId!,
                                    analyticId: link!,
                                },
                                link,
                            )
                        }
                    >
                        <span
                            className={
                                searchParams.get("analyticId") === link
                                    ? "pl-2"
                                    : ""
                            }
                        >
                            {name}
                        </span>
                    </li>
                ))
            ) : (
                <li className="p-2 text-obsidian">
                    There are no links available.
                </li>
            )}
            {devEnvironment ? (
                <>
                    <DownloadButton
                        canvasID="f16edfe7-45c5-40c6-ad53-fc1c99e71e1b"
                        text="MDMC Report"
                        style={{
                            base: CLASS_NAMES.listItem.leftNav.base,
                            default: CLASS_NAMES.listItem.leftNav.default,
                            disabled: "text-gray-700",
                        }}
                    ></DownloadButton>
                    <DownloadButton
                        canvasID="7856e052-6263-4d83-91e7-28d52f13941d"
                        text="Elastic Sample Report"
                        style={{
                            base: CLASS_NAMES.listItem.leftNav.base,
                            default: CLASS_NAMES.listItem.leftNav.default,
                            disabled: "text-gray-700",
                        }}
                    ></DownloadButton>
                </>
            ) : null}
        </ul>
    );
});
