import LinearScale from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScale/LinearScale.type";
import StringLinearScale from "types/StringLinearScale";
/**
 * Assumes that format was validated by schema before conversion
 * We want to convert empty strings to null.
 * Empty strings should be falsy
 * @param scale StringLinearScale
 * @returns LinearScale
 */
export function convertLinearScaleToNumbers(
    scale: StringLinearScale,
): LinearScale {
    try {
        // Keys to convert, excluding the data type and units
        const keysToConvert: (keyof Omit<
            LinearScale,
            "scaledDataType" | "scaledUnits"
        >)[] = ["sourceMin", "sourceMax", "scaledMin", "scaledMax"];

        // Initialize the result with properties that don't need conversion
        const result: Partial<LinearScale> = {
            scaledDataType: scale.scaledDataType,
            scaledUnits: scale.scaledUnits,
        };

        // Convert and assign the numeric properties
        keysToConvert.forEach((key) => {
            if (scale[key]) {
                const newValue = parseFloat(scale[key]);
                result[key] = isNaN(newValue) ? null : newValue;
            } else {
                result[key] = null;
            }
        });

        return result as LinearScale;
    } catch (error) {
        console.error("could not convert all values to numbers");
        throw new Error("could not convert all values to numbers");
    }
}

/**
 * The opposite of @see convertLinearScaleToNumbersConverts. Converts a number based Linear Scale to a string based format for
 * for usage of a form, as casting is an unreliable method for validating values.
 * @param scale LinearScale
 * @returns StringLinearScale
 */
export function convertLinearScaleToString(
    scale: LinearScale,
): StringLinearScale {
    const keysToConvert: (keyof Omit<
        StringLinearScale,
        "scaledDataType" | "scaledUnits"
    >)[] = ["sourceMin", "sourceMax", "scaledMin", "scaledMax"];

    // Initialize the result with properties that don't need conversion
    const result: Partial<StringLinearScale> = {
        scaledDataType: scale.scaledDataType || "",
        scaledUnits: scale.scaledUnits || "",
    };

    keysToConvert.forEach((key) => {
        const value = scale[key];

        result[key] =
            value !== null && value !== undefined ? value.toString() : "";
    });

    return result as StringLinearScale;
}
