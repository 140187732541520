import ScaledSourceRangeItem from "./ScaledSourceRangeItem.type";

const scaledSourceRangeConfig: ScaledSourceRangeItem[] = [
    {
        title: "Scale Source Range",
        min: {
            label: "Source Min",
            fieldName: "sourceMin",
        },
        max: {
            label: "Source Max",
            fieldName: "sourceMax",
        },
    },
    {
        title: "Scaled Range",
        min: {
            label: "Scaled Min",
            fieldName: "scaledMin",
        },
        max: {
            label: "Scaled Max",
            fieldName: "scaledMax",
        },
    },
    {
        title: "Scaled Data",
        min: {
            label: "Data Type",
            fieldName: "scaledDataType",
        },
        max: {
            label: "Units",
            fieldName: "scaledUnits",
        },
    },
];

export default scaledSourceRangeConfig;
