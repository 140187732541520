import LEFT_DRAWER_TAB from "./LEFT_DRAWER_TAB.enum";
import type LEFT_DRAWER_TAB_VALUE_T from "./LEFT_DRAWER_TAB_VALUE_T.type";

const LEFT_DRAWER_TAB_VALUES: Readonly<LEFT_DRAWER_TAB_VALUE_T[]> =
    Object.freeze([
        {
            iconName: "hotel",
            label: "Locations",
            tabType: LEFT_DRAWER_TAB.LOCATIONS,
        },
        {
            iconName: "chart-line",
            label: "Analytics",
            tabType: LEFT_DRAWER_TAB.ANALYTICS,
        },
        {
            iconName: "clock-rotate-left",
            label: "Event Logs",
            tabType: LEFT_DRAWER_TAB.LOGS,
        },
        {
            iconName: "logout",
            label: "Log Out",
            tabType: LEFT_DRAWER_TAB.LOGOUT,
        },
    ]);

export default LEFT_DRAWER_TAB_VALUES;
