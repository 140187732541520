import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import type ButtonProps from "./utilities/Button.props";

const baseButtonClass =
    "font-nunito font-semibold text-[15px] rounded-[4px] py-2 px-4 w-auto mx-auto";

const containedClassName =
    "bg-cobalt drop-shadow-contained blur-sm text-white hover:bg-opacity-[90%] hover:drop-shadow-container-hover hover:blur-md px-[16px] py-[8px]";

const outlinedClassName =
    "bg-white drop-shadow-outline shadow-md blur-6 border-2 border-solid border-[#A3ACB7] text-cobalt hover:border-cobalt hover:bg-cobalt hover:bg-opacity-[4%]";

const disabledClassName = "bg-gray-200 text-gray-700";

const textClassName =
    "bg-white text-cobalt hover:bg-primary-lightGray hover:bg-opacity-25 focus:bg-primary-lightGray";
const disabledTextClassName = "bg-white text-gray-600";
const secondaryClassName = "bg-primary-lightGray text-cobalt";

export default memo(function Button({
    variant,
    ...props
}: ButtonProps): JSX.Element {
    const baseClass = useMemo(
        () =>
            extendClassName(
                baseButtonClass,
                variant === "contained"
                    ? containedClassName
                    : variant === "outlined"
                    ? outlinedClassName
                    : variant === "secondary"
                    ? secondaryClassName
                    : variant === "text"
                    ? textClassName
                    : "",
            ),
        [variant],
    );

    return (
        <button
            {...props}
            className={extendClassName(
                baseClass,
                `${
                    props.disabled && variant === "contained"
                        ? disabledClassName
                        : props.disabled && variant === "text"
                        ? disabledTextClassName
                        : ""
                }${props.className ? ` ${props.className}` : ""}`,
            )}
        />
    );
});
