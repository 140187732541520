/**
 * Spaghetti Code
 *
 * Temporary formatting done to kibana analytic link to force a fullscreen view
 * without headers when rendering the iframe.
 *
 * This should ideally be done in the backend and this function removed.
 */

//URLs that should not show any filters
const flagUrlsNoFilter = [
    "https://kibana.srtlabs.com/app/dashboards#/view/e8abc8b0-e596-11ec-91b2-150fc7417dae?_g=(filters%3A!())",
];

function addEmbedParameter(url: string): string {
    // EDGE CASE: This returns the original URL without modification if it does not contain both the `?`
    // and `g_` characters, or if the`?` character occurs after the `g_` character,
    // to avoid processing errors. We don't know what this URL is, best not to mess with it.
    const questionMarkIndex = url.indexOf("?");
    const gUnderscoreIndex = url.indexOf("_g");
    if (
        questionMarkIndex === -1 ||
        gUnderscoreIndex === -1 ||
        questionMarkIndex >= gUnderscoreIndex
    ) {
        return url;
    }

    const baseUrl = url.slice(0, questionMarkIndex);
    const paramsString = url.slice(questionMarkIndex + 1);

    const params = new URLSearchParams(paramsString);

    const gParam = params.get("_g") || "(filters%3A!())"; //Found in every url we've come across, good indicator of format
    // Check if URL is in flagUrlsNoFilter
    if (flagUrlsNoFilter.includes(url)) {
        params.set("embed", "true");
    } else {
        // For URLs with expected structure, always set these parameters
        params.set("embed", "true");
        params.set("show-time-filter", "true");
        params.set("show-query-input", "true");
        params.set("hide-filter-bar", "false");
    }

    let newUrl = `${baseUrl}?embed=true&_g=${gParam}`;

    if (!flagUrlsNoFilter.includes(url)) {
        newUrl +=
            "&show-time-filter=true&show-query-input=true&hide-filter-bar=false";
    }

    params.forEach((value, key) => {
        if (
            ![
                "embed",
                "show-time-filter",
                "show-query-input",
                "hide-filter-bar",
            ].includes(key)
        ) {
            newUrl += `&${key}=${value}`;
        }
    });

    return newUrl;
}

export default addEmbedParameter;
