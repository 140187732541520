import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import { Query, QueryKey } from "@tanstack/react-query";
import queryClient from "stores/queryClient";
import findProblemsWithZone from "./findProblemsWithZone.util";

interface PropagateDeviceUpdatesToZoneOnMapProps<ZoneWithOverallHealth> {
    zoneIds: string[];
    updateFeature: (
        id: string,
        updatedData: Partial<ZoneWithOverallHealth>,
    ) => void;
}

/**
 * Takes a list of zoneIds the device is related to, and updates their overall
 * on the map
 *
 * If the zoneIds array is empty, the function returns without doing anything.
 *
 * @param
 * @returns
 */
export default async function propagateDeviceUpdatesToZonesOnMap<
    ZoneWithOverallHealth,
>({
    zoneIds,
    updateFeature,
}: PropagateDeviceUpdatesToZoneOnMapProps<ZoneWithOverallHealth>): Promise<void> {
    if (zoneIds.length === 0) {
        console.warn("No ids found, stopped propagating");
        return;
    }
    //Creates a filter that matches active and stale query keys that match the pattern ["zone", zoneId]
    //with zoneId being whatever matches in zoneIds

    const filter = {
        predicate: (
            query: Query<unknown, Error, unknown, QueryKey>,
        ): boolean => {
            if (
                !Array.isArray(query.queryKey) ||
                query.queryKey[0] !== "zone"
            ) {
                return false;
            }
            const zoneId = query.queryKey[1];
            return zoneIds.includes(zoneId as string);
        },
    };

    //Retrieve all zone data from cache (this should also get the latest health data?)
    const zoneQueries = queryClient.getQueriesData(filter);

    zoneQueries.map(async ([key, data]) => {
        try {
            //Assuming data has the structure of Zone
            const zone = await findProblemsWithZone(data as unknown as Zone);
            queryClient.setQueryData(["zone", zone._id], zone);
            await updateFeature(zone._id, zone as ZoneWithOverallHealth);
        } catch (e) {
            // This can be triggered by a device subscription sending an update
            // before the zone document loads in but does not affect functionality
            console.error(
                `Error propagating device changes to zone ${key}: ${e}`,
            );
        }
    });
}
