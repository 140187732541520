import Keycloak from "keycloak-js";
import React, { useState } from "react";

export enum KeycloakAuthState {
    // we have not been logged in yet / we don't know if we are authorized or not
    UNKNOWN,
    // we are all good to go, if we are authorized we have a token,
    AUTHORIZED,
    // we are not good, either login failed, or something happened for login to fail
    NOT_AUTHORIZED,
}

/**
 * react hook for handling keycloak authentication,
 * uses these env variables
 * ```
 * clientId: String(process.env.REACT_APP_KEYCLOAK_CLIENT_ID),
 * realm: String(process.env.REACT_APP_KEYCLOAK_REALM),
 * url: String(process.env.REACT_APP_KEYCLOAK_URL),
 * ```
 *
 * for conneting to keycloak
 *
 * returns an object for checking the state of authentication, logging out, redirection to the login page,
 * if we are connecting, and the keycloak jwt token
 */
const useKeycloak = (): {
    initializationFailed: boolean;
    authState: KeycloakAuthState;
    token: string;
    loggedIn: boolean;
    logout: () => void;
    redirectToLogin: () => void;
    connectingToKeyCloak: boolean;
} => {
    /*
     // STATE
    */
    const [connectingToKeyCloak, setConnectingToKeyCloak] = useState(true);
    const [keycloak, setKeycloak] =
        React.useState<Keycloak.KeycloakInstance | null>(null);

    // was keycloak able to initialize itself, we default to true
    const [initializationFailed, setInitializationFailed] =
        React.useState(true);

    // the token from keycloak
    const [token, setToken] = React.useState("");
    // the authorization state, SEE "KeycloakAuthState"
    const [authState, setAuthState] = React.useState<KeycloakAuthState>(
        KeycloakAuthState.UNKNOWN,
    );
    /*-----------------------------------------------------------------
  //                         ON PAGE LOAD!                         //
  -----------------------------------------------------------------*/

    React.useEffect(() => {
        try {
            new Promise<void>((resolve, reject): void => {
                const k = Keycloak({
                    clientId: String(process.env.REACT_APP_KEYCLOAK_CLIENT_ID),
                    realm: String(process.env.REACT_APP_KEYCLOAK_REALM),
                    url: String(process.env.REACT_APP_KEYCLOAK_URL),
                });

                let resolved = false;
                let error = false;

                k.onReady = (): void => {
                    if (!error) {
                        resolved = true;
                        setConnectingToKeyCloak(false);
                        resolve();
                    }
                };

                setKeycloak(k);

                setTimeout((): void => {
                    if (!resolved) {
                        error = true;
                        reject();
                    }
                }, 10 * 1000);
            }).catch((): void => {
                console.error("failed to connect to keycloak, timeout");
                setConnectingToKeyCloak(false);
                setInitializationFailed(true);
            });
        } catch (e) {
            console.error(e);
            setInitializationFailed(true);
            setKeycloak(null);
        }
    }, []);

    /*-----------------------------------------------------------------
  //                   ON KEYCLOAK CHANGE, LOGIN                   //
  -----------------------------------------------------------------*/

    React.useEffect(() => {
        try {
            if (keycloak) {
                (async (): Promise<void> => {
                    const successful = await keycloak.init({
                        onLoad: "login-required",
                    });

                    if (!successful) {
                        setAuthState(KeycloakAuthState.NOT_AUTHORIZED);
                        setToken("");
                    } else {
                        await keycloak.loadUserInfo();
                        setAuthState(KeycloakAuthState.AUTHORIZED);
                        setToken("Bearer " + String(keycloak.token));
                    }
                })();
            }
        } catch (e) {
            console.error(e);
            setAuthState(KeycloakAuthState.UNKNOWN);
            setToken("");
            keycloak?.logout();
        }
    }, [keycloak]);

    React.useEffect(() => {
        try {
            if (keycloak) {
                keycloak.onTokenExpired = (): void => {
                    setToken("");
                    setAuthState(KeycloakAuthState.UNKNOWN);
                    keycloak.logout();
                    redirectToLogin();
                };
            }
        } catch (e) {
            console.error(e);
            setAuthState(KeycloakAuthState.UNKNOWN);
            setToken("");
            keycloak?.logout();
        }
    }, [keycloak]);

    const loggedIn = React.useMemo(
        () => Boolean(token && authState === KeycloakAuthState.AUTHORIZED),
        [authState, token],
    );

    const logout = React.useCallback(() => {
        keycloak?.logout();
    }, [keycloak]);

    const redirectToLogin = React.useCallback(
        () => keycloak?.login(),
        [keycloak],
    );

    return {
        initializationFailed,
        authState,
        token,
        loggedIn,
        logout,
        redirectToLogin,
        connectingToKeyCloak,
    };
};

export default useKeycloak;
