import { Fill, Stroke, Style } from "ol/style";
import zoneColorFamilies from "stores/classes/Openlayers/utils/zoneColorFamily.data";
import FeatureDisplayState from "types/OpenLayers/FeatureDisplayState";
import FeatureStyleSetterProps from "types/OpenLayers/FeatureStyleSetterProps";

export const getSublocationOverlayStyle = ({
    state = "default",
}: FeatureStyleSetterProps): Style[] => {
    const colorPicker = (
        state: keyof FeatureDisplayState,
    ): { background: string; border: string } => {
        return zoneColorFamilies["safe"][state];
    };

    const { background, border } = colorPicker(state);

    return [
        //Polygon Style
        new Style({
            stroke: new Stroke({
                color: border,
                width: 3,
            }),
            fill: new Fill({
                color: background,
            }),
        }),
    ];
};
