import CurrentTab from "components/TabListItem/CurrentTab.type";
import DEVICE_DRAWER_TABS from "./DEVICE_DRAWER_TABS.enum";
const DEVICE_DRAWER_TABS_VALUES: Readonly<CurrentTab<DEVICE_DRAWER_TABS>[]> =
    Object.freeze([
        {
            name: "Status",
            value: DEVICE_DRAWER_TABS.STATUS,
            icon: "gauge-low",
        },
        {
            name: "Info",
            value: DEVICE_DRAWER_TABS.INFO,
            icon: "circle-info",
        },
        {
            name: "History",
            value: DEVICE_DRAWER_TABS.EVENTS,
            icon: "clock-rotate-left",
        },
        {
            name: "Map",
            value: DEVICE_DRAWER_TABS.MAP,
            icon: "map",
        },
        {
            name: "Alerts",
            value: DEVICE_DRAWER_TABS.ALERTS,
            icon: "bell",
        },
        {
            name: "Settings",
            value: DEVICE_DRAWER_TABS.SETTINGS,
            icon: "gear",
        },
    ]);

export default DEVICE_DRAWER_TABS_VALUES;
