/* eslint-disable prettier/prettier */
const inchesToCentimeters = (x: number): number => x * 2.54;
const centimetersToInches = (x: number): number => x / 2.54;
const inchesToMillimeters = (x: number): number => x * 25.4;
const millimetersToInches = (x: number): number => x / 25.4;
const feetToMeters = (x: number): number => x * 0.3048;
const metersToFeet = (x: number): number => x / 0.3048;
const metersPerSecondToMilesPerHour = (x: number): number => x * 2.2369;
const milesPerHourToMetersPerSecond = (x: number): number => x / 2.2369;

/**
 * Conversions for length and derivatives of length, such as velocity and acceleration
 */
const lengthConversions = {
    m: {
        ft: metersToFeet,
    },
    "m/s": {
        mph: metersPerSecondToMilesPerHour,
        "ft/s": metersToFeet,
    },
    mph: {
        "m/s": milesPerHourToMetersPerSecond,
    },
    ft: {
        m: feetToMeters,
    },
    "ft/s": {
        "m/s": feetToMeters,
    },
    mm: {
        in: millimetersToInches,
    },
    "mm/s": {
        "in/s": millimetersToInches,
    },
    "mm/s²": {
        "in/s²": millimetersToInches,
    },
    cm: {
        in: centimetersToInches,
    },
    in: {
        mm: inchesToMillimeters,
        cm: inchesToCentimeters,
    },
    "in/s": {
        "mm/s": inchesToMillimeters,
    },
    "in/s²": {
        "mm/s²": inchesToMillimeters,
    },
};

export default lengthConversions;
