import ToggleButton from "components/ToggleButton/ToggleButton";
import ToggleButtonGroup from "components/ToggleButtonGroup/ToggleButtonGroup";
import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";

type ViewType = "Map" | "List";

export default observer(function ViewSelector() {
    const [view, setView] = useState<ViewType>("Map");
    const { setParams } = useQuery();
    const location = useLocation();
    const navigate = useNavigate();

    const switchView = useCallback(
        (data: ViewType): void => {
            setView(data);

            switch (data) {
                case "Map":
                    navigate({
                        pathname: "/home/maps",
                        search: location.search,
                    });
                    break;
                case "List":
                    setParams({ filter: "device" });
                    navigate({
                        pathname: "/home/fleet",
                        search: `${location.search}`,
                    });
                    break;
            }
        },
        [setView, location.search],
    );

    useEffect(() => {
        if (location.pathname.includes("maps")) {
            setView("Map");
        }
        if (location.pathname.includes("fleet")) {
            setView("List");
        }
    }, [setView, location.pathname]);

    return (
        <ToggleButtonGroup<ViewType> value={view} onChange={switchView}>
            <ToggleButton value="Map">Map</ToggleButton>
            <ToggleButton value="List">List</ToggleButton>
        </ToggleButtonGroup>
    );
});
