import { observer } from "mobx-react-lite";
import LocationMapContainer from "./LocationMapContainer/LocationMapContainer";
import SublocationMapContainer from "./SublocationMapContainer/SublocationMapContainer";
import type MapsProps from "./utilities/Maps.props";

export default observer(function Maps({
    organizationStore,
}: MapsProps): JSX.Element {
    if (organizationStore.sublocation) {
        return <SublocationMapContainer />;
    }

    if (organizationStore.location) {
        return <LocationMapContainer />;
    }

    return (
        <div className="flex flex-1 flex-col items-center h-full w-full">
            <h1 className="text-sm">Please Select a location</h1>
        </div>
    );
});
