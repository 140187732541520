import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import LocationMapOverlayDetails from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetails.type";
import OpenLayersStore from "stores/classes/Openlayers/OpenLayersStore.class";
import LayerNames from "types/OpenLayers/LayerNames.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";

type LocationMapUtilsDependencies = {
    openLayersStore: OpenLayersStore;
    data: LocationMapOverlayDetails | ZoneWithOverallHealth[];
    mapViewMode: MAP_VIEW_TYPE;
    map: MapDisplayDetails;
};

export const updateLocationMapFeatures = async ({
    openLayersStore,
    data,
    mapViewMode,
    map,
}: LocationMapUtilsDependencies): Promise<void> => {
    try {
        // Remove features that no longer exist in the data
        //TODO: does not handle changes in data? This should be the responsibility of a
        removeObsoleteLocationMapFeatures(openLayersStore, data, mapViewMode);

        // Create or update features based on the mapViewMode
        await createOrUpdateLocationMapFeatures(
            openLayersStore,
            data,
            mapViewMode,
            map,
        );

        // Add features to layers
        openLayersStore.addFeaturesToLayers(
            mapViewMode as LayerNames,
            openLayersStore.features,
        );
    } catch (error) {
        console.error("Unable to complete map update", error);
    }
};

const removeObsoleteLocationMapFeatures = (
    openLayersStore: OpenLayersStore,
    data: LocationMapOverlayDetails | ZoneWithOverallHealth[],
    mapViewMode: MAP_VIEW_TYPE,
): void => {
    const existingFeatureIds = Array.from(openLayersStore.features.keys());
    const newFeatureIds = data.map((item) =>
        mapViewMode == MAP_VIEW_TYPE.SUBLOCATION_OVERLAY
            ? (item as LocationMapOverlayDetail).sub_location_id
            : (item as ZoneWithOverallHealth)._id,
    );
    const featuresToRemove = existingFeatureIds.filter(
        (id) => !newFeatureIds.includes(id),
    );

    featuresToRemove.forEach((id) => {
        openLayersStore.featureManager?.removeFeature(id);
    });
};

// const removeObsoleteFeatures = (
//     openLayersStore: OpenLayersStore,
//     data: Device[] | ZoneWithOverallHealth[],
// ): void => {
//     const existingFeatures = Array.from(openLayersStore.features.values());

//     existingFeatures.forEach((feature) => {
//         let updatedFeature: Device | ZoneWithOverallHealth | undefined;

//         if (
//             feature.type === "Device" ||
//             feature.type === "GroupedDevice" ||
//             feature.type === "MobileDevice"
//         ) {
//             updatedFeature = (data as Device[]).find(
//                 (item) => item._id === feature._id,
//             );
//         } else if (feature.type === "Zone") {
//             updatedFeature = (data as ZoneWithOverallHealth[]).find(
//                 (item) => item._id === feature._id,
//             );
//         }
//         if (updatedFeature) {
//             //If the feature exists in updated data
//             //Check if the health status has changed

//             if (checkIfFeatureHealthChanged(feature, updatedFeature)) {
//                 //update the feature in the store
//                 openLayersStore.updateFeature(feature._id, updatedFeature);
//             }
//         } else {
//             openLayersStore.featureManager?.removeFeature(feature._id);
//         }
//     });
// };

const createOrUpdateLocationMapFeatures = async (
    openLayersStore: OpenLayersStore,
    data: LocationMapOverlayDetails | ZoneWithOverallHealth[],
    mapViewMode: MAP_VIEW_TYPE,
    map: MapDisplayDetails,
): Promise<void> => {
    try {
        if (!openLayersStore.featureManager)
            throw new Error("Feature manager not initialized");
        const featurePromises = data.map(async (item) => {
            const id =
                mapViewMode == MAP_VIEW_TYPE.SUBLOCATION_OVERLAY
                    ? (item as LocationMapOverlayDetail).sub_location_id
                    : (item as ZoneWithOverallHealth)._id;
            const existingFeature =
                openLayersStore.featureManager?.getFeatureById(id);

            if (existingFeature) {
                // Update the OpenLayers feature if it exists already
                openLayersStore.updateFeature(existingFeature._id, item);
            } else {
                if (mapViewMode === MAP_VIEW_TYPE.SUBLOCATION_OVERLAY) {
                    // Add the new feature to the store
                    await openLayersStore.featureManager?.createAndAddOverlayFeatureToStore(
                        mapViewMode,
                        item as LocationMapOverlayDetail,
                        map,
                    );
                } else if (mapViewMode === MAP_VIEW_TYPE.ZONE) {
                    await openLayersStore.featureManager?.createAndAddFeatureToStore(
                        mapViewMode,
                        item as ZoneWithOverallHealth,
                        map,
                    );
                }
            }
        });

        // Wait for all feature creation and update promises to resolve
        await Promise.all(featurePromises);
    } catch (error) {
        console.error(
            "Could not create or update location map features",
            error,
        );
        throw new Error("Could not create or update location map features");
    }
};
