import { memo } from "react";
import type ZoneScheduleCalendarTableTimeDataProps from "./utilities/ZoneScheduleCalendarTableTimeData.props";

export default memo(function ZoneScheduleCalendarTableTimeData({
    time,
}: ZoneScheduleCalendarTableTimeDataProps): JSX.Element {
    return (
        <td className="h-4 relative w-10 px-8 text-gray-700">
            <span className="absolute -top-2.5 pl-1 uppercase ">{time}</span>
            <div
                className="ml-auto border-t-1 border-gray-700 absolute right-0 "
                style={{
                    width: "0.75rem",
                    top: "-0.05rem",
                }}
            />
        </td>
    );
});
