import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import {
    UseQueryOptions,
    UseQueryResult,
    useQuery,
} from "@tanstack/react-query";
import ZoneService from "services/ZoneService";

interface UseZoneProps
    extends Omit<UseQueryOptions<Zone | null, Error>, "queryFn" | "queryKey"> {
    zoneId: string | null;
}

function useZone({
    zoneId,
    ...rest
}: UseZoneProps): UseQueryResult<Zone | null, Error> {
    return useQuery<Zone | null, Error>({
        ...rest,
        queryKey: ["zone", zoneId],
        queryFn: async (): Promise<Zone | null> => {
            if (!zoneId || zoneId === "") return Promise.resolve(null);
            return ZoneService.getZone(zoneId);
        },
        enabled: !!zoneId,
    });
}

export default useZone;
