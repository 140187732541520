import type DeviceStateDimensions from "@srtlabs/m1_types/lib/Device/DeviceState/DeviceStateDimensions/DeviceStateDimensions.type";
import useSaveDevice from "hooks/useSaveDevice.hook";
import { memo, useCallback, useEffect, useMemo, useState } from "react";
import isEditableType from "utils/isEditableType";
import CurrentDeviceCardContentEditController from "./CurrentDeviceCardContentEditController/CurrentDeviceCardContentEditController";
import CurrentDeviceCardContentEditDimensions from "./CurrentDeviceCardContentEditDimensions/CurrentDeviceCardContentEditDimensions";
import type CurrentDeviceCardContentEditProps from "./utilities/CurrentDeviceCardContentEdit.props";
import DeviceDisplayDrawerContent from "@srtlabs/m1_types/lib/Device/DeviceDisplay/DeviceDisplayDrawerContent/DeviceDisplayDrawerContent.type";

export default memo(function CurrentDeviceCardContentEdit({
    device,
}: CurrentDeviceCardContentEditProps): JSX.Element {
    const { mutate: saveDevice } = useSaveDevice(device);

    const [editing, setEditing] = useState(false);

    const [editableDimensions, setEditableDimensions] =
        useState<DeviceStateDimensions>({});

    const resetEditableDimensions = useCallback((): void => {
        setEditableDimensions(
            device.display.drawerContent
                .filter(({ type }): boolean => isEditableType(type))
                .reduce((dimensions, drawerItem): DeviceStateDimensions => {
                    dimensions[drawerItem.referenceID] =
                        device.state.dimensions[drawerItem.referenceID];

                    return dimensions;
                }, {} as DeviceStateDimensions),
        );
    }, [device.state.dimensions, device.display.drawerContent]);

    const [editableDrawers, setEditableDrawers] =
        useState<DeviceDisplayDrawerContent>([]);

    const resetEditableDrawers = useCallback((): void => {
        setEditableDrawers(device.display.drawerContent);
    }, [device.display.drawerContent]);

    useEffect(() => {
        if (!editing) {
            resetEditableDimensions();
            resetEditableDrawers();
        }
    }, [editing, device]);

    const [saving, setSaving] = useState(false);
    const [error, setError] = useState("");

    /**
     * Used for submitting form data for the multitude of display types that exist
     * as forms for devices. Does not provide validation so this must be done at the
     * indivdual form/component level.
     */
    const save = useCallback(async (): Promise<void> => {
        if (saving) return;
        setError("");

        try {
            setSaving(true);
            // console.log(
            //     "DEBUGGING!",
            //     toJS({
            //         dimensions: {
            //             ...device.state.dimensions,
            //             ...editableDimensions,
            //         },
            //     }),
            // );
            await saveDevice({
                ...device,
                display: {
                    ...device.display,
                    drawerContent: editableDrawers,
                },
                state: {
                    ...device.state,
                    dimensions: {
                        ...device.state.dimensions,
                        ...editableDimensions,
                    },
                },
            });
        } catch (e) {
            setError(String(e));
        } finally {
            setSaving(false);
        }
    }, [saving, editableDimensions, editableDrawers, device]);

    const [errors, setErrors] = useState<Record<string, boolean>>({});

    const setHasErrors = useCallback(
        (dimensionKey: string, bool: boolean): void => {
            setErrors((prevErrors) => ({
                ...prevErrors,
                [dimensionKey]: bool,
            }));
        },
        [],
    );
    const saveable = useMemo(
        (): boolean =>
            Object.values(errors).every((hasErrors): boolean => !hasErrors),
        [errors],
    );

    const isDisabled = useMemo(() => {
        return Object.entries(editableDimensions).length === 0;
    }, [editableDimensions]);

    return (
        <div className="flex flex-1 flex-col h-full">
            <CurrentDeviceCardContentEditDimensions
                dimensions={editableDimensions}
                drawers={editableDrawers}
                device={device}
                disabled={!editing || saving}
                updateEditable={setEditableDimensions}
                updateDrawers={setEditableDrawers}
                setHasErrors={setHasErrors}
            />

            <CurrentDeviceCardContentEditController
                saving={saving}
                saveable={saveable}
                save={async (): Promise<void> => {
                    setEditing(false);
                    await save();
                }}
                error={error}
                editing={editing}
                setEditing={(): void => setEditing(true)}
                onCancel={(): void => setEditing(!editing)}
                disabled={isDisabled}
                text={{
                    cancel: "Cancel",
                    saveText: "Save",
                    edit: "Edit Threshold",
                }}
            />
        </div>
    );
});
