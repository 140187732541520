import { useMemo, useState } from "react";
import sortBy, { SortMeasure } from "utils/sort";
import NOTIFICATION_SORT_OPTIONS from "views/Home/NotificationContainer/NotificationTableHeader/util/NOTIFICATION_SORT_OPTION.enum";
import NotificationTableColumn from "views/Home/NotificationContainer/NotificationTableHeader/util/NotificationTableColumn.type";
import TableNotificationItem from "views/Home/NotificationContainer/NotificationTableHeader/util/TableNotificationItem.type";
import notificationSortOptionsData from "views/Home/NotificationContainer/NotificationTableHeader/util/notificationSortOptionsData.data";

const useNotificationSort = (
    notifications: TableNotificationItem[],
): {
    sorted: TableNotificationItem[];
    setSortOption: (option: NOTIFICATION_SORT_OPTIONS) => void;
    reverse: boolean;
    setReverse: (reverse: boolean) => void;
    currentOption: NotificationTableColumn;
} => {
    const [sortOption, setSortOption] = useState<NOTIFICATION_SORT_OPTIONS>(
        NOTIFICATION_SORT_OPTIONS.TIME,
    );
    const [reverse, setReverse] = useState<boolean>(true);

    const currentOption = useMemo<NotificationTableColumn>(
        () =>
            notificationSortOptionsData.find(
                (option) => option.type === sortOption,
            ) ?? notificationSortOptionsData[0],
        [sortOption],
    );

    const sorted = useMemo<TableNotificationItem[]>(() => {
        const sortFn = sortBy(
            currentOption.sort.path,
            currentOption.sort.measure as SortMeasure<unknown>,
        );

        const temp = [...notifications].sort(sortFn);

        if (reverse) {
            return temp.reverse();
        }
        return temp;
    }, [notifications, currentOption, reverse]);

    const value = {
        sorted,
        setSortOption,
        reverse,
        setReverse,
        currentOption,
    };

    return value;
};

export default useNotificationSort;
