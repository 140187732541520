import dayjs from "dayjs";
import type { OccupancyTimeFrame } from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
import type CalendarEvent from "./CalendarEvent.type";
import CalendarEventType from "./CalendarEventType.enum";
import { CALENDAR_RESOURCES_OCCUPANCY } from "./CALENDAR_RESOURCES.values";

export default function occupiedToCalendar({
    startTime,
    endTime,
}: OccupancyTimeFrame): CalendarEvent {
    return {
        start: dayjs(new Date(startTime)),
        end: dayjs(new Date(endTime)),
        title: "Occupancy Detected",
        resourceId: CALENDAR_RESOURCES_OCCUPANCY.resourceId,
        type: CalendarEventType.OCCUPANCY_DETECTED,
        occupied: true,
    };
}
