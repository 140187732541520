interface IconMap {
    [key: string]: string;
}

const iconMap: IconMap = {
    "angle-left": "angle-left.svg",
    "angle-regular-up": "angle-regular-up.svg",
    "angle-right": "angle-right.svg",
    "arrow-down": "arrow-down.svg",
    "arrow-regular-up": "arrow-regular-up.svg",
    "list-timeline": "list-timeline.svg",
    logout: "arrow-right-from-bracket.svg",
    "arrow-up": "arrow-up.svg",
    bell: "bell.svg",
    calendar: "calendar.svg",
    "caret-down": "caret-down.svg",
    "chart-line": "chart-line.svg",
    "chevron-down": "chevron-down.svg",
    "chevron-right": "chevron-right.svg",
    "chevron-up": "chevron-up.svg",
    "circle-info": "circle-info.svg",
    clock: "clock.svg",
    "clock-rotate-left": "clock-rotate-left.svg",
    "gauge-low": "gauge-low.svg",
    "error-circle-outline": "error-exclamation.svg",
    gear: "gear.svg",
    hotel: "hotel.svg",
    map: "map.svg",
    minus: "minus.svg",
    plus: "plus.svg",
    "reactangle-list": "retangle-list.svg",
    sliders: "sliders.svg",
    "sort-duotone": "sort-duotone.svg",
    spinner: "spinner.svg",
    "spinner-third": "spinner-third.svg",
    x: "x.svg",
    xmark: "xmark.svg",
};

export default iconMap;
