import Axios, { AxiosError, AxiosResponse } from "axios";
import rootStore from "stores/rootStore";

/**
 * the request object, controls options making an axios call to the web server
 */
type RequestOptions = {
    method?: "GET" | "POST" | "PUT" | "DELETE" | "PATCH";
    data?: unknown;
    headers?: Record<string, string>;
};

/**
 * the base web url, uses the .env to build a string for connecting to the webserver
 * example:
 * https://dev-kibana.srtlabs.com
 */
export const BASE_WEB_URL = `${
    process.env.REACT_APP_MAKE_SECURE_CONNECTION === "true"
        ? "https://"
        : "http://"
}${process.env.REACT_APP_KIBANA_DOMAIN}`;

class KibanaService {
    /**
     * makes an axios request using a url, @see RequestOptions, and any other options you want to pass to axios
     *
     * if we fail a call due to a 403 error, log the userout
     *
     * @param url - the endpoint example: /app/kibana
     */
    public async request<T>(
        url: string,
        { method = "GET", data, headers = {} }: RequestOptions = {
            method: "GET",
            data: {},
            headers: {},
        },
        other: Record<string, string> = {},
    ): Promise<AxiosResponse<T>> {
        try {
            return await Axios({
                url: BASE_WEB_URL + url, //dev-kibana.srtlabs.com
                method,
                data,
                withCredentials: true, // Allows cross-site Access-Control requests to be made using cookies, authorization headers or TLS client certificates.
                headers: {
                    Authorization: rootStore.apiStore.token, //for jwt token
                    credentials: "include", //request to include cookies in the response headers to set locally.
                    ...headers,
                },
                ...other,
            });
        } catch (err) {
            console.error(err);

            if ((err as AxiosError)?.response?.status === 403) {
                // rootStore.userStore.logout();
            }

            throw err;
        }
    }
}

export default new KibanaService();
