import useQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { Coordinate } from "ol/coordinate";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import rootStore from "stores/rootStore";
import SublocationCanvasContainerProps from "types/OpenLayers/SublocationCanvasContainerProps.type";
import useUpdateMapSize from "utils/OpenLayers/utilities/useUpdateMapSize";
import toggleSetParamsId from "utils/toggleSetParamsId";
import MapController from "../MapController/MapController";
import MapToolTip from "../MapToolTip/MapToolTip";
import checkIsMapIsReady from "../utilities/checkIfMapIsReady";
import featureToParamMap from "../utilities/featureToParamMap";
import useMapOnHoverListener from "../utilities/useMapHoverListener";
import useMapOnClickHandler from "../utilities/useMapOnClickHandler";
import {
    createLayersIfNeeded,
    updateSublocationMapFeatures,
} from "../utilities/useMapSetup";
import useUpdateFeatureOnExternalTrigger from "../utilities/useUpdateFeatureOnExternalTrigger";
export default observer(function SublocationMapCanvasContainer({
    imageSrc,
    dimensions,
    isImageLoading,
    map,
    mapViewMode,
    data,
}: SublocationCanvasContainerProps) {
    const openLayersStore = rootStore.openLayersStore;
    const mapRef = useRef<HTMLDivElement | null>(null);
    const tooltipRef = useRef<HTMLDivElement | null>(null);
    const [tooltip, setTooltip] = useState({
        visible: false,
        content: "",
        coordinate: [0, 0] as Coordinate,
    });

    const { locId, sublId, currentDevice, currentZone, setParams } = useQuery();

    useUpdateMapSize(openLayersStore.map);

    useMapOnClickHandler({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        clickedOnFeature: (feature): void => {
            const param = featureToParamMap[feature.type];
            if (param === "currentDevice") {
                setParams({
                    currentDevice: toggleSetParamsId(
                        feature._id,
                        currentDevice,
                    ),
                    currentZone: null,
                });
            } else if (param === "currentZone") {
                setParams({
                    currentDevice: null,
                    currentZone: toggleSetParamsId(feature._id, currentZone),
                });
            }
        },
        clickedOnMap: () => {
            //Close both drawers if clicking on the map
            setParams({
                currentDevice: null,
                currentZone: null,
                currentDrawerTab: null,
            });
        },
    });

    useMapOnHoverListener({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        mapViewMode,
        onHoverIn: ({ coordinate, content }) =>
            setTooltip({
                visible: true,
                coordinate,
                content,
            }),
        onHoverOut: () => setTooltip(tooltip),
    });

    /**
     * If currentDevice or currentZone is available, we can assume the drawer is open and
     * trigger the relevant feature to update its style
     */
    const isDrawerTriggered = useMemo(
        () => Boolean(currentDevice || currentZone),
        [currentDevice, currentZone],
    );

    useUpdateFeatureOnExternalTrigger({
        featureManager: openLayersStore.featureManager,
        map: openLayersStore.map,
        trigger: {
            active: isDrawerTriggered,
            featureId: currentDevice || currentZone,
            newState: "selected",
        },
    });

    // Utility function to handle the setting up and initialization of the OpenLayers map
    const initializeOpenLayersMap = useCallback(() => {
        try {
            if (!mapRef.current) throw new Error("map ref not present");
            const extent = [0, 0, dimensions.width, dimensions.height];
            openLayersStore.setMapImageLayer(imageSrc, extent);
            openLayersStore.setMapDisplayDetails(map);
            openLayersStore.initializeSublocationMap(
                mapRef.current,
                extent,
                map.defaultZoomPercent,
                map.defaultPanXPercent,
                map.defaultPanYPercent,
            );
        } catch (err) {
            console.error(err);
        }
    }, [dimensions, isImageLoading, mapRef.current, imageSrc, map]);

    const cleanUp = (): void => {
        openLayersStore.resetMap();
    };

    const renderMapState = useCallback((): JSX.Element => {
        if (openLayersStore.isLoading || !openLayersStore.map) {
            return <span>Initializing map...</span>;
        } else {
            return (
                <>
                    {/* Other map-related components */}
                    <MapToolTip
                        toolTipRef={tooltipRef}
                        visible={tooltip.visible}
                        coordinate={tooltip.coordinate}
                        content={tooltip.content}
                    />
                    <MapController
                        zoomIn={openLayersStore.zoomIn}
                        zoomOut={openLayersStore.zoomOut}
                        isAtMaxZoom={openLayersStore.isAtMaxZoom}
                        isAtMinZoom={openLayersStore.isAtMinZoom}
                    />
                </>
            );
        }
    }, [openLayersStore.isLoading, tooltip]);

    /**
     * Initializes the map and subsequent layer and feature managers when the trigger effect changes. This will allow us to
     * manipulate the map
     */
    useEffect(() => {
        if (!openLayersStore.map) initializeOpenLayersMap();
        return (): void => cleanUp();
    }, [locId, sublId]);

    /**
     * Once the map is created, intializes the layers and features for the decisive map.
     */
    useEffect(() => {
        checkIsMapIsReady(data, openLayersStore.map);

        // Create layers if they don't exist
        createLayersIfNeeded(openLayersStore, mapViewMode);

        // Update features based on the mapViewMode and data
        updateSublocationMapFeatures({
            openLayersStore,
            data,
            mapViewMode,
            map,
        });
    }, [
        openLayersStore.map,
        openLayersStore.areLayersPresent,
        mapViewMode,
        data,
    ]);

    useEffect(() => {
        if (currentDevice || currentZone) {
            openLayersStore.disablePan();
            openLayersStore.disableZoom();
        } else {
            openLayersStore.enableZoom();
            openLayersStore.enablePan();
        }
    }, [currentDevice, currentZone, openLayersStore.map]);

    return (
        <div
            id="map-canvas-container"
            ref={mapRef}
            className="flex-1 relative w-full h-full has-ignoreClass-device-drawer has-ignoreClass-zone-drawer"
        >
            <div className="absolute inset-0 flex flex-1 h-full items-center justify-center">
                {renderMapState()}
            </div>
        </div>
    );
});
