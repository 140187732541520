import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import { LOG_TYPE } from "../../ZoneLogs";
import RowStyleT from "./RowStyle.props";

export default function setTableRowStyle(
    tableRowStyle: RowStyleT,
    {
        type,
        health,
    }: { health: HEALTH_STATUS | null | "offline"; type: LOG_TYPE },
): RowStyleT {
    if (type === LOG_TYPE.ZONE) {
        return {
            background: "bg-lighter-warning",
            border: "shadow-inner-warning",
        };
    }
    switch (health) {
        case HEALTH_STATUS.WARNING:
            return {
                ...tableRowStyle,
                background: "bg-lighter-warning",
                border: "shadow-inner-warning",
            };
        case HEALTH_STATUS.UNSAFE:
            return {
                ...tableRowStyle,
                background: "bg-lighter-unsafe",
                border: "shadow-inner-unsafe",
            };
        case HEALTH_STATUS.SAFE:
            return {
                ...tableRowStyle,
                background: "bg-white",
                border: "shadow-inner-safe",
            };
        default:
            return {
                ...tableRowStyle,
                border: "shadow-inner-offline",
            };
    }
}
