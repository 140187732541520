import { memo } from "react";

export default memo(function Logs(): JSX.Element {
    return (
        <div>
            {[0, 1].map(
                (num): JSX.Element => (
                    <h1> Logs {num} </h1>
                ),
            )}
        </div>
    );
});
