import { useCallback, useEffect, useMemo } from "react";
import { useLocation, useNavigate, useSearchParams } from "react-router-dom";
import rootStore from "stores/rootStore";
import cleanObj from "utils/cleanObj";
import UpdateURLQuery, {
    UpdateURLQueryValues,
} from "./utilities/UpdateURlQuery.enum";
import UseQueryContextT from "./utilities/UseQueryContextT.type";

/**
 * useQueryContext is a hook for handling the query parameters in the url,
 * to keep the parameters that we want in the url, and to update all the related mobxstores
 * as of the time of writing that is:
 * - organizationStore,
 * - zone store
 */
export default function useQueryContext(): UseQueryContextT {
    const navigate = useNavigate();
    const location = useLocation();
    const [searchParams] = useSearchParams();

    /**
     * Parses the query parameters from the URL search string,
     * updates them with any missing values specified in the UpdateURLQueryValues array,
     * and returns an object with all the parsed and updated query parameters.
     * The use of useMemo ensures that this parsing and updating only when the URL string changes
     */
    const query = useMemo(() => {
        const params = new URLSearchParams(location.search);
        const q: Record<string, string | null> = {};

        params.forEach((value, key) => {
            q[key] = value;
        });

        for (const key of UpdateURLQueryValues) {
            if (!q[key]) {
                q[key] = "";
            }
        }

        return q;
    }, [location.search, location.pathname]);

    /**
     * q quick way of moving to a location and also a device at that location if the device id is known
     * takes the location arguments (orgId, locId, sublId) and an optional deviceId
     *
     * if no device id is passed, rerouteLocation will attempt to use the currrent value
     */
    // const rerouteLocation = useCallback(
    //     (
    //         orgId: string,
    //         locId: string,
    //         sublId: string,
    //         currentDevice?: string,
    //         currentDrawerTab?: string
    //     ): void => {
    //         const params = new URLSearchParams({
    //             ...query,
    //             orgId: orgId,
    //             locId: locId,
    //             sublId: sublId,
    //             currentDevice: currentDevice || query.currentDevice || "",
    //             currentDrawerTab:
    //                 currentDrawerTab || query.currentDrawerTab || "",
    //         });

    //         navigate({
    //             pathname: location.pathname,
    //             search: params.toString(),
    //         });
    //     },
    //     [query, location]
    // );

    // const push = useCallback(
    //     (key: UpdateURLQuery): ((value: string | null) => void) =>
    //         (value): void => {
    //             const q = cleanObj(query) as Record<string, string>;

    //             const params = new URLSearchParams(q);

    //             if (value !== null) params.set(key, String(value));

    //             navigate.push({
    //                 pathname: location.pathname,
    //                 search: params.toString(),
    //             });
    //         },
    //     [query, location]
    // );

    /**
     * TODO: Replace with useReduce
     * Allows for the setting of multiple params in the URL
     * An easy way to update the query, insrt an Record<string, string> object and its keys and values, will
     * be placed ontop of the current query
     */
    const setParams = useCallback(
        (record: Record<string, string | null>): void => {
            const q = cleanObj(query) as Record<string, string | null>;

            const combine = cleanObj({ ...q, ...record });
            const params = new URLSearchParams(combine);

            navigate({
                pathname: location.pathname,
                search: params.toString(),
            });
        },
        [query, location.pathname],
    );

    /**
     * Ensures that there is always a default filter set for the fleet view. Temporary
     * Until Zone List View is visible.
     */
    useEffect(() => {
        if (location.pathname.includes("fleet")) {
            // If "filter" parm is not there, set it to "devices" by default
            if (!query.filter) {
                setParams({ filter: "devices" });
            }
        } else {
            // If the URL doesn't have "fleet", set "filter" to null to remove it
            if (query.filter) {
                setParams({ filter: null });
            }
        }
    }, [query.filter, setParams, location.pathname]);
    /**
     * Removes all query values and keys
     */
    const clear = useCallback((): void => {
        navigate({
            pathname: location.pathname,
            search: "",
        });
    }, [location, navigate]);

    /** update the [mobx]organizationStore when the query updates
     *  these stores keep the query values of the current:
     *  orgId, locId, sublId, and zoneId in memory
     */
    useEffect(() => {
        if (rootStore.organizationStore.orgId !== query.orgId) {
            rootStore.organizationStore._setOrgID(query.orgId || "");
        }
        if (rootStore.organizationStore.locId !== query.locId) {
            rootStore.organizationStore._setLocID(query.locId || "");
        }
        if (rootStore.organizationStore.sublId !== query.sublId) {
            rootStore.organizationStore._setSublID(query.sublId || "");
        }
        if (rootStore.organizationStore.zoneId !== query.currentZone) {
            rootStore.organizationStore._setZoneID(query.currentZone || "");
        }
    }, [
        query,
        rootStore.organizationStore.sublId,
        rootStore.organizationStore.orgId,
        rootStore.organizationStore.locId,
        rootStore.organizationStore.zoneId,
    ]);

    //TODO: Make this function generic so that the reactionManager can know when to run reactions
    //TODO: It should return a boolean/enum value and say what it's switchig to
    /*
     * Takes two arrays of strings, 'locKeys' and 'sublKeys', as input. The function
     * conditionally updates the URL query params based on the length of the 'sublKeys' array.
     * If the 'sublKeys' array has only one element, sets the URL query params 'LOCID' and 'SUBLID' to the first element of 'locKeys' and 'sublKeys', respectively.
     * Otherwise, sets only the 'LOCID' query params to the first element of 'locKeys'.
     * uses the 'setParams' function from the component's state to update the URL query params.
     */
    const selector = useCallback(
        (locKeys: string[], sublKeys: string[]) => {
            if (locKeys.length === 0) {
                console.error("locKeys must have at least one element");
                return;
            }
            const nonEmptySublKeys = sublKeys.filter((key) => key);
            //if first location has only 1 sublocation, load the sublocation, else load the location
            if (nonEmptySublKeys.length === 1) {
                setParams({
                    [UpdateURLQuery.LOCID]: locKeys[0],
                    [UpdateURLQuery.SUBLID]: sublKeys[0],
                });
            } else {
                setParams({ [UpdateURLQuery.LOCID]: locKeys[0] });
            }
        },
        [setParams],
    );

    /**
     * Auto navigate the user if there is only 1 organization
     */
    useEffect(() => {
        const queryParams = new URLSearchParams(location.search);
        if (queryParams.has("orgId")) {
            // If the orgId query parameter is already set, do not auto-navigate
            return;
        }

        const orgIds = Object.keys(rootStore.userStore.access);
        if (orgIds.length === 1) {
            navigate(
                `/home/maps?orgId=${orgIds[0]}${searchParams.toString()}`,
                {
                    replace: true,
                },
            );
        }
    }, [rootStore.userStore.access, navigate, location.search, searchParams]);

    /**
     * Set the query parameters in localStorage on initial load
     */
    useEffect(() => {
        const query = Object.fromEntries(searchParams.entries());
        try {
            localStorage.setItem("userSpecifiedURL", JSON.stringify(query));
        } catch (error) {
            console.error("Error saving query params to localStorage:", error);
        }
    }, [searchParams]);

    /**
     * Handles the case where the user has not specified a location/sublocation in the URL
     * and there is at least one location in the organization data.
     */

    useEffect(() => {
        // If organization data is missing or invalid, do nothing
        if (!rootStore.organizationStore.organization) {
            return;
        }

        const query = Object.fromEntries(searchParams.entries());

        // Check if user has specified a location/sublocation in the URL
        try {
            const allParamsExpectOrg = Object.fromEntries(
                Object.entries(query).filter(([key]) => key !== "orgId"),
            );
            const isAllNull = Object.values(allParamsExpectOrg).every(
                (id) => id === null,
            );

            // If no location/sublocation specified, select the first one
            if (isAllNull) {
                const locKeys = Object.keys(
                    rootStore.organizationStore.organization.locations,
                );
                if (locKeys.length > 0) {
                    const sublKeys = Object.keys(
                        rootStore.organizationStore.organization.locations[
                            locKeys[0]
                        ].sublocations,
                    );
                    selector(locKeys, sublKeys);
                }
            }
        } catch (error) {
            console.error(
                "Error parsing query params from localStorage:",
                error,
            );
        }
    }, [
        rootStore.organizationStore.organization,
        query,
        navigate,
        searchParams,
        rootStore.userStore.access,
        selector,
    ]);

    return {
        orgId: query.orgId,
        locId: query.locId,
        sublId: query.sublId,
        analyticId: query.analyticId,
        currentDevice: query.currentDevice,
        currentZone: query.currentZone,
        currentDrawerTab: query.currentDrawerTab,
        filter: query.filter,
        setParams: setParams,
        // rerouteLocation: rerouteLocation,
        clear: clear,
    };
}
