import useMouseOver from "hooks/useMouseOverListener";
import { memo, useEffect, useState } from "react";
import formatDate from "utils/formatDate";
import timeFromNow from "utils/timeFromNow";

/**
 * The time column for event logs. It shows either a trimmed down version of the timestamp or full details depending on event = "mouseover".
 * It will also convert the time to UTC format if the params for a relevant drawer are passed and the drawer is open.
 */
export default memo(function EventTimeCol({
    eventTime,
    drawerIsOpen,
}: {
    eventTime: string | Date;
    drawerIsOpen?: boolean;
}): JSX.Element {
    const [timeColRef, setTimeColRef] = useState<HTMLTableCellElement | null>(
        null,
    );
    const { mousedOver } = useMouseOver(timeColRef);
    const [showUTC, setShowUTC] = useState(false);

    useEffect(() => {
        setShowUTC(Boolean(mousedOver || drawerIsOpen));
    }, [mousedOver, drawerIsOpen]);

    return (
        <td
            key="time"
            ref={(ref): void => setTimeColRef(ref)}
            className="pl-4 h-min"
        >
            {showUTC ? formatDate(eventTime) : timeFromNow(eventTime)}
        </td>
    );
});
