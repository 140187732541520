import { createContext } from "react";

const ZoneCurrentDayContext = createContext<{
    currentDay: Date;
    setCurrentDay(d: Date): void;
}>({
    currentDay: new Date(),
    setCurrentDay() {
        //
    },
});

export default ZoneCurrentDayContext;
