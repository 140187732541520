import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import Icon from "components/Icons/Icon/Icon";
import getIconSize from "components/Icons/Icon/getIconSize.util";
import { memo, useMemo } from "react";
import IconSize from "types/IconSize";
import extendClassName from "utils/extendClassName";
import { HEALTH_COLOR } from "utils/healthColor";
import type MultiRangeSafeValMetricProps from "./utilities/MultiRangeSafeValMetric.props";
import findRange from "./utilities/findRange";

export default memo(function MultiRangeSafeValMetric({
    value,
    unit,
    operatingRange: [min, max],
    safe,
    unsafe,
    warning,
}: MultiRangeSafeValMetricProps): JSX.Element {
    const halfIconWidth = (width: IconSize): number => getIconSize(width) / 2;
    const clamp = (value: number, min: number, max: number): number =>
        Math.min(Math.max(value, min), max);
    const markerPosition = useMemo(() => {
        if (min === max) return 50;
        const range = max - min;
        const valueInRange = value - min;
        const percentage = (valueInRange / range) * 100;
        return clamp(percentage, 0, 100);
    }, [value, max, min]);

    const bars = useMemo(() => {
        // metric.length.value 30 -> 21
        const s = findRange(min, max, HEALTH_STATUS.SAFE);
        const u = findRange(min, max, HEALTH_STATUS.UNSAFE);
        const w = findRange(min, max, HEALTH_STATUS.WARNING);

        return [...safe.map(s), ...unsafe.map(u), ...warning.map(w)];
    }, [safe, unsafe, warning, min, max]);

    if (isNaN(value)) {
        return <h1>Error. value is not a number</h1>;
    }

    return (
        <div className="flex flex-1 justify-center items-center pr-2 pl-1 has-ignoreClass-device-drawer has-ignoreClass-zone-drawer">
            <div className="relative w-full h-1 bg-primary-lightGray">
                <Icon
                    name="caret-down"
                    className="text-obsidian absolute bottom-1.75 "
                    size={"sm"}
                    style={{
                        left: `calc(${markerPosition}% - ${halfIconWidth(
                            "sm",
                        )}px)`,
                    }}
                />
                {bars.map(({ left, right, type }, index) => (
                    <div
                        key={`${type}-${index}`}
                        className={extendClassName(
                            "absolute h-1",
                            "bg" + (HEALTH_COLOR[type] || "-gray-700"),
                        )}
                        style={{
                            left: `${left}%`,
                            right: `${100 - right}%`,
                        }}
                    />
                ))}

                <span className="float-left text-xxs font-bold absolute left-0 top-1.25">
                    {min}
                </span>
                <span className="float-left text-xxs font-bold absolute right-0 top-1.25">
                    {max}
                </span>
                <span className="float-left text-xxs font-bold absolute left-1/2 top-1.25">
                    {unit === "none" ? "" : unit}
                </span>
            </div>
        </div>
    );
});
