import ZoneForm from "types/FormTypes/ZoneForm";
import * as Yup from "yup";

const zoneFormSchema: Yup.ObjectSchema<ZoneForm> = Yup.object().shape({
    name: Yup.string().required("Zone name is required"),
    description: Yup.string().required("Description is required"),
    location: Yup.string(),
    sublocation: Yup.string(),
    customerId: Yup.string(),
    type: Yup.string(),
});

export default zoneFormSchema;
