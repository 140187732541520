import { useEffect, useState } from "react";
import rootStore from "stores/rootStore";

export interface ImageDimensions {
    width: number;
    height: number;
}

interface ImageLoadingState {
    /**
     * The width and height of the image taken after it's loaded
     */
    dimensions: ImageDimensions | null;

    /**
     * An error caught by the error event handler that comes from @see HTMLImageElement
     * Displays a generic message
     */
    error: string | null;

    /**
     * Indicates that img.onload has been completed, meaning that the dimensions and other properties can be retrieved.
     */
    isImageLoading: boolean;
}

const defaultState: ImageLoadingState = {
    dimensions: null,
    error: null,
    isImageLoading: false, //we only want to run the check if there is a img string to be loaded
};

/**
 * Custom hook to fetch and return the dimensions (width and height) of an image given its URL.
 *
 * The hook listens for the image's onload event to ensure it's fully loaded before reading its dimensions.
 * If the image fails to load or is inaccessible, the hook returns null.
 *
 * @param {string} url - The URL of the image whose dimensions are to be fetched.
 * @returns {ImageDimensions | null} An object with the image's width and height or null if not available.
 */
export default function useImageDimensions(url: string): ImageLoadingState {
    //done because large image files may trigger a race condition between onload and onerror
    const [isImageSuccessfullyLoaded, setIsImageSuccessfullyLoaded] =
        useState(false);
    const [state, setState] = useState<ImageLoadingState>({
        dimensions: null,
        error: null,
        isImageLoading: url ? true : false, //we only want to run the check if there is a img string to be loaded
    });

    useEffect(() => {
        if (!url) {
            setState(defaultState);
            setIsImageSuccessfullyLoaded(false);
            return;
        }

        const img = new Image();

        img.src = url;

        img.onload = (): void => {
            setIsImageSuccessfullyLoaded(true);
            setState({
                dimensions: { width: img.width, height: img.height },
                error: null,
                isImageLoading: false,
            });
        };

        img.onerror = (): void => {
            if (isImageSuccessfullyLoaded) return;
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Failed to set image dimensions.`,
                {
                    variant: "error",
                },
            );
            setState({
                dimensions: null,
                error: "Failed to load image.",
                isImageLoading: false,
            });
        };

        return (): void => {
            img.onload = null; // Cleanup onload
            img.onerror = null; // Cleanup onerror to avoid potential memory leaks
        };
    }, [url]);

    return state;
}
