import InputField from "components/InputField/InputField";
import { memo } from "react";
import {
    Control,
    Controller,
    FieldErrors,
    UseFormTrigger,
} from "react-hook-form";
import StringMultiRangeSafeVal from "types/StringMultiRangeSafeVal";
type OperatingRangeContainerProps = {
    control: Control<StringMultiRangeSafeVal>;
    trigger: UseFormTrigger<StringMultiRangeSafeVal>;
    errors: FieldErrors<StringMultiRangeSafeVal>;
};

export default memo(function OperatingRangeContainer({
    control,
    trigger,
    errors,
}: OperatingRangeContainerProps): JSX.Element {
    /**
     * Since the min and max values are connected we want to validate them at the same
     * time, in case the max value corrects an error triggered from the min value
     * and vice versa.
     */
    return (
        <>
            <span className="capitalize mt-2"> Display Range </span>
            <div className="flex flex-row">
                <Controller
                    name="operatingRange.0"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => {
                        return (
                            <InputField
                                label="Min"
                                type="number"
                                onChange={(event): void => {
                                    field.onChange(event);
                                    errors.operatingRange &&
                                        errors.operatingRange[1] &&
                                        trigger("operatingRange.1");
                                }}
                                value={field.value}
                                error={fieldState.error?.message}
                                classes={{ label: "w-14" }}
                            />
                        );
                    }}
                />
                <Controller
                    name="operatingRange.1"
                    control={control}
                    render={({ field, fieldState }): JSX.Element => (
                        <InputField
                            label="Max"
                            type="number"
                            onChange={(event): void => {
                                field.onChange(event);
                                errors.operatingRange &&
                                    errors.operatingRange[0] &&
                                    trigger("operatingRange.0");
                            }}
                            value={field.value}
                            error={fieldState.error?.message}
                            classes={{ label: "w-14" }}
                        />
                    )}
                />
            </div>
        </>
    );
});
