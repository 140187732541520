import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import NOTIFICATION_SORT_OPTIONS from "./NOTIFICATION_SORT_OPTION.enum";
import NotificationTableColumn from "./NotificationTableColumn.type";

// An array that defines the available sort options for the notifications.
const notificationSortOptionsData: NotificationTableColumn[] = [
    {
        type: NOTIFICATION_SORT_OPTIONS.STATUS,
        label: "Status",
        sort: {
            path: "status",
            measure: (value: unknown): number => {
                // Cast the value to a string, since status is a string property.
                const status = value as HEALTH_STATUS;

                // Define the sort order for different status values.
                switch (status) {
                    case HEALTH_STATUS.UNSAFE:
                        return 1;
                    case HEALTH_STATUS.WARNING:
                        return 2;
                    case HEALTH_STATUS.SAFE:
                        return 3;
                    default:
                        return 4;
                }
            },
        },
    },
    {
        type: NOTIFICATION_SORT_OPTIONS.NAME,
        label: "Device Name",
        sort: {
            path: "deviceName",
        },
    },
    {
        type: NOTIFICATION_SORT_OPTIONS.MESSAGE,
        label: "Message",
        sort: {
            path: "message",
        },
    },
    {
        type: NOTIFICATION_SORT_OPTIONS.SUBLOCATION,
        label: "Sublocation",
        sort: {
            path: "sublocation",
        },
    },
    {
        type: NOTIFICATION_SORT_OPTIONS.TIME,
        label: "Time",
        sort: {
            path: "event_time",
            measure: (value: unknown): number => {
                // Cast the value to a string, since event_time is a string property.
                const eventTime = new Date(value as string).getTime();
                // Parse the event time using the timeFromNow function.
                return eventTime;
            },
        },
    },
];

export default notificationSortOptionsData;
