import { action, makeObservable, observable } from "mobx";

/**
 * holds the keycloak-token for reactivity
 */
export default class ApiStore {
    /**
     * the jwt token
     */
    @observable
    token = "";

    /**
     * sets the @see token
     */
    @action
    setToken = (token: string): void => {
        this.token = token;
    };

    public constructor() {
        makeObservable(this);
    }
}
