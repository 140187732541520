import EventLogsTable from "components/Tables/EventLogsTable/EventLogsTable";
import { memo } from "react";
import ZoneLogsRowItem from "../CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/ZoneLogsRowItem";
import ZoneLogsRowItemProps from "../CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/ZoneLogsRowItem.props";

const ZoneLogsTable = memo(
    ({ logs }: { logs: ZoneLogsRowItemProps[] }): JSX.Element => (
        <EventLogsTable>
            {logs.map((props, index) => (
                <ZoneLogsRowItem key={props.id + String(index)} {...props} />
            ))}
        </EventLogsTable>
    ),
);

export default ZoneLogsTable;
