import { observer } from "mobx-react-lite";
import { CLASS_NAMES } from "styles/classNameConstants";
import CurrentDeviceCardContent from "./CurrentDeviceCardContent/CurrentDeviceCardContent";
import CurrentDeviceCardHeader from "./CurrentDeviceCardHeader/CurrentDeviceCardHeader";
import CurrentDeviceCardTabs from "./CurrentDeviceCardTabs/CurrentDeviceCardTabs";
import type CurrentDeviceCardDrawerProps from "./utilities/CurrentDeviceCardDrawer.props";

export default observer(function CurrentDeviceCardDrawer({
    device,
    currentTab,
    setCurrentTab,
}: CurrentDeviceCardDrawerProps): JSX.Element {
    return (
        <>
            <CurrentDeviceCardTabs
                currentTab={currentTab}
                setCurrentTab={setCurrentTab}
            />
            <div className={CLASS_NAMES.drawer.overallContentWrapper}>
                <div
                    className={`${CLASS_NAMES.drawer.overallContentContainer} max-w-full`}
                >
                    {device ? (
                        <>
                            {" "}
                            <CurrentDeviceCardHeader device={device} />
                            <div className="flex flex-col overflow-y-auto border-l-1 border-grey-700 h-full">
                                <CurrentDeviceCardContent
                                    device={device}
                                    currentTab={currentTab}
                                />
                            </div>{" "}
                        </>
                    ) : (
                        <div className="w-full h-full flex p-4">
                            <p className="my-auto">
                                Please select a device to view it's status
                            </p>
                        </div>
                    )}
                </div>
            </div>
        </>
    );
});
