import { memo, useEffect, useMemo, useState } from "react";
import MultiRangeUnitConversionMetric from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemNonGeneric/MultiRangeUnitConversionMetricContainer/MultiRangeUnitConversionMetric/MultiRangeUnitConversionMetric";
import MultiRangeUnitConversionEditableContent from "./MultiRangeUnitConversionEditableContent/MultiRangeUnitConversionEditableContent";
import MultiRangeUnitConversion from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_UNIT_CONVERSION_DIMENSION/MultiRangeUnitConversion.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import convertUnits, { round } from "utils/unitConversions/unitConversions";
import convertMultiRangeUnitConversionDimension from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentStatus/CurrentDeviceCardContentStatusDrawerItem/CardContentStatusDrawerItemNonGeneric/MultiRangeUnitConversionMetricContainer/utilities/convertMultiRangeUnitConversionDimension";
import MultiRangeUnitConversionEditDrawerProps from "./utilities/MultiRangeUnitConversionEditDrawer.props";

export default memo(function MultiRangeUnitConversionEditDrawer({
    value,
    drawer,
    updateDrawer,
    disabled,
    onChange,
    options,
    setHasErrors,
}: MultiRangeUnitConversionEditDrawerProps): JSX.Element {
    const dimension = options.multiRangeUnitConversion;

    const [formState, setFormState] =
        useState<MultiRangeUnitConversion>(dimension);

    useEffect(() => {
        onChange({ [DISPLAY_TYPE.MULTI_RANGE_UNIT_CONVERSION]: formState });
    }, [formState]);

    const setUnit = (unit: string): void => {
        updateDrawer({
            ...drawer,
            unit,
        });
    };

    const convertedMetricValue = useMemo(() => {
        return round(convertUnits(value, dimension.baseUnits, drawer.unit), 1);
    }, [drawer.unit]);

    const convertedDimension = useMemo(() => {
        return convertMultiRangeUnitConversionDimension(dimension, drawer.unit);
    }, [drawer.unit, dimension]);

    return (
        <>
            <div className="flex w-full my-8">
                <MultiRangeUnitConversionMetric
                    value={convertedMetricValue}
                    unit={drawer.unit}
                    {...convertedDimension}
                />
            </div>

            <span className="text-center">
                Default Reading: <i>{options.multiRangeUnitConversion!.base}</i>
            </span>

            {!disabled ? (
                <MultiRangeUnitConversionEditableContent
                    onChange={onChange}
                    dimension={options}
                    unit={drawer.unit}
                    baseUnits={dimension.baseUnits}
                    setUnit={setUnit}
                    setHasErrors={setHasErrors}
                    setFormState={setFormState}
                />
            ) : null}
        </>
    );
});
