import { useEffect, useState } from "react";
import OrganizationService from "services/OrganizationService";
import DeviceNotification from "types/Notification.type";
/**
 * Gets notifications associated with a specific organization and caches them.
 * @param {UseNotificationsProps} props
 * @returns {UseQueryResult<DeviceNotification[], Error>} The query result object containing notifications data and query state.
 */
function useNotifications(orgId: string | null): DeviceNotification[] {
    const [notifications, setNotifications] = useState<DeviceNotification[]>(
        [],
    );

    useEffect(() => {
        const fetchNotifications = async (): Promise<void> => {
            if (!orgId || orgId === "") {
                setNotifications([]);
                return;
            }

            try {
                const data = await OrganizationService.getNotifications(orgId);
                setNotifications(data);
            } catch (error) {
                console.error("Error fetching notifications:", error);
            }
        };

        fetchNotifications();

        const intervalId = setInterval(fetchNotifications, 60000);

        return () => {
            clearInterval(intervalId);
        };
    }, [orgId]);

    return notifications;
}

export default useNotifications;
