import User from "types/User";
import BaseService from "./BaseService";

class UserService {
    /**
     * fetches the user info from the users/me end point on the api, returns a User
     *
     * @async
     * @return User object
     */
    public async getMe(): Promise<User> {
        const { data: user } = await BaseService.request<User>("/users/me");

        return user;
    }
}

export default new UserService();
