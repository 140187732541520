import { action, computed, makeObservable, observable } from "mobx";
import { OptionsObject, SnackbarKey, SnackbarMessage } from "notistack";

/**
 * mobx store for snackbar notification from notistack
 * essentially lets use use notistack without having to be in a react hook context
 */
export default class SnackbarStore {
    @observable
    private _enqueueSnackbar: EnqueueSnackbar | null = null;

    @computed
    public get enqueueSnackbar(): EnqueueSnackbar | null {
        return this._enqueueSnackbar;
    }

    @action
    public setEnqueueSnackbar(
        enqueueSnackbar: (
            message: SnackbarMessage,
            options?: OptionsObject,
        ) => SnackbarKey,
    ): void {
        this._enqueueSnackbar = enqueueSnackbar;
    }

    public constructor() {
        makeObservable(this);
    }
}

export type EnqueueSnackbar = (
    message: SnackbarMessage,
    options?: OptionsObject,
) => SnackbarKey;
