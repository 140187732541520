import { createContext } from "react";
import UseQueryContextT from "./UseQueryContextT.type";

const UseQueryContext = createContext<UseQueryContextT>({
    orgId: null,
    locId: null,
    sublId: null,
    analyticId: null,
    currentDevice: null,
    currentZone: null,
    currentDrawerTab: null,
    filter: "device",
    setParams(): void {
        /* */
    },
    clear(): void {
        /* */
    },
});

export default UseQueryContext;
