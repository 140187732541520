import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import Icon from "components/Icons/Icon/Icon";
import { observer } from "mobx-react-lite";
import { useEffect, useMemo, useState } from "react";
import rootStore from "stores/rootStore";
import extendClassName from "utils/extendClassName";
import type DeviceIconProps from "./utilities/DeviceIcon.props";
import DeviceIconSize from "./utilities/DeviceIconSize.enum";

export default observer(function DeviceIcon({
    overallHealth,
    offline,
    image,
    containerSize = rootStore.viewportStore.isMobile
        ? DeviceIconSize.SMALL
        : DeviceIconSize.MEDIUM,
}: DeviceIconProps): JSX.Element {
    const [containerRef, setContainerRef] = useState<HTMLDivElement | null>(
        null,
    );
    const [iconDimensions, setIconDimensions] = useState<{
        width: number;
        height: number;
    } | null>(null);
    const dimension = useMemo(() => {
        switch (containerSize) {
            case DeviceIconSize.XSMALL:
                return "w-2 h-2";
            case DeviceIconSize.SMALL:
                return "w-3 h-3";
            case DeviceIconSize.MEDIUM:
                return "w-4 h-4";
            case DeviceIconSize.LARGE:
                return "w-6 h-6";
            case DeviceIconSize.XLARGE:
                return "w-9 h-9";
            case DeviceIconSize.XXLARGE:
                return "w-12 h-12";
            case DeviceIconSize.XXXLARGE:
                return "w-14 h-14";
            default:
                return "w-4 h-4";
        }
    }, [containerSize]);

    const backgroundColor = useMemo(() => {
        if (offline) return "bg-gray-700";
        else {
            switch (overallHealth) {
                case HEALTH_STATUS.SAFE:
                    return "bg-safe";
                case HEALTH_STATUS.UNSAFE:
                    return "bg-unsafe";
                case HEALTH_STATUS.WARNING:
                    return "bg-warning";
                default:
                    return "bg-gray-700";
            }
        }
    }, [offline, overallHealth]);

    useEffect(() => {
        if (containerRef) {
            const img = new Image();
            //In order to determine the proper size of the icon, we need to preload the image to determine if it's width
            //and height fit into the container or not
            img.onload = (): void => {
                const containerSize = Math.min(
                    containerRef.clientWidth,
                    containerRef.clientHeight,
                );
                //Rember that the div is in the shape of circle! Don't use a square aspect ratio calc
                const maxIconSize = (containerSize * Math.sqrt(2)) / 2; // Max size that fits in a circle

                const scale = maxIconSize / Math.max(img.width, img.height);
                const newWidth = img.width * scale;
                const newHeight = img.height * scale;

                setIconDimensions({ width: newWidth, height: newHeight });
            };
            img.src = `${process.env.REACT_APP_ICON_BASE_URL}${image}`;
        }
    }, [containerRef, image]);

    return (
        <div
            ref={setContainerRef}
            className={extendClassName(
                `rounded-full overflow-hidden flex items-center justify-center`,
                dimension,
                backgroundColor,
            )}
        >
            {iconDimensions && (
                <Icon
                    src={process.env.REACT_APP_ICON_BASE_URL + image}
                    fill="#fff"
                    width={iconDimensions.width}
                    height={iconDimensions.height}
                />
            )}
        </div>
    );
});
