import Button from "components/Button/Button";
import M1LogoTransparent from "components/Icons/M1LogoTransparent/M1LogoTransparent";

export default function SiteOffline(): JSX.Element {
    return (
        <div className="w-screen h-screen flex flex-col justify-center items-center bg-white text-[#3C3C3C] font-sans text-center">
            <div className="w-10 mb-4">
                <M1LogoTransparent />
            </div>
            <>
                <p className="text-red my-4">Connection Offline</p>
                <p>
                    No connection detected. Once a connection has been
                    re-established please refresh.
                </p>
            </>
            <div className="my-4">
                <Button
                    variant="secondary"
                    onClick={(): void => window.location.reload()}
                >
                    Refresh
                </Button>
            </div>
        </div>
    );
}
