import type Device from "@srtlabs/m1_types/lib/Device/Device.type";
import type DevicesMetricsMetric from "@srtlabs/m1_types/lib/Device/DeviceMetrics/DevicesMetricsMetric/DevicesMetricsMetric.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import isObject from "utils/isObject";
import IssuesWithDevice from "utils/IssuesWithDevice";

/**
 * a temporary funciton to take a device and update its health depending on what we find in that devices metrics
 */
export default function findProblemsWithDevice(device: Device): Device {
    try {
        // Tracks status of metric health across multiple dimensions. The most severe
        // health value will be used if there are multiple dimensions for the metric.
        const metricHealthStatuses: Record<string, HEALTH_STATUS> = {};

        device.display.drawerContent.forEach((drawer): void => {
            let health = HEALTH_STATUS.SAFE;

            if (drawer.hiddenFromDashboard === true) {
                device.deviceHealth[drawer.referenceID] = HEALTH_STATUS.SAFE;
                return;
            }

            if (
                Object.prototype.hasOwnProperty.call(
                    device.metrics,
                    drawer.referenceID,
                )
            ) {
                // If metric health has not been defined yet, set it to safe
                if (!metricHealthStatuses[drawer.referenceID]) {
                    metricHealthStatuses[drawer.referenceID] =
                        HEALTH_STATUS.SAFE;
                    device.deviceHealth[drawer.referenceID] =
                        HEALTH_STATUS.SAFE;
                }
                // If metric health has already been determined to be unsafe,
                // we do not need to evaluate further.
                else if (
                    metricHealthStatuses[drawer.referenceID] ===
                    HEALTH_STATUS.UNSAFE
                )
                    return;

                const metric = device.metrics[drawer.referenceID];

                if (isObject(metric)) {
                    const { value } = metric as DevicesMetricsMetric;

                    const util = IssuesWithDevice.getUtilsForType(drawer);

                    if (util) {
                        try {
                            const { error, warning } = util.handler(
                                util.transform(value),
                                device.state.dimensions[drawer.referenceID]?.[
                                    drawer.type
                                ],
                                drawer,
                            );

                            if (error) {
                                health = HEALTH_STATUS.UNSAFE;
                            } else if (warning) {
                                health = HEALTH_STATUS.WARNING;
                            }
                        } catch (e) {
                            console.warn(
                                "failed to find problem with device, %s %s",
                                device._id,
                                e,
                            );
                        }
                    }
                }
            }

            // If health is unsafe or it is warning and the current status is safe,
            // update the status to reflect the more severe health value.
            if (
                health === HEALTH_STATUS.UNSAFE ||
                (health === HEALTH_STATUS.WARNING &&
                    metricHealthStatuses[drawer.referenceID] ===
                        HEALTH_STATUS.SAFE)
            ) {
                metricHealthStatuses[drawer.referenceID] = health;
                device.deviceHealth[drawer.referenceID] = health;
            }
        });

        if (
            device.display.drawerContent.every(
                (drawer): boolean =>
                    device.deviceHealth[drawer.referenceID] ===
                    HEALTH_STATUS.SAFE,
            )
        ) {
            device.deviceHealth.overAllHealth = HEALTH_STATUS.SAFE;
        } else if (
            device.display.drawerContent.every(
                (drawer): boolean =>
                    device.deviceHealth[drawer.referenceID] !==
                    HEALTH_STATUS.UNSAFE,
            )
        ) {
            device.deviceHealth.overAllHealth = HEALTH_STATUS.WARNING;
        } else {
            device.deviceHealth.overAllHealth = HEALTH_STATUS.UNSAFE;
        }
        return device;
    } catch (err) {
        console.error("Error setting device health", err);
        throw new Error("Error setting device health");
    }
}
