import { memo } from "react";
import Icon from "../Icon/Icon";
import extendClassName from "utils/extendClassName";
import IconProps from "../Icon/Icon.props";

interface SpinnerProps extends Omit<IconProps, "name"> {
    variant?: "spinner" | "spinner-third";
}

/**
 * A loading component that represent a circle of mini-circles and rotates them.
 * TailwindCss only applies animations to absolutely positioned elements, so it is recommened to wrap
 * them in a relative div of a fixed width and height.
 */
export default memo(function Spinner({
    variant = "spinner",
    color,
    className,
    fill,
    size,
}: SpinnerProps): JSX.Element {
    return (
        <Icon
            name={variant}
            color={color}
            fill={fill}
            size={size}
            className={extendClassName("animate-spin", className)}
        />
    );
});
