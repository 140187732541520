import AnalyticsDashboardLink from "@srtlabs/m1_types/lib/Organization/Locations/Location/AnalyticsDashboardLink/AnalyticsDashboardLink.type";
import { useNavigateToLocation } from "hooks/useNavigateToLocation";
import useURLQuery from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import { Link, useLocation } from "react-router-dom";
import rootStore from "stores/rootStore";
import removeWhitespace from "utils/removeWhitespace";
const SeperatorClass = "mx-1 text-bold";

/**
 *
 * Since we have the style of location header used in a variety of areas,
 * use this component when you need to display organization, location, and sublocation
 * names on the same line. Later this may include zone names as well.
 *
 */

export default observer(function LocationInfoSection(): JSX.Element {
    const baseClass =
        "pl-4 text-md block my-auto pl-4 text-gray-500 ml-2 truncate max-w-3/4 font-nunito";

    const { analyticId } = useURLQuery();
    const location = useLocation();
    const organizationStore = rootStore.organizationStore;
    const notificationsInURL = location.pathname.includes("notifications");

    /**
     * Based on the given link, retrieves the @see AnalyticsDashboardLink.
     * @param link analytic link used as unique identifier.
     * @returns {string | null} the name of the link or nothing
     */
    const getAnalyticLink = (link: string): AnalyticsDashboardLink | null => {
        if (
            organizationStore.analytics &&
            organizationStore.analytics.length > 0
        ) {
            const selectedAnalytic = organizationStore.analytics.find(
                (obj) => obj.link === link,
            );
            return selectedAnalytic || null;
        }

        return null;
    };

    const { generatePath } = useNavigateToLocation();
    const path = (locId?: string, sublId?: string): string =>
        generatePath("maps", {
            orgId: rootStore.organizationStore.orgId,
            locId,
            sublId,
        });

    return (
        <p className={baseClass}>
            {organizationStore.orgName ? (
                <>
                    <Link
                        to={path(rootStore.organizationStore.locId)}
                        className="hover:underline"
                    >
                        {removeWhitespace(organizationStore.orgName)}
                    </Link>
                    <span className={SeperatorClass}>|</span>
                </>
            ) : null}
            {organizationStore.locName ? (
                <Link
                    to={path(rootStore.organizationStore.locId)}
                    className="hover:underline"
                >
                    {removeWhitespace(organizationStore.locName)}
                </Link>
            ) : null}
            {notificationsInURL ? (
                <>
                    <span className={SeperatorClass}>|</span>Alerts
                </>
            ) : null}
            {organizationStore.sublName && !notificationsInURL ? (
                <>
                    <span className={SeperatorClass}>|</span>
                    <Link
                        className="hover:underline"
                        to={path(
                            rootStore.organizationStore.locId,
                            rootStore.organizationStore.sublId,
                        )}
                    >
                        {removeWhitespace(organizationStore.sublName)}
                    </Link>
                </>
            ) : null}
            {analyticId ? (
                <>
                    <span className={SeperatorClass}>|</span>
                    {getAnalyticLink(analyticId)?.name}
                </>
            ) : null}
        </p>
    );
});
