import { memo } from "react";
import type RackStatusShelfContainerProps from "./utilities/RackStatusShelfContainer.props";
import extendClassName from "utils/extendClassName";
import RackStatusShelf from "./RackStatusShelf/RackStatusShelf";

export default memo(function RackStatusShelfContainer({
    device,
    setCurrentShelfID,
    isCurrent,
}: RackStatusShelfContainerProps): JSX.Element {
    return (
        <div
            className={extendClassName(
                "w-full mb-1 flex justify-center items-center text-obsidian",
                isCurrent
                    ? "border-gray-900 border-2"
                    : "border-gray-700 border-1",
                device ? "pointer" : "",
            )}
        >
            {device ? (
                <RackStatusShelf
                    onClick={(): void => setCurrentShelfID(device._id)}
                    device={device}
                />
            ) : (
                <span className="text-red p-2 text-xs">
                    Error: invalid shelf
                </span>
            )}
        </div>
    );
});
