import type ZoneEvents from "@srtlabs/m1_types/lib/Cassandra/ZoneEvents/ZoneEvents.type";

export default function temporaryRemoveDuplicates(
    zoneEvents: ZoneEvents[],
): ZoneEvents[] {
    return zoneEvents.map(toFormatedZoneEvent).reduce((uniqueEvents, event) => {
        if (!uniqueEvents.some(matchesGivenEvent(event))) {
            uniqueEvents.push(event);
        }
        return uniqueEvents;
    }, [] as ZoneEvents[]);
}

function matchesGivenEvent(event: ZoneEvents): (a: ZoneEvents) => boolean {
    return function (otherEvent: ZoneEvents): boolean {
        return (
            event.occupied == otherEvent.occupied &&
            event.temperature == otherEvent.temperature &&
            event.start_time.toLocaleString() ==
                otherEvent.start_time.toLocaleString() &&
            event.end_time.toLocaleString() ==
                otherEvent.end_time.toLocaleString()
        );
    };
}

function toFormatedZoneEvent(zoneEvent: ZoneEvents): ZoneEvents {
    zoneEvent.start_time = new Date(zoneEvent.start_time);
    zoneEvent.end_time = new Date(zoneEvent.end_time);

    return zoneEvent;
}
