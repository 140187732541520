import type CalendarEvent from "../../../utilities/CalendarEvent.type";
import CalendarEventType from "../../../utilities/CalendarEventType.enum";

export default function getZIndex(event: CalendarEvent): string {
    if (event.type == CalendarEventType.OVERRIDE) {
        return "z-10";
    } else if (event.type == CalendarEventType.OCCUPANCY_DETECTED) {
        return "z-10";
    } else if (event.type == CalendarEventType.BOOKING) {
        return "z-10";
    }

    return "z-10";
}
