import RelatedNotificationListContainer from "components/RelatedNotificationsList/RelatedNotificationContainer";
import { memo } from "react";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import CURRENT_ZONE_SIMPLE_TAB from "../utilities/CURRENT_ZONE_SIMPLE_TAB.enum";
import type ZoneDrawerContentProps from "./utilities/ZoneDrawerContent.props";
import ZoneInfo from "./ZoneInfo/ZoneInfo";
import ZoneLogs from "./ZoneLogs/ZoneLogs";
import ZoneStatus from "./ZoneStatus/ZoneStatus";

export default memo(function ZoneDrawerContent({
    currentTab,
    zone,
}: ZoneDrawerContentProps): JSX.Element {
    if (!currentTab || !zone) return <></>;

    return (
        <>
            {currentTab === CURRENT_ZONE_SIMPLE_TAB.STATUS ? (
                <ZoneStatus zone={zone as ZoneWithOverallHealth} />
            ) : currentTab === CURRENT_ZONE_SIMPLE_TAB.INFO ? (
                <ZoneInfo zone={zone} />
            ) : currentTab === CURRENT_ZONE_SIMPLE_TAB.LOGS ? (
                <ZoneLogs zone={zone as ZoneWithOverallHealth} />
            ) : currentTab === CURRENT_ZONE_SIMPLE_TAB.ALERTS ? (
                <RelatedNotificationListContainer
                    notificationSrcIds={zone.devices}
                />
            ) : null}
        </>
    );
});
