import { default as useURLQuery } from "hooks/useQuery/useQuery";
import useZones from "hooks/useZones.hook";
import { observer } from "mobx-react-lite";
import { useCallback, useMemo } from "react";
import rootStore from "stores/rootStore";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import toggleSetParamsId from "utils/toggleSetParamsId";
import FleetControls from "../FleetControls/FleetControls";
import FleetList from "../FleetList/FleetList";
import FLEET_SORT_OPTIONS from "../utilities/FleetSortOptions.enum";
import useZoneFleetSort from "../utilities/useZoneFleetSort";
import zoneFleetColHeaders from "../utilities/ZoneFleetColHeaders.data";
import ZoneRowItem from "./ZoneRowItem";

export default observer(function ZoneListContainer() {
    const { setParams, currentZone } = useURLQuery();

    const { data, pendingFetchData } = useZones(
        rootStore.organizationStore.activeZones,
    );

    const sortedDataInput = useMemo(() => {
        return pendingFetchData ? [] : [...data.values()];
    }, [data, pendingFetchData]);

    const { sorted, setSortOption, reverse, setReverse } =
        useZoneFleetSort(sortedDataInput);

    const setZoneAsCurrentZone = useCallback(
        (zoneId: string): void => {
            setParams({
                currentZone: toggleSetParamsId(zoneId, currentZone),
            });
        },
        [setParams, currentZone],
    );

    return (
        <>
            <FleetControls
                reverse={reverse}
                setReverse={setReverse}
                setSortOption={setSortOption}
                initialSort={{
                    title: "Name",
                    type: FLEET_SORT_OPTIONS.NAME,
                    ascending: true,
                }}
                options={zoneFleetColHeaders}
            />
            <FleetList
                onClickItem={setZoneAsCurrentZone}
                items={sorted}
                isLoading={pendingFetchData}
                renderRow={({ item, onClickItem }): JSX.Element => (
                    <ZoneRowItem
                        zone={item as ZoneWithOverallHealth}
                        onClickOfZone={(): void => onClickItem(item._id)}
                    />
                )}
            />
        </>
    );
});
