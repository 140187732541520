import { memo, useCallback, useMemo } from "react";
import type MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import type RANGE_FLOAT_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/RANGE_FLOAT_DIMENSION/RANGE_FLOAT_DIMENSION.type";
import type RANGE_INTEGER_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/RANGE_INTEGER_DIMENSION/RANGE_INTEGER_DIMENSION.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION.type";
import MultiRangeEditableContentProps from "./utilities/MultiRangeEditableContent.props";
import ToggleBaseContainer from "../ToggleBaseContainer/ToggleBaseContainer";
import OperatingRangeContainer from "../OperatingRangeContainer/OperatingRangeContainer";
import multiRangeSchema from "schemas/multiRangeSchema.schema";
import HealthRangeContainer from "../HealthRangeContainer/HealthRangeContainer";
import MultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/MULTI_RANGE_SAFE_VAL_DIMENSION/MultiRangeSafeVal.type";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import LinearScaledMultiRangeSafeVal from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScaledMultiRangeSafeVal.type";
import { Resolver, useForm } from "react-hook-form";
import { ObjectSchema, ValidationError } from "yup";
import {
    convertMultiRangeSafeValToStrings,
    convertStringMultiRangeSafeValToNumbers,
} from "utils/convertMultiRangeVal";
import StringMultiRangeSafeVal from "types/StringMultiRangeSafeVal";
import MultiRangeSafeValErrors from "types/MultiRangeSafeValErrors";
import convertStringToDotNotation from "utils/convertStringToDotNotation";

/**
 * Our default data for the RHF form. Because text fields handle numbers as strings,
 * we must remember to convert these fields to numbers in the final computation
 */
const multiRangeState: StringMultiRangeSafeVal = {
    unsafe: [],
    warning: [],
    safe: [],
    operatingRange: ["0", "0"],
    base: HEALTH_STATUS.SAFE,
};

/**
 * An all-purpose component meant to manage the multiRangeEditableFields. Fields that can allow for an array of ranges
 * per health status. As long as they fall within the overall operating range.
 */
export default memo(function MultiRangeEditableContent({
    options,
    setHasErrors,
    setFormState,
    dimensionType,
}: MultiRangeEditableContentProps<
    | MULTI_RANGE_SAFE_VAL_DIMENSION
    | RANGE_FLOAT_DIMENSION
    | RANGE_INTEGER_DIMENSION
    | LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION
>): JSX.Element {
    /**
     * Converts @see LinearScaledMultiRangeSafeVal or @see MultiRangeSafeVal to an acceptable object for
     * this component to use (i.e., removes the scale)
     */
    const convertDimensionOptionsToForm = useCallback(
        (
            dimensions: MultiRangeSafeVal | LinearScaledMultiRangeSafeVal,
        ): StringMultiRangeSafeVal => {
            try {
                const newForm: StringMultiRangeSafeVal =
                    convertMultiRangeSafeValToStrings(dimensions);
                return newForm;
            } catch (err) {
                console.error("Could not convert dimension type to form", err);
                throw new Error(
                    "Could not convert dimension options to relevant type",
                );
            }
        },
        [],
    );

    /**
     *  Output: We need a default value for if the expected dimension is not found,
     *  rather than put through an error, set an empty state, which is later updated
     *  with the imported dimensions.
     */
    const dimension: StringMultiRangeSafeVal = useMemo(() => {
        try {
            let output: StringMultiRangeSafeVal = multiRangeState;
            if (
                DISPLAY_TYPE.MULTI_RANGE_SAFE_VAL in options &&
                options.multiRangeSafeVal
            ) {
                output = convertDimensionOptionsToForm(
                    options.multiRangeSafeVal,
                );
            }
            if (
                DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL in options &&
                options.linearScaledMultiRangeSafeVal
            ) {
                output = convertDimensionOptionsToForm(
                    options.linearScaledMultiRangeSafeVal,
                );
            }
            return output;
        } catch (err) {
            console.error(
                "Could not resolve dimension type, aborting setting dimensions",
                err,
            );
            throw new Error("Could not find multirange");
        }
    }, [options, dimensionType]);

    /**
     * Validates the form values against the multiRangeSafeVal schema.
     * As a side effect, if the errors are found alerts the global form handler @see CurrentDeviceCardContentEdit disable save fields. Does not update global form state
     * If no errors, updates global form state
     *
     * @param {StringMultiRangeSafeVal} values - The current form values.
     * @returns {ValidationError} - An object containing validation errors, if any. This gets resolved into a format that RHF understands
     */
    const useYupValidationResolver = (
        validationSchema: ObjectSchema<StringMultiRangeSafeVal>,
    ): ((data: StringMultiRangeSafeVal) => Promise<{
        values: StringMultiRangeSafeVal;
        errors: MultiRangeSafeValErrors;
    }>) =>
        useCallback(
            async (data: StringMultiRangeSafeVal) => {
                try {
                    //Attempt to valiate form values
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    });

                    //Assuming they pass, convert them to their proper type as numbers
                    const valuesAsNum: MultiRangeSafeVal =
                        convertStringMultiRangeSafeValToNumbers(values);

                    // //Submit them to a global form state and enable submit buttons
                    setFormState(valuesAsNum);
                    setHasErrors(false);
                    return {
                        values,
                        errors: {} as unknown as MultiRangeSafeValErrors,
                    };
                } catch (errors) {
                    //We are only worried about Yup.ValidationErrors here
                    if (ValidationError.isError(errors)) {
                        //Disable submit buttons, since we found some errors
                        setHasErrors(true);
                        const resolvedErrors = {
                            values: data,
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => {
                                    //RHF uses dot notation to locate fields/inputs
                                    //so we need to convert the yup paths (which use ["0"]/[0]), to dots
                                    return {
                                        ...allErrors,
                                        [(currentError.path &&
                                            convertStringToDotNotation(
                                                currentError.path,
                                            )) ||
                                        "unknown"]: {
                                            type:
                                                currentError.type ??
                                                "validation",
                                            message: currentError.message,
                                        },
                                    };
                                },
                                {},
                            ),
                        };
                        return resolvedErrors;
                    } else {
                        //TODO: Handle other types of errors, most likely with the toastit note
                        console.error(
                            "An unexpected error occurred during validation:",
                            errors,
                        );

                        return {
                            values: data,
                            errors: {
                                default:
                                    "An unexpected error occurred. Please try again or contact support.",
                            },
                        };
                    }
                }
            },
            [validationSchema],
        );

    const resolver: Resolver<StringMultiRangeSafeVal, unknown> =
        useYupValidationResolver(multiRangeSchema);

    const {
        control,
        trigger,
        formState: { errors },
    } = useForm<StringMultiRangeSafeVal>({
        defaultValues: dimension,
        resolver: resolver,
        mode: "onChange",
    });

    return (
        <form
            onSubmit={(): void => {
                return;
            }}
            className="flex flex-1 flex-col"
        >
            <ToggleBaseContainer
                title={`Toggle Default Reading`}
                control={control}
            />
            <OperatingRangeContainer
                control={control}
                trigger={trigger}
                errors={errors}
            />
            <ul className="flex flex-col flex-wrap">
                {(["safe", "warning", "unsafe"] as HEALTH_STATUS[]).map(
                    (health) => {
                        return (
                            <HealthRangeContainer
                                key={health}
                                control={control}
                                health={health}
                                trigger={trigger}
                                errors={errors}
                            />
                        );
                    },
                )}
            </ul>
        </form>
    );
});
