import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import {
    useMutation,
    UseMutationOptions,
    UseMutationResult,
    useQueryClient,
} from "@tanstack/react-query";
import ZoneService from "services/ZoneService";
import rootStore from "stores/rootStore";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import findProblemsWithZone from "utils/findProblemsWithZone.util";

type UseSaveZoneProps = Omit<
    UseMutationOptions<Zone, Error, Zone, { prevZone: Zone | undefined }>,
    "mutationFn" | "onSuccess" | "onError" | "oneSettled"
>;

function useSaveZone(
    zone: Zone,
    props?: UseSaveZoneProps,
): UseMutationResult<
    ZoneWithOverallHealth,
    Error,
    ZoneWithOverallHealth,
    { prevZone: Zone | undefined }
> {
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: ZoneService.put,
        onMutate: async (updatedZone) => {
            //cancel any current queries (fetches so that they don't overwrite our current data)
            queryClient.cancelQueries({ queryKey: ["zone", updatedZone._id] });
            //snapshot of latest query data
            const prevZone = queryClient.getQueryData<Zone>([
                "zone",
                updatedZone._id,
            ]);
            //optimistic update
            queryClient.setQueryData<Zone>(["zone", zone._id], updatedZone);
            return { prevZone };
        },
        onSuccess: async (returnedZone: ZoneWithOverallHealth) => {
            //Compute and update zone health status (relying on culmalative device data)
            const updatedZone: ZoneWithOverallHealth =
                await findProblemsWithZone(returnedZone);

            //Map trigger should be here?
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Successfully updated: ${updatedZone.name}`,
                {
                    variant: "success",
                    autoHideDuration: 2500,
                },
            );
        },
        onError: (err, updatedZone, context) => {
            console.error(err);
            if (context?.prevZone) {
                //rollback to latest zone cache query on error
                queryClient.setQueryData(
                    ["zone", updatedZone._id],
                    updatedZone,
                );
            }
            rootStore.snackbarStore.enqueueSnackbar?.(
                `Unable to update settings for: ${updatedZone}`,
                {
                    variant: "error",
                    autoHideDuration: 3000,
                },
            );
        },
        onSettled: (zone) => {
            if (zone) {
                queryClient.invalidateQueries({ queryKey: ["zone", zone._id] });
            }
        },
        ...props,
    });
}

export default useSaveZone;
