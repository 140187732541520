/**
 * Filters out any `null` values from an array of items.
 * Useful for removing null items resulting from `Promise.all`.
 * @param items The array of items to filter.
 * @returns The filtered array, without any null items.
 */
function filterNulls<T>(array: (T | null | undefined)[]): T[] {
    return array.filter(
        (item): item is T => item !== null || item !== undefined,
    );
}

export default filterNulls;
