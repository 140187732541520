/**
 * concats strings to make a single class name
 * also ignores empty strings / undefined values
 *
 * @param className - the first / base class string to use
 * @param other - the rest of the the class names to concat to "className:
 * @return a single className string
 */
export default function extendClassName(
    className: string,
    ...other: (string | undefined)[]
): string {
    let s = className.slice();
    for (let i = 0; i < other.length; i++) {
        if (other[i]) {
            s += " " + other[i];
        }
    }

    return s;
}
