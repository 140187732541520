import Icon from "components/Icons/Icon/Icon";
import { observer } from "mobx-react-lite";
import { useMemo } from "react";
import { Link } from "react-router-dom";
import extendClassName from "utils/extendClassName";

interface NotificationBellProps {
    orgId: string;
    locId: string;
    sublId: string;
    isMobile: boolean;
    numberOfNotifications: number;
}

export default observer(function NotificationBell({
    orgId,
    locId,
    sublId,
    isMobile,
    numberOfNotifications,
}: NotificationBellProps) {
    const showNotifications = useMemo(
        () =>
            (locId && numberOfNotifications) ||
            (sublId && numberOfNotifications),
        [sublId, locId, numberOfNotifications],
    );

    return (
        <div className="flex items-center">
            <div
                className={extendClassName(
                    "w-2 h-2 sm:w-3 sm:h-3 mx-2 flex justify-center items-center bg-gray-100 border-[1.7px] rounded-full relative cursor-pointer",
                    showNotifications ? "border-red" : "border-obsidian",
                    "ignore-onClickOutside",
                )}
            >
                <Link to={`/home/notifications?orgId=${orgId}&locId=${locId}`}>
                    <Icon
                        name="bell"
                        size={isMobile ? "sm" : "md"}
                        className="ignore-onClickOutside"
                    />
                    {showNotifications ? (
                        <span
                            className={extendClassName(
                                "absolute flex justify-center items-center px-1.25 w-auto rounded-full bg-red text-sm text-white",
                                isMobile
                                    ? "top-[0] left-[14px]"
                                    : "top-2 right-0 ",
                            )}
                        >
                            {numberOfNotifications}
                        </span>
                    ) : null}
                </Link>
            </div>
        </div>
    );
});
