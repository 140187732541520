import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import Button from "components/Button/Button";
import Spinner from "components/Icons/Spinner/Spinner";
import useSaveZone from "hooks/useSaveZone.hook";
import {
    FormEvent,
    memo,
    useCallback,
    useEffect,
    useMemo,
    useState,
} from "react";
import rootStore from "stores/rootStore";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import HVACSettings from "./HVACSettings/HVACSettings";
import ZoneAutomation from "./ZoneAutomation/ZoneAutomation";
import ZoneOccupancy from "./ZoneOccupancy/ZoneOccupancy";
import ZoneSettingFormErrorBase from "./data/ZoneSettingFormErrorBase";
import type ZoneSettingFormProps from "./types/FormData.type";
import ZoneSettingProps from "./types/ZoneSettingsProps.props";
import {
    validateHVAC,
    validateOccupancyOverride,
} from "./utilities/validateForm";

export default memo(function ZoneSettings({
    zone,
}: ZoneSettingProps): JSX.Element {
    const ZoneSettingFormBaseData = useMemo(() => {
        const zoneTemps = zone.attributes.store.recommendedTemperature;
        return {
            enableAutomation: zone.attributes.store.enableAutomation,
            occupancy: zone.attributes.store.occupancyOverride || {
                userDefinedStartTime: "",
                userDefinedEndTime: "",
            },
            hvac: {
                heating: {
                    occupied: zoneTemps.heatingOccupied,
                    unoccupied: zoneTemps.heatingUnoccupied,
                },
                cooling: {
                    occupied: zoneTemps.coolingOccupied,
                    unoccupied: zoneTemps.coolingUnoccupied,
                },
            },
        };
    }, [zone]);

    const [formData, setFormData] = useState<ZoneSettingFormProps>(
        ZoneSettingFormBaseData,
    );
    const [isSubmitting, setSubmitting] = useState(false);
    const [errors, setErrors] = useState(ZoneSettingFormErrorBase);

    const errorsFound = useMemo((): boolean => {
        const { occupancy, hvac } = errors;

        return !!(
            hvac.cooling.occupied ||
            hvac.cooling.unoccupied ||
            hvac.heating.unoccupied ||
            hvac.heating.occupied ||
            occupancy.userDefinedStartTime ||
            occupancy.userDefinedEndTime ||
            occupancy.other
        );
    }, [errors]);

    const { mutate: saveZone } = useSaveZone(zone);

    //This is a rather verbose method, it can b simplified by converting YUP schema validation and RHF
    const onSubmit = useCallback(
        async (e: FormEvent): Promise<void> => {
            e.preventDefault();
            setSubmitting(true);
            try {
                if (!errorsFound) {
                    const updatedZone: Zone = { ...zone };
                    updatedZone.attributes.store.enableAutomation =
                        formData.enableAutomation;
                    if (
                        formData.occupancy.userDefinedStartTime &&
                        formData.occupancy.userDefinedEndTime
                    ) {
                        updatedZone.attributes.store.occupancyOverride =
                            formData.occupancy;
                    } else {
                        updatedZone.attributes.store.occupancyOverride = {
                            userDefinedStartTime: "",
                            userDefinedEndTime: "",
                        };
                    }
                    updatedZone.attributes.store.recommendedTemperature = {
                        heatingOccupied: formData.hvac.heating.occupied,
                        heatingUnoccupied: formData.hvac.heating.unoccupied,
                        coolingOccupied: formData.hvac.cooling.occupied,
                        coolingUnoccupied: formData.hvac.cooling.unoccupied,
                    };
                    await saveZone(updatedZone as ZoneWithOverallHealth);
                } else {
                    //This is one type of fix. We should capture proper validation errors as the user is entering data.
                    //However, this is alternate method. If errors are found after the user presses submit, provide a detail explanation of
                    //What those errors are and where.
                    rootStore.snackbarStore.enqueueSnackbar?.(
                        <div>
                            <p>
                                Failed to update zone settings for: {zone.name}
                                Errors found for the following reasons:
                            </p>
                            <ul>
                                {/**There is no validation provided for automation */}
                                <li key={2}>
                                    {errors.hvac.cooling
                                        ? `Cooling: There is at least 1 invalid field`
                                        : null}{" "}
                                    <br />
                                    {errors.hvac.heating
                                        ? `Heating: There is at least 1 invalid field`
                                        : null}
                                </li>
                                <li key={3}>
                                    {errors.occupancy.other
                                        ? `Occupancy: ${errors.occupancy.other}`
                                        : null}
                                </li>
                            </ul>
                        </div>,
                        {
                            variant: "error",
                        },
                    );
                }
            } catch (error) {
                console.error(error);
                rootStore.snackbarStore.enqueueSnackbar?.(
                    `Failed to update zone settings for: ${zone.name}`,
                    {
                        variant: "error",
                    },
                );
            } finally {
                setSubmitting(false);
            }
        },
        [formData, errors, errorsFound],
    );

    useEffect(() => {
        setErrors({
            ...errors,
            occupancy: validateOccupancyOverride(formData),
            hvac: validateHVAC(formData),
        });
    }, [formData]);

    return (
        <>
            <div className="flex flex-row py-4">
                <h2 className="text-lg py-4 pl-16 my-auto min-w-25">
                    Zone HVAC Settings
                </h2>
            </div>
            <form
                onSubmit={onSubmit}
                className="flex my-2 flex-col pl-16 pr-14"
            >
                {isSubmitting ? (
                    <Spinner />
                ) : (
                    <>
                        <ZoneAutomation
                            formData={formData}
                            errors={errors}
                            setFormData={setFormData}
                        />
                        <HVACSettings
                            formData={formData}
                            errors={errors}
                            setFormData={setFormData}
                        />
                        <ZoneOccupancy
                            formData={formData}
                            errors={errors}
                            setFormData={setFormData}
                        />
                        <Button
                            variant={"contained"}
                            type="submit"
                            className="mx-2 mt-6 w-7"
                            disabled={errorsFound}
                        >
                            Save
                        </Button>{" "}
                    </>
                )}
            </form>
        </>
    );
});
