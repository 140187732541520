import Access from "@srtlabs/m1_types/lib/Access/Access.type";
import { action, makeAutoObservable, observable } from "mobx";
import User from "types/User";

/**
 * React store the literally extends the user and makes the user key fields reactive
 * also adds the ability to logout
 */
export default class UserStore implements User {
    @observable
    public _id = "";
    @observable
    public name = "";
    @observable
    public email = "";
    @observable
    public phone = "";
    @observable
    public picture = "";
    @observable
    public position = "";
    @observable
    public notification = "";
    @observable
    public "role-groups" = {};
    @observable
    public access: Access = {};

    private _logout: () => void = (): void => {
        /*
         */
    };

    public logout(): void {
        if (this._logout) {
            this._logout();
        } else {
            console.error("ERROR, FAILED LOGOUT, logout is not set?");
        }
    }

    public setLogout(callback: () => void): void {
        this._logout = callback;
    }

    public get type(): "user" {
        return "user";
    }

    /**
     * takes a unreactive user, and sets them to be this reactive user
     */
    @action
    public setUser(user: User): void {
        this._id = user._id;
        this.name = user.name;
        this.email = user.email;
        this.phone = user.phone;
        this.picture = user.picture;
        this.position = user.position;
        this.notification = user.notification;
        this["role-groups"] = user["role-groups"];
        this.access = user.access;
    }

    public constructor() {
        makeAutoObservable(this);
    }
}
