import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import type OLMap from "ol/Map";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";

/**
 * Checks if there is data or a openlayers instance to render the map
 * If not, it throws an error and specific messaging for each problem
 * @param data Data to populate as features on the map
 * @param map Openlayers instance of the map
 */
export default function checkIsMapIsReady(
    data: (
        | Device
        | ZoneWithOverallHealth
        | undefined
        | LocationMapOverlayDetail
    )[],
    map: OLMap | null,
): void {
    try {
        if (data.length === 0) {
            throw new Error(
                "Condition not met for creating features and layers. No map data present",
            );
        }
        if (!map) {
            throw new Error(
                "Condition not met for creating features and layers. Map reference not detected",
            );
        }
        return;
    } catch (err) {
        console.warn(err);
    }
}
