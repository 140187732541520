import extendClassName from "utils/extendClassName";
import NotificationTableHeaderProps from "./NotificationTableHeaderProps.props";
import UseSortableTableOutput from "hooks/useSortTable/UseSortableTableOutput.props";
import NOTIFICATION_SORT_OPTIONS from "./util/NOTIFICATION_SORT_OPTION.enum";
import { useCallback } from "react";
export default function NotificationTableHeader<
    T extends string | number | symbol,
>({
    options,
    handleSetSort,
    arrowType,
    isColumnSelected,
    handleHover,
}: NotificationTableHeaderProps<NOTIFICATION_SORT_OPTIONS> &
    UseSortableTableOutput<T>): JSX.Element {
    const colSpan = useCallback(
        (header: string): string =>
            header === "Status"
                ? "w-[6%]"
                : header === "Message"
                ? "w-[40%]"
                : "w-[16%]",
        [],
    );
    return (
        <thead className="h-5 w-full sticky top-0 right-0 left-0 border-gray-700">
            <tr className="h-5 text-left font-normal bg-gray-100 shadow-inner-obsidian">
                {options.map(({ label, type }) => (
                    <th
                        key={label}
                        onClick={(): void =>
                            handleSetSort({ title: label, type })
                        }
                        onMouseOver={(): void => handleHover(type, true)}
                        onMouseOut={(): void => handleHover(type, false)}
                        className={extendClassName(
                            "rounded-sm group cursor-pointer px-4 py-0 h-full border-r-1 border-gray-700 ",
                            colSpan(label),
                        )}
                    >
                        <span
                            className={
                                "w-min p-0 h-min group flex flex-row my-auto"
                            }
                        >
                            {label}{" "}
                            <button
                                className={extendClassName(
                                    "w-min pl-1 mx-1 group-hover:visible",
                                    isColumnSelected(type) ? "" : "invisible",
                                )}
                            >
                                {/**if column is not selected show static button until selected */}
                                {arrowType(type)}
                            </button>
                        </span>
                    </th>
                ))}
            </tr>
        </thead>
    );
}
