/**
 * Prevents the user from typing alphabet and certain shortcuts
 * Meant for strictly number fields
 * @param event
 */
export default function preventAlphabetForNumInput(
    event: React.KeyboardEvent<HTMLInputElement>,
): void {
    const allowedKeys = [
        "Backspace",
        "Delete",
        "Tab",
        "Enter",
        "Escape",
        "ArrowRight",
        "ArrowDown",
        "ArrowLeft",
        "ArrowUp",
        "End",
        "Home",
    ];
    const key = event.key;

    if (allowedKeys.includes(key)) {
        return;
    }
    if (
        (key >= "0" && key <= "9") ||
        ((key === "-" || key === ".") && !event.shiftKey)
    ) {
        return;
    }
    event.preventDefault();
}
