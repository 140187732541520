import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import DeviceService from "services/DeviceService";
import queryClient from "stores/queryClient";
import DeviceNotification from "types/Notification.type";
import TableNotificationItem from "views/Home/NotificationContainer/NotificationTableHeader/util/TableNotificationItem.type";

/**
 * A service call that retrieves a device and maps its information to be set as a TableRowItem
 */
export default async function getMappedNotification(
    notification: DeviceNotification,
): Promise<TableNotificationItem> {
    // Check if device data for this ID is already cached
    const device: Device = await queryClient.ensureQueryData({
        queryKey: ["device", notification.device_id],
        queryFn: () => DeviceService.getDevice(notification.device_id),
    });

    return {
        id: notification.id,
        status: notification.notification_type as HEALTH_STATUS,
        deviceName: device.headers.deviceName,
        device,
        message: notification.message,
        sublocation: device.headers.sublocation,
        event_time: notification.event_time,
    };
}
