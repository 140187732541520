import { v4 } from "uuid";
import CALENDAR_RESOURCES_T from "./CalendarResource.type";
import CALENDAR_RESOURCES_COL from "./CALENDAR_RESOURCES.enum";

export const CALENDAR_RESOURCES_RECOMMENDED: CALENDAR_RESOURCES_T = {
    resourceId: v4(),
    resourceTitle: CALENDAR_RESOURCES_COL.RECOMMENDED,
};

export const CALENDAR_RESOURCES_OCCUPANCY: CALENDAR_RESOURCES_T = {
    resourceId: v4(),
    resourceTitle: CALENDAR_RESOURCES_COL.OCCUPANCY,
};

export const CALENDAR_RESOURCES_OVERRIDE: CALENDAR_RESOURCES_T = {
    resourceId: v4(),
    resourceTitle: CALENDAR_RESOURCES_COL.OVERRIDE,
};

export const CALENDAR_RESOURCES = {
    CALENDAR_RESOURCES_OCCUPANCY,
    CALENDAR_RESOURCES_RECOMMENDED,
    CALENDAR_RESOURCES_OVERRIDE,
};

export const CALENDAR_RESOURCES_VALUES = [
    CALENDAR_RESOURCES_OCCUPANCY,
    CALENDAR_RESOURCES_RECOMMENDED,
];
