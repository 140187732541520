import getFeatureStatus from "stores/classes/Openlayers/utils/getFeatureStatus";
import { getZoneStyle } from "./getZoneStyle";
import { FeatureUpdateFunction } from "stores/classes/Openlayers/utils/FeatureUpdateFunctions.util";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";

/**
 * Updates Zone style and status. Zones cannnot change coordinates yet so this is exluded.
 */
const updateZoneFeature: FeatureUpdateFunction<ZoneWithOverallHealth> = async (
    zone,
    map,
    state,
    existingFeature,
) => {
    const status = await getFeatureStatus("Zone", zone);

    return {
        properties: {
            ...zone,
            featureType: "Zone",
            layerName: "Zone",
        },
        style: getZoneStyle({ feature: existingFeature, status, state }),
    };
};

export default updateZoneFeature;
