import { memo, useCallback, useMemo } from "react";
import LinearScaledMultiRangeEditableContentProps from "./utilities/LinearScaledMultiRangeEditableContent.props";
import DISPLAY_TYPE from "@srtlabs/m1_types/lib/Displays/DISPLAY_TYPE/DISPLAY_TYPE.enum";
import ScaledRangeContainer from "./ScaledRangeContainer/ScaledRangeContainer";
import LinearScale from "@srtlabs/m1_types/lib/Displays/Dimensions/LINEAR_SCALED_MULTI_RANGE_SAFE_VAL_DIMENSION/LinearScale/LinearScale.type";
import { ObjectSchema, ValidationError } from "yup";
import linearScaledMultiRangeValidationSchema from "../../../../../../../../../schemas/linearScaledMultiRangeValidationSchema.schema";
import { Resolver, useForm } from "react-hook-form";
import scaledSourceRangeConfig from "./ScaledRangeContainer/ScaledSourceRangeConfig.data";
import StringLinearScale from "types/StringLinearScale";
import LinearScaleErrors from "types/LinearScaleErrors";
import convertStringToDotNotation from "utils/convertStringToDotNotation";
import {
    convertLinearScaleToNumbers,
    convertLinearScaleToString,
} from "./utilities/convertLinearScale";

/**
 * Our default data for the RHF form. Because text fields handle numbers as strings,
 * we must remember to convert these fields to numbers in the final computation
 */
const linearScaledState: StringLinearScale = {
    sourceMin: "",
    sourceMax: "",
    scaledMin: "",
    scaledMax: "",
    scaledDataType: "",
    scaledUnits: "",
};
export default memo(function LinearScaledMultiRangeEditableContent({
    options,
    setHasErrors,
    setLinearScaleState,
}: LinearScaledMultiRangeEditableContentProps): JSX.Element {
    /**
     *  Output: We need a default value for if the expected dimension is not found,
     *  rather than put through an error, set an empty state, which is later updated
     *  with the imported dimensions.
     */
    const dimension: StringLinearScale = useMemo(() => {
        try {
            let output: StringLinearScale = linearScaledState;
            if (
                DISPLAY_TYPE.LINEAR_SCALED_MULTI_RANGE_SAFE_VAL in options &&
                options.linearScaledMultiRangeSafeVal
            ) {
                output = convertLinearScaleToString(
                    options.linearScaledMultiRangeSafeVal.scale,
                );
            }

            return output;
        } catch (err) {
            console.error(
                "Could not resolve dimension type, aborting setting dimensions",
            );
            throw new Error("Could not find linearscaledmultirange");
        }
    }, [options]);

    /**
     * Validates the form values against the multiRangeSafeVal schema.
     * As a side effect, if the errors are found alerts the @see to disable save fields
     * If no errors, updates global form state
     *
     * @param {StringLinearScale} values - The current form values.
     * @returns {object} - An object containing validation errors, if any.
     */
    const useYupValidationResolver = (
        validationSchema: ObjectSchema<StringLinearScale>,
    ): ((data: StringLinearScale) => Promise<{
        values: StringLinearScale;
        errors: LinearScaleErrors;
    }>) =>
        useCallback(
            async (data: StringLinearScale) => {
                try {
                    //Attempt to valiate form values
                    const values = await validationSchema.validate(data, {
                        abortEarly: false,
                    });

                    //Assuming they pass, convert them to their proper type as numbers
                    //empty strings will be converted to null
                    const valuesAsNum: LinearScale =
                        convertLinearScaleToNumbers(values);

                    //Submit them to a global form state and enable submit buttons
                    setLinearScaleState(valuesAsNum);
                    setHasErrors(false);

                    return {
                        values,
                        errors: {} as unknown as LinearScaleErrors,
                    };
                } catch (errors) {
                    //We are only worried about Yup.ValidationErrors here
                    if (ValidationError.isError(errors)) {
                        //Disable submit buttons, since we found some errors
                        setHasErrors(true);
                        const resolvedErrors = {
                            values: data,
                            errors: errors.inner.reduce(
                                (allErrors, currentError) => {
                                    //RHF uses dot notation to locate fields/inputs
                                    //so we need to convert the yup paths (which use []), to dots
                                    return {
                                        ...allErrors,
                                        [(currentError.path &&
                                            convertStringToDotNotation(
                                                currentError.path,
                                            )) ||
                                        "unknown"]: {
                                            type:
                                                currentError.type ??
                                                "validation",
                                            message: currentError.message,
                                        },
                                    };
                                },
                                {},
                            ),
                        };
                        return resolvedErrors;
                    } else {
                        //TODO: Handle other types of errors, most likely with the toastit note
                        console.error(
                            "An unexpected error occurred during validation:",
                            errors,
                        );

                        return {
                            values: data,
                            errors: {
                                default:
                                    "An unexpected error occurred. Please try again or contact support.",
                            },
                        };
                    }
                }
            },
            [validationSchema],
        );

    const resolver: Resolver<StringLinearScale, unknown> =
        useYupValidationResolver(linearScaledMultiRangeValidationSchema);

    const {
        control,
        trigger,
        formState: { errors },
    } = useForm<StringLinearScale>({
        defaultValues: dimension,
        resolver: resolver,
        mode: "onChange",
    });

    return (
        <form
            onSubmit={(): void => {
                return;
            }}
            className="flex flex-1 flex-col"
        >
            <ul className="flex flex-col flex-wrap">
                {scaledSourceRangeConfig.map((item) => (
                    <ScaledRangeContainer
                        key={item.title}
                        title={item.title}
                        minLabel={item.min.label}
                        minFieldName={item.min.fieldName}
                        maxLabel={item.max.label}
                        maxFieldName={item.max.fieldName}
                        control={control}
                        trigger={trigger}
                        errors={errors}
                    />
                ))}
            </ul>
        </form>
    );
});
