import { Feature } from "ol";
import { Polygon } from "ol/geom";
import FeatureInteractiveState from "types/OpenLayers/FeatureInteractiveState";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import { getZoneStyle } from "./getZoneStyle";
import { transformPolygonPointsToCoordinates } from "./utilities/transformPolygonPointToCoordinate";

/**
 * Create an OpenLayers feature for a given zone.
 *
 * This function first transform the position of the zone to fit within the conventions
 * of the OpenLayers map and then creates a feature
 * object is attached as properties to the feature, allowing for easier styling and access
 * later
 * @param {Zone} zone - The zone object containing the coordinates and other important information
 * @returns {Feature} - The resulting OpenLayers feature with the correct coordinates and the zone's properties attached.
 */
export const createZoneFeature = async (
    zone: ZoneWithOverallHealth,
    map: MapDisplayDetails,
    state: FeatureInteractiveState,
): Promise<Feature> => {
    const feature = new Feature({
        geometry: new Polygon(
            transformPolygonPointsToCoordinates(zone.points, map),
        ),
    });

    //Specifically set the zone id as the feature id for easier retrival
    feature.setId(zone._id);
    // feature.setGeometryName(zone.name);
    const status = zone.overallHealth;
    // Attach the entire device object to the feature for easier styling/access
    feature.setProperties(zone);

    //Set custom featureType for ease of access
    feature.set("featureType", "Zone");
    feature.set("layerName", "Zone");

    const style = getZoneStyle({ feature, status, state });
    feature.setStyle(style);

    return feature;
};
