import { memo, useMemo } from "react";
import extendClassName from "utils/extendClassName";
import healthColor from "utils/healthColor";
import type CurrentDeviceCardContentStatusDrawerItemStatusBarProps from "./utilities/CurrentDeviceCardContentStatusDrawerItemStatusBar.props";

export default memo(function CurrentDeviceCardContentStatusDrawerItemStatusBar({
    health,
    offline,
}: CurrentDeviceCardContentStatusDrawerItemStatusBarProps): JSX.Element {
    const background = useMemo(() => {
        if (offline === true) return "bg-gray-700";

        return healthColor(health);
    }, [offline, health]);

    return (
        <div
            className={extendClassName("h-full w-3.5 opacity-75", background)}
        />
    );
});
