import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import GroupedDevice from "@srtlabs/m1_types/lib/GroupedDevice/GroupedDevice.type";
import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import MobileDevice from "@srtlabs/m1_types/lib/MobileDevice/MobileDevice.type";
import LocationMapOverlayDetail from "@srtlabs/m1_types/lib/Organization/Locations/Location/LocationMaps/LocationMap/LocationMapOverlayDetails/LocationMapOverlayDetail/LocationMapOverlayDetail.type";
import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import FeatureType from "types/OpenLayers/FeatureType.type";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";
import calculateDeviceHealth from "utils/calculateDeviceHealth";
import capitalize from "utils/capitalize";
import findProblemsWithZone from "utils/findProblemsWithZone.util";

type StatusCalculator = (
    data:
        | Device
        | GroupedDevice
        | MobileDevice
        | Zone
        | LocationMapOverlayDetail,
) => "offline" | HEALTH_STATUS | Promise<"offline" | HEALTH_STATUS>;

const statusCalculators: Record<FeatureType, StatusCalculator> = {
    Device: (data) => calculateDeviceHealth(data as Device),
    GroupedDevice: (data) => calculateDeviceHealth(data as GroupedDevice),
    MobileDevice: (data) => calculateDeviceHealth(data as MobileDevice),
    Zone: async (data) => {
        const zone = await findProblemsWithZone(data as ZoneWithOverallHealth);
        return zone.overallHealth;
    },
    SublocationOverlay: () => HEALTH_STATUS.SAFE,
};

export default async function getFeatureStatus(
    type: FeatureType,
    data:
        | Device
        | GroupedDevice
        | MobileDevice
        | Zone
        | LocationMapOverlayDetail,
): Promise<"offline" | HEALTH_STATUS> {
    //TODO: error handling or wqarning if type is not found
    const format = capitalize(type) as FeatureType;
    const calculator = await statusCalculators[format];
    return calculator && calculator(data);
}
