import Device from "@srtlabs/m1_types/lib/Device/Device.type";
import Zone from "@srtlabs/m1_types/lib/Zone/Zone.type";
import type OpenLayersStore from "stores/classes/Openlayers/OpenLayersStore.class";
import LayerNames from "types/OpenLayers/LayerNames.type";
import MapDisplayDetails from "types/OpenLayers/MapDisplayDetails.type";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import ZoneWithOverallHealth from "types/ZoneWithOverallHealth.type";

//TODO: add error handling

type SublocationMapUtilsDependencies = {
    openLayersStore: OpenLayersStore;
    data: Device[] | ZoneWithOverallHealth[];
    mapViewMode: MAP_VIEW_TYPE;
    map: MapDisplayDetails;
};

/**
 * Add description
 * @param openLayersStore
 * @param mapViewMode
 */
export const createLayersIfNeeded = (
    openLayersStore: OpenLayersStore,
    mapViewMode: MAP_VIEW_TYPE,
): void => {
    if (!openLayersStore.areLayersPresent) {
        openLayersStore.createLayer(mapViewMode);
    }
};

export const updateSublocationMapFeatures = async ({
    openLayersStore,
    data,
    mapViewMode,
    map,
}: SublocationMapUtilsDependencies): Promise<void> => {
    // Remove features that no longer exist in the data
    //TODO: does not handle changes in data? This should be the responsibility of a
    removeObsoleteSublocationMapFeatures(openLayersStore, data);

    // Create or update features based on the mapViewMode
    await createOrUpdateSublocationMapFeatures(
        openLayersStore,
        data,
        mapViewMode,
        map,
    );

    // Add features to layers
    openLayersStore.addFeaturesToLayers(
        mapViewMode as LayerNames,
        openLayersStore.features,
    );
};

const removeObsoleteSublocationMapFeatures = (
    openLayersStore: OpenLayersStore,
    data: Device[] | Zone[],
): void => {
    const existingFeatureIds = Array.from(openLayersStore.features.keys());
    const newFeatureIds = data.map((item) => item._id);
    const featuresToRemove = existingFeatureIds.filter(
        (id) => !newFeatureIds.includes(id),
    );

    featuresToRemove.forEach((id) => {
        openLayersStore.featureManager?.removeFeature(id);
    });
};

// const removeObsoleteFeatures = (
//     openLayersStore: OpenLayersStore,
//     data: Device[] | ZoneWithOverallHealth[],
// ): void => {
//     const existingFeatures = Array.from(openLayersStore.features.values());

//     existingFeatures.forEach((feature) => {
//         let updatedFeature: Device | ZoneWithOverallHealth | undefined;

//         if (
//             feature.type === "Device" ||
//             feature.type === "GroupedDevice" ||
//             feature.type === "MobileDevice"
//         ) {
//             updatedFeature = (data as Device[]).find(
//                 (item) => item._id === feature._id,
//             );
//         } else if (feature.type === "Zone") {
//             updatedFeature = (data as ZoneWithOverallHealth[]).find(
//                 (item) => item._id === feature._id,
//             );
//         }
//         if (updatedFeature) {
//             //If the feature exists in updated data
//             //Check if the health status has changed

//             if (checkIfFeatureHealthChanged(feature, updatedFeature)) {
//                 //update the feature in the store
//                 openLayersStore.updateFeature(feature._id, updatedFeature);
//             }
//         } else {
//             openLayersStore.featureManager?.removeFeature(feature._id);
//         }
//     });
// };

const createOrUpdateSublocationMapFeatures = async (
    openLayersStore: OpenLayersStore,
    data: Device[] | Zone[],
    mapViewMode: MAP_VIEW_TYPE,
    map: MapDisplayDetails,
): Promise<void> => {
    try {
        if (!openLayersStore.featureManager)
            throw new Error("Feature manager not initialized");
        const featurePromises = data.map(async (item) => {
            // Check if the feature already exists by searching for its id in the featureStore
            const existingFeature =
                openLayersStore.featureManager?.getFeatureById(item._id);

            if (existingFeature) {
                // Update the OpenLayers feature if it exists already
                openLayersStore.updateFeature(existingFeature._id, item);
            } else {
                // Add the new feature to the store
                await openLayersStore.featureManager?.createAndAddFeatureToStore(
                    mapViewMode,
                    item,
                    map,
                );
            }
        });

        // Wait for all feature creation and update promises to resolve
        await Promise.all(featurePromises);
    } catch (error) {
        console.error(
            "Could not create or update sublocation map features",
            error,
        );
        // throw new Error("Could not create or update sublocation map features");
    }
};
