import { IReactionDisposer, reaction } from "mobx";
import WebsocketStore from "./Websocket.class";
import type OrganizationStore from "./classes/OrganizationStore.class";
import rootStore from "./rootStore";

/**
 * A reaction is a function that gets automatically executed whenever
 * an observed value changes.
 *
 * This class sets up reactions for various changes in the application state,
 * such as changes in the organization, location, or sublocation.
 */
class ReactionManager {
    private organizationStore: OrganizationStore;
    private websocketStore: WebsocketStore;

    //dispose of the reactions when they are no longer needed.
    private disposers: IReactionDisposer[] = [];

    constructor(
        organizationStore: OrganizationStore,
        websocketStore: WebsocketStore,
    ) {
        this.organizationStore = organizationStore;
        this.websocketStore = websocketStore;
        this.disposers = [];
        this.setupReactions();
    }
    /**
     * Each reaction monitors a variable that is subject to change.
     * These reactions are pushed to a disposers array, that will be cleared
     * when the class is unmounted.
     */
    public setupReactions(): void {
        /**
         * when the @see OrganizationStore.location updates, unsubscribe from old devices, and
         * resubscribe to the new location devices
         * note that this should only run when location is set (not sublocation)
         * @see deviceStore.clearAndSubscribe
         * @see organizationStore.location
         */
        this.disposers.push(
            reaction(
                () => [rootStore.apiStore.token, rootStore.userStore._id],
                ([token, userId]) => {
                    if (token && userId) {
                        rootStore.websocketStore.connect("/subscribe", token);
                    } else {
                        rootStore.websocketStore.disconnect();
                    }
                },
            ),
            reaction(
                () => ({
                    // Using .slice() to create shallow copies of the arrays.
                    // So that the reaction triggers on content changes,
                    // MobX tracks changes by reference.
                    locId: this.organizationStore.locId,
                    locationDevices: this.organizationStore.locationDevices,
                    mobileDevices: this.organizationStore.mobileDevices,
                    connected: this.websocketStore.connected,
                }),
                ({ locationDevices, mobileDevices, connected }) => {
                    if (connected) {
                        let shouldResubscribe = false;
                        // Check if all devices are currently subscribed
                        [...locationDevices, ...mobileDevices].forEach(
                            (device) => {
                                if (!this.websocketStore.isSubscribed(device)) {
                                    shouldResubscribe = true;
                                }
                            },
                        );

                        // Resubscribe if any device is not subscribed
                        if (shouldResubscribe) {
                            this.websocketStore.clearAndSubscribe([
                                ...locationDevices,
                                ...mobileDevices,
                            ]);
                        }
                    }
                },
            ),
            reaction(
                () => ({
                    organization: this.organizationStore.organization,
                    connected: this.websocketStore.connected,
                }),
                ({ organization, connected }) => {
                    if (connected && !organization) {
                        this.websocketStore.unsubscribeFromAll();
                    }
                },
            ),
        );
    }

    public dispose(): void {
        this.disposers.forEach((disposer) => disposer());
    }
}

export default new ReactionManager(
    rootStore.organizationStore,
    rootStore.websocketStore,
);
