import { memo } from "react";

export default memo(function EmptyAlertView() {
    return (
        <div className="flex items-center justify-center flex-col text-center w-full h-full">
            <h2 className="text-lg font-nunito font-bold">No Alerts Yet</h2>
            <p className="w-full p-8">
                New alerts will appear here as action is recommended.{" "}
            </p>
        </div>
    );
});
