import { animated, useTransition } from "@react-spring/web";
import Spinner from "components/Icons/Spinner/Spinner";
import { memo, useCallback, useEffect, useState } from "react";
import extendClassName from "utils/extendClassName";
import ConnectionStatusBannerProps from "./utilities/ConnectionStatusBanner.props";

enum BANNER_MESSAGE {
    CONNECTING = "Establishing connection... Application may not behave as normal.",
    OPEN = "Connection established successfully.",
    CLOSING = "Closing connection to server...",
    CLOSED = "Connection to server lost.",
}

export default memo(function ConnectionStatusBanner({
    readyState,
}: ConnectionStatusBannerProps): JSX.Element {
    const [message, setMessage] = useState<BANNER_MESSAGE | "">(
        BANNER_MESSAGE.CONNECTING,
    );
    const [isBannerOpen, setBannerOpen] = useState(false);

    let timeOutID: NodeJS.Timeout;

    const backgroundColor = useCallback((readyState: number): string => {
        if (readyState === 1) {
            return "bg-green text-white";
        }
        if (readyState === 3) {
            return "bg-red text-white";
        }
        return "bg-yellow";
    }, []);

    const transitions = useTransition(isBannerOpen, {
        from: { height: 0, paddingTop: 0, paddingBottom: 0 },
        enter: { height: 50, paddingTop: 20, paddingBottom: 20 },
        leave: { height: 0, paddingTop: 0, paddingBottom: 0 },
        reverse: isBannerOpen,
    });

    /**
     * Clears the messaging and timeoutID to reset the banner
     */
    const clearBanner = useCallback((): void => {
        setBannerOpen(false);
        setMessage("");
        clearTimeout(timeOutID);
    }, []);

    useEffect(() => {
        //If the latency is more than half a second we show the message.
        timeOutID = setTimeout(() => {
            setBannerOpen(true);
            switch (readyState) {
                case 0:
                    setMessage(BANNER_MESSAGE.CONNECTING);
                    break;
                case 1:
                    setMessage(BANNER_MESSAGE.OPEN);
                    timeOutID = setTimeout((): void => clearBanner(), 2500);
                    break;
                case 2:
                    setMessage(BANNER_MESSAGE.CLOSING);
                    break;
                case 3:
                    setMessage(BANNER_MESSAGE.CLOSED);
                    break;
                default:
                    setMessage(BANNER_MESSAGE.CONNECTING);
            }
        }, 600);
    }, [readyState]);

    return transitions(
        ({ height }, item) =>
            item && (
                <animated.div
                    className={extendClassName(
                        "z-[200] w-full flex items-center justify-center transition ease-in-out",
                        isBannerOpen ? backgroundColor(readyState) : "bg-white",
                    )}
                    style={{
                        height: height.to({
                            range: [0.0, 1.0],
                            output: [0, 1],
                        }),
                    }}
                >
                    <div className=" w-full relative text-center flex flex-row gap-2 justify-center items-center">
                        {readyState === 0 ? (
                            <Spinner variant="spinner" size={10} />
                        ) : null}
                        {message}
                    </div>
                </animated.div>
            ),
    );
});
