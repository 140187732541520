import { useEffect, useRef } from "react";

type DelayedUpdateFunction<T extends unknown[]> = (...args: T) => void;

/**
 * Returns a function that will call the provided
 * `updateFn` after the specified `delay` in milliseconds.
 *
 * @template T - Represents the types of arguments accepted by the `updateFn`, we can pass multi here.
 * @param {number} delay - The delay before calling the `updateFn`.
 * @param {DelayedUpdateFunction<T>} updateFn - The function to be called after the specified delay.
 * @returns {DelayedUpdateFunction<T>} A function that will perform the `updateFn` after the specified delay with the provided args.
 */
const useDelayedUpdate = <T extends unknown[]>(
    delay: number,
    updateFn: DelayedUpdateFunction<T>,
): DelayedUpdateFunction<T> => {
    const timeoutIdRef = useRef<ReturnType<typeof setTimeout> | null>(null);

    useEffect(() => {
        return (): void => {
            if (timeoutIdRef.current !== null) {
                clearTimeout(timeoutIdRef.current);
            }
        };
    }, []);

    const delayedUpdate: DelayedUpdateFunction<T> = (...args: T) => {
        if (timeoutIdRef.current !== null) {
            clearTimeout(timeoutIdRef.current);
        }
        timeoutIdRef.current = setTimeout(() => {
            updateFn(...args);
        }, delay);
    };

    return delayedUpdate;
};
export default useDelayedUpdate;
