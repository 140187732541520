import { createContext } from "react";

export interface ToggleButtonGroupContextType<T> {
    value: T;
    onChange: (value: T) => void;
}

const ToggleButtonGroupContext = createContext<
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    ToggleButtonGroupContextType<any> | undefined
>(undefined);

export default ToggleButtonGroupContext;
