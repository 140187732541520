import ZoneSubType from "@srtlabs/m1_types/lib/Zone/ZoneSubType/ZoneSubType.enum";
import ErrorDrawer from "components/ErrorDrawer/ErrorDrawer";
import useQuery from "hooks/useQuery/useQuery";
import useZone from "hooks/useZone.hook";
import { memo } from "react";
import ZoneHVAC from "./ZoneHVAC/ZoneHVAC";
import ZoneSimple from "./ZoneSimple/ZoneSimple";

const zoneError =
    "We are unable to retrieve the relevant zone data. Please refresh and try again.";
export default memo(function CurrentZoneDrawer(): JSX.Element {
    const { currentZone } = useQuery();
    const { data: zone, isLoading, isError } = useZone({ zoneId: currentZone });
    if (!zone || isLoading)
        return <ErrorDrawer message="Loading relevant zone data..." />;
    if (isError) {
        return <ErrorDrawer message={zoneError} />;
    }

    return zone.subType === ZoneSubType.SCHEDULED_HVAC ? (
        <ZoneHVAC zone={zone} />
    ) : (
        <ZoneSimple zone={zone} />
    );
});
