import CurrentDeviceCardContentFunction from "components/CurrentDeviceCard/CurrentDeviceCardDrawer/CurrentDeviceCardContent/CurrentDeviceCardContentFunction/CurrentDeviceCardContentFunction";
import { useCallback, useEffect, useRef, useState } from "react";
import ExecutionFunctionContainerProps from "./ExecutionFunctionContainerProps.props";
import { observer } from "mobx-react-lite";

/**
 * Because we are unable to automatically receive updates about the robotStatus automatically
 * We are forced to retrieve them at a specific cadence. This cadence should be interrupted
 * if the user executes a function, upon which it will be fetched immediately
 * After the fetch, the cadence will continue as normal
 * While the fetch is occurring, we will feign a loading display
 */

export default observer(function ExecutionFunctionConainer({
    device,
    executablesStore,
}: ExecutionFunctionContainerProps): JSX.Element | null {
    const [isIntervalPaused, setPauseInterval] = useState(false);
    const intervalRef = useRef<ReturnType<typeof setInterval> | null>(null);

    /**
     * This func retrieves all execution functions available to the device
     * @return { data, state, error }
     **/
    const tick = useCallback(async () => {
        await executablesStore.getExecutionFunctions(device._id);
    }, [device._id, executablesStore.getExecutionFunctions]);

    const startInterval = useCallback(
        (sec = 6): void => {
            tick(); // execute tick once before setting the interval
            setPauseInterval(false);
            intervalRef.current = setInterval(tick, sec * 1000);
        },
        [tick],
    );

    const stopInterval = useCallback(() => {
        clearInterval(intervalRef.current as NodeJS.Timer);
        return null;
    }, [intervalRef.current]);

    /**
     * Toggles current Interval
     */
    const toggleInterval = useCallback(() => {
        setPauseInterval(!isIntervalPaused);
        if (intervalRef.current) {
            clearInterval(intervalRef.current);
            intervalRef.current = null;
        } else {
            startInterval();
        }
    }, [isIntervalPaused, intervalRef.current, startInterval]);

    /**
     * Pause Interval for an alotted amount of time.
     */
    const pauseInterval = useCallback(
        (seconds: number) => {
            setPauseInterval(true);
            clearInterval(intervalRef.current as NodeJS.Timer);
            const timer = setTimeout(() => {
                intervalRef.current = setInterval(tick, 6000);
                setPauseInterval(false);
            }, 1000 * seconds);
            return (): void => clearTimeout(timer);
        },
        [tick, intervalRef.current],
    );

    /**
     * Initializes the interval loop
     * We clear the interval using the stopInterval call.
     * This will be executed when the component unmounts or when the dependency array
     * changes.
     */
    useEffect(() => {
        if (!isIntervalPaused || !intervalRef.current) {
            startInterval();
        }
        return (): void => {
            if (intervalRef.current) {
                stopInterval();
            }
        };
    }, [device._id, isIntervalPaused]);

    /**
     * Gets execution functions on first render or when the user switches devices.
     */

    return executablesStore.executionFunctions ? (
        <CurrentDeviceCardContentFunction
            device={device}
            executionFunctions={executablesStore.executionFunctions}
            intervalRef={intervalRef}
            isIntervalPaused={isIntervalPaused}
            pauseInterval={pauseInterval}
            startInterval={startInterval}
            toggleInterval={toggleInterval}
            stopInterval={stopInterval}
        />
    ) : null;
});
