import type { Map } from "ol";
import { Coordinate } from "ol/coordinate";
import { Polygon } from "ol/geom";
import { useEffect, useRef } from "react";
import type FeatureManager from "stores/classes/Openlayers/FeatureManager.class";
import ManagedFeatureUnion from "types/OpenLayers/ManagedFeatureUnion.type";
import MAP_VIEW_TYPE from "types/OpenLayers/MapView.enum";
import getTopmostPointPixel from "utils/OpenLayers/utilities/getTopMostPixelPoint";

interface HoverProps {
    isVisible: boolean;
    coordinate: Coordinate;
    content: string;
}

interface useMapOnHoverListenerProps {
    featureManager: FeatureManager | null;
    map: Map | null;
    onHoverIn: (props: HoverProps) => void;
    onHoverOut: (props: HoverProps) => void;
    mapViewMode: MAP_VIEW_TYPE;
}

/**
 * Listens for on hover events, to display tooltips
 * @param featureManager
 * @param map
 * @param onHover
 * @param mapViewMode
 */
export default function useMapOnHoverListener({
    featureManager,
    map,
    mapViewMode,
    onHoverIn,
    onHoverOut,
}: useMapOnHoverListenerProps): void {
    const featureManagerRef = useRef<FeatureManager | null>(null);
    const mapRef = useRef<Map | null>(null);
    // Check if featureManager and map are available
    useEffect(() => {
        featureManagerRef.current = featureManager;
        mapRef.current = map;
    }, [featureManager, map]);

    /**
     * Handles the hover behavior for a feature. If the feature is not selected, it updates the feature's style to the hover style
     * and triggers the onHoverIn callback with the feature's details.
     */
    const handleFeatureHover = (
        feature: ManagedFeatureUnion,
        map: Map,
        content: string,
        onHoverIn: (props: HoverProps) => void,
    ): void => {
        if (!featureManagerRef.current || !mapRef.current) return;
        const hoveredFeature = feature.olFeature;
        const geometry = hoveredFeature.getGeometry();

        if (geometry instanceof Polygon) {
            const coordinate = getTopmostPointPixel(geometry, map, 50);
            onHoverIn({
                isVisible: true,
                coordinate,
                content: content || "",
            });
        }

        if (feature.state !== "selected") {
            featureManagerRef.current.updateFeatureStyle(feature._id, "hover");
        }
    };

    /**
     * Resets the style of the previously hovered feature to the default style.
     * This is done only if the feature is not in the selected state.
     */
    const resetPreviousFeatureStyle = (previousFeatureId: string): void => {
        if (!featureManagerRef.current) return;
        const previousFeature =
            featureManagerRef.current.getFeatureById(previousFeatureId);
        if (previousFeature && previousFeature.state !== "selected") {
            featureManagerRef.current.updateFeatureStyle(
                previousFeatureId,
                "default",
            );
        }
    };

    useEffect(() => {
        if (!featureManagerRef.current || !mapRef.current) {
            return;
        }

        let previouslyHoveredFeatureId: string | null = null;

        featureManagerRef.current.handleOnHover(
            ({ isHover, feature, content }) => {
                try {
                    if (isHover && feature && mapRef.current) {
                        handleFeatureHover(
                            feature,
                            mapRef.current,
                            content || "",
                            onHoverIn,
                        );

                        if (
                            previouslyHoveredFeatureId &&
                            previouslyHoveredFeatureId !== feature._id
                        ) {
                            resetPreviousFeatureStyle(
                                previouslyHoveredFeatureId,
                            );
                        }

                        previouslyHoveredFeatureId = feature._id;
                    } else {
                        // Reset the style of the previously hovered feature when no longer hovering
                        if (previouslyHoveredFeatureId) {
                            resetPreviousFeatureStyle(
                                previouslyHoveredFeatureId,
                            );
                            previouslyHoveredFeatureId = null;
                        }

                        onHoverOut({
                            isVisible: false,
                            coordinate: [0, 0],
                            content: "",
                        });
                    }
                } catch (err) {
                    console.warn(
                        "Could not handle feature on hover action",
                        err,
                    );
                }
            },
        );

        // Cleanup: Remove the hover event listener
        return (): void => featureManagerRef.current?.removeOnHover();
    }, [featureManager, map, mapViewMode]);
}
