import dayjs from "dayjs";
import advancedFormat from "dayjs/plugin/advancedFormat";
import timezone from "dayjs/plugin/timezone";

dayjs.extend(advancedFormat);
dayjs.extend(timezone); // to use time zone put `z`

const F_STRING = "MM/DD/YYYY hh:mm:ss A z" as const;

export default function formatDate(dateStr: string | Date): string {
    try {
        return dayjs(dateStr).format(F_STRING);
    } catch (e) {
        return dayjs(new Date().toString()).format(F_STRING);
    }
}

(window as unknown as Record<string, unknown>).formatDate = formatDate;
