import type HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";

function getPercentOfRange(val: number, min: number, max: number): number {
    if (Math.ceil(val) >= max) return 100;
    else if (val < min) return 0;
    return ((val - min) / (max - min)) * 100;
}

type InnerAndOuterRangePercentWithHealth = {
    left: number;
    right: number;
    type: HEALTH_STATUS;
};

type FindRangeWithInnerAndOuterRange = (
    numberTuple: number[],
) => InnerAndOuterRangePercentWithHealth;

export default function findRange(
    min: number,
    max: number,
    type: HEALTH_STATUS,
): FindRangeWithInnerAndOuterRange {
    return function findRangeWithInnerAndOuterRange([
        innerMin,
        innerMax,
    ]): InnerAndOuterRangePercentWithHealth {
        return {
            left: getPercentOfRange(innerMin, min, max),
            right: getPercentOfRange(innerMax, min, max),
            type: type,
        };
    };
}
