import HEALTH_STATUS from "@srtlabs/m1_types/lib/HEALTH_STATUS/HEALTH_STATUS.enum";
import EventTimeCol from "components/Tables/Columns/EventTimeCol/EventTimeCol";
import RowStyleT from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/RowStyle.props";
import setTableRowStyle from "components/ZoneDrawerContainer/CurrentZoneDrawer/ZoneHVAC/CurrentZoneDrawerContent/ZoneLogs/ZoneLogsRowItem/utilities/setTableRowStyleReduce";
import { id } from "date-fns/locale";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useReducer, useState } from "react";
import capitalize from "utils/capitalize";
import extendClassName from "utils/extendClassName";

interface RelatedNotificationsRowItemProps {
    health: HEALTH_STATUS;
    timeStamp: string;
    message: string;
}

const initialState: RowStyleT = {
    border: "shadow-inner-offline shadow-md",
    background: "",
};

export default observer(function RelatedNotificationsRowItem({
    health,
    timeStamp,
    message,
}: RelatedNotificationsRowItemProps): JSX.Element {
    const [isOpen, setIsOpen] = useState(false);

    //Handles the opening and closing of the click-to-expand area
    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialState,
    );

    useEffect(() => {
        dispatch({
            event_type: health,
        });
    }, [health]);

    return (
        <>
            <tr
                data-title={timeStamp}
                className={extendClassName(
                    "text-sm bg-white border-1 border-gray-700 h-[70px] w-full",
                    tableRowStyle.background,
                )}
                onClick={(): void => handleToggle()}
                data-id={id}
            >
                <td
                    key="status"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 capitalize font-semibold",
                        tableRowStyle.border,
                        tableRowStyle.background,
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    <span className=" text-sm h-full self-center">
                        {capitalize(health)}
                    </span>
                </td>
                <td
                    key="message"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 cursor-pointer",
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    <div className=" truncate-2-lines">{message}</div>
                </td>
                <EventTimeCol eventTime={timeStamp} drawerIsOpen={isOpen} />
            </tr>
            {isOpen ? (
                <tr className="text-sm bg-white border-1 border-gray-700 h-min w-full">
                    <td className="p-4 shadow-inner-md" colSpan={12}>
                        <div className="flex flex-col">
                            <span className="text-sm">{message}</span>
                        </div>
                    </td>
                </tr>
            ) : null}
        </>
    );
});
