import { RecommendedTemperature } from "@srtlabs/m1_types/lib/Zone/ZoneHVAC/ZoneHVACAttributes/ScheduledHVACStore/ScheduledHVACStore.type";
export default function calculateTemperature(
    recommendedTemperature: RecommendedTemperature,
    occupied: boolean,
): number {
    const heating = false;

    if (!occupied) {
        return heating
            ? recommendedTemperature.heatingUnoccupied
            : recommendedTemperature.coolingUnoccupied;
    } else {
        return heating
            ? recommendedTemperature.heatingOccupied
            : recommendedTemperature.heatingOccupied;
    }
}
