import { useEffect, useState } from "react";
import DeviceNotification from "types/Notification.type";
import filterNulls from "utils/filterNulls";
import TableNotificationItem from "../NotificationTableHeader/util/TableNotificationItem.type";
import useMappedNotifications from "./useMappedNotifications";

const errorTableItem: TableNotificationItem = {} as TableNotificationItem;
errorTableItem.message = "Unable to fetch notifications.";

/**
 *  Maps and transforms the fetched notifications into a specific format suitable for display (as TableNotificationItem),
 *  and manages the loading state. Primary purpose is to prepare data for UI display because types don't exactly match
 * @param notifications
 * @returns
 */
const useTableReadyNotifications = (
    notifications: DeviceNotification[],
): {
    notifications: TableNotificationItem[];
    loading: boolean;
    error: boolean;
    setError: React.Dispatch<React.SetStateAction<boolean>>;
} => {
    const { data, pendingFetchData } = useMappedNotifications(notifications);

    const [mappedNotifications, setMappedNotifications] = useState<
        TableNotificationItem[]
    >([]);

    const [isLoading, setIsLoading] = useState(true);
    const [error, setError] = useState(false);

    useEffect(() => {
        // Handle loading state based on pending data
        setIsLoading(pendingFetchData);
    }, [pendingFetchData]);

    useEffect(() => {
        // Process notifications only when data is available and not loading
        if (!pendingFetchData && data.length > 0) {
            try {
                setMappedNotifications(filterNulls(data));
            } catch (error) {
                console.error("Error processing notifications:", error);
                setMappedNotifications([errorTableItem]);
                setError(true);
            }
        }
    }, [data, pendingFetchData]);

    // Handle initial loading state
    useEffect(() => {
        if (pendingFetchData) {
            setIsLoading(true);
        }
    }, [pendingFetchData]);

    return {
        notifications: mappedNotifications,
        loading: isLoading,
        error,
        setError,
    };
};

export default useTableReadyNotifications;
