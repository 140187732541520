import useDynamicTableHeight from "hooks/useDynamicListHeight";
import { default as useURLQuery } from "hooks/useQuery/useQuery";
import { observer } from "mobx-react-lite";
import DeviceListContainer from "./DeviceListContainer/DeviceListContainer";
import ZoneListContainer from "./ZoneListContainer/ZoneListContainer";

export default observer(function Fleet(): JSX.Element {
    const { filter } = useURLQuery();

    // This hook runs whenever the items in the store change or the number of rows changes.
    // It checks whether the number of rows required to display all the items exceeds the maximum
    // number of rows that can fit in the container, and resizes the container accordingly.
    const { containerRef, tableRef } = useDynamicTableHeight(80);

    return (
        <div
            className="overflow-y-scroll relative mx-14 my-6 md:mb-6 border-1 border-gray-700"
            ref={containerRef}
        >
            <table
                className="table-auto border-collapse w-full min-w-[600px]"
                ref={tableRef}
            >
                {filter === "devices" ? (
                    <DeviceListContainer />
                ) : filter === "zones" ? (
                    <ZoneListContainer />
                ) : (
                    <tbody>
                        <tr className="text-center w-full h-full">
                            <td className="w-full p-8" colSpan={100}>
                                Option not selected.
                            </td>
                        </tr>
                    </tbody>
                )}
            </table>
        </div>
    );
});
