import { animated, easings, useSpring, useTransition } from "@react-spring/web";
import useClickOutside from "hooks/useClickOutside";
import useDevice from "hooks/useDevice.hook";
import { default as UseRouterQuery } from "hooks/useQuery/useQuery";
import { useResizeObserver } from "hooks/useResizeObserver";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useMemo, useState } from "react";
import { CLASS_NAMES } from "styles/classNameConstants";
import CurrentDeviceCardDrawer from "./CurrentDeviceCardDrawer/CurrentDeviceCardDrawer";
import {
    default as CurrentDeviceCardTab,
    default as DEVICE_DRAWER_TABS,
} from "./CurrentDeviceCardDrawer/CurrentDeviceCardTabs/utilities/DEVICE_DRAWER_TABS.enum";

export default observer(function CurrentDeviceCard(): JSX.Element {
    const { currentDevice, currentDrawerTab, setParams } = UseRouterQuery();
    const [currentTab, setCurrentTab] = useState(DEVICE_DRAWER_TABS.STATUS);
    const handleClickOutside = useCallback(() => {
        setParams({ currentDevice: null, currentDrawerTab: null });
    }, [setParams]);
    const { setRef, rootRef } = useClickOutside(
        handleClickOutside,
        () => null,
        "has-ignoreClass-device-drawer",
        Boolean(currentDevice),
    );
    const { width: observedDrawerWidth } = useResizeObserver(rootRef);

    const drawerWidth = useMemo(() => {
        return observedDrawerWidth || 871; //871px is the width of the static tabs PLUS the width of the drawer
    }, [observedDrawerWidth]);

    // When opening the drawer, the drawer will slide/fade in starting
    // at 0.5x drawer width. This makes it so the transition happens
    // quickly without feeling rushed or frantic
    const slideProps = useSpring({
        right: currentDevice ? 0 : -drawerWidth * 0.5,
        delay: 0,
        config: { duration: 200, easing: easings.easeOutQuad },
    });

    // Create a unique key that changes when the device changes
    const transitionKey = useMemo(
        () => (currentDevice ? `device-${currentDevice}` : null),
        [currentDevice],
    );

    // When transitioning from one device to another, the drawer will
    // have a swipe effect with the old device sliding out to the left
    // and the new device sliding in from the right
    const transitions = useTransition(transitionKey, {
        from: { opacity: 0, transform: "translateX(100px)" },
        enter: { opacity: 1, transform: "translateX(0px)" },
        leave: { opacity: 0, transform: "translateX(-100px)" },
        reverse: Boolean(currentDevice),
        config: { duration: 200 },
    });

    const { data: device } = useDevice({ deviceId: currentDevice });

    /**
     * Handles whatever the default tab will be when the device drawer is open.
     * Sets the last tab clicked by the user if available, otherwise the default tab
     * is @see {DEVICE_DRAWER_TABS.STATUS}
     */
    useEffect(() => {
        if (currentDrawerTab && currentDevice) {
            setCurrentTab(currentDrawerTab as CurrentDeviceCardTab);
        } else {
            setCurrentTab(DEVICE_DRAWER_TABS.STATUS);
        }
    }, [currentDrawerTab, currentDevice]);

    return transitions((transitionProps, transitionKey) => {
        return (
            transitionKey && (
                <animated.div
                    ref={setRef}
                    style={{
                        transform: transitionProps.transform,
                        ...slideProps,
                        opacity: transitionProps.opacity,
                    }}
                    className={CLASS_NAMES.drawer.drawerContainer}
                >
                    <CurrentDeviceCardDrawer
                        device={device || null}
                        currentTab={currentTab}
                        setCurrentTab={setCurrentTab}
                    />
                </animated.div>
            )
        );
    });
});
