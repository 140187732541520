import EventTimeCol from "components/Tables/Columns/EventTimeCol/EventTimeCol";
import { observer } from "mobx-react-lite";
import { useCallback, useEffect, useReducer, useState } from "react";
import EVENT_TYPE from "types/EVENT_TYPE";
import extendClassName from "utils/extendClassName";
import { initialLogState } from "utils/initialStates";
import ZoneLogsRowItemProps from "./utilities/ZoneLogsRowItem.props";
import setTableRowStyle from "./utilities/setTableRowStyleReduce";

export default observer(function ZoneLogsRowItem({
    message,
    time,
    id,
    event_type,
    source,
}: ZoneLogsRowItemProps): JSX.Element {
    //Determines the style of the table row item depending on it's
    //event type.
    const [tableRowStyle, dispatch] = useReducer(
        setTableRowStyle,
        initialLogState,
    );

    const [isOpen, setIsOpen] = useState(false);

    const [deviceStatus, setDeviceStatus] = useState<EVENT_TYPE | "offline">();

    //Handles the opening and closing of the click-to-expand area
    const handleToggle = useCallback(() => {
        setIsOpen(!isOpen);
    }, [isOpen]);

    /**
     * Sets styling of the table row depending on event type
     */
    useEffect(() => {
        const event = message.includes("offline")
            ? "offline"
            : (event_type as EVENT_TYPE);
        dispatch({ event_type: event });
    }, [event_type, message]);

    /**
     * Device status can change over time.
     */
    useEffect(() => {
        if (
            event_type === EVENT_TYPE.RECOMMENDATION ||
            event_type === EVENT_TYPE.WARNING
        ) {
            setDeviceStatus(EVENT_TYPE.WARNING);
            return;
        } else {
            //Why event offline?
            const event = message.includes("offline")
                ? "offline"
                : (event_type as EVENT_TYPE);
            setDeviceStatus(event);
        }
    }, [event_type, message]);

    return (
        <>
            <tr
                className={extendClassName(
                    "text-sm bg-white border-1 border-gray-700 h-[70px] w-full",
                    tableRowStyle.background,
                )}
                onClick={(): void => handleToggle()}
                data-id={id}
                key={id}
            >
                <td
                    key="status"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 capitalize font-semibold",
                        tableRowStyle.border,
                        tableRowStyle.background,
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    {deviceStatus}
                </td>
                <td
                    key="message"
                    className={extendClassName(
                        "p-4 h-min border-r-1 border-gray-700 cursor-pointer",
                    )}
                    style={{ boxSizing: "border-box" }}
                >
                    <div className=" truncate-2-lines">{message}</div>
                </td>

                <EventTimeCol eventTime={time} drawerIsOpen={isOpen} />
            </tr>
            {isOpen ? (
                <tr className="text-sm bg-white border-1 border-gray-700 h-min w-full">
                    <td className="p-4 shadow-inner-md" colSpan={12}>
                        {message.length >= 96 ? (
                            <p className="mb-1">{message}</p>
                        ) : null}
                        {source ? <p>Source: {source} </p> : null}
                        {!message && !source ? (
                            <p>There are no additional messages.</p>
                        ) : null}
                    </td>
                </tr>
            ) : null}
        </>
    );
});
