enum CURRENT_ZONE_HVAC_TAB {
    STATUS = "status",
    INFO = "info",
    SETTINGS = "settings",
    SCHEDULE = "schedule",
    EDITING_MAP_POSITION = "editing_map_position",
    EVENTS = "history",
    ALERTS = "alerts",
}

export default CURRENT_ZONE_HVAC_TAB;
