/**
 * Removes any falsey values from object
 * @param obj Any object
 * @returns
 */
export default function cleanObj(obj: Record<string, unknown>): {
    [k: string]: string;
} {
    return Object.fromEntries(
        Object.entries(obj).filter((keyValue) => keyValue[1]),
    ) as {
        [k: string]: string;
    };
}
