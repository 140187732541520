import { ReactNode, memo } from "react";

export default memo(function EventLogsTable({
    children,
}: {
    children: ReactNode;
}): JSX.Element {
    return (
        <table className="table-auto w-full h-min border-grey-700">
            <thead className="w-full">
                <tr className="h-min text-obsidian font-semibold text-left w-full">
                    <td className="p-4 border-1 border-gray-700 h-min w-[200px]">
                        Status
                    </td>
                    <td className="p-4 border-1 border-gray-700 h-min ">
                        Message
                    </td>
                    <td className="p-4 border-1 border-gray-700 h-min w-[220px]">
                        Time
                    </td>
                </tr>
            </thead>
            <tbody className="w-full">{children}</tbody>
        </table>
    );
});
